import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { Grid, IconButton, Typography } from '@mui/material';

type SelectedResponseCardType = {
  message: { message: string; timestamp?: string };
  chatName: string;
  removeItem: () => void;
};
const SelectedResponseCard = (props: SelectedResponseCardType) => {
  let data = props.message;
  let chatName = props.chatName;
  let removeItem = props.removeItem;
  return (
    <Grid
      container
      sx={{
        width: '400',
      }}
    >
      <IconButton
        onClick={removeItem}
        sx={{
          maxWidth: '24px',
          height: '24px',
          margin: '24px 16px',
        }}
      >
        <RemoveCircleOutlineOutlined
          sx={{
            maxWidth: '24px',
            height: '24px',
          }}
          color="error"
        />
      </IconButton>
      <Grid
        item
        sx={{
          border: 'solid 2px #F2F2F2',
          borderRadius: '8px',
          marginTop: '16px',
          marginBottom: '16px',
          marginRight: '24px',
          width: '300px',
          padding: '16px',
        }}
      >
        {data.message.length > 180
          ? data.message.slice(0, 180) + '...'
          : data.message}
        <Grid
          container
          item
          sx={{
            margin: '16px',

            justifyContent: 'space-between',
          }}
        >
          <IconButton>
            <FindInPageOutlinedIcon
              color="secondary"
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
          </IconButton>
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            textAlign={'right'}
            color={'#837F89'}
            fontFamily={'Graphik'}
            fontSize={14}
            fontWeight={400}
            lineHeight={'125%'}
            letterSpacing={'0.16px'}
          >
            <Typography marginRight={'16px'}>{chatName}</Typography>
            <Typography marginRight={'16px'}>
              {data.timestamp ? data.timestamp : '01 Jan 2022, 1:00pm'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectedResponseCard;
