import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Link, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import axios from 'api/axios';
import { CollectionContext } from 'components/CollectionProvider';
import CollectionNotFound from 'components/CollectionNotFound';
import ComponentLoading from 'components/ComponentLoading';
import Document from 'components/Document';
import { FilesContext } from 'components/FilesProvider';

/**
 * The individual document page component which should render all components to be displayed specific to this page
 */

interface IResponse {
  content: string;
  metadata: {
    file_name: string;
    extra_metadata: string;
    title: string;
    UID: string;
    page: number;
    chunk_id: number;
    index_time: number;
    source: string;
    id: string;
  };
}

interface IDocument extends Array<IResponse> {}

export interface IOrderedContent {
  startIndex: number;
  src?: string;
  imagePath?: string;
  text?: string;
}

export interface ChunkData extends IOrderedContent {
  chunkId: number;
}

interface IContent {
  rawContent: string;
  orderedContent?: IOrderedContent[];
}

export interface IChunk {
  pageId: number;
  chunkId: number;
  content: IContent;
}

export interface IChunkList extends Array<IChunk> {}

export default function DocumentPage(): React.JSX.Element {
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [document, setDocument] = useState<IChunkList>();
  const { collections, loadingCollections, getCollections } =
    useContext(CollectionContext);
  const { index, doc_name } = useParams();
  const currentCollection =
    collections && collections.find((item) => item === index);

  const { getFileList } = useContext(FilesContext);

  const updateFileList = useCallback(async () => {
    if (index) {
      await getFileList(index);
    }
  }, [index, getFileList]);

  useEffect(() => {
    if (!currentCollection || !doc_name) return;
    setLoadingDocument(true);

    axios({
      method: 'post',
      url: `/searchvectors`,
      data: {
        output: '*<metadata:*>',
        query: `@metadata:${doc_name}`,
        index: currentCollection,
      },
    })
      .then((response: AxiosResponse) => {
        setLoadingDocument(false);
        setDocument(() => {
          const data: IDocument = response.data;
          const formatedChunkList: IChunkList = data.map((chunk) => {
            return {
              pageId: chunk.metadata.page,
              chunkId: chunk.metadata.chunk_id,
              content: { rawContent: chunk.content },
            };
          });
          return formatedChunkList;
        });
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        setLoadingDocument(false);
      });
  }, [doc_name, currentCollection]);

  if (loadingCollections || loadingDocument) {
    // Show the loading interface
    return <ComponentLoading />;
  }

  if (currentCollection === undefined) {
    // Show the no collections interface
    return <CollectionNotFound getCollections={getCollections} />;
  }

  if (doc_name === undefined) {
    // Show the no document interface
    return (
      <Grid container>
        <Grid item xs={12} px={3} py={4}>
          <Typography variant="h2">Document Not Found</Typography>
        </Grid>
        <Grid item xs={12} px={3}>
          <Typography>
            Unable to find the specified document. It may have been moved or
            deleted. Please return to the{' '}
            <Link to={`/collection/${currentCollection}`}>
              {currentCollection}
            </Link>{' '}
            collection page and try finding the document to view again.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {document && doc_name && currentCollection && (
        <Document
          chunkList={document}
          doc_name={doc_name}
          index={currentCollection}
        />
      )}
    </>
  );
}
