import React, { useContext, useState, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AnalyticsOutlined from '@mui/icons-material/AnalyticsOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import PrivReq from 'api/axios';
import { SnackbarContext } from 'components/SnackbarProvider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ComponentLoading from 'components/ComponentLoading';
import { GraphIndustryContext } from 'components/GraphIndustryProvider';
import ConfigUserPermissions, {
  IConfigUserPermissions,
} from '../GraphsPage/RBAC/ConfigUserPermissions';

type DeleteGraphDialogProps = {
  graphName?: string;
  industryName?: string;
  open: boolean;
  setOpen: (value: boolean) => void;
};

export default function DeleteGraphDialog({
  open,
  setOpen,
  graphName,
  industryName,
}: Readonly<DeleteGraphDialogProps>): React.JSX.Element {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isEntireGraph, setIsEntireGraph] = useState(false);

  const createAlert = useContext(SnackbarContext);
  const { updateGraphs } = useContext(GraphIndustryContext);

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDeleteGraph = () => {
    setDeleteLoading(true);
    PrivReq({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      method: 'post',
      url: '/cleargraph',
      data: {
        graph_name: graphName,
        industry_name: industryName,
      },
    })
      .then((response: AxiosResponse) => {
        if (response.data.status.toLowerCase() === 'success') {
          createAlert({
            message: `'${graphName}' graph deleted`,
            severity: 'success',
          });
          setTimeout(() => {
            updateGraphs();
            setDeleteLoading(false);
            handleDialogClose();
            navigate(`/graphs`);
            createAlert({
              message: `'${graphName}' graph deleted`,
              severity: 'success',
            });
          }, 2000);
        } else {
          createAlert({
            message: `Unable to delete '${graphName}' graph`,
            severity: 'error',
          });
          setDeleteLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        setDeleteLoading(false);
        createAlert({
          message: `Unable delete '${graphName}' graph`,
          severity: 'error',
        });
      });
  };

  const handleDeleteEntireGraph = () => {
    setDeleteLoading(true);
    PrivReq({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      method: 'post',
      url: '/clearentiregraph',
      data: {
        graph_name: graphName,
      },
    })
      .then((response: AxiosResponse) => {
        if (response.data.status.toLowerCase() === 'success') {
          createAlert({
            message: `'${graphName}' graph and related industries deleted`,
            severity: 'success',
          });
          setTimeout(() => {
            updateGraphs();
            setDeleteLoading(false);
            handleDialogClose();
            createAlert({
              message: `'${graphName}' graph and related industries deleted`,
              severity: 'success',
            });
          }, 2000);
        } else {
          createAlert({
            message: `Unable to delete '${graphName}' graph`,
            severity: 'error',
          });
          setDeleteLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        createAlert({
          message: `Unable to delete '${graphName}' graph`,
          severity: 'error',
        });
        setDeleteLoading(false);
      });
  };

  useEffect(() => {
    !industryName && setIsEntireGraph(true);
  }, []);

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Delete graph: <b>{graphName}</b>
      </DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to delete this{' '}
          {isEntireGraph ? <b>entire</b> : ''} graph ?
        </p>
        <p style={{ color: '#CB2939' }}>This action cannot be undone</p>
        <p></p>
        <Grid container flexDirection="row" justifyContent="start">
          <Grid item>
            <AnalyticsOutlined
              sx={{
                color: '#B3B2B5',
                width: '65px',
                height: '65px',
              }}
            />
          </Grid>
          <Grid item>
            <p>
              Graph name: <b>{graphName}</b>
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      {deleteLoading && <ComponentLoading />}
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={isEntireGraph ? handleDeleteEntireGraph : handleDeleteGraph}
          disabled={deleteLoading}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * about the not mandatory graphName and setGraphToDelete:
 * They exist to handle when you have a list of buttons, and have to update state
 * to give to the DeleteGraphDialog the right graphName value
 *
 * See storybook
 */
type DeleteGraphButtonProps = {
  setOpen: (value: boolean) => void;
  graphName?: string;
  setGraphToDelete?: (graphName: string) => void;
  trashIcon?: boolean;
  trashIconColor?: 'primary' | 'secondary' | 'success' | 'info' | 'warning';
};

export function DeleteGraphButton({
  setOpen,
  trashIcon,
  trashIconColor,
  graphName,
  setGraphToDelete,
}: Readonly<DeleteGraphButtonProps>): React.JSX.Element {
  const permissions = ConfigUserPermissions();

  const [userPermissions, setUserPermissions] =
    useState<IConfigUserPermissions>({});

  const conditionalResponse = () => {
    if (!!graphName && !!setGraphToDelete) {
      setOpen(true);
      setGraphToDelete(graphName);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    setUserPermissions(permissions);
  }, []);

  return (
    <>
      {!trashIcon && userPermissions?.ALLOW_DELETE_GRAPH_BTN && (
        <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          Delete Graph
        </Button>
      )}
      {!!trashIcon && userPermissions?.ALLOW_DELETE_GRAPH_BTN && (
        <IconButton
          size="large"
          color={trashIconColor ?? 'error'}
          onClick={() => {
            conditionalResponse();
          }}
        >
          <DeleteOutlined
            color={trashIconColor ?? 'error'}
            fontSize="inherit"
          />
        </IconButton>
      )}
    </>
  );
}
