import React, { useState, useEffect, ChangeEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { grey } from 'theme';

import { listIndexesAPI } from 'api';
import { useAuthState } from 'components/AuthProvider';
import SecureTextField from 'components/SecureTextField';
import { containsSpecialChars } from 'common/utils/common';
import { DEFAULT_INDEX } from 'components/Page/ChatPage/ChatPage';
interface ChatInputInputProps {
  handleSubmit: (searchIndex: string, textInput: string) => void;
}

export const FloatingChatInput = styled(Grid)`
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 7px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const ChatInput: React.FC<ChatInputInputProps> = ({ handleSubmit }) => {
  const { accessToken } = useAuthState();
  const [searchIndex, setIndexInput] = useState(DEFAULT_INDEX);
  const [textInput, setTextInput] = useState<string>('');
  const [availableIndexes, setListIndex] = useState<string[]>([DEFAULT_INDEX]);
  const [message, setMessage] = useState<string>('');

  const handleIndexChange = (val: string | null) => setIndexInput(val || '');

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 1800) {
      setTextInput(event.target.value as string);
    }
  };

  const handleInvalidChars = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isNotValidString = containsSpecialChars(event.target.value);
    if (isNotValidString) {
      setMessage(`The following characters are not allowed: \\/:*”’<>|`);
      return (event.target.value = '');
    }
    setMessage('');
    if (event.target.value.length > 100) {
      setMessage('Character Limit cannot exceed 100 characters.');
      return (event.target.value = '');
    }
  };

  useEffect(() => {
    const initializeChat = async () => {
      const { data } = await listIndexesAPI(accessToken);
      if (data) {
        const indexesList = data.map(
          (entry: Record<'index_name', string>) => entry['index_name'],
        );
        setListIndex(indexesList);
      }
    };

    initializeChat();
  }, [accessToken]);

  return (
    <>
      <FloatingChatInput gap={1} bgcolor={grey[200]} p={0}>
        <SecureTextField
          multiline
          fullWidth
          id="outlined-basic"
          variant="outlined"
          value={textInput}
          //onUpdate={(value) => setTextInput(value)}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            handleInvalidChars(event);
            setTextInput(event.target.value);
          }}
          placeholder="Write prompts to harvest document insights..."
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') {
          //     handleSubmit(searchIndex, textInput);
          //   }
          // }}
          sx={{
            background: 'white',
            borderRadius: '5px',
            minHeight: '56px',
            margin: '8px 0',
          }}
          InputProps={{
            style: {
              minHeight: '56px',
              padding: '0 9px',
              background: 'white',
            },
          }}
        />

        <IconButton
          color="primary"
          size="large"
          onClick={() => {
            if (textInput !== '') {
              handleSubmit(searchIndex, textInput);
              setTextInput('');
            }
          }}
        >
          <SendIcon />
        </IconButton>
      </FloatingChatInput>
      <p style={{ color: '#CB2939', padding: '0 9px' }}>{message}</p>
    </>
  );
};

export default ChatInput;
