import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import { TableCellStyled, ExpandableContent } from './SavedSearches.styles';

interface ExpandableTableCellProps {
  children: React.ReactNode;
}

const ExpandableTableCell: React.FC<ExpandableTableCellProps> = ({
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [needsTruncation, setNeedsTruncation] = useState(false);
  const cellRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = cellRef.current;
    if (element) {
      const lineHeight = parseFloat(
        window.getComputedStyle(element).lineHeight,
      );
      const maxVisibleHeight = lineHeight * 6;
      setNeedsTruncation(element.scrollHeight > maxVisibleHeight);
    }
  }, [children]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <TableCellStyled>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ExpandableContent expanded={expanded} ref={cellRef}>
          {children}
        </ExpandableContent>
        {needsTruncation && (
          <Button
            size="small"
            onClick={toggleExpand}
            style={{ alignSelf: 'flex-end', fontSize: '10px' }}
          >
            {expanded ? '-collapse' : '+expand'}
          </Button>
        )}
      </div>
    </TableCellStyled>
  );
};

export default ExpandableTableCell;
