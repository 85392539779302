import React, { ChangeEvent, useState, useContext, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IConfigData } from '../interfaces';
import { SnackbarContext } from 'components/SnackbarProvider';
import SecureTextField from 'components/SecureTextField';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from '@mui/material';

type AddRowDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  subcategory: string;
  handleAddRow: (
    displayValue: string,
    actualKey: string,
    configValue: string,
  ) => void;
};

type TReq = { message: string };

export default function AddRowDialog({
  open,
  setOpen,
  subcategory,
  handleAddRow,
}: Readonly<AddRowDialogProps>): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');

  const [displayValue, setDisplayValue] = useState<string>('');
  const [actualKey, setActualKey] = useState<string>('');
  const [configValue, setConfigValue] = useState<string>('');
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [enableSendBTN, setEnableSendBTN] = useState<boolean>(false);

  const createAlert = useContext(SnackbarContext);

  const handleDialogClose = () => {
    cleanLocalState();
    setOpen(false);
  };

  const cleanLocalState = () => {
    setActualKey('');
    setConfigValue('');
    setDisplayValue('');
    setMessage('');
  };

  const handleSendData = () => {
    handleAddRow(displayValue, actualKey, configValue);
    handleDialogClose();
  };

  const handleChangeConfigValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setConfigValue(event.target.value as string);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Add a new row for {subcategory}:
        <Typography>Complete the main fields first:</Typography>
      </DialogTitle>
      <DialogContent>
        <SecureTextField
          autoFocus
          margin="dense"
          id="display_value"
          label="DISPLAY VALUE"
          placeholder="display value"
          type="text"
          fullWidth
          variant="outlined"
          value={displayValue}
          onUpdate={setDisplayValue}
        />

        <SecureTextField
          autoFocus
          margin="dense"
          id="actual_key"
          label="ACTUAL KEY"
          placeholder="actual key"
          type="text"
          fullWidth
          variant="outlined"
          value={actualKey}
          onUpdate={setActualKey}
        />

        <TextField
          autoFocus
          margin="dense"
          id="config_value"
          label="CONFIG VALUE"
          placeholder="config value"
          type="text"
          fullWidth
          variant="outlined"
          value={configValue}
          onChange={(event) => handleChangeConfigValue(event)}
        />

        {/* <FormControlLabel
                  control={<Checkbox />}
                  label="Check here to test regex in Config Value"
                  color="theme.pallete.secondary.main"
                  onClick={() => setCheckBox(!checkBox)}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleDialogClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={handleSendData}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
