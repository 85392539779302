import { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Grid,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';

import { INewDocument, SearchFilterItem } from 'types';
import SecureTextField from 'components/SecureTextField';
import { search_vector } from 'components/CollectionProvider';

export default function Searchbar({
  index,
  documents,
  automaticSearch,
  setLoadingSearch,
  setFilteredDocuments,
}: {
  index: string;
  documents: INewDocument[];
  automaticSearch?: boolean;
  setLoadingSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setFilteredDocuments: React.Dispatch<React.SetStateAction<INewDocument[]>>;
}) {
  const [query, setQuery] = useState('');
  const [searchTag, setSearchTag] = useState('similarity');

  const searchConfiguration: SearchFilterItem = {
    label: 'Search With',
    options: [
      {
        name: 'Similarity',
        value: 'similarity',
      },
      {
        name: 'Metadata',
        value: 'metadata',
      },
      {
        name: 'Content',
        value: 'content',
      },
    ],
  };

  const search = useCallback(
    async (controller?: AbortController) => {
      if (!query) {
        setFilteredDocuments(documents);
        setLoadingSearch(false);
        return;
      }

      setLoadingSearch(true);
      const result = (await search_vector(
        query,
        index,
        searchTag,
        controller,
      )) as string[];

      if (result === undefined) return;

      setFilteredDocuments(
        documents.filter((elem) =>
          result.find((item) => elem.doc_name.includes(item)),
        ),
      );
      setLoadingSearch(false);
    },
    [
      index,
      query,
      searchTag,
      documents,
      setLoadingSearch,
      setFilteredDocuments,
    ],
  );

  useEffect(() => {
    if (!automaticSearch) return;
    const controller = new AbortController();
    search(controller);
    return () => controller.abort();
  }, [automaticSearch, search]);

  return (
    <Grid container sx={{ width: '100%' }}>
      <FormControl sx={{ width: 180 }}>
        <InputLabel>{searchConfiguration.label}</InputLabel>
        <Select
          label={searchConfiguration.label}
          id="Search-Tag-Select"
          value={searchTag}
          onChange={(event) => setSearchTag(event.target.value)}
          sx={{ textAlign: 'left' }}
        >
          {searchConfiguration.options.map((elem) => (
            <MenuItem key={elem.value} value={elem.value}>
              {elem.value && elem.value[0].toUpperCase() + elem.value.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <SecureTextField
        htmlEntitiesMode
        label="Search"
        value={query}
        sx={{ width: '400px', mx: 1 }}
        onUpdate={(v) => setQuery(v)}
        onKeyDown={(e) => e.code === 'Enter' && search()}
      />
      <IconButton onClick={() => search()}>
        <SearchIcon />
      </IconButton>
    </Grid>
  );
}
