const getDate = (epoch: number) => {
  if (typeof epoch !== 'number') return epoch;
  const padL = (nr: number, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
  const date = new Date(epoch * 1000);
  const day = padL(date.getDate());
  const month = padL(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = padL(date.getHours());
  const minutes = padL(date.getMinutes());
  const seconds = padL(date.getSeconds());
  const formatedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formatedDate;
};

export default getDate;
