import React from 'react';

export const TxtIcon = () => {
  return (
    <svg viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M23.0455 8.00279C22.1433 8.00193 21.2782 7.6487 20.6401 7.02057C20.002 6.39244 19.6429 5.54069 19.6416 4.65216V0H4.44014C3.26253 0 2.13315 0.460706 1.30046 1.28075C0.467773 2.10078 0 3.21297 0 4.37268V30.7323C0 31.892 0.467773 33.0043 1.30046 33.8243C2.13315 34.6443 3.26253 35.105 4.44014 35.105H22.6892C23.8668 35.105 24.9961 34.6443 25.8288 33.8243C26.6615 33.0043 27.1293 31.892 27.1293 30.7323V8.00279H23.0455Z"
          fill="#0072FF"
        />
      </g>
      <path
        d="M27.1303 8.00279H23.0465C22.1443 8.00193 21.2792 7.6487 20.6411 7.02057C20.003 6.39244 19.6439 5.54069 19.6426 4.65216V0L27.1303 8.00279Z"
        fill="#0072FF"
      />
      <path
        d="M21.5498 13.6057H4.51358C4.35005 13.6053 4.19335 13.5411 4.07772 13.4273C3.96208 13.3134 3.89692 13.1591 3.89648 12.998C3.89648 12.8367 3.96143 12.6819 4.07711 12.5677C4.1928 12.4535 4.34977 12.3891 4.51358 12.3887H21.5498C21.7136 12.3891 21.8706 12.4535 21.9863 12.5677C22.102 12.6819 22.1669 12.8367 22.1669 12.998C22.1665 13.1591 22.1013 13.3134 21.9857 13.4273C21.8701 13.5411 21.7134 13.6053 21.5498 13.6057Z"
        fill="#0072FF"
      />
      <path
        d="M21.5498 17.2405H4.51358C4.35005 17.2401 4.19335 17.1759 4.07772 17.062C3.96208 16.9482 3.89692 16.7938 3.89648 16.6328C3.89648 16.4715 3.96143 16.3167 4.07711 16.2025C4.1928 16.0882 4.34977 16.0239 4.51358 16.0234H21.5498C21.7136 16.0239 21.8706 16.0882 21.9863 16.2025C22.102 16.3167 22.1669 16.4715 22.1669 16.6328C22.1665 16.7938 22.1013 16.9482 21.9857 17.062C21.8701 17.1759 21.7134 17.2401 21.5498 17.2405Z"
        fill="#0072FF"
      />
      <path
        d="M21.5498 20.8753H4.51358C4.34977 20.8748 4.1928 20.8105 4.07711 20.6962C3.96143 20.582 3.89648 20.4273 3.89648 20.2659C3.89692 20.1049 3.96208 19.9506 4.07772 19.8367C4.19335 19.7228 4.35005 19.6586 4.51358 19.6582H21.5498C21.7134 19.6586 21.8701 19.7228 21.9857 19.8367C22.1013 19.9506 22.1665 20.1049 22.1669 20.2659C22.1669 20.4273 22.102 20.582 21.9863 20.6962C21.8706 20.8105 21.7136 20.8748 21.5498 20.8753Z"
        fill="#0072FF"
      />
      <path
        d="M21.5498 24.5091H4.51358C4.34992 24.5091 4.19296 24.445 4.07724 24.3311C3.96151 24.2171 3.89648 24.0625 3.89648 23.9013C3.89648 23.74 3.96143 23.5853 4.07711 23.471C4.1928 23.3568 4.34977 23.2924 4.51358 23.292H21.5498C21.7136 23.2924 21.8706 23.3568 21.9863 23.471C22.102 23.5853 22.1669 23.74 22.1669 23.9013C22.1669 24.0625 22.1019 24.2171 21.9862 24.3311C21.8705 24.445 21.7135 24.5091 21.5498 24.5091Z"
        fill="#0072FF"
      />
      <path
        d="M30.3499 27.7227H9.71827C8.25477 27.7227 7.06836 28.891 7.06836 30.3323V36.39C7.06836 37.8313 8.25477 38.9997 9.71827 38.9997H30.3499C31.8134 38.9997 32.9998 37.8313 32.9998 36.39V30.3323C32.9998 28.891 31.8134 27.7227 30.3499 27.7227Z"
        fill="#0072FF"
      />
      <path d="M14.1175 34.6729V35.8542H12.875V34.6729H14.1175Z" fill="white" />
      <path
        d="M18.307 30.9678V31.9086H16.959V35.8539H15.7528V31.9086H14.418V30.9678H18.307Z"
        fill="white"
      />
      <path
        d="M21.8225 35.8539L20.7763 34.333L19.8771 35.8539H18.5026L20.0883 33.3385L18.4531 30.9678H19.8771L20.8951 32.4399L21.7729 30.9678H23.1408L21.5766 33.4279L23.2464 35.8539H21.8225Z"
        fill="white"
      />
      <path
        d="M27.2741 30.9678V31.9086H25.9277V35.8539H24.7199V31.9086H23.3867V30.9678H27.2741Z"
        fill="white"
      />
    </svg>
  );
};

export default TxtIcon;
