import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { IDeleteSubcategory } from '../interfaces';

export default function deleteConfigData({
  category,
  content,
}: IDeleteSubcategory) {
  const payload = {
    category,
    data: content,
  };

  type TReq = {
    message: string;
    status?: number;
  };

  const req: TReq = { message: '...' };

  const deleteReq = async () => {
    await PrivReq.post('/updateconfigurable/delete', {
      ...payload,
    })
      .then((response: AxiosResponse) => {
        req.status = response.status;
        req.message = response.data.status || 'rules deleted';
      })
      .catch((error) => {
        if (error.response) {
          req.status = error.response.status;
          req.message = error.response.data.status;
        } else {
          req.message = 'Could not retrieve error from server';
        }
      });
    return req;
  };

  return deleteReq();
}
