import React, { useCallback, useContext, useEffect } from 'react';
import { FormControlLabel, IconButton, Switch, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { pdf } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import { Document, ImageRun, Packer, Paragraph } from 'docx';
import { FileChild } from 'docx/build/file/file-child';

import PdfView from 'components/PdfView';
import { IOrderedContent } from 'components/Page/DocumentPage';
import { FilesContext } from 'components/FilesProvider';
import { Files } from 'types';
import useMetadataFile from '../../common/hooks/useMetadataFile';
import { UserProperties } from 'components/AppWrapper';
import LoadingButton from 'components/UI/LoadingButton';

type FileTopBarProps = {
  pdfView: boolean;
  htmlString: string | null;
  editFile: boolean;
  fullscreen: string;
  index: string;
  doc_name: string;
  currentChunk: IOrderedContent[];
  newDocVersion: boolean;
  loadingDraft: boolean;
  setNewDocVersion: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  setFullscreen: React.Dispatch<React.SetStateAction<string>>;
  handlePdfView: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditFile: () => void;
  setLeftPanelPercentWidth: React.Dispatch<React.SetStateAction<number>>;
  handleDeleteDraft: () => void;
};

export default function FileTopBar({
  pdfView,
  htmlString,
  editFile,
  fullscreen,
  index,
  doc_name,
  currentChunk,
  newDocVersion,
  loadingDraft,
  onSave,
  handleEditFile,
  handlePdfView,
  setFullscreen,
  setLeftPanelPercentWidth,
  handleDeleteDraft,
}: FileTopBarProps): React.JSX.Element {
  const user = useContext(UserProperties);
  const { dispatch, uploadFiles, setMetadatas, setMandatoryMetadatas } =
    useContext(FilesContext);
  const navigate = useNavigate();
  const { metadata, mandatoryMetadata } = useMetadataFile({ index, doc_name });

  const handleFullscreen = (screen: string) => {
    let screenPercentWidth = 50;
    setFullscreen((prev) => {
      if (prev === screen) return 'both';
      if (screen === 'sourceFile') screenPercentWidth = 100;
      if (screen === 'chunkFile') screenPercentWidth = 0;
      return screen;
    });
    setLeftPanelPercentWidth(screenPercentWidth);
  };

  const exportToDocx = async (currentChunk: IOrderedContent[]) => {
    const children: FileChild[] = [];
    for (const chunk of currentChunk) {
      chunk.text && children.push(new Paragraph(chunk.text));
      if (chunk.src) {
        const arrayBuffer = await fetch(chunk.src)
          .then((r) => r.blob())
          .then((r) => r.arrayBuffer());

        children.push(
          new Paragraph({
            children: [
              new ImageRun({
                data: arrayBuffer,
                transformation: {
                  width: 100,
                  height: 100,
                },
              }),
            ],
          }),
        );
      }
    }

    const doc = new Document({ sections: [{ children }] });

    let docxBlob: Blob | undefined = undefined;
    await Packer.toBlob(doc).then((blob) => {
      docxBlob = blob;
    });
    return docxBlob;
  };

  const exportToTxt = (currentChunk: IOrderedContent[]): Blob => {
    let text = '';
    currentChunk.forEach(
      (chunk) =>
        (text = chunk.text?.includes('\n')
          ? text + chunk.text
          : text + chunk.text + '\n'),
    );
    const blob = new Blob([text], {
      type: 'text/plain',
    });
    return blob;
  };

  const updateFile = useCallback(async () => {
    let blob: Blob | undefined;

    if (doc_name.endsWith('.pdf')) {
      blob = await pdf(PdfView({ currentChunk, getDocument: true })).toBlob();
    } else if (doc_name.endsWith('.txt') && currentChunk) {
      blob = exportToTxt(currentChunk);
    } else {
      blob = await exportToDocx(currentChunk);
    }

    if (!blob) return;

    const file = new File([blob], `${doc_name}`, {
      type: blob.type,
    });

    const filesProcessed: Files = { [file.name]: { file } };
    dispatch({ type: 'enableImport' });
    dispatch({ type: 'addFiles', payload: filesProcessed });
    uploadFiles(filesProcessed);
    handleDeleteDraft();
    navigate(`/collection/${index}`);
  }, [
    currentChunk,
    doc_name,
    index,
    dispatch,
    uploadFiles,
    navigate,
    handleDeleteDraft,
  ]);

  useEffect(() => {
    if (metadata) setMetadatas(metadata);
    if (mandatoryMetadata) setMandatoryMetadatas(mandatoryMetadata);
  }, [metadata, mandatoryMetadata, setMetadatas, setMandatoryMetadatas]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        borderBottom: '0.5px solid var(--grey-grey-500, #b3b2b5)',
      }}
    >
      <div
        style={{
          width: '100%',
          padding: 8,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <FormControlLabel
          value="pdf-view"
          control={
            <Switch
              checked={pdfView}
              onChange={handlePdfView}
              inputProps={{ 'aria-label': 'controlled' }}
              color="secondary"
            />
          }
          label={pdfView ? 'PDF view' : 'Raw view'}
          labelPlacement="start"
        />
        <div style={{ display: 'flex' }}>
          {!pdfView && (
            <>
              {!editFile ? (
                <>
                  {newDocVersion && (
                    <Button
                      color="secondary"
                      type="button"
                      onClick={updateFile}
                    >
                      Update Document
                    </Button>
                  )}
                  {user.role !== 'READ' && (
                    <IconButton onClick={handleEditFile}>
                      <EditIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                <LoadingButton
                  onClick={onSave}
                  color="secondary"
                  loading={loadingDraft}
                >
                  Save Draft
                </LoadingButton>
              )}
            </>
          )}
          <IconButton onClick={() => handleFullscreen('chunkFile')}>
            {fullscreen !== 'chunkFile' ? (
              <OpenInFullIcon />
            ) : (
              <CloseFullscreenIcon />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
}
