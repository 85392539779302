import { useState } from 'react';
import { IconButton } from '@mui/material';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import {
  CardContainer,
  ContentMessage,
  ExpandButton,
  PropertyText,
  Row,
} from './ReviewDocumentModalCard.styles';
import { SelectedMessagesInterface } from 'types';

type ReviewDocumentModalCardType = {
  message: SelectedMessagesInterface;
  removeFunction: (message: string) => void;
};

export const ReviewDocumentModalCard = ({
  message,
  removeFunction,
}: ReviewDocumentModalCardType) => {
  const [formattedMessage, setFormattedMessage] = useState<string>(
    message.message.length > 500
      ? message.message.slice(0, 500) + '...'
      : message.message,
  );

  return (
    <CardContainer elevation={3}>
      <Row style={{ alignSelf: 'end' }}>
        <PropertyText>{message.chatName}</PropertyText>
        <PropertyText>{message.timestamp}</PropertyText>
      </Row>
      <Row
        style={{
          alignItems: 'start',
          gap: '16px',
        }}
      >
        <IconButton
          style={{
            width: '24px',
            height: '24px',
          }}
          onClick={() => {
            removeFunction(message.message);
          }}
        >
          <RemoveCircleOutlineOutlined color="error" />
        </IconButton>
        <ContentMessage>{formattedMessage}</ContentMessage>
      </Row>
      <Row
        style={{
          alignSelf: 'end',
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Graphik',
        }}
      >
        {message.message && message.message.length > 500 && (
          <ExpandButton
            onClick={() => {
              setFormattedMessage(
                formattedMessage.length <= 503
                  ? message.message
                  : message.message.slice(0, 500),
              );
            }}
          >
            {formattedMessage.length <= 503 ? 'Expand' : 'Collapse'}
          </ExpandButton>
        )}
      </Row>
    </CardContainer>
  );
};
