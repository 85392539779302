import { IconButtonProps, IconButton, Tooltip } from '@mui/material';

type CustomIconButtonProps = IconButtonProps & {
  tooltip: string;
  icon: JSX.Element;
};

export const CustomIconButton = ({
  tooltip,
  icon,
  ...buttonProps
}: CustomIconButtonProps) => {
  return (
    <>
      {buttonProps.disabled ? (
        <IconButton {...buttonProps}>{icon}</IconButton>
      ) : (
        <Tooltip title={tooltip} arrow>
          <span>
            <IconButton {...buttonProps}>{icon}</IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default CustomIconButton;
