const AsteriskOutlined = (props: { title: string }) => {
  return (
    <svg
      version="1.1"
      id="svg2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 1200 1200"
      enableBackground="new 0 0 1200 1200"
      xmlSpace="preserve"
      fill="#ffffff"
      stroke="#ffffff"
    >
      <title>{props.title}</title>
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#000000"
        strokeWidth="45.6"
      >
        {' '}
        <path
          id="path3756"
          d="M489.838,29.354v443.603L68.032,335.894L0,545.285l421.829,137.086 l-260.743,358.876l178.219,129.398L600.048,811.84l260.673,358.806l178.146-129.398L778.101,682.465L1200,545.379l-68.032-209.403 l-421.899,137.07V29.443H489.84L489.838,29.354z"
        ></path>{' '}
      </g>
    </svg>
  );
};

export default AsteriskOutlined;
