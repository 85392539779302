import {
  createContext,
  useState,
  ReactNode,
  FC,
  useContext,
  useEffect,
} from 'react';

import axios from 'api/axios';
import { PromptData } from 'components/ChatEndpointsForm/ChatEndpointsForm';
import { SnackbarContext } from 'components/SnackbarProvider';

import { showError } from 'api';

export interface SavedSearch {
  id: string;
  name?: string; // API name
  prompt_title?: string;
  prompt_objective?: string;
  prompt_suffix?: string;
  prompt_prefix?: string;
  prompt_template?: string;
  user_name?: string;
}

interface SavedSearchesContextType {
  savedSearches: SavedSearch[];
  addSavedSearch: (search: SavedSearch) => void;
  deleteSavedSearch: (ids: string[]) => void;
  updateSavedSearch: (id: string, updatedSearch: SavedSearch) => void;
  chatEndpoint: Partial<SavedSearch>;
  chatModalEndpoint: Partial<SavedSearch>;
  setModalChatEndpoint: (endpoint: Partial<SavedSearch>) => void;
  selectedOption: string | null;
  setSelectedOption: (option: string) => void;
  savePromptData: (apiType: string, data: SavedSearch) => Promise<void>;
  getSavedPrompts: (apiType: string) => Promise<PromptData[]>;
  endpointType: string;
  setEndpointType: (apiType: string) => void;
  handleDeleteAllPrompts: () => void;
  apiTypes: string[];
}

const SavedSearchesContext = createContext<
  SavedSearchesContextType | undefined
>(undefined);

export const SavedSearchesProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const [chatEndpoint, setChatEndpoint] = useState<Partial<SavedSearch>>({});
  const [chatModalEndpoint, setModalChatEndpoint] = useState<
    Partial<SavedSearch>
  >({});
  const [selectedOption, setSelectedOption] = useState<string | null>('');
  const [endpointType, setEndpointType] = useState('search');
  const createAlert = useContext(SnackbarContext);
  const deleteApiTypes = ['chatcompletion', 'search', 'searchandgenerate'];
  const apiTypes = [
    'chatcompletion',
    'search',
    'searchvectors',
    'searchandgenerate',
  ];

  const apiSaveEndpoints: Record<string, string> = {
    search: '/prompt/search/save',
    chatcompletion: '/prompt/chatcompletion/save',
    searchandgenerate: '/prompt/searchandgenerate/save',
  };

  const savePromptData = async (apiType: string, data: SavedSearch) => {
    try {
      const endpoint = apiSaveEndpoints[apiType];
      if (!endpoint) {
        console.error(`Endpoint not found for apiType: ${apiType}`);
        showError(new Error(`Endpoint not found for apiType: ${apiType}`));
        return;
      }
      await axios.post(endpoint, data);
    } catch (error) {
      showError(error);
    }
  };

  const getSavedPrompts = async (apiType: string) => {
    try {
      const response = await axios.post(
        `/prompt/${apiType}/get_prompts`,
        {},
        {
          headers: {
            apiToken: process.env.API_TOKEN,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      showError(error);
    }

    return null;
  };

  useEffect(() => {
    const loadPrompts = async () => {
      try {
        const searchPrompts: PromptData[] = await getSavedPrompts('search');
        const chatCompletionPrompts: PromptData[] =
          await getSavedPrompts('chatcompletion');
        const searchAndGeneratePrompts: PromptData[] =
          await getSavedPrompts('searchandgenerate');

        const allPrompts = [
          ...searchPrompts.map((prompt) => ({
            ...prompt,
            id: prompt.id || crypto.randomUUID(),
          })),
          ...chatCompletionPrompts.map((prompt) => ({
            ...prompt,
            id: prompt.id || crypto.randomUUID(),
          })),
          ...searchAndGeneratePrompts.map((prompt) => ({
            ...prompt,
            id: prompt.id || crypto.randomUUID(),
          })),
        ];
        setSavedSearches(allPrompts);
      } catch (error) {}
    };

    loadPrompts();
  }, []);

  const addSavedSearch = (search: SavedSearch) => {
    setSavedSearches((prev) => {
      const updatedSearches = [
        { ...search, id: `${crypto.randomUUID()}` },
        ...prev,
      ];
      // localStorage.setItem('savedSearches', JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  const handleDeleteAllPrompts = async () => {
    try {
      let successCount = 0;
      let failureCount = 0;

      for (const apiType of deleteApiTypes) {
        const requestBody = {
          user: 'quasar.user',
          delete_all: 'true',
        };

        try {
          const response = await axios.delete(`/prompt/${apiType}`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: requestBody,
          });

          if (response.status === 200) {
            successCount++;
          } else {
            failureCount++;
          }
        } catch (error) {
          failureCount++;
        }
      }

      if (successCount === deleteApiTypes.length) {
        createAlert({
          message: `Successfully deleted all prompts.`,
          severity: 'success',
        });
      } else if (failureCount > 0) {
        createAlert({
          message: `Failed to delete prompts for ${failureCount}.`,
          severity: 'error',
        });
      }

      setSavedSearches([]);
    } catch (error) {
      showError(error);
    }
  };

  const deleteSavedSearch = (ids: string[]) => {
    setSavedSearches((prevSearches) => {
      const updatedSearches = prevSearches.filter(
        (search) => !ids.includes(search.id),
      );
      localStorage.setItem('savedSearches', JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  const updateSavedSearch = (id: string, updates: Partial<SavedSearch>) => {
    setSavedSearches((prev) => {
      const updatedSearches = prev.map((search) =>
        search.id === id ? { ...search, ...updates } : search,
      );
      localStorage.setItem('savedSearches', JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  return (
    <SavedSearchesContext.Provider
      value={{
        savedSearches,
        addSavedSearch,
        deleteSavedSearch,
        updateSavedSearch,
        chatEndpoint,
        chatModalEndpoint,
        setModalChatEndpoint,
        selectedOption,
        setSelectedOption,
        savePromptData,
        getSavedPrompts,
        setEndpointType,
        endpointType,
        apiTypes,
        handleDeleteAllPrompts,
      }}
    >
      {children}
    </SavedSearchesContext.Provider>
  );
};

export const useSavedSearches = () => {
  const context = useContext(SavedSearchesContext);
  if (!context) {
    throw new Error(
      'useSavedSearches must be used within a SavedSearchesProvider',
    );
  }
  return context;
};
