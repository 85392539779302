import { Autocomplete, Box, Button, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { Job } from 'types';
import JobCard from 'components/JobCard';
import { harcodedJobs } from './harcodedData';
import { Subtitle, Title } from './JobsPage.styles';
import SecureTextField from 'components/SecureTextField';
import ComponentLoading from 'components/ComponentLoading';
import { SnackbarContext } from 'components/SnackbarProvider';
import { getFequentTask } from 'api';
import NothingToSeeHere from 'components/NothingToSeeHere';

const JobsPage = () => {
  const navigate = useNavigate();
  const createAlert = useContext(SnackbarContext);
  const [loading, setLoading] = useState(true);
  const [jobList, setJobList] = useState<null | Job[]>(harcodedJobs);
  const [filteredJob, setFilteredJob] = useState<Job | null>(null);

  const navigateToJobUpload = () => navigate('/job-upload');
  const navigateToDocGen = () => navigate('/taskgen-pro');

  const getJobList = useCallback(() => {
    const onError = () => {
      createAlert({
        message: `There was a problem trying to get the jobs`,
        severity: 'error',
      });
    };

    const onLoading = (loading: boolean) => setLoading(loading);

    getFequentTask({ list: 'jobs', onSuccess: setJobList, onError, onLoading });
  }, [createAlert]);

  useEffect(() => getJobList(), [getJobList]);

  if (!jobList) return <div>Something went wrong!</div>;

  return (
    <Grid container height="100%" flexDirection="column" wrap="nowrap">
      <Grid
        container
        wrap="nowrap"
        borderBottom="1px solid #B3B2B5"
        height="80px"
        px={4}
        py={3}
        boxSizing={'border-box'}
        alignItems="center"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <IconButton
            aria-label="back"
            color="inherit"
            onClick={navigateToDocGen}
          >
            <ArrowBack />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Subtitle>TaskGen Pro</Subtitle>
            <Title>TaskGen Pro</Title>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Autocomplete
            disabled={loading || jobList.length < 1}
            size="small"
            sx={{ m: 1, width: 300 }}
            id="autocomplete-index-select"
            value={filteredJob}
            options={jobList}
            getOptionLabel={(option: Job) => option.name}
            onChange={(e, value) => setFilteredJob(value)}
            renderInput={(params) => (
              <SecureTextField
                {...params}
                label="Search"
                variant="outlined"
                style={{ background: 'white', borderRadius: '5px' }}
              />
            )}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            color="primary"
            variant="outlined"
            component={Link}
            to={`/task-upload`}
          >
            Create Task
          </Button>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/job-upload`}
            startIcon={<AddIcon />}
          >
            Create Job
          </Button>
        </Box>
      </Grid>
      <Grid
        container
        item
        sx={{
          display: 'flex',
          flexGrow: 1,
          padding: '36px',
          overflowY: 'auto',
          height: 'calc(100vh - 204px)',
          flexDirection: 'column',
          backgroundColor: '#FAFAFA',
        }}
      >
        {loading ? (
          <ComponentLoading />
        ) : (
          <>
            {jobList.length < 1 ? (
              <NothingToSeeHere
                docGenPage
                currentIndex={undefined}
                elementToAdd={'job'}
                handleAddButton={navigateToJobUpload}
                handleRefreshButton={getJobList}
              />
            ) : (
              <Grid item container spacing={2}>
                {filteredJob ? (
                  <JobCard job={filteredJob} />
                ) : (
                  <>
                    {jobList.map((job, idx) => (
                      <JobCard key={idx} job={job} />
                    ))}
                  </>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default JobsPage;
