import { Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)`
  font-family: Graphik;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 8px;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: '#333333',
  fontWeight: 'bold'
`;
