import styled from 'styled-components';

export const BlockCodeContainer = styled('div')`
  span {
    &::-webkit-scrollbar {
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #888;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background: #dedede;
    }
  }
`;
