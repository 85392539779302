import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useState } from 'react';

type CustomProps = {
  onUpdate?: (
    value: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value?: number | null;
  max?: number;
};
type SecureNumberFieldProps = TextFieldProps & CustomProps;

export default function SecureNumberField({
  value,
  onUpdate,
  max,
  ...props
}: SecureNumberFieldProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = +event.target.value;

    if (max && inputValue > max) {
      return setErrorMessage(`Length exceeded. The maximum length is ${max}`);
    }

    if (!max && inputValue > 400) {
      return setErrorMessage('Length exceeded. The maximum length is 400');
    }

    if (onUpdate) onUpdate(inputValue, event);
    return setErrorMessage(null);
  };

  return (
    <TextField
      {...props}
      type="number"
      value={value}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => handleChange(event)}
      error={errorMessage ? true : false}
      helperText={errorMessage}
    />
  );
}
