import axios from 'api/axios';

// Your API call function
const fetchImageFromApi = async (url: string) => {
  try {
    const urlPattern = /v2\/acnopenai\/(.*)/;
    const match = url.match(urlPattern);
    const response = await axios.get(`${match ? match[1] : ''}`, {
      headers: {
        apiToken: process.env.API_TOKEN,
        'Content-Type': 'application/json',
      },
      responseType: 'blob', // Set response type to blob
    });

    if (response.status === 200) {
      const blobUrl = URL.createObjectURL(response.data);
      return blobUrl; // Return the blob URL
    }
  } catch (error) {
    console.error('Error fetching image:', error);
  }
  return null; // Return null if there's an error
};

export const getFormattedMessage = async (message: string) => {
  const codeIndexRegex = new RegExp(`\`\`\``, 'g');
  const indicesSearch = Array.from(message.matchAll(codeIndexRegex));
  let allCodeIndices: number[] = [];
  indicesSearch.forEach((code, i) =>
    code.index === undefined
      ? allCodeIndices
      : allCodeIndices.push(i % 2 === 0 ? code.index - 1 : code.index + 3),
  );

  const messageStart = 0;
  const firstCodeIndex = allCodeIndices[0];
  const messageEnd = message.length - 1;
  const lastCodeIndex = allCodeIndices[allCodeIndices.length - 1];
  if (firstCodeIndex !== messageStart) allCodeIndices.unshift(messageStart);
  if (lastCodeIndex !== messageEnd) allCodeIndices.push(messageEnd);

  const codeIndices = allCodeIndices.flatMap((v, i) =>
    i < allCodeIndices.length - 1 && i !== 0 ? [v, v] : v,
  );

  const sliceIndices = Array.from(
    { length: Math.ceil(codeIndices.length / 2) },
    (v, i) => codeIndices.slice(i * 2, i * 2 + 2),
  );

  const formattedMessages = await Promise.all(
    sliceIndices.map(async (indices) => {
      const start = indices[0];
      const end = indices[1] + 1;
      const string = message.slice(start, end);
      const undefinedProperties = {
        text: undefined,
        code: undefined,
        table: undefined,
        images: [] as {
          url: string;
          thumbnail: string;
          key: string;
          imageData: string | null;
        }[],
      };

      const isCode = string.includes('```') && string;
      if (isCode) {
        const codeMessage = {
          ...undefinedProperties,
          code: string,
          key: `${crypto.randomUUID()}`,
        };

        const isTable = string.includes('```table');
        if (!isTable) return codeMessage;

        const start = '```table\n'.length;
        const end = string.lastIndexOf('\n```\n');
        const tableContent = JSON.parse(string.slice(start, end));
        const tableMessage = {
          ...undefinedProperties,
          table: tableContent,
          key: `${crypto.randomUUID()}`,
        };

        return tableMessage;
      } else {
        const textMessage = {
          ...undefinedProperties,
          text: string,
          key: `${crypto.randomUUID()}`,
        };

        // Extract URLs for images
        const urlRegex = /https?:\/\/[^\s]+\.(jpg|jpeg|png|gif|bmp|webp|svg)/gi; // Matches http and https URLs
        const urls = string.match(urlRegex) || [];

        // Fetch image data for each URL and replace in text
        for (const url of urls) {
          const imageData = await fetchImageFromApi(url); // Use your API call function
          if (imageData) {
            const imageMessage = {
              url,
              thumbnail: url, // Adjust for thumbnails as needed
              key: `${crypto.randomUUID()}`,
              imageData, // Store the actual image data
            };
            textMessage.images.push(imageMessage);
            // Replace URL in text with a placeholder
            textMessage.text = textMessage.text?.replace(url, '[Image]');
          }
        }

        return textMessage;
      }
    }),
  );

  return formattedMessages;
};
