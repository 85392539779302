import useMultiUploadFormConfig from 'common/hooks/useMultiUploadForm';
import ComponentLoading from 'components/ComponentLoading';
import { ConfirmationDialog } from 'components/UI';

import UploadSourceForm from 'components/UploadSourceForm';
import { useEffect, useState } from 'react';
import { FormValues, Source } from 'types';

interface MultiuploadSettingsProps {
  index: string;
}

const MultiuploadSettings = ({ index }: MultiuploadSettingsProps) => {
  const {
    loading,
    formConfig,
    subCategories,
    getConfigList,
    removeConfiguration,
  } = useMultiUploadFormConfig({
    index,
  });
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [configToDelete, setConfigToDelete] = useState<FormValues | null>(null);
  const [configToDeleteIndex, setConfigToDeleteIndex] = useState<string | null>(
    null,
  );

  useEffect(() => getConfigList(), [getConfigList]);

  const handleClose = () => {};

  if (loading) return <ComponentLoading />;

  return (
    <>
      {subCategories &&
        formConfig &&
        Object.values(formConfig).map((config, idx) => {
          const inputs = config.map((i) => i.formInputs);
          const values = config.map((i) => i.formValues);
          const selectedSource = values.map((i) => i.source_type)[0] as Source;

          const handleDelete = () => {
            if (configToDelete && configToDeleteIndex !== null) {
              const { key, source_type, config_name } = configToDelete;
              const configData = {
                key,
                source_type,
                index: configToDeleteIndex,
                config_name,
              };
              removeConfiguration(configData);
              setOpenConfirmationDialog(false);
              setConfigToDelete(null);
              setConfigToDeleteIndex(null);
            } else {
              setOpenConfirmationDialog(false);
              setConfigToDelete(null);
              setConfigToDeleteIndex(null);
            }
          };

          return (
            <>
              <UploadSourceForm
                key={index}
                index={index}
                inputs={inputs}
                initialFormValues={values}
                selectedSource={selectedSource}
                handleClose={handleClose}
                removeConfiguration={() => {
                  const config_name = values.map((i) => i.config_name)[0];
                  setConfigToDeleteIndex(index);
                  setConfigToDelete({ ...values[0], config_name });
                  setOpenConfirmationDialog(true);
                }}
              />
              <ConfirmationDialog
                open={openConfirmationDialog}
                title="Delete source"
                content={
                  <>
                    <p>Are you sure you want to delete this configuration?</p>
                  </>
                }
                leftButtonProps={{ name: 'Delete', onClick: handleDelete }}
                rightButtonProps={{
                  name: 'Cancel',
                  onClick: () => setOpenConfirmationDialog(false),
                }}
              />
            </>
          );
        })}
    </>
  );
};

export default MultiuploadSettings;
