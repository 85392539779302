import { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormControl } from '@mui/material';
import { Dropdown } from '@mui/base/Dropdown';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import {
  ButtonGroupStyled,
  ButtonStyled,
  ListContainerStyled,
} from './ButtonGroup/ButtonGroup.styles';
import { Files } from 'types';
import { FilesContext } from 'components/FilesProvider';
import { ListOfValues, FieldValues } from 'types';
import SelectComponent from './SelectComponent';
import UploadDataGridTableProvider from 'components/UploadDataGridTableProvider';
import StyledButton from './ButtonGroup/StyledButton';
import { UploadTable } from './UploadDataGridTable.styles';
import { getIndexMetadatas } from 'components/MetadataProvider/MetadaProvider';
import ComponentLoading from 'components/ComponentLoading';

export interface Data {
  name: string;
  [key: string]: string;
}
interface UploadDataGridTableProps {
  files: Files;
  index: string;
}

export default function UploadDataGridTable({
  files,
  index,
}: UploadDataGridTableProps) {
  const {
    state,
    mandatoryMetadatas,
    removeFile,
    enableImport,
    setMetadatas,
    setMandatoryMetadatas,
  } = useContext(FilesContext);

  const navigate = useNavigate();
  const [data, setData] = useState<Data[]>();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [fields, setFields] = useState<ListOfValues>();
  const showButtonGroup = selectedRows.length > 0;

  const goToSettings = useCallback(
    () => navigate(`/collection/${index}/settings`),
    [index, navigate],
  );

  const handleClose = () => setSelectedRows([]);

  const handleDelete = () => {
    if (selectedRows.length > 0 && data) {
      selectedRows.forEach((e, i) => {
        const rowToDelete = parseInt(selectedRows[i] as string);
        removeFile(data[rowToDelete].name);
        setData((prev) =>
          prev?.filter((e) => e.name !== data[rowToDelete].name),
        );
      });
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getIndexMetadatas(index);
      let mandatoryMetadata =
        response &&
        response.mandatory_metadata &&
        Object.keys(response.mandatory_metadata).length > 0
          ? Object.keys(response.mandatory_metadata)
          : [];
      setMandatoryMetadatas(mandatoryMetadata);

      const fieldValues: any = await UploadDataGridTableProvider(index);
      setFields(fieldValues);

      setData(
        Object.keys(files).map((fileName) => {
          let newColumns: { [key: string]: string[] } = {};
          fieldValues?.values.forEach((e: FieldValues) => {
            newColumns[e.FIELD_NAME] =
              mandatoryMetadata.includes(e.FIELD_NAME) && e.OPTIONS.length === 1
                ? [e.OPTIONS[0]]
                : [];
          });
          return { name: fileName, ...newColumns };
        }),
      );

      enableImport();
    };
    fetchData();
  }, [index, files, enableImport, setMandatoryMetadatas]);

  const rows = useMemo(
    () =>
      data?.map((e, i) => {
        const row: { [key: string]: string | number } = {
          id: i,
          doc_name: e.name,
        };
        fields?.values.forEach(
          (e: FieldValues) => (row[e.FIELD_NAME] = 'Select'),
        );
        return row;
      }),
    [data, fields],
  );

  const columns = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'doc_name',
        headerName: 'Document Name',
        sortable: true,
        headerClassName: 'table-header',
        minWidth: 300,
      },
    ];

    fields?.values.forEach((e: FieldValues) => {
      columns.push({
        field: e.FIELD_NAME,
        headerName: `${e.SUBCATEGORY} ${
          mandatoryMetadatas.includes(e.FIELD_NAME) ? ' *' : ''
        }`,
        flex: 1,
        headerClassName: 'table-header',
        cellClassName: 'table-select-cell',
        renderCell: (props) => (
          <SelectComponent
            data={e.OPTIONS}
            label={'Select'}
            func={setData}
            rowProps={props}
            selectedProperties={data}
            globalFunction={setMetadatas}
          />
        ),
      });
    });

    columns.push({
      field: 'AddColumn',
      minWidth: 125,
      sortable: false,
      renderHeader: () => <Button onClick={goToSettings}>+ Add Column</Button>,
      disableColumnMenu: true,
    });

    return columns;
  }, [data, fields, mandatoryMetadatas, setMetadatas, goToSettings]);

  if (state.importDisabled || !columns || !rows || !data) {
    return <ComponentLoading />;
  }

  return (
    <UploadTable>
      <FormControl fullWidth={true}>
        <DataGrid
          onRowSelectionModelChange={(selectedRows: GridRowSelectionModel) => {
            setSelectedRows(selectedRows);
          }}
          columns={columns}
          rows={rows}
          checkboxSelection
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 15]}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F2F2F2',
              color: 'black',
              fontSize: 16,
              fontFamily: 'Graphik',
              fontWeight: '600',
              lineHeight: 20,
              wordWrap: 'break-word',
            },
            '& .Mui-checked': {
              color: '#333333',
            },
            '& .Mui-selected': {
              backgroundColor: '#F2F2F2 !important',
              borderColor: `'#F2F2F2 !important'`,
            },
            '& .table-select-cell': {
              color: '#0041F0',
              padding: '10px',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .table-select-cell:focus': {
              color: '#03000F',
              backgroundColor: '#F2F2F2',
            },
            '& .table-select-cell:hover': {
              color: '#03000F',
              backgroundColor: '#F2F2F2',
            },
          }}
          getRowHeight={() => 'auto'}
        />
      </FormControl>
      {showButtonGroup && (
        <ListContainerStyled>
          <ButtonGroupStyled>
            <Dropdown>
              <ButtonStyled color="#CB2939" onClick={handleDelete}>
                Delete
              </ButtonStyled>
            </Dropdown>
            {fields?.values.map((e) => (
              <StyledButton
                key={e.FIELD_NAME}
                data={data}
                options={e.OPTIONS}
                fieldName={e.FIELD_NAME}
                subcategory={e.SUBCATEGORY}
                selectedRows={selectedRows}
                setData={setData}
                setMetadatas={setMetadatas}
              />
            ))}
            <ButtonStyled onClick={handleClose}>Close</ButtonStyled>
          </ButtonGroupStyled>
        </ListContainerStyled>
      )}
    </UploadTable>
  );
}
