import { Box, IconButton } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';

import { ApiIcon } from '../Page/ChatPage/Assets/ApiIcon';
import ConfigurationButton from 'components/ChatView/ConfigurationButton';
import { useSavedSearches } from 'common/utils/savedSearchesContext';

export interface PopoverButtons {
  chatEndpoint: string;
  outputFormat: string;
}

interface PopoverButton {
  name: keyof PopoverButtons;
  icon: React.ReactElement;
  type?: string;
  title: string;
  description: string;
  options: string[];
}

interface PopoverListProps {
  popoverButtons: PopoverButtons;
  disabledButtons?: {
    [key: string]: boolean;
  };
  setOpenEndpointForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelect: ({ name, value }: { name: string; value: string }) => void;
}

const popoverButtonsList: PopoverButton[] = [
  {
    name: 'chatEndpoint',
    description: 'Change the API used to format the output',
    icon: <ApiIcon />,
    options: [
      'Chat Completion',
      'Search',
      'Search Vectors',
      'Search And Generate',
    ],
    title: 'API Selected',
  },
  {
    name: 'outputFormat',
    description: 'Change the desired output format of the answer',
    icon: <ListIcon sx={{ width: '30px', height: '30px' }} />,
    options: ['Standard Paragraph', 'Bulleted List', 'Numbered List', 'Table'],
    title: 'Output Style',
    type: 'radio',
  },
];

function PopoverList({
  popoverButtons,
  disabledButtons,
  setOpenEndpointForm,
  handleSelect,
}: PopoverListProps) {
  const { setSelectedOption } = useSavedSearches();

  const handleSelectWithContext = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    handleSelect({ name, value });

    setSelectedOption(value);
  };

  return (
    <Box display={'flex'} pt={1}>
      <Box display="flex" justifyContent="right" width="400px" gap="8px">
        {popoverButtonsList.map((button, index) => (
          <ConfigurationButton
            key={button.name}
            name={button.name}
            icon={button.icon}
            title={button.title}
            description={button.description}
            type={button.type}
            options={button.options}
            value={popoverButtons[button.name]}
            onSelect={handleSelectWithContext}
            disabled={
              disabledButtons &&
              disabledButtons[button.name as keyof PopoverButtons]
            }
          />
        ))}
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <IconButton onClick={() => setOpenEndpointForm(true)}>
          <SettingsIcon sx={{ width: '30px', height: '30px' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default PopoverList;
