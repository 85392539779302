import { Grid } from '@mui/material';

import DocGenPageHeader from 'components/DocGenPageHeader';
import JobListPreview from 'components/JobListPreview';
import { useEffect, useMemo, useState } from 'react';
import { Job, TableData } from 'types';
import TaskTable from 'components/TaskTable';
import ComponentLoading from 'components/ComponentLoading';
import NothingToSeeHere from 'components/NothingToSeeHere';
import { useNavigate } from 'react-router-dom';

export default function DocGenerationPage(): React.JSX.Element {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [jobList, setJobList] = useState<null | Job[]>(null);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [taskList, setTaskList] = useState<TableData[] | null>(null);
  const isEmpty = useMemo(
    () =>
      !loadingJobs &&
      jobList &&
      jobList.length < 1 &&
      !loadingTasks &&
      taskList &&
      taskList.length < 1,
    [jobList, loadingJobs, taskList, loadingTasks],
  );

  useEffect(() => {
    refresh && setRefresh(false);
  }, [refresh]);

  return (
    <Grid container height="100%" flexDirection="column" wrap="nowrap">
      <DocGenPageHeader />
      <Grid
        container
        item
        sx={{
          flexWrap: 'nowrap',
          display: 'flex',
          flexGrow: 1,
          padding: '16px',
          overflowY: 'auto',
          height: 'calc(100vh - 204px)',
          backgroundColor: '#FAFAFA',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {isEmpty && (
          <NothingToSeeHere
            elementToAdd="task"
            currentIndex={undefined}
            docGenPage
            handleAddButton={() => navigate('/task-upload')}
            handleRefreshButton={() => setRefresh(true)}
          />
        )}
        {!refresh && (
          <>
            <JobListPreview
              jobList={jobList}
              loadingJobs={loadingJobs}
              setJobList={setJobList}
              setLoading={setLoadingJobs}
            />
            <TaskTable
              taskRows={taskList}
              loadingTasks={loadingTasks}
              setTaskRows={setTaskList}
              setLoadingTasks={setLoadingTasks}
            />
          </>
        )}
        {(loadingTasks || loadingJobs) && <ComponentLoading />}
      </Grid>
    </Grid>
  );
}
