import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import SearchColumnInput from 'components/SearchColumnInput';
import { DialogTitleStyled } from './AddColumnDialog.styles';

type AddColumnDialogProps = {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddColumn: (newColumn: string | null) => void;
};

export default function AddColumnDialog({
  openDialog,
  setOpenDialog,
  handleAddColumn,
}: AddColumnDialogProps): React.JSX.Element {
  const [inputValue, setInputValue] = useState<string | null>(null);

  const handleDialogClose = () => {
    setInputValue(null);
    setOpenDialog(false);
  };

  const handleAdd = () => {
    handleDialogClose();
    handleAddColumn(inputValue);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitleStyled>Add column:</DialogTitleStyled>
      <DialogContent sx={{ padding: '2rem' }}>
        <SearchColumnInput
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Divider sx={{ mt: 4 }} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleAdd}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
