import { useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';

type ClickEvent = React.MouseEvent<HTMLElement>;

interface ConfigurationButtonProps {
  name: string;
  icon: React.ReactElement;
  type?: string;
  title: string;
  description: string;
  options: string[];
  value: string;
  disabled?: boolean;
  onSelect: ({ name, value }: { name: string; value: string }) => void;
}

const ConfigurationButton = ({
  name,
  icon,
  type = 'list',
  title,
  description,
  options,
  value,
  disabled,
  onSelect,
}: ConfigurationButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: ClickEvent) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const radioGroup = (): JSX.Element => {
    return (
      <FormControl>
        <RadioGroup value={value}>
          {options.map((item) => (
            <FormControlLabel
              key={crypto.randomUUID()}
              value={item}
              control={<Radio color="secondary" />}
              label={item}
              onClick={() => onSelect({ name, value: item })}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const list = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={(e) => onSelect({ name, value: e.target.value })}
        >
          {options.map((item) => (
            <MenuItem key={crypto.randomUUID()} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick} disabled={disabled}>
        {icon}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: -10,
              left: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <Box sx={{ width: '240px', padding: '15px' }}>
          <Typography
            variant="h2"
            sx={{ textWrap: 'wrap', marginBottom: '6px' }}
          >
            {title}
          </Typography>
          <Typography sx={{ textWrap: 'wrap' }}>{description}</Typography>
          <Divider sx={{ margin: '14px 0px' }} />
          {type === 'radio' ? radioGroup() : list()}
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default ConfigurationButton;
