import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type InfoDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  subcategory: string;
  categoryTitle: string;
  category: string;
};

export default function InfoAboutRuleDialog({
  open,
  setOpen,
  category,
  categoryTitle,
  subcategory,
}: Readonly<InfoDialogProps>): React.JSX.Element {
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>Information about {subcategory} rule:</DialogTitle>
      <DialogContent>
        <strong>{subcategory}</strong> is a{' '}
        <strong>{!!categoryTitle ? categoryTitle : category}</strong> rule.
        <p>All fields are mandatory, so the rule can be saved</p>
        <p>
          To add one more row to this rule, click on <strong>Add row</strong>{' '}
          button
        </p>
        <p>
          To add a rule, click on the <strong>Add subcategory</strong> button
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
