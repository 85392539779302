import React from 'react';

export const XslIcon = () => {
  return (
    <svg viewBox="0 0 201 241" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M140.1 49.25C134.633 49.2447 129.391 47.0704 125.525 43.2046C121.659 39.3387 119.485 34.097 119.48 28.6299V0H27.3398C20.2029 0 13.3581 2.83523 8.31152 7.88184C3.26492 12.9284 0.429687 19.7729 0.429688 26.9099V189.13C0.429688 196.267 3.26492 203.112 8.31152 208.158C13.3581 213.205 20.2029 216.04 27.3398 216.04H137.94C145.077 216.04 151.921 213.205 156.968 208.158C162.015 203.112 164.85 196.267 164.85 189.13V49.25H140.1Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M164.85 49.25H140.1C134.632 49.2447 129.391 47.0704 125.525 43.2046C121.659 39.3387 119.485 34.097 119.479 28.6299V0L164.85 49.25Z"
        fill="#00C650"
      />
      <path
        d="M184.37 170.61H59.3296C50.4599 170.61 43.2695 177.801 43.2695 186.67V223.95C43.2695 232.82 50.4599 240.01 59.3296 240.01H184.37C193.239 240.01 200.429 232.82 200.429 223.95V186.67C200.429 177.801 193.239 170.61 184.37 170.61Z"
        fill="#00C650"
      />
      <path d="M87.8601 213.38V220.65H80.3301V213.38H87.8601Z" fill="white" />
      <path
        d="M109.889 220.65L103.56 211.29L98.1096 220.65H89.7795L99.3894 205.17L89.4795 190.58H98.1096L104.28 199.64L109.6 190.58H117.889L108.409 205.72L118.53 220.65H109.889Z"
        fill="white"
      />
      <path
        d="M141.73 216.57C140.867 217.951 139.629 219.058 138.16 219.76C136.372 220.597 134.414 221.005 132.44 220.95C129.463 221.075 126.529 220.206 124.1 218.48C123.026 217.674 122.147 216.636 121.529 215.443C120.911 214.25 120.569 212.933 120.53 211.59H128.27C128.326 212.603 128.757 213.559 129.48 214.27C130.2 214.941 131.156 215.301 132.14 215.27C132.541 215.294 132.943 215.238 133.322 215.106C133.701 214.973 134.051 214.768 134.35 214.5C134.617 214.242 134.827 213.93 134.966 213.586C135.106 213.241 135.172 212.872 135.16 212.5C135.17 212.148 135.109 211.797 134.98 211.469C134.851 211.14 134.657 210.842 134.41 210.59C133.881 210.055 133.253 209.627 132.56 209.33C131.834 209.01 130.814 208.62 129.5 208.16C127.907 207.635 126.35 207.003 124.84 206.27C123.6 205.631 122.535 204.697 121.74 203.55C120.818 202.162 120.362 200.515 120.44 198.85C120.404 197.2 120.879 195.579 121.8 194.21C122.746 192.872 124.054 191.831 125.57 191.21C127.318 190.499 129.194 190.159 131.08 190.21C133.944 190.052 136.773 190.897 139.08 192.6C140.063 193.399 140.867 194.394 141.442 195.522C142.017 196.65 142.35 197.886 142.42 199.15H134.55C134.463 198.265 134.083 197.435 133.47 196.79C133.158 196.491 132.787 196.258 132.381 196.107C131.975 195.955 131.543 195.888 131.11 195.91C130.36 195.876 129.624 196.118 129.04 196.59C128.771 196.846 128.561 197.157 128.427 197.503C128.292 197.849 128.235 198.22 128.26 198.59C128.245 199.262 128.497 199.913 128.96 200.4C129.458 200.92 130.057 201.332 130.72 201.61C131.43 201.92 132.45 202.32 133.78 202.8C135.403 203.329 136.984 203.977 138.51 204.74C139.757 205.413 140.832 206.364 141.65 207.52C142.583 208.931 143.046 210.601 142.97 212.29C142.957 213.804 142.528 215.284 141.73 216.57Z"
        fill="white"
      />
      <path
        d="M154.26 215.08H164V220.65H147V190.58H154.28L154.26 215.08Z"
        fill="white"
      />
      <path
        d="M119.32 150.84H45.9995C39.6495 150.84 34.4795 144.52 34.4795 136.74V83.55C34.4795 75.78 39.6495 69.46 45.9995 69.46H119.35C125.69 69.46 130.86 75.78 130.86 83.55V136.74C130.83 144.52 125.66 150.84 119.32 150.84ZM45.9995 76.6599C42.8895 76.6599 40.3696 79.75 40.3696 83.55V136.74C40.3696 140.54 42.8895 143.63 45.9995 143.63H119.35C122.45 143.63 124.969 140.54 124.969 136.74V83.55C124.969 79.75 122.45 76.6599 119.35 76.6599H45.9995Z"
        fill="#00C650"
      />
      <path d="M127.889 118.81H37.3994V126.02H127.889V118.81Z" fill="#00C650" />
      <path
        d="M127.889 94.0898H37.3994V101.3H127.889V94.0898Z"
        fill="#00C650"
      />
      <path d="M104.71 73.0596H97.5V147.23H104.71V73.0596Z" fill="#00C650" />
      <path
        d="M67.7803 73.0596H60.5703V147.23H67.7803V73.0596Z"
        fill="#00C650"
      />
    </svg>
  );
};

export default XslIcon;
