import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';

import config from 'config';
import { logoutAPI } from 'api';
import { UserProperties } from 'components/AppWrapper';
import { QuasarLogo, IdentityManagementLogo } from 'icons';

/**
 * The global site header component that appears on every page
 */
export default function SiteHeader(): React.JSX.Element {
  const user = useContext(UserProperties);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToHomePage = () => {
    const { protocol, host } = window.location;
    window.location.assign(`${protocol}//${host}/`);
  };

  const navigateToIM = () => {
    const { protocol, host } = window.location;
    window.location.assign(
      `${protocol}//${host}/${config.urls.app['identity-management']}/users`,
    );
  };

  return (
    <>
      <Grid
        container
        id="site-header"
        component="header"
        sx={{
          height: '64px',
          borderBottom: '0.5px solid #B3B2B5',
          boxSizing: 'border-box',
        }}
        alignItems="center"
        // paddingLeft={0}
        paddingRight={2}
      >
        <Grid item display={'flex'}>
          <IconButton onClick={navigateToHomePage} sx={{ mx: 1 }}>
            <QuasarLogo />
          </IconButton>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: '#CCCBCE',
            height: 41,
            alignSelf: 'unset',
          }}
        />
        <Grid
          item
          flexGrow={1}
          ml={2}
          onClick={navigateToHomePage}
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant="h1" component="span" fontWeight={700}>
            GenWizard
          </Typography>
          <Typography variant="h1">Quasar++</Typography>
        </Grid>
        <Grid item mr={2}>
          <IconButton onClick={navigateToIM}>
            <IdentityManagementLogo />
          </IconButton>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: '#CCCBCE', height: 32, alignSelf: 'unset' }}
        />
        <Grid item>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, gap: 1, padding: 0 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              disableRipple
            >
              <Avatar
                alt="Test User"
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: '#CCCBCE',
                  color: '#3B3944',
                  fontWeight: 600,
                }}
              >
                {user?.username[0].toUpperCase()}
              </Avatar>
              {anchorEl ? (
                <ExpandLessIcon sx={{ color: '#3B3944' }} />
              ) : (
                <ExpandMoreIcon sx={{ color: '#3B3944' }} />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => logoutAPI(!!user.samlUser)}>Sign Out</MenuItem>
      </Menu>
    </>
  );
}
