import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

type InfoDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setUrgentConfirmation: (value: boolean) => void;
};

export default function AlertConfirmationDialog({
  open,
  setOpen,
  setUrgentConfirmation,
}: Readonly<InfoDialogProps>): React.JSX.Element {
  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSetUrgentConfirmation = async () => {
    setUrgentConfirmation(true);
    handleDialogClose();
  };

  async function handleAwait() {
    await handleSetUrgentConfirmation();
  }

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>Confirm the action:</DialogTitle>
      <DialogContent>
        <Typography></Typography>
        <Typography
          variant="caption"
          gutterBottom
          sx={{ display: 'block' }}
          color="#8d021f"
        >
          This kind of action may not be undone
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleAwait}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
