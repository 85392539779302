import { Button, Typography } from '@mui/material';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ICategories } from '../interfaces';
import {
  ChatOutlined,
  HideSourceOutlined,
  DriveFolderUploadOutlined,
  FileCopyOutlined,
  ApiOutlined,
  PersonPinOutlined,
  SecurityOutlined,
  PublicOutlined,
  KeyOutlined,
} from '@mui/icons-material';

import ComponentLoading from 'components/ComponentLoading';

export type TCategoriesList = {
  categories: ICategories[];
  handleChangeCategory: (value: string, categoryTitle?: string) => void;
};
export default function CategoriesList({
  categories,
  handleChangeCategory,
}: Readonly<TCategoriesList>): React.JSX.Element {
  type TTitlesAndIcons = {
    title: string;
    icon: JSX.Element;
    categoryLabel: string;
  };

  const titlesAndIcons: TTitlesAndIcons[] = [
    {
      title: 'Multi-source',
      icon: <DriveFolderUploadOutlined />,
      categoryLabel: 'multisource',
    },
    {
      title: 'Clients',
      icon: <PersonPinOutlined />,
      categoryLabel: 'clients',
    },
    {
      title: 'Security Settings',
      icon: <SecurityOutlined />,
      categoryLabel: 'security_settings',
    },
    {
      title: 'API',
      icon: <ApiOutlined />,
      categoryLabel: 'api',
    },
    {
      title: 'Embedd',
      icon: <FileCopyOutlined />,
      categoryLabel: 'embedd',
    },
    {
      title: 'Manage LLMs',
      icon: <ChatOutlined />,
      categoryLabel: 'llm',
    },
    {
      title: 'Data Scrubbing',
      icon: <HideSourceOutlined />,
      categoryLabel: 'scrub',
    },
    {
      title: 'Global Settings',
      icon: <PublicOutlined />,
      categoryLabel: 'global_settings',
    },
    {
      title: 'Secret Managers',
      icon: <KeyOutlined />,
      categoryLabel: 'secret_managers',
    },
  ];

  const CategoriesListItem = categories.map((_item) => {
    const categoryTitle =
      titlesAndIcons.find(
        (titleAndIcon) => titleAndIcon.categoryLabel === _item.categoryLabel,
      )?.title ?? _item.categoryLabel;

    const categoryIcon = titlesAndIcons.find(
      (titleAndIcon) => titleAndIcon.categoryLabel === _item.categoryLabel,
    )?.icon;

    return (
      <ListItem
        key={_item.id}
        value={_item.id}
        alignItems="flex-start"
        disablePadding
        sx={{
          m: 1,
        }}
      >
        <Button
          fullWidth
          component="label"
          role={undefined}
          variant="outlined"
          color="secondary"
          tabIndex={-1}
          sx={{
            boxShaddow: 1,
            justifyContent: 'flex-start',
          }}
          startIcon={categoryIcon}
          onClick={() => {
            handleChangeCategory(_item.id, categoryTitle);
          }}
        >
          <Typography variant="caption">{categoryTitle}</Typography>
        </Button>
      </ListItem>
    );
  });

  return (
    <List
      sx={{
        //width: '100%',
        maxWidth: 270,
        //bgcolor: 'background.paper',
        //position: 'relative',
        //bottom: 70,
        border: 0,
        //borderRadius: 3,
        //borderColor: 'black',
        //spacing: 0,
        margin: 1,
        padding: 1,
      }}
      // component="nav"
      // aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Rules:
      //   </ListSubheader>
      // }
    >
      {CategoriesListItem && CategoriesListItem.length > 0 ? (
        CategoriesListItem
      ) : (
        <ComponentLoading />
      )}
    </List>
  );
}
