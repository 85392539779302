import React, { ChangeEvent, useContext, useRef } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Button, Typography } from '@mui/material';

import {
  HeaderStyled,
  HeaderActionsStyled,
  HeaderInfoStyled,
  HeaderPageControlStyled,
  HeaderTitleStyled,
  BrowseButtonStyled,
} from './Upload.styles';
import DragDropFile from './DragDropFile';
import UploadDataGridTable from 'components/UploadDataGridTable';
import { FilesContext } from 'components/FilesProvider';
import ComponentLoading from 'components/ComponentLoading';
import { SnackbarContext } from 'components/SnackbarProvider';
import { Files } from 'types';
import {
  containsSpecialChars,
  isObjectEmpty,
  isValidExtension,
} from 'common/utils/common';

export default function Upload(): React.JSX.Element {
  const createAlert = useContext(SnackbarContext);
  const { index } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { state, dispatch, uploadFiles } = useContext(FilesContext);

  const handleCancel = () => {
    navigate(-1);
    dispatch({ type: 'resetState' });
  };

  const handleUpload = () => {
    navigate(`/collection/${index}`);
    uploadFiles(state.files);
  };

  const handleBrowse = () => inputRef.current?.click();

  const onFileDrop = (fileList: FileList) => {
    if (fileList.length > 0) {
      const filesProcessed: Files = {};
      Object.values(fileList).forEach((file) => {
        if (!isValidExtension(file.name)) {
          return createAlert({
            message: 'This file is not supported',
            severity: 'error',
          });
        }
        if (containsSpecialChars(file.name)) {
          return createAlert({
            message: `The file name contains one or more of the not allowed characters: \\/:*”’<>|`,
            severity: 'error',
          });
        }
        filesProcessed[file.name] = { file };
      });
      if (isObjectEmpty(filesProcessed)) return;
      dispatch({ type: 'addFiles', payload: filesProcessed });
    }
  };

  const handleChange = function (event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    if (event.target?.files) {
      onFileDrop(event.target.files);
    }
  };

  if (!index) return <ComponentLoading />;

  return (
    <>
      <HeaderStyled>
        <HeaderPageControlStyled>
          <IconButton sx={{ color: '#03000F' }} onClick={handleCancel}>
            <KeyboardBackspaceIcon sx={{ width: '24px', height: '24px' }} />
          </IconButton>
          <HeaderInfoStyled>
            <Typography variant="h2">Collections</Typography>
            <HeaderTitleStyled>Upload Files</HeaderTitleStyled>
          </HeaderInfoStyled>
        </HeaderPageControlStyled>

        <HeaderActionsStyled>
          <BrowseButtonStyled onClick={handleBrowse}>Browse</BrowseButtonStyled>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={state.importDisabled}
            type="button"
            onClick={handleUpload}
          >
            Upload
          </Button>
        </HeaderActionsStyled>
      </HeaderStyled>

      <input
        ref={inputRef}
        type="file"
        accept={
          '.pptx, .pdf, .docx, .csv, .txt, .rtf, .html, .xlsx, .wav, .flac, .mp3, .jpeg, .jpg, .png, .zip'
        }
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
        hidden
      />

      {state.files ? (
        <UploadDataGridTable files={state.files} index={index} />
      ) : (
        <DragDropFile
          index={index}
          onFileDrop={onFileDrop}
          handleChange={handleChange}
        />
      )}
    </>
  );
}
