import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  Container,
  Content,
  Title,
  Subtitle,
} from './SomethingWentWrong.styles';

interface NothingToSeeHereProps {
  missingElement: string;
  handleRefresh: () => void;
}

export default function NothingToSeeHere({
  missingElement,
  handleRefresh,
}: NothingToSeeHereProps) {
  return (
    <Container>
      <Content>
        <ErrorOutlineIcon sx={{ width: '65px', height: '65px' }} />
        <Title>Sorry, something went wrong.</Title>
        <Subtitle>
          Please, try{' '}
          <span
            style={{
              color: '#7500C0',
            }}
            onClick={handleRefresh}
          >
            refreshing
          </span>{' '}
          the list of {missingElement}.
        </Subtitle>
      </Content>
    </Container>
  );
}
