import {
  Breakpoint,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';

import { DialogContentSytled } from './ConfirmationDialog.styles';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  form?: boolean;
  size?: Breakpoint | false;
  content: string | JSX.Element;
  leftButtonProps: ButtonProps & { name: string };
  rightButtonProps: ButtonProps & { name: string };
  onClose?: () => void;
}

const ConfirmationDialog = ({
  open,
  title,
  form,
  size,
  content,
  leftButtonProps,
  rightButtonProps,
  onClose,
}: ConfirmationDialogProps) => {
  const isSubmitType = (type?: 'button' | 'submit' | 'reset' | undefined) => {
    return type === 'submit';
  };

  const onSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const event = e as unknown as React.MouseEvent<
      HTMLButtonElement,
      MouseEvent
    >;
    if (isSubmitType(rightButtonProps.type) && rightButtonProps.onClick) {
      rightButtonProps.onClick(event);
    }
    if (isSubmitType(leftButtonProps.type) && leftButtonProps.onClick) {
      leftButtonProps.onClick(event);
    }
    onClose && onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose ?? leftButtonProps.onClick}
      maxWidth={size ?? 'sm'}
      component={form ? 'form' : undefined}
      onSubmit={onSubmit}
    >
      <DialogTitle component={'div'} sx={{ padding: '24px 24px 16px' }}>
        <Typography variant="h4"> {title}</Typography>
      </DialogTitle>
      <DialogContentSytled sx={{ padding: '0px 24px' }}>
        {content}
      </DialogContentSytled>
      <DialogActions sx={{ padding: 3 }}>
        <Button
          {...leftButtonProps}
          variant={leftButtonProps?.variant ?? 'contained'}
          color={leftButtonProps?.color ?? 'error'}
          type={leftButtonProps.type ?? 'button'}
          onClick={
            isSubmitType(leftButtonProps.type)
              ? undefined
              : leftButtonProps.onClick
          }
        >
          {leftButtonProps.name}
        </Button>
        <Button
          {...rightButtonProps}
          variant={rightButtonProps?.variant ?? 'outlined'}
          color={rightButtonProps?.color ?? 'inherit'}
          type={rightButtonProps.type ?? 'button'}
          onClick={
            isSubmitType(rightButtonProps.type)
              ? undefined
              : rightButtonProps.onClick
          }
        >
          {rightButtonProps.name}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
