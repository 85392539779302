import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { IConfigData } from '../interfaces';

export default async function getSubCategoryContent(
  categoryID: string,
  subcategoryID: string,
): Promise<{
  subCategoryContent: IConfigData[];
  status: string;
}> {
  return await PrivReq({
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    method: 'get',
    url: `/getconfigurable/${categoryID}/${subcategoryID}`,
  })
    .then((response: AxiosResponse) => {
      if (response.data.length > 0) {
        return {
          subCategoryContent: response.data,
          status: 'success loading rules content.',
        };
      } else {
        return {
          subCategoryContent: [],
          status: 'no content for this subcategory',
        };
      }
    })
    .catch((error: AxiosError) => {
      if (process.env.NODE_ENV === 'development') {
        return {
          subCategoryContent: [],
          status: 'Error fetching rules from server',
        };
      }
      return {
        subCategoryContent: [],
        status: 'error retrieving data from server',
      };
    });
}
