import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { ISubCategories } from '../interfaces';

export default async function getClients(_id: string): Promise<{
  clients: ISubCategories[];
  status: string;
}> {
  return await PrivReq({
    method: 'get',
    url: `/getconfigurable/${_id}`,
  })
    .then((response: AxiosResponse) => {
      return {
        clients: response.data,
        status: 'clients list loaded',
      };
    })
    .catch((error: AxiosError) => {
      return {
        clients: [],
        status: 'error returning clients list',
      };
    });
}
