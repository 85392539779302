import { Box, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface InputWithTooltipProps {
  children: React.JSX.Element;
  label: string;
  tooltip?: string;
}

const InputWithTooltip = ({
  children,
  label,
  tooltip,
}: InputWithTooltipProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontFamily: 'Graphik',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '125%',
            }}
          >
            {label}
          </Typography>

          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <InfoIcon sx={{ height: '16px', width: '16px' }} />
            </Tooltip>
          )}
        </Box>
      </Box>

      {children}
    </>
  );
};

export default InputWithTooltip;
