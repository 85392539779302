import { styled } from '@mui/system';
import { grey } from 'theme';
import Box from '@mui/material/Box';

export const CardContainer = styled(Box)<{ active: number }>`
  border-radius: 5px;
  padding: 24px 16px;
  border: 2px solid ${(props) => (props.active ? '#a100ff' : grey[200])};
  transition:
    border 100ms,
    color 300ms,
    background 300ms,
    box-shadow 300ms;

  h4,
  svg {
    color: ${(props) => (props.active ? '#a100ff' : 'inherit')};
  }

  background: ${(props) => (props.active ? grey[100] : 'inherit')};
  box-shadow: ${(props) =>
    props.active ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 'inherit'};

  &:hover {
    // border: 2px solid transparent;
    h4,
    svg {
      color: #a100ff;
    }
    background: ${grey[100]};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;
