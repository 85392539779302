import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { SnackbarContext } from 'components/SnackbarProvider';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip(props: {
  listOfValues: string[];
  selectedValues: any;
  setValuesFunction: Function;
  maxSelectedLimit: number;
}): React.JSX.Element {
  const listOfValues = props.listOfValues; //['Value1', 'Value2', 'Value3', 'Value4', 'Value5'];
  const setValues = props.setValuesFunction;
  const [componentValues, setComponentValues] = useState<string[]>([]);
  const createAlert = useContext(SnackbarContext);

  const [updatePage, setUpdatepage] = useState(0);

  useEffect(() => {
    let tempStr = localStorage.getItem('createIndexTempInfo') || '';
    if (tempStr) {
      let tempObj = JSON.parse(tempStr);

      setComponentValues([
        ...tempObj.modalInfo.metadatas.optional,
        ...tempObj.modalInfo.metadatas.mandatory,
      ]);
      setTimeout(() => {
        localStorage.removeItem('createIndexTempInfo');
      }, 1000);
    }
  }, [updatePage]);

  const handleChange = (event: SelectChangeEvent<typeof componentValues>) => {
    const {
      target: { value },
    } = event;
    if (event.target.value.length <= props.maxSelectedLimit) {
      // setValues(typeof value === 'string' ? value.split(',') : value);
      setComponentValues(typeof value === 'string' ? value.split(',') : value);
      setValues((prev: any) => {
        let valuesArr = value as string[];
        prev = valuesArr.map((item: string) => {
          return { field: item, mandatory: false };
        });
        return prev;
      });
    } else {
      createAlert({
        severity: 'error',
        message: `Maximum limit of selected items reached (${props.maxSelectedLimit}). Try deselecting any of the current ones first`,
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 1, mb: 0.5 }}>
      <FormControl fullWidth>
        <InputLabel id="multiple-chip-label">Metadata</InputLabel>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={componentValues}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Metadata" />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                width: '100%',
              }}
            >
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {listOfValues.map((name) => (
            <MenuItem
              key={name}
              value={name}

              // style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        {componentValues.length > 0 && (
          <Box sx={{ border: 'solid red px', marginTop: 2 }}>
            {/* <FormLabel component={'legend'}>Is Mandatory</FormLabel>
            <FormGroup>
              {selectedValues.map((item: any) => {
                return (
                  <FormControlLabel
                    key={`${item.field}`}
                    control={
                      <Switch
                        onChange={(event) => {
                          setValues((prev: any) => {
                            // prev[item].mandatory = !prev[item].mandatory;
                            prev.map((elem: any) => {
                              if (elem.field === item.field) {
                                elem.mandatory = !elem.mandatory;
                              }
                              return elem;
                            });
                            return prev;
                          });
                          setUpdatepage((prev: number) => prev + 1);
                        }}
                        name={item.field}
                        checked={item.mandatory}
                      />
                    }
                    label={item.field}
                  ></FormControlLabel>
                );
              })}
            </FormGroup> */}
          </Box>
        )}
      </FormControl>
    </Box>
  );
}
