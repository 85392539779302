import { DialogContent, styled } from '@mui/material';

export const DialogContentSytled = styled(DialogContent)`
  font-family: Graphik;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  p {
    margin: 0;
    font-family: Graphik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;
