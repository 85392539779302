import {
  Autocomplete,
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, Fragment, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Refresh } from '@mui/icons-material';

import { camelize, decamelize, getColor, getTableFilters } from 'common/utils';
import { stableSort } from 'common/utils/sortTable';
import { StyledTableCell } from './Table.styles';
import SecureTextField from 'components/SecureTextField';
import PopoverFilter from 'components/PopoverFilter';
import CustomIconButton from '../CustomIconButton';
import NotFound from '../NotFound';
import {
  Cells,
  HeadCell,
  Order,
  StatusList,
  TableData,
  FilterValues,
  RowActions,
  DefaultTableOrder,
  PopoverFilters,
  SearchResult,
} from 'types';

interface EnhancedTableHeadProps {
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  enableActionColumn?: boolean;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead({
  order,
  orderBy,
  headCells,
  enableActionColumn,
  onRequestSort,
}: EnhancedTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              fontWeight: 600,
              color: 'black',
              fontFamily: 'Graphik',
              fontSize: '16px',
              fontStyle: 'normal',
              lineHeight: '125%',
            }}
            align={headCell.cellProps?.align ?? 'left'}
            padding={headCell.cellProps?.padding ?? 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            {...headCell.cellProps}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onRequestSort(headCell.id)}
            >
              <Box
                sx={{
                  paddingLeft:
                    headCell.cellProps?.align === 'center' ? '26px' : undefined,
                }}
              >
                {headCell.label}
              </Box>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {enableActionColumn && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableProps {
  headCells: HeadCell[];
  rows: TableData[];
  title?: string;
  cellProps?: Cells;
  statusList?: StatusList;
  rowActions?: RowActions;
  defaultRowsPerPage?: number;
  tableFilters?: PopoverFilters;
  defaultOrder?: DefaultTableOrder;
  searchBy?: string;
  rowMetadata?: string[];
  editable?: 'show_controls' | 'hide_controls';
  handleAddRow?: () => void;
  enableRowClick?: ((row: TableData) => boolean) | boolean;
  handleRowClick?: (row: TableData) => void;
  handleEdit?: (action: 'Save' | 'Cancel') => void;
  textFieldValidator?: (text: string) => string | undefined;
  handleRowAction?: ({
    row,
    action,
    status,
  }: {
    row: TableData;
    action: string;
    status: string;
  }) => void;

  handleCellAction?: ({
    row,
    columnKey,
    value,
  }: {
    row: TableData;
    columnKey: string;
    value: boolean | string;
  }) => void;
  refreshTable?: () => void;
}

export default function EnhancedTable({
  rows,
  title,
  editable,
  headCells,
  rowActions,
  statusList,
  cellProps,
  tableFilters,
  defaultRowsPerPage,
  defaultOrder,
  searchBy,
  rowMetadata,
  handleAddRow,
  handleRowAction,
  handleCellAction,
  enableRowClick,
  handleRowClick,
  textFieldValidator,
  handleEdit,
  refreshTable,
}: EnhancedTableProps) {
  const serachList = useMemo(
    () =>
      rows.map((row) => ({
        label: (row.name as string) ?? (row.task as string),
        id: row.rowId as string,
      })),
    [rows],
  );
  const { filters, initialFilterValues } = getTableFilters(tableFilters);
  const [notFound, setNotFound] = useState(false);
  const [order, setOrder] = useState<Order>(defaultOrder?.order ?? 'asc');
  const [orderBy, setOrderBy] = useState<string>(defaultOrder?.name ?? '');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage ?? 5);
  const [searchResult, setSearchResult] = useState<SearchResult>(null);
  const [filterValues, setFilterValues] = useState<FilterValues>(
    initialFilterValues ?? {},
  );
  const editionModeActiveByDefault = editable === 'hide_controls';
  const [editionMode, setEditMode] = useState(editionModeActiveByDefault);
  const showRowActions = rowActions && rowActions?.length > 0 && editionMode;

  const isClickEnable = (row: TableData) => {
    if (!enableRowClick) return;
    const isBoolean = typeof enableRowClick === 'boolean';
    return isBoolean ? enableRowClick : enableRowClick(row);
  };

  const handleSelectRow = (row: TableData) =>
    isClickEnable(row) && handleRowClick && handleRowClick(row);

  const filteredRows = useMemo(() => {
    if (!searchBy) return rows;
    const listFilteredBySearch = rows.filter((row) =>
      searchResult ? row.rowId === searchResult.id : row,
    );
    const isSearchFilterActive = !!searchResult;

    let isAnyFilterActive = Object.values(filterValues)
      .flatMap((obj) => Object.values(obj))
      .includes(true);

    const getTimeThen = ({
      amount,
      timeUnit,
    }: {
      amount: number;
      timeUnit: 'days' | 'months';
    }) => {
      const currentDate = new Date();
      const targetDate = new Date(currentDate);

      timeUnit === 'days'
        ? targetDate.setDate(currentDate.getDate() - amount)
        : targetDate.setMonth(currentDate.getMonth() - amount);

      const epochTime = Math.floor(targetDate.getTime() / 1000);
      return epochTime;
    };

    const getTimeRange = (
      filterKey: string,
    ): { amount: number; timeUnit: 'days' | 'months' } => {
      if (filterKey === 'lastDay') return { amount: 1, timeUnit: 'days' };
      if (filterKey === 'last7Days') return { amount: 7, timeUnit: 'days' };
      if (filterKey === 'last30Days') return { amount: 30, timeUnit: 'days' };
      if (filterKey === 'last3Months') return { amount: 3, timeUnit: 'months' };
      return { amount: 7, timeUnit: 'days' };
    };

    const getFilterDates = ({
      dates,
      timeRange,
    }: {
      dates: number[];
      timeRange: {
        amount: number;
        timeUnit: 'days' | 'months';
      };
    }) => {
      const targetDate = getTimeThen(timeRange);
      const datesWithinTheRange = dates.filter((f) => f >= targetDate);
      return datesWithinTheRange;
    };

    let filteredByTime = listFilteredBySearch;
    let filteredlist: TableData[] = [];
    let isFilterByStatusActive = false;

    Object.keys(filterValues).forEach((category) =>
      Object.keys(filterValues[category]).forEach((filter) => {
        const isFilterActive = filterValues[category][filter];
        if (!isFilterActive) return;

        if (category === 'Start Time' || category === 'End Time') {
          const filterBy = `epoch${category.replace(/ /g, '')}`;
          const timeRange = getTimeRange(filter);
          const dates = filteredByTime.map((item) => item[filterBy] as number);
          const filteredDates = getFilterDates({ dates, timeRange });
          const listFilteredByDate = filteredByTime.filter((i) =>
            filteredDates.includes(i[filterBy] as number),
          );
          filteredByTime = listFilteredByDate;
        }

        if (category === 'Status') {
          isFilterByStatusActive = true;
          const columnKey = camelize(category);
          const filteredList = filteredByTime.filter(
            (row) => row[columnKey] === decamelize(filter),
          );
          filteredlist = [...filteredlist, ...filteredList];
        }
      }),
    );

    if (filteredlist.length === 0 && !isFilterByStatusActive) {
      filteredlist = filteredByTime;
    }

    const isEmptyResult =
      (filteredlist.length === 0 && isAnyFilterActive) ||
      (listFilteredBySearch.length === 0 && isSearchFilterActive);
    setNotFound(isEmptyResult);

    return isAnyFilterActive ? filteredlist : listFilteredBySearch;
  }, [rows, searchBy, searchResult, filterValues]);

  const visibleRows = useMemo(
    () =>
      stableSort({
        rows: filteredRows,
        order,
        orderBy,
      }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, filteredRows],
  );

  const handleSearchChange = (value: SearchResult) => setSearchResult(value);

  const handleRequestSort = (property: string) => {
    const newOrder = orderBy === property && order === 'asc' ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getElementProps = (cellId: string) => {
    if (!cellProps) return {};
    const selectedProps = cellProps
      .filter((cell) => cell.id === cellId)
      .map((cell) => cell.cellProps)[0];
    return selectedProps;
  };

  const getCellType = (cellId: string) => {
    if (!cellProps) return;
    const type = cellProps
      .filter((cell) => cell.id === cellId)
      .map((cell) => cell.type)[0];
    return type;
  };

  const handleEditMode = (action?: 'Save' | 'Cancel') => {
    setEditMode((prev) => !prev);
    handleEdit && action && handleEdit(action);
  };

  const addRow = () => {
    if (!handleAddRow) return;
    handleAddRow();
    const rowsPerPageOptions = [5, 10, 15];
    const nextOptionIndex = rowsPerPageOptions.indexOf(rowsPerPage) + 1;
    const indexLimit = rowsPerPageOptions.length - 1;
    const isRowsLimitReached = visibleRows.length === rowsPerPage;

    const increaseRowsPerpage = () => {
      setRowsPerPage(rowsPerPageOptions[nextOptionIndex]);
      setPage(0);
    };

    if (!isRowsLimitReached) return;
    nextOptionIndex <= indexLimit
      ? increaseRowsPerpage()
      : handleChangePage(page + 1);
  };

  const onRowActionClick = ({
    e,
    row,
    action,
    status,
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    row: TableData;
    action: string;
    status: string;
  }) => {
    e.stopPropagation();
    handleRowAction && handleRowAction({ row, action, status });
    const isLastItemOnThePage =
      action === 'Delete' && visibleRows.length === 1 && rows.length > 1;
    isLastItemOnThePage && handleChangePage(page - 1);
  };

  const hanldeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleEditMode('Save');
  };

  const isRequired = (row: TableData) => {
    const isCheckbox = (column: string) => getCellType(column) === 'checkbox';
    const required = Object.keys(row)
      .filter((column: string) => isCheckbox(column))
      .map((column: string) => row[column])
      .every((isChecked) => !isChecked);

    const checkbox = document.getElementById(
      `${row.id}-checkbox`,
    ) as HTMLInputElement;

    if (checkbox) {
      const isValid = checkbox.validity.valid;
      let error = '';
      if (required && !isValid) {
        error = 'Please check any of these boxes if you want to proceed.';
      }
      checkbox.setCustomValidity(error);
    }

    return required;
  };

  return (
    <Box
      sx={{ width: '100%', padding: '0px 16px', borderRadius: 2, px: 3, py: 2 }}
      component={'form'}
      onSubmit={hanldeSubmit}
    >
      <Toolbar
        sx={{
          '@media (min-width: 0px)': { paddingRight: 0, paddingLeft: 0 },
          p: 0,
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          justifyContent: 'space-between',
        }}
      >
        {title && (
          <Typography
            sx={{
              fontFamily: 'Graphik',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '25px',
              padding: '12px 16px',
            }}
          >
            {title}
          </Typography>
        )}

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {editable === 'show_controls' && (
            <>
              {editionMode && (
                <>
                  <CustomIconButton
                    icon={<CloseIcon />}
                    tooltip="Cancel"
                    aria-label="cancel"
                    type="button"
                    onClick={() => handleEditMode('Cancel')}
                  />
                  <CustomIconButton
                    icon={<DoneIcon />}
                    tooltip="Save"
                    aria-label="save"
                    type="submit"
                  />
                </>
              )}
              {!editionMode && (
                <CustomIconButton
                  icon={<EditIcon />}
                  tooltip="Edit"
                  type="button"
                  aria-label="edit"
                  onClick={() => handleEditMode()}
                />
              )}
            </>
          )}
          {filters && (
            <PopoverFilter
              filters={filters}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
          )}
          {searchBy && (
            <Autocomplete
              sx={{ minWidth: 300 }}
              size="small"
              disablePortal
              id="autocomplete-task-select"
              value={searchResult}
              options={serachList}
              onChange={(e, value) => handleSearchChange(value)}
              renderOption={(props, option) => {
                const key = `listItem-${option.id}`;
                return (
                  <li {...props} key={key}>
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => (
                <SecureTextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  style={{ background: 'white', borderRadius: '5px' }}
                />
              )}
            />
          )}
          {refreshTable && (
            <CustomIconButton
              type="button"
              icon={<Refresh />}
              tooltip="Refresh list"
              aria-label="refresh_list_label"
              onClick={refreshTable}
            />
          )}
        </Box>
      </Toolbar>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {rows.length > 0 && (
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                enableActionColumn={showRowActions}
                onRequestSort={handleRequestSort}
              />
              {notFound ? (
                <TableCell colSpan={6}>
                  <NotFound itemNotFound="task" backgroundColor="white" />
                </TableCell>
              ) : (
                <TableBody>
                  {visibleRows.map((row, idx) => (
                    <TableRow
                      tabIndex={-1}
                      key={idx}
                      hover
                      sx={{
                        cursor: isClickEnable(row) ? 'pointer' : undefined,
                      }}
                      onClick={() => handleSelectRow(row)}
                    >
                      {Object.keys(row)
                        .filter((columnKey) =>
                          rowMetadata
                            ? !rowMetadata.includes(columnKey)
                            : columnKey,
                        )
                        .map((columnKey) => (
                          <TableCell
                            key={columnKey}
                            {...getElementProps(columnKey)}
                            sx={{
                              color: 'black',
                              fontFamily: 'Graphik',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '125%',
                              letterspacing: '0.16px',
                            }}
                          >
                            {!getCellType(columnKey) ||
                            (getCellType(columnKey) === 'textField' &&
                              !editionMode) ? (
                              <>{row[columnKey]}</>
                            ) : (
                              <>
                                {getCellType(columnKey) === 'textField' &&
                                  handleCellAction && (
                                    <SecureTextField
                                      required
                                      fullWidth
                                      margin="none"
                                      variant="outlined"
                                      customValidator={textFieldValidator}
                                      onUpdate={(value) =>
                                        handleCellAction({
                                          row,
                                          value,
                                          columnKey,
                                        })
                                      }
                                      value={row[columnKey] as string}
                                    />
                                  )}

                                {getCellType(columnKey) === 'chip' &&
                                  statusList && (
                                    <Chip
                                      label={row[columnKey]}
                                      sx={{
                                        width:
                                          cellProps &&
                                          cellProps.filter(
                                            (cell) => cell.id === columnKey,
                                          )[0].width
                                            ? cellProps.filter(
                                                (cell) => cell.id === columnKey,
                                              )[0].width
                                            : '100px',
                                        color:
                                          getColor({
                                            status: row['status'] as string,
                                            column: columnKey as string,
                                            statusList,
                                          }) === 'rgba(0, 0, 0, 0.08)'
                                            ? undefined
                                            : 'white',
                                        backgroundColor: getColor({
                                          status: row['status'] as string,
                                          column: columnKey as string,
                                          statusList,
                                        }),
                                      }}
                                    />
                                  )}

                                {getCellType(columnKey) === 'checkbox' &&
                                  handleCellAction && (
                                    <Checkbox
                                      id={`${row.id}-checkbox`}
                                      color="default"
                                      checked={row[columnKey] as boolean}
                                      disabled={!editionMode}
                                      required={isRequired(row)}
                                      onChange={(e) =>
                                        handleCellAction({
                                          row,
                                          columnKey,
                                          value: e.target.checked,
                                        })
                                      }
                                    />
                                  )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      {editionMode && rowActions && handleRowAction && (
                        <StyledTableCell align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: 2,
                            }}
                          >
                            {rowActions.map((button, idx) => {
                              const { inactiveRows, ...rest } = button;
                              return (
                                <Fragment key={button.name + idx}>
                                  {!button.inactiveRows?.includes(
                                    row.rowId as string,
                                  ) && (
                                    <>
                                      {button.icon ? (
                                        <CustomIconButton
                                          type="button"
                                          icon={button.icon}
                                          tooltip={button.name}
                                          aria-label={button.name.toLowerCase()}
                                          disabled={
                                            !!button.inactiveRows?.includes(
                                              row.rowId as string,
                                            )
                                          }
                                          onClick={(e) =>
                                            onRowActionClick({
                                              e,
                                              row,
                                              action: button.name,
                                              status: button.status,
                                            })
                                          }
                                          {...rest}
                                        />
                                      ) : (
                                        <Button
                                          type="button"
                                          disabled={button.inactiveRows?.includes(
                                            row.rowId as string,
                                          )}
                                          onClick={(e) =>
                                            onRowActionClick({
                                              e,
                                              row,
                                              action: button.name,
                                              status: button.status,
                                            })
                                          }
                                          {...(rest as ButtonProps)}
                                        >
                                          {button.name}
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Box>
                        </StyledTableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
        <Box sx={{ paddingTop: 1, justifyContent: 'center' }}>
          {editionMode && editable === 'show_controls' && (
            <Button
              color="secondary"
              sx={{ width: '100%' }}
              onClick={addRow}
              startIcon={<AddIcon />}
              type="button"
            >
              Add group
            </Button>
          )}
        </Box>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
