import { Box, Button, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';

import axios from 'api/axios';
import { getHeadCells } from 'common/utils/getCellsHead';
import { SnackbarContext } from 'components/SnackbarProvider';
import Table from 'components/UI/Table';
import {
  CeleryProcessStatus,
  CeleryStatusData,
  // ICeleryProcessStatus,
  // ICeleryProcessStatusData,
  Cells,
  HeadCell,
  TableData,
} from 'types';

const celeryHeadCells: HeadCell[] = getHeadCells([
  'Celery',
  { label: 'Status', cellProps: { align: 'center' } },
]);

const fileHeadCells: HeadCell[] = getHeadCells([
  'File Name',
  'Index',
  'Task ID',
  'Queue',
  'Status',
  'UID',
  'Elapsed Time',
  'Parent Task ID',
]);

const cellProps: Cells = [
  {
    id: 'status',
    cellProps: { align: 'center' },
    type: 'chip',
  },
];

const statusList = {
  succes: 'Up',
  error: 'Down',
};

export default function UploadsStatusPage(): React.JSX.Element {
  const createAlert = useContext(SnackbarContext);
  const [loadingCeleryHealt, setLoadingCeleryHealt] = useState(false);
  const [loadingCeleryStatus, setLoadingCeleryStatus] = useState(false);
  const [uploadRows, setUploadRows] = useState<null | TableData[]>(null);
  const [genericRows, setGenericRows] = useState<null | TableData[]>(null);
  const [multisourceRows, setMultisourceRows] = useState<null | TableData[]>(
    null,
  );

  const [celeryRows, setCeleryRows] = useState<null | TableData[]>(null);

  const getCeleryRows = useCallback(
    (controller: AbortController) => {
      setLoadingCeleryHealt(true);
      axios({
        url: `celerygetqueue/status/health`,
        method: 'get',
        signal: controller.signal,
      })
        .then((response: AxiosResponse) => {
          const data: { [key: string]: string } = response.data.status;
          const dataKeys = Object.keys(data);
          const newCeleryRows = dataKeys.map((key) => ({
            celeryName: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
            status: `${data[key].charAt(0).toUpperCase()}${data[key].slice(1)}`,
          }));
          setCeleryRows(newCeleryRows);
          createAlert({
            message: `Celery status list obtained successfully`,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => {
          if (error.code === 'ERR_CANCELED') return;
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Something went wrong`,
            severity: 'error',
          });
        })
        .finally(() => setLoadingCeleryHealt(false));
    },
    [createAlert],
  );

  const getFilesRows = useCallback(
    (controller: AbortController) => {
      setLoadingCeleryStatus(true);
      axios({
        url: `celerygetqueue/status`,
        method: 'get',
        signal: controller.signal,
      })
        .then((response: AxiosResponse) => {
          const celeryStatusData: CeleryStatusData = response.data.status;
          if (Object.values(celeryStatusData).length === 0) {
            return setLoadingCeleryStatus(false);
          }

          const celeryNames = Object.keys(celeryStatusData);
          const uploadKey = 'celery@upload';
          const genericKey = 'celery@generic';
          const multisourceKey = 'celery@multisource';
          const uploadValues: CeleryProcessStatus[] = [];
          const multisourceValues: CeleryProcessStatus[] = [];
          const genericValues: CeleryProcessStatus[] = [];

          celeryNames.forEach((key: string) => {
            key.includes(uploadKey) &&
              uploadValues.push(...celeryStatusData[key]);
            key.includes(genericKey) &&
              genericValues.push(...celeryStatusData[key]);
            key.includes(multisourceKey) &&
              multisourceValues.push(...celeryStatusData[key]);
          });

          const getCeleryStatusRows = (
            celeryProcess: CeleryProcessStatus[],
          ) => {
            return celeryProcess.map((celeryStatus) => ({
              fileName: celeryStatus.filename,
              index: celeryStatus.name,
              taskId: celeryStatus.id,
              queue: celeryStatus.type,
              status: celeryStatus.state,
              uid: celeryStatus.uid,
              elapsedTime: celeryStatus.elapsed_time,
              parentTaskID: celeryStatus.parent_taskid,
            }));
          };

          const newUploadRows = getCeleryStatusRows(uploadValues);
          const newGenericRows = getCeleryStatusRows(genericValues);
          const newMultisourceRows = getCeleryStatusRows(multisourceValues);

          newUploadRows.length > 0 && setUploadRows(newUploadRows);
          newGenericRows.length > 0 && setGenericRows(newGenericRows);
          newMultisourceRows.length > 0 &&
            setMultisourceRows(newMultisourceRows);

          setLoadingCeleryStatus(false);
          createAlert({
            message: `File status list obtained successfully`,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => {
          if (error.code === 'ERR_CANCELED') return;
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Something went wrong`,
            severity: 'error',
          });
          setLoadingCeleryStatus(false);
        });
    },
    [createAlert],
  );

  const refreshTables = useCallback(() => {
    const controller = new AbortController();
    setCeleryRows(null);
    setUploadRows(null);
    setGenericRows(null);
    setMultisourceRows(null);
    getCeleryRows(controller);
    getFilesRows(controller);
    return () => controller.abort();
  }, [getFilesRows, getCeleryRows]);

  useEffect(() => refreshTables(), [refreshTables]);

  return (
    <Grid container height="100%" flexDirection="column">
      <Grid
        item
        container
        wrap="nowrap"
        borderBottom="1px solid #B3B2B5"
        height="80px"
        px={3}
        boxSizing={'border-box'}
        alignItems="center"
        sx={{ alignItems: 'center' }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography variant="h2">Uploads Status</Typography>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }} textAlign="right">
          <Button color="primary" variant="contained" onClick={refreshTables}>
            Refresh
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        item
        sx={{
          flexWrap: 'nowrap',
          display: 'flex',
          flexGrow: 1,
          padding: '36px',
          overflowY: 'auto',
          height: 'calc(100vh - 204px)',
          backgroundColor: '#F2F2F2',
        }}
      >
        <Grid
          item
          container
          wrap="nowrap"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            height: '100%',
          }}
        >
          <Grid item>
            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                gap: '16px',
                flexDirection: 'column',
                backgroundColor: 'white',
              }}
            >
              {loadingCeleryHealt ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                  }}
                >
                  <Skeleton />
                  <Skeleton sx={{ height: '20vh', transform: 'none' }} />
                </Box>
              ) : (
                <>
                  {celeryRows && (
                    <Table
                      headCells={celeryHeadCells}
                      rows={celeryRows}
                      title={'Celery status'}
                      cellProps={cellProps}
                      statusList={statusList}
                    />
                  )}
                </>
              )}
            </Paper>
          </Grid>

          <Grid item flexGrow={1}>
            <Paper
              elevation={3}
              sx={{
                height: '100%',
                display: 'flex',
                gap: '16px',
                flexDirection: 'column',
                backgroundColor: 'white',
              }}
            >
              {loadingCeleryStatus ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    flexGrow: 1,
                  }}
                >
                  <Skeleton />
                  <Skeleton sx={{ height: '100%', transform: 'none' }} />
                </Box>
              ) : (
                <>
                  {uploadRows ? (
                    <Table
                      headCells={fileHeadCells}
                      rows={uploadRows}
                      title={'Upload queue'}
                    />
                  ) : (
                    <Grid
                      item
                      flexGrow={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography>
                        There are no files being processed on upload queue
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Paper>
          </Grid>
          <Grid item flexGrow={1}>
            <Paper
              elevation={3}
              sx={{
                height: '100%',
                display: 'flex',
                gap: '16px',
                flexDirection: 'column',
                backgroundColor: 'white',
              }}
            >
              {loadingCeleryStatus ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    flexGrow: 1,
                  }}
                >
                  <Skeleton />
                  <Skeleton sx={{ height: '100%', transform: 'none' }} />
                </Box>
              ) : (
                <>
                  {multisourceRows ? (
                    <Table
                      headCells={fileHeadCells}
                      rows={multisourceRows}
                      title={'Multisource queue'}
                    />
                  ) : (
                    <Grid
                      item
                      flexGrow={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography>
                        There are no files being processed on multisource queue
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Paper>
          </Grid>
          <Grid item flexGrow={1}>
            <Paper
              elevation={3}
              sx={{
                height: '100%',
                display: 'flex',
                gap: '16px',
                flexDirection: 'column',
                backgroundColor: 'white',
              }}
            >
              {loadingCeleryStatus ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    flexGrow: 1,
                  }}
                >
                  <Skeleton />
                  <Skeleton sx={{ height: '100%', transform: 'none' }} />
                </Box>
              ) : (
                <>
                  {genericRows ? (
                    <Table
                      headCells={fileHeadCells}
                      rows={genericRows}
                      title={'Generic queue'}
                    />
                  ) : (
                    <Grid
                      item
                      flexGrow={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography>
                        There are no files being processed on generic queue
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
