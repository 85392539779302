import { useState, useEffect, useContext } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
  Chip,
} from '@mui/material';

import { ReactComponent as DeleteIcon } from './assets/Close.svg';
import { SnackbarContext } from 'components/SnackbarProvider';

export default function SelectComponent(props: {
  data: any;
  rowProps: any;
  func: any;
  selectedProperties: any;
  label: any;
  globalFunction: any;
}) {
  const data = props.data;
  const rowProps = props.rowProps;
  const updateValues = props.func;
  const selectedValues = props.selectedProperties;
  const globalFunction = props.globalFunction;
  const propsLabel =
    selectedValues[rowProps.id][rowProps.field].length !== 0 ? '' : props.label;

  const [label, setLabel] = useState(propsLabel);
  const typeOfValue: string[] = [];
  const [updateState, setUpdateState] = useState(0);

  const createAlert = useContext(SnackbarContext);

  useEffect(() => {
    globalFunction([JSON.stringify(selectedValues)]);
  }, [updateState]);

  const handleChange = (event: SelectChangeEvent<typeof typeOfValue>) => {
    setLabel('');
    const {
      target: { value },
    } = event;

    if (selectedValues[rowProps.id][rowProps.field].length < 5) {
      updateValues(
        (prev: { [x: string]: { [x: string]: string | string[] } }) => {
          prev[rowProps.id][rowProps.field] = value;
          return prev;
        },
      );
    } else {
      createAlert({
        message: 'Maximum limit reached.',
        severity: 'warning',
      });
    }
    setUpdateState((prev) => prev + 1);
  };

  const handleDelete = (value: string) => {
    if (selectedValues[rowProps.id][rowProps.field].length === 1) {
      setLabel(propsLabel);
    }

    updateValues((prev: { [x: string]: { [x: string]: any[] } }) => {
      prev[rowProps.id][rowProps.field] = prev[rowProps.id][
        rowProps.field
      ].filter((e) => e !== value);
      return prev;
    });
    setUpdateState((prev) => prev + 1);
  };

  return (
    <FormControl fullWidth={true} sx={{ height: 'auto' }}>
      <InputLabel
        id="select-component-label"
        sx={{
          color: '#0041F0',
        }}
      >
        {selectedValues[rowProps.id][rowProps.field].length === 0 ? label : ''}
      </InputLabel>
      <Select
        onOpen={() => {
          setLabel('');
        }}
        onBlur={() => {
          if (selectedValues[rowProps.id][rowProps.field].length === 0) {
            setLabel(propsLabel);
          }
        }}
        sx={{
          boxShadow: 'none',
          '& .MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: 'none',
            },
        }}
        labelId="select-component-label"
        placeholder="Select"
        multiple
        value={selectedValues[rowProps.id][rowProps.field]}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onMouseDown={(event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                }}
                onDelete={() => {
                  handleDelete(value);
                }}
                sx={{
                  backgroundColor: '#F7EBFF',
                  color: '#7500C0',
                  border: '#7500C0 solid 1px',
                  fontSize: 14,
                  fontFamily: 'Graphik',
                  fontWeight: '400',
                  lineHeight: 17.5,
                  letterSpacing: 0.16,
                  wordWrap: 'break-word',
                  padding: '4px',
                }}
                deleteIcon={<DeleteIcon />}
              />
            ))}
          </Box>
        )}
      >
        {data.map((value: string) => (
          <MenuItem
            key={value}
            value={value}
            sx={{
              color: '#03000F',
              fontSize: 16,
              fontFamily: 'Graphik',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
