import { IConfigData } from '../interfaces';

export default function CheckForEmptyValues(data: IConfigData[]): boolean {
  const required = [
    'configKey',
    'configValue',
    'displayValue',
    'role',
    'isSecret',
    'subcategory',
  ];
  const isNoneEmpty = data.every((obj) =>
    required.every((prop) => obj[prop] !== undefined && obj[prop] !== ''),
  );

  return isNoneEmpty;
}
