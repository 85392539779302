import { AxiosError } from 'axios';
import PrivReq from 'api/axios';
import { IConfigData } from '../interfaces';
type TReq = { message?: string };
export default async function insertNewConfigData(
  newConfigData: IConfigData[],
): Promise<TReq[]> {
  if (newConfigData === null || newConfigData.length === 0) {
    return [{ message: 'No data to send. Please, try again.' }];
  }

  const insertNewConfigData = async () => {
    const reqs: Promise<TReq[]>[] = newConfigData.map(async (confPayload) => {
      try {
        await PrivReq({
          method: 'post',
          url: `/updateconfigurable/insert`,
          data: confPayload,
        });
        return [
          {
            message: `${confPayload.categoryLabel?.toUpperCase()} rules created successfully...`,
          },
        ];
      } catch (error) {
        if (error instanceof AxiosError) {
          return [
            {
              message: `${confPayload.categoryLabel?.toUpperCase()}: ${error.message}`,
            },
          ];
        } else {
          throw error;
        }
      }
    });
    const results = await Promise.allSettled(reqs);
    return results.flatMap((result) =>
      result.status === 'fulfilled' ? result.value : [],
    );
  };
  try {
    return await insertNewConfigData();
  } catch (error) {
    if (error instanceof Error) {
      return [{ message: 'Internal server error.' }];
    } else {
      throw error;
    }
  }
}
