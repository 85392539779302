import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { MuiContentEditable, placeHolderSx } from './styles';
import { Box, Divider, Grid, Paper } from '@mui/material';
import LexicalEditorTopBar from '../LexicalEditorTopBar';
// import TreeViewPlugin from '../CustomPlugins/TreeViewPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import ImagesPlugin from '../CustomPlugins/ImagePlugin';
import FloatingTextFormatToolbarPlugin from '../CustomPlugins/FloatingTextFormatPlugin';
import lexicalEditorTheme from '../theme/lexicalEditorTheme';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { TableNode, TableCellNode, TableRowNode } from '@lexical/table';
import { ListNode, ListItemNode } from '@lexical/list';
import { ImageNode } from '../CustomNodes/ImageNode';
import { $createImageNode } from '../CustomNodes/ImageNode';
import {
  $getRoot,
  $createParagraphNode,
  $createTextNode,
  $insertNodes,
} from 'lexical';
import ComponentLoading from 'components/ComponentLoading';
import { $generateHtmlFromNodes } from '@lexical/html';
import { decode } from 'html-entities';
import { $generateNodesFromDOM } from '@lexical/html';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }
}

function LexicalEditorWrapper(props) {
  const { currentChunk, onContentUpdate, extractedText, readMode, htmlString } =
    props;

  const prepopulatedRichText = (editor) => {
    const root = $getRoot();
    if (root.getFirstChild() === null) {
      if (htmlString) {
        const parser = new DOMParser();
        const dom = parser.parseFromString(htmlString, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        return $insertNodes(nodes);
      }
      if (!currentChunk && extractedText) {
        const paragraph = $createParagraphNode();
        paragraph.append($createTextNode(extractedText));
        root.append(paragraph);
      } else {
        currentChunk.forEach((content) => {
          if (content.src) {
            const src = content.src;
            const image = $createImageNode({
              src,
              altText: content.imagePath,
            });
            root.append(image);
          }
          if (content.text) {
            decode(content.text);
            const paragraph = $createParagraphNode();
            paragraph.append($createTextNode(decode(content.text)));
            root.append(paragraph);
          }
        });
      }
    }
  };

  const lexicalEditorConfig = {
    editorState: prepopulatedRichText,
    namespace: 'MyEditor',
    theme: lexicalEditorTheme,
    editable: !readMode,
    onError,

    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ],
  };

  function onChange(editorState, editor) {
    editorState.read(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      const nodeMap = editorState._nodeMap;
      const nodeArray = Array.from(nodeMap, ([key, value]) => ({
        key,
        value,
      }));
      if (readMode) return;
      onContentUpdate({ nodeArray, htmlString });
    });
  }

  if (!lexicalEditorConfig) return <ComponentLoading />;

  return (
    <Grid container wrap="nowrap" direction="column" height="100%">
      <LexicalComposer initialConfig={lexicalEditorConfig}>
        {!readMode && (
          <Grid item>
            <LexicalEditorTopBar />
            <Divider />
          </Grid>
        )}
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box width="100%" padding={6} sx={{ overflowY: 'auto' }}>
            <Paper
              elevation={3}
              sx={{
                minWidth: '688px',
                boxSizing: 'border-box',
              }}
            >
              <RichTextPlugin // #312D4B
                contentEditable={<MuiContentEditable />}
                placeholder={<Box sx={placeHolderSx}>Enter some text...</Box>}
                ErrorBoundary={LexicalErrorBoundary}
              />
            </Paper>
          </Box>

          <OnChangePlugin onChange={onChange} />
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          <ListPlugin />
          {/* <LinkPlugin /> */}
          <ImagesPlugin captionsEnabled={false} />
          <FloatingTextFormatToolbarPlugin />
        </Grid>
      </LexicalComposer>
    </Grid>
  );
}

export default LexicalEditorWrapper;
