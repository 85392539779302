import { styled } from '@mui/system';

export const ProcessingDocumentStyled = styled('div')`
  display: flex;
  width: 100%;
  padding: 16px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  border: 0.5px solid var(--grey-grey-500, #b3b2b5);
  background: var(--grey-white, #fff);
  box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.13);
`;

export const ProcessingDocumentTitleStyled = styled('div')`
  align-self: stretch;
  color: var(--grey-grey-1000, #03000f);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Sub Header/1 */
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 25px */
`;

export const ProcessingDocumentSubtitleStyled = styled('div')`
  align-self: stretch;
  color: var(--grey-grey-1000, #03000f);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.16px;
`;
