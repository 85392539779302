import {
  GridColDef,
  GridRowsProp,
  GridEditSingleSelectCell,
  GridEditSingleSelectCellProps,
  useGridApiContext,
} from '@mui/x-data-grid';
import { ISubCategories, IConfigData } from '../interfaces';
import React from 'react';

interface IGeneralCols {
  userPermission: boolean;
  listcat: string[];
  ClientsList: IConfigData[];
}
export default function GeneralCols({
  userPermission,
  listcat,
  ClientsList,
}: Readonly<IGeneralCols>): GridColDef[] {
  const getRoleValue = (value: string): string => {
    const newRoleValue = ClientsList.find((_) => value === _.subcategory)
      ?.role as string;
    return newRoleValue;
  };

  return [
    {
      field: 'subcategory',
      headerName: 'SUBCATEGORY',
      // renderHeader: (params: GridColumnHeaderParams) => (
      //     <b>{'SUBCATEGORY'}</b>
      //   ),
      width: 175,
      editable: false,
      type: 'string',
      headerAlign: 'left',
    },
    {
      field: 'parentConfig',
      headerName: 'PARENT CONFIG',
      width: 175,
      editable: userPermission,
      type: 'singleSelect',
      valueOptions: [...listcat],
      //valueSetter: (params)=>setRoleValue(params?.value),
      headerAlign: 'left',
    },
    {
      field: 'displayValue',
      headerName: 'DISPLAY VALUE',
      width: 175,
      editable: userPermission,
      type: 'string',
      headerAlign: 'left',
    },
    {
      field: 'configKey',
      headerName: 'ACTUAL KEY',
      width: 175,
      editable: false,
      type: 'string',
      headerAlign: 'left',
    },
    {
      field: 'configValue',
      headerName: 'CONFIG VALUE',
      width: 191,
      editable: userPermission,
      type: 'string',
      valueGetter: (params) => params?.value ?? '',
      renderCell: (params) =>
        !!params?.row.isSecret ? '*******' : (params?.value.toString() ?? ''),
      headerAlign: 'left',
    },
    {
      field: 'role',
      // valueGetter: (params) => {
      //   return getRoleValue(params.row.parentConfig.value) ?? params?.value;
      // },
      headerName: 'ROLE',
      width: 175,
      editable: userPermission,
      headerAlign: 'left',
      type: 'string',
    },
  ];
}
