import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { Collection } from 'components/CollectionProvider';
import { shorten } from 'common/utils';

import { grey, purple } from 'theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FilesContext } from 'components/FilesProvider';

type CollectionListProps = {
  collections: Collection[];
};

/**
 * Shows a list of collections displayed in a grid, with each card linking to the collections page.
 */
export default function CollectionList({
  collections,
}: CollectionListProps): React.JSX.Element {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const { setCurrentIndex } = useContext(FilesContext);

  const slicedCollections = collections.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  const handleSelectIndex = (index: string) => {
    setCurrentIndex(index);
    navigate(`/collection/${index}`);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Grid container spacing={3}>
        {slicedCollections.map((item: Collection, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
            <Card
              onClick={() => handleSelectIndex(item.index_name)}
              sx={{
                cursor: 'pointer',
                // background: '#f3f3f6',
                border: '1px solid #f3f3f6',
                boxShadow: 'none',
                transition:
                  'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.01)',
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                  '& .hover-text': {
                    display: 'block',
                  },
                  '& .normal-text': {
                    display: 'none',
                  },
                },
              }}
            >
              <CardHeader
                titleTypographyProps={{ variant: 'h2' }}
                sx={{
                  borderBottom: `1px solid ${grey[300]}`,
                  pb: 1,
                  mb: 1,
                  overflowWrap: 'anywhere',
                }}
                title={item.index_name}
              />
              <CardContent>
                {shorten(item?.description, 70)
                  ?.split('\n')
                  .map((e: string, index: number) => {
                    return <Typography key={index}>{e}</Typography>;
                  })}

                <Grid container justifyContent="space-between">
                  <Typography fontWeight={'medium'}>
                    Number of Documents
                  </Typography>
                  <Typography color={purple[500]}>
                    {item.num_of_docs} Document{item.num_of_docs > 1 ? 's' : ''}
                  </Typography>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems={'center'}
                  pt={2}
                >
                  <Typography fontWeight={'medium'}>Last updated</Typography>
                  <Typography color={grey[600]}>
                    {item.last_update_time}
                  </Typography>
                </Grid>

                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2,
                    '& .hover-text': {
                      display: 'none',
                    },
                    '&:hover .hover-text': {
                      display: 'inline-block',
                      // marginRight: 1,
                    },
                  }}
                >
                  <Typography className="hover-text">View Documents</Typography>
                  <ArrowForwardIcon />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container flexDirection="row" justifyContent="center">
        <Grid item paddingTop="13px">
          <Pagination
            count={Math.ceil(collections.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
