import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext, useMemo } from 'react';

import { SnackbarContext } from 'components/SnackbarProvider';
import { getFileExtension } from 'common/utils';

interface FullSource {
  fileSource: string;
  confidence: string;
}

const parseSourceString = (sourceString: string): FullSource[] => {
  const decodeUnicode = (str: string): string =>
    str.replace(/\\u[\dA-Fa-f]{4}/g, (match) =>
      String.fromCharCode(parseInt(match.replace('\\u', ''), 16)),
    );

  const results: FullSource[] = [];
  let regex =
    /(?:Sources:\[|Source:|'| )([^']+?\.\w+) \((Confidence:\d+\.\d+%)\)/g;
  let match: RegExpExecArray | null;
  const containsTwoSources = (str: string): boolean => {
    const match = str.match(/Source:/g);
    return match !== null && match.length >= 2;
  };
  const isDinamycIndexResponse =
    sourceString.includes('Index used:') && containsTwoSources(sourceString);

  while (
    (match = regex.exec(sourceString)) !== null &&
    !isDinamycIndexResponse
  ) {
    results.push({
      fileSource: decodeUnicode(match[1].trim()),
      confidence: match[2].split(':')[1].trim(),
    });
  }

  if (results.length === 0 && sourceString.includes('Index used:')) {
    regex = /Source:([\w\-.]+\.\w+) \((Confidence:\d+\.\d+%)\)/;
    match = regex.exec(sourceString);
    if (match) {
      results.push({
        fileSource: decodeUnicode(match[1].trim()),
        confidence: match[2].split(':')[1].trim(),
      });
    }
  }

  if (results.length === 0 && /Source:[^']+\.csv/.test(sourceString)) {
    regex = /Source:([^']+\.csv) \((Confidence:\d+\.\d+%)\)/;
    match = regex.exec(sourceString);
    if (match) {
      results.push({
        fileSource: decodeUnicode(match[1].trim()),
        confidence: match[2].split(':')[1].trim(),
      });
    }
  }

  if (results.length === 0) {
    regex = /([\w\-.]+)/g;
    match = regex.exec(sourceString);
    if (match) {
      results.push({
        fileSource: decodeUnicode(match[1].trim()),
        confidence: 'N/A',
      });
    }
  }

  return results;
};

interface MessageSourceProps {
  index?: string | null;
  source: string;
}

const MessageSource = ({ source, index }: MessageSourceProps) => {
  const navigate = useNavigate();
  const createAlert = useContext(SnackbarContext);

  const fullSources = useMemo(() => parseSourceString(source), [source]);

  const extractIndexUsed = (str: string) => {
    const regex = /Index used:(\S+)/;
    const match = regex.exec(str);
    return match ? match[1] : null;
  };

  const indexUsed = index ? index : extractIndexUsed(source);

  const navigateToSorceDocument = (documentName: string) => {
    const invalidIndex = 'default_doc_idx';
    const isValidIndex = indexUsed !== invalidIndex;
    const allowedExtensions = ['pdf', 'docx', 'txt'];
    const fileExtension = getFileExtension(documentName);
    const isValidExtension = allowedExtensions.includes(fileExtension);

    const navigateToSourceIndex = () => {
      if (!indexUsed) return;
      createAlert({
        severity: 'info',
        message: `Navigation is not available for document "${documentName}". Redirecting to `,
        link: { text: indexUsed, route: `/collection/${indexUsed}` },
      });
      setTimeout(() => {
        navigate(`/collection/${indexUsed}`);
      }, 2000);
    };

    const navigationDisabled = () => {
      createAlert({
        severity: 'info',
        message: `Navigation is not available for index "${invalidIndex}"`,
      });
    };

    if (!isValidIndex) return navigationDisabled();

    isValidExtension
      ? navigate(`/collection/${indexUsed}/document/${documentName}`)
      : navigateToSourceIndex();
  };

  return (
    <>
      {fullSources.map((source) => (
        <Box sx={{ mb: 2 }}>
          <div
            style={{ display: 'flex' }}
            onClick={() => navigateToSorceDocument(source.fileSource)}
          >
            <span style={{ fontWeight: 'bold' }}>Document Name:</span>
            <Typography sx={{ '&:hover': { color: `#A100FF` }, pl: 1 }}>
              {source.fileSource}
            </Typography>
          </div>
          <p>
            <span style={{ fontWeight: 'bold' }}>Confidence: </span>{' '}
            {source.confidence}
          </p>
        </Box>
      ))}
    </>
  );
};

export default MessageSource;
