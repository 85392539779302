export const IdentityManagementLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg3713"
      width="40"
      height="40"
      viewBox="0 0 168.96001 168.96001"
    >
      <defs id="defs3717" />
      <image
        width="168.96001"
        height="168.96001"
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAhAAAAIQCAYAAADQAFeJAAAACXBIWXMAAC4jAAAuIwF4pT92AAAG
    nmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0w
    TXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRh
    LyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDUgNzkuMTYzNDk5LCAyMDE4LzA4LzEz
    LTE2OjQwOjIyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3Jn
    LzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0i
    IiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJo
    dHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdEV2dD0iaHR0cDovL25zLmFk
    b2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlRXZlbnQjIiB4bWxuczpwaG90b3Nob3A9Imh0
    dHA6Ly9ucy5hZG9iZS5jb20vcGhvdG9zaG9wLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5v
    cmcvZGMvZWxlbWVudHMvMS4xLyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0Mg
    MjAxOSAoTWFjaW50b3NoKSIgeG1wOkNyZWF0ZURhdGU9IjIwMTktMTEtMjdUMTU6MDg6MTgrMTE6
    MDAiIHhtcDpNZXRhZGF0YURhdGU9IjIwMTktMTEtMjdUMTU6MDg6MTgrMTE6MDAiIHhtcDpNb2Rp
    ZnlEYXRlPSIyMDE5LTExLTI3VDE1OjA4OjE4KzExOjAwIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAu
    aWlkOmU1N2UzMmFiLWE1YTgtNGFjNS04MzY0LWEwNDk2NzdjZGY3OSIgeG1wTU06RG9jdW1lbnRJ
    RD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjMzMmVjZTgwLTAxZjMtNWY0Ni04MTNjLWUyYjM1N2Ex
    MzQ2ZCIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjYwMzExOTU3LWM3MDctNGFh
    Zi1iODhmLTY3NzQ0MWQ3NTg3MSIgcGhvdG9zaG9wOkNvbG9yTW9kZT0iMyIgcGhvdG9zaG9wOklD
    Q1Byb2ZpbGU9InNSR0IgSUVDNjE5NjYtMi4xIiBkYzpmb3JtYXQ9ImltYWdlL3BuZyI+IDx4bXBN
    TTpIaXN0b3J5PiA8cmRmOlNlcT4gPHJkZjpsaSBzdEV2dDphY3Rpb249ImNyZWF0ZWQiIHN0RXZ0
    Omluc3RhbmNlSUQ9InhtcC5paWQ6NjAzMTE5NTctYzcwNy00YWFmLWI4OGYtNjc3NDQxZDc1ODcx
    IiBzdEV2dDp3aGVuPSIyMDE5LTExLTI3VDE1OjA4OjE4KzExOjAwIiBzdEV2dDpzb2Z0d2FyZUFn
    ZW50PSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoTWFjaW50b3NoKSIvPiA8cmRmOmxpIHN0RXZ0
    OmFjdGlvbj0ic2F2ZWQiIHN0RXZ0Omluc3RhbmNlSUQ9InhtcC5paWQ6ZTU3ZTMyYWItYTVhOC00
    YWM1LTgzNjQtYTA0OTY3N2NkZjc5IiBzdEV2dDp3aGVuPSIyMDE5LTExLTI3VDE1OjA4OjE4KzEx
    OjAwIiBzdEV2dDpzb2Z0d2FyZUFnZW50PSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoTWFjaW50
    b3NoKSIgc3RFdnQ6Y2hhbmdlZD0iLyIvPiA8L3JkZjpTZXE+IDwveG1wTU06SGlzdG9yeT4gPHBo
    b3Rvc2hvcDpEb2N1bWVudEFuY2VzdG9ycz4gPHJkZjpCYWc+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6
    cGhvdG9zaG9wOmRkOTZlMTZjLWRjOTYtMTE3YS1iYzgzLWE3ZTdjMzFhMzM0YjwvcmRmOmxpPiA8
    L3JkZjpCYWc+IDwvcGhvdG9zaG9wOkRvY3VtZW50QW5jZXN0b3JzPiA8L3JkZjpEZXNjcmlwdGlv
    bj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoHpJbsAACZ7SURB
    VHic7d1Nchxpeh/wpwDwa6bbxMhaKMK2CHnlHaETEHMBEzqAg5gTNBXhfXP2jhjoBI0OH0BsXWDA
    E6i4V4TAcHgrA5rv7mGXFwmQBaCyKt/8qDc/fr+OahaqMrMeslCV/3zzyczZYrEIAIAUO7kLAACG
    R4AAAJIJEABAMgECAEgmQAAAyQQIACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkE
    CAAgmQABACQTIACAZAIEAJBMgAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAA
    AJIJEABAMgECAEgmQAAAyQQIACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAg
    mQABACQTIACAZAIEAJBMgAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJ
    EABAMgECAEgmQAAAyQQIACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQAB
    ACQTIACAZAIEAJBMgAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABA
    MgECAEgmQAAAyQQIACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQABACQT
    IACAZAIEAJBMgAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAsr3c
    BQzZ//6f/7Ttlzy4vq0yj4jLLdUBvfN//u9vYja7/mEWMbu+8+mxiOv7s6X71z9d/292a96b+7Ol
    +59mLx7fNG3Jay4/fnuZ92tZfnzV8je+7vK0ax6fov/xv/577hIGTYDor8OIOLr+8zAinlec711E
    XETE+fXtotWqACAEiL45jIiTiDiOiGc1l/Hi+vbq+uf3EXEWEW9DmACgJXog+uEkitGCf46Ir6J+
    eFjleUT8KiL+NYoQcdTisgGYKAEir6MoRgW+iWLUoGsvI+LXUYSVwy28HgAjJUDkcRDFaMCvo93R
    hqpeRDHacRoR+xleH4CBEyC27ySKIyZe5i0jIordJfOwWwOARALEdp1FsbviaeY6lj2LYiTkTeY6
    ABgQAWI79qPY0n+1frKsvo4i4ADARgJE9/ajaFqseh6HnF5F0Zuxn7cMAPpOgOjWfgwnPNx4GUVz
    JQCUEiC6sx/DCw83XoXdGQCsIUB05zSGGR5uvIqI17mLAKCfBIhuvI5+N0xW9atwiCcAKwgQ7TuI
    cR0SeRaaKgG4Q4Bo31n06zwPTT2LcQUiAFogQLTrJLZzTYtt+ypcOwOAJQJEu97kLqBDp7kLAKA/
    BIj2nESeC2Nty4vQUAnANQGiPW9yF7AFr3MXAEA/CBDtOIpxjz7ceBnFUSYATJwA0Y6T3AVs0XHu
    AgDIT4Box3HuArboJHcBAOQnQDR3HOM678Mmz8NuDIDJEyCaO8pdQAZHuQsAIC8BornD3AVkcJi7
    AADyEiCaG+OZJzc5zF0AAHkJEM0c5C4gkymGJgCWCBDNHOQuAAByECCo6yh3AQDkI0A0c5S7AADI
    QYAAAJIJEABAMgECAEgmQDRznrsAAMhBgKCu89wFAJCPANHMRe4CACAHAaKZi9wFZPIudwEA5CVA
    NDfFlelF7gIAyEuAaG6eu4AMznMXAEBeAkRz57kLyOA8dwEA5CVANHeeu4At+xB2YQBMngDR3GVE
    fJe7iC06y10AAPkJEO04y13AFp3lLgCA/ASIdryNYmh/7N6F3RcAhADRprPcBWzBm9wFANAPAkR7
    TiPiKncRHXoX02sYBaCEANGey4h4nbmGLr3JXQAA/SFAtOssIt7nLqID34XRBwCWCBDtO8ldQMuu
    Ynx/JwAaEiDaN4+IX+YuokUnUeyeAYBPBIhuvIlxXGTrH6I4RBUAbhEgunMcw+6HeBfjbgoFoAEB
    ojuXUYSIIR7a+T6K2gFgJQGiWxcRcRTDChHvo6j5Mm8ZAPSZANG9eUQcxDB2Z7wL4QGACgSI7biM
    YsXc56t2fhvCAwAVCRDbcxlFX0HfDvG8ioi/C+d6ACCBALF9byLib6MfuzTeRcRhOFQTgEQCRB7z
    KFbcv4g8lwH/EBE/j2KXxUWG1wdg4ASIvM5iu0Hi/fVrHYRrWwDQgACR32UUQeIgil6Eb6Pdwz6v
    rpf5t1GElbMWlw3ARO3lLoBb3sbnfoTjKFb4RxHxImEZV1HsIjm/Xta8hboA4BYBor/exu3mxv0o
    AsXd++dL08zDYZgAbIEAMRyXcTssvM1SBQCEHggAoAYBAgBIJkAAAMkECAAgmQABACQTIACAZAIE
    AJBMgAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAMgECAEgmQAAA
    yQQIACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQABACQTIACAZAIEAJBM
    gAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAMgECAEgmQAAAyQQI
    ACCZAAEAJBMgAIBkAgQAkGwvdwHARgd3bvsRcbj0/Isay/wQERd3HptHxOX1/fM7f9Keg+vbYRTv
    5c3PN889q7HMd3d+vozi/Ywo3uflG7RCgIB+OYpixXIYxcqkTjio4lncX1Etv9bXS/evolgZXVz/
    OQ/Boqqj2PB+LiJidm+2lEcjSpb7crZ6pvdx+72ch2BBDQIE5LMfxQrm5vY8XylrPY1iBfUiIl4t
    Pf4+iiBxc7vcblm9dLR0exFxs/4uW/XffnxNQLg3xeZpSz2PiOexiJdLC7iK2+/lvN6imRIBArbr
    MCJOot+Boarn17evrn++CRRnMZ0V0EEU7+VxRLxstFq/I21J6wNKhWU9jYiX17eIz4Hi7fXtsnIp
    TIYA0Z7z3AVswdn1jTTHS7enOQvp2HKg+BDFiucsxhcm9qN4L19HWQhcXmMv3V8sImYr7pdNf3N/
    ERGzsmnu/7hWxUixHCi+iYjvQpjgDgGiPV3tq+6T89wFDMhBFCuY46jXFDd0z6IIEjdh4jSKMHGZ
    raLmjqMYPXr56ZFFxGJ2vSpub/BhrXsvcydYdFTPcpj4Nor38ry1pTNIDuOEdp1E8cX6r1GsPKcY
    Hu56FhG/ioj/F8WK5yhnMYn2owiCFxHxjxHxMhblEy9K7q+3KJm2bAkpS14xy2Ll3RSvIuLXUfyb
    nETxb8QECRDQ3H5EvIli6/qbmMZoVF03K5/z6HeQOIhi1OQiivCTEATvr5YXJY9Xm7vqtDXiQM0E
    ce1ZFL/vF1H8/u83WhqDI0BAfQfxeSXzdYy7v6FtL6KfQeIgIs4Wn0eQnpaPKtRb+1aaa5H+CuXT
    lsWXZulhydMofv8vQpCYFAGCFPu5C+iJ/Si+KD+tZHIWM3DLQeIgYx37Ubyn87h9qGqUrWg3rn7L
    ksfyLoQq+zyWw0S9fSRrS2vRcpB43c1L0CcCBCkOcxfQA2/i84gD7XkRRSA7je0H1ZNIHUW6syJf
    rLqfqNFow5p6MngaxW6fi+jX6BItEyCgmuOwq2Ibvori3/l4C691GMWIwzcR8XTj2raVFoPUxsjN
    j28qa2MjZXch41kUo0tnYfRylAQIWO8giuH1fwxHVGzL0yj+vd9GNyue/ShGkv45Ip7fW6+u2GWQ
    vo5t1nFQf09F8yM3OvAqthcK2SIBAsq9jmIL1VEVebyM9ofBDyMW53F3F1TC+rV2I2XZ4ZMVeiWq
    JorKoxGV52jNTSg8DaMRoyFAwH37UYw6/CrsrsjtaRTD4G9aWNbruB51iIjEdefmRspNuwrKZmyz
    92H5fnqY2IqvovhsHeZ5edokQMBtx1Fs9Rp16Jevo1jx7NeYdz+K3SG/2jxpzaGIRflTKVKO96j8
    GjVGMjr2PIr38iRbBbRCgIDPTqMYZjXq0E8votildJgwz+GimOdlxO2GwcYr4Bqzr19I6s6RGo2U
    Zc9vP0w8jaJ59c3WX5nWCBDweZfFV3nLoIJnUf3kU8cRcR6Lzc2vaxspV0xTPvf6x7dxiOemerK2
    U973dbhA32AJEEzdYWiUHJqbvoiTNdOcxNrRpBWr0Xb2XlSesfQkUhvuL9ZNk1pPzTla9iqKz+B+
    3jJIJUAwZUdRbM06PHOYvonVIeIsFvFNpSW00Ei5/HRSI2XdMtbNV9ZIWfIC2aPDZzd9Eft5yyCF
    AMFUnUSxFavfYdjuhoizuHUq6jojDTWHIuov5dZcDVsm1yx5xaJqHg3SESFiYPZyFwAZnERU3EJl
    CG7ey6NYCg+LiJi1cH/zk+sUE9+fvdpCNk/1eYrb0yYVWXuWDtyEiKMorm5LjxmBYGpOQngYo29+
    +pMHr0q3olc+sXmXxN2pyvdMdLj9vqKOirOUPtOD0YZ1jEQMhADBlJyE8DBaf/Gzx/Ho0e7nB+qs
    JWvu3kiaraSRMu+VOXsXKZ6HozN6T4BgKk5CeBi9v/zZk3jwIPFrrem6M7FxMXWRydN2UE8mL0OI
    6DUBgik4DuFhEnZ2ZvGXf/EkdnaWd+Z30EhZcWW8fpBgwzBDjcc3lTWARsq7XoUzVvaWAMHYHYat
    mEnZ3d2J//gXTyIi6fQKm+/X6EUom6PuCEPaa6cGkeQX2JZvot0LqtESAYIx24+iGcuhmhPz6OFu
    7D99dPvBqo2UCWvsNhopF6U/lDxeY/Sj2vP9TA/X3kbEQeYauEOAYKz2Q3iYtC9++jCePKp4pHrS
    ujOhkTJxqKNSmFh+utohIjXCRO88jSJE0CMCBGN1GjeXbWayfrb/OHZ3d6KjQzJWT7qoNWDQ5NXX
    T1sxZAzA83DxrV4RIBijk7h1NkKmamdnFj/bf/zp51vrz6aZosb8dRspN7dJ1mikLHkww5U5U3wd
    +iF6Q4BgbA6jGH2AiCj6Ib746cPih5KVY2rz5LYbKevPtz5w9DsrlDoLJ5nqBaeybqDnSX0QZu2f
    Ovcs9D1wx5dfPow//PFjfPz444pnF3HvHM4rHipze9J6My4WS5+F5UVsuL+IiFnZNGnV1J4jg2dR
    7Mp4nbcMjECQTQfh4TT0PbDCzmwWP9t/tH6iNhspF7fvp7xGa9slZX0ZFUZhBuCrsCsjOwGCsTiK
    4ksFVnr0cDd+8uRB4lw1GynrL6XCfCmPblhulaNB+ussdwFTJ0A0snBrdGvVWdsLZHyePn0Ys6Wz
    VN66FkWsvn9P4ojC3Ynvz162kMWan9ZP3/jTNYwEcbMrg0wECLKYtbv/4k0UXyaw1s5sFl/eNFTe
    Ur7GXCw9XS07dL/2TXuF9QFlGFmh1OvQUJmNJsoGNFHW12J+OIji0C6o5IufPojf/f6HkobKJYuo
    1Ui5cbaSiZcbKVOaKhfRXiNlMW3KHNk9jaL36SRvGdNkBIKhO8tdAMPzs7unub5RY5fEuqcXS/e3
    4d7L3Bky2XY9W/IqisO32TIBgq27faXERo4i4kVbC2M6Hj7cjUcPd4sfNq5MNweFKpOun6x8N8Pq
    Z6r1TayzMkyU9IQMwGnuAqZIgGDITnMXwHB9+UXRC1G2Yl97v/batXp0qDZ31WlrFDysBPEiHNa5
    dQIEQ3USzvlAA7dGISIiaaRhxUONV9hV51pu6Kz4MpsaPpvFmt54k7uAqdFE2YAmyqze5C6A4fvy
    i4fxp3/7Q/kESR2RGx7d0Ax5935pI2VZFVWWX8GgWihvuxmFOM9bxnQYgWCrdndb+Wo6CYdt0oL7
    oxDRfOO7rHGxwaJb280x3kbKG69zFzAlAgRD9CZ3AYzHkycP6jVSNkwGKY2UdR/fVNbGRsrhhYyX
    URzazRYIEI0s3JJvjZ2E0Qda9JMne7Gz+/mrsCwXLPccNMsOzToO6r92ahAZrDe5C5gKAYKheZ27
    AMbni5/eXCNjw0jDBos1P1WdcVHy+Kb7i3XTVKimWazplVfh7JRbIUA0kHtbfmi33d3Gv25H4cgL
    OvDkyV7szO6cvrGy1ROvXJdXGT4oCxNN3Hnd9DAxOCe5C5gCAaKJ3Gvkod2aO2llKXDHzmwWjx5v
    Oigt4Zd4TVCo+1HYHFNqvMZymKgSbobjde4CpkCAYCgOohiahE789CefL/WdsgJOn2ndLBWGJSq9
    1GLFvXoG2EgZUfRJHeUuYuwECIbiJHcBjNuDvZ14sHf/K/FeRliUPL5StUCwfhnrpc23vp5hZoVS
    J7kLGDsBgqE4yV0A4/fkyYNYuRptZ+9F5YkXJfdLF74calrafTKCSPEqNFN2SoBoIHdLwZBueyu2
    7BIchUM32YLHj5b6IJLWnRsmLus16NC9l7kTLLZdTybHuQsYMwGiidxr5SHdmjlpvASoYHd3djtE
    3JPwy7xm0nofCVfmrOEkdwFj5loYDMFx7gIG6l3J44cR8XSLdQzK40d78cc/fYxFVLy0xNon1ykm
    vj/7rYV8iIiLkrkPZhVH5m6XlVRk7Vl64kUUDdgXecsYJwGikQHn8uE4Diu7TT5EcQGh8yi+KM8r
    zLMfRZA4iuLf+HnrVQ3U48e7Ef++YY15/XS1kFF57fsubr+PFxVLPlhEHM6K9/IoNryX5dWsiC/j
    cBwRp5lrGCUBgr47zl1AT32IiLcRcRYR8xrzX8bnldWbKALFSRTHz0+632Q2m30ahbhl45p19QQb
    ZvsuFvE2ZvE2ivekjovr29vr1zuYLeI4ZteXvG+QCEYSKY5CgOiEHogGcrcVDOnWwHGz2UfnQ0T8
    Ioph2ddRLzyschnFl+xBRPw8ynd/TMLDmyt0Nh1kvNNTsNRr8G1E/E0Uv99nUT88rHIRxXt5uIj4
    24j4tkI9Y/YyHI3RCQGiidxr5YHcHj64c7nk6o7D7osbV/E5OJx1/FrnUWy1/SKKwDI5jRop1z/9
    LorgcBLb2S8/v36tv1lEfLduwpVhYun+QE8odeM4dwFjJEDQZ0e5C+iJ72I7weGusyj6JL5dP9n4
    7O7O4sHeTtm69P79xf3H77iKiL+L4nf6oo0aE11EsRL9eUR8KK902ClhjaPcBYyRAEGfHecuILOb
    UYfjaHeIO8VlFFuwv8j0+tk8fLji67EsRayw9PT7iMVhXPcoZHYexa6NaqMRax4ZmOPcBYyRANFA
    D/YODOJW00FMu5nvKoqtprO8ZXxyFsX+9KvMdWzNp90YSb/E9yb+LvKNOpS5jGKF+vex2PzXG3x0
    KDyNYjSNFgkQ9NVx7gIy+hDFSmeet4x75lHUNYkQ8amRcqVKq9VvI+/o0SansTyyVGV/zbAd5y5g
    bASIJnJv2g/lVs9R7TmH7SqKL7p53jJKzWNiIaLyr/Dtle67GMZZEM9iKURsHI0Ydpg4yl3A2AgQ
    9NVR7gIyuNltMc9bxkbzmMjW3KM7oxD3NswXKx9/HxHHsRjMCvcsbvW4LJb+PyovchcwNgIEnXr0
    qNYhnIcxzcM3T6L/4eHGeUT8fe4iuvbwwYZGyvuuongfLzsop0tnEfEP6ycZRaQ4yl3AmAgQDSz8
    t/G/mg5bfJuG4pfRjy79FKcx8hNO1Tih1OsYTgi863Us4v2qJ0YRHQqHuQsYEwGCPjrKXcCWvY/i
    dNJDdBIj74d4UHop+pVHXJx1WkxLdmazmM2iuN1+6nhx836WNFIOPEwc5S5gTAQI+ugwdwFbdpy7
    gAYuYrjhp5K7jZQlK9CrKLbgIxaLWCzdfvy4iI8fF/Hx44/x8c8/bqHiRi5i0/s57ARxmLuAMREg
    msh9dMMQbvU8rz3n8Pwy+nWOgDpOY8SnvC4fgbjVSHkaw38fb5xGLD5EDD0rrPQsinPM0AIBgr45
    yl3AFn2I8Vwl8E3uArqyt7mR8ioiTqtm6h/+/GP8kGEkYm93Fru7s9jdWdp9sepWTP767vwjOjbj
    IHcBYyFANJB7434ItxoO6802SG9ieN36Zc5ipKMQn0Ygyn+hT2M87+ONtxGrGypH4Ch3AWMhQNA3
    h7kL2JIPMZCGuwRvchfQlfKzUi6uIuK0Trr+/vsf4/vvP3Zdeuzt7cTe7k4UYwspt+u/143FyrtD
    dJi7gLEQIOibg9wFbMmb3AV04CxGekTG7u6sbF16FuMbfbhxFmWjSsNOEAe5CxgLAaKJ3PsHhnBL
    96LWXMMyxtGHG2e5C+jC3u6s7KnTpudK+dP3f+6k5gd7u2sbQCt6e/9jPOz0ENNq0u6UAEFnnjzZ
    S53loIMy+ugsdwEdOstdQBcePlhxQqnipEsXGcrZ6MGDWmeAXeVs+YfBR4fPDnMXMAbJ3/B81uBM
    i6x2kLuALTnLXUCH5lGMsDzLXEerdndXbmudtvUV8Mc/FqMQj9NDd9fmsYgPMRvX+xnFd808cw2D
    ZwSCPjnMXcAWfBc93Wpt0dvcBbRtd/UujLdtv84f/9Bsd8bDB7ufR0va8/bTveVGymFvPx3mLmAM
    BAj6ZD93AVvwNncBW3CWu4AuPNjbWV5/vo+eNU92EBxurOiDGLyD3AWMQe/GywZlhJ+qzA5zF9Cx
    qxjpyvWOeRR/16eZ62jV7Pbm1llXH//f/+HP8ZMnDzpaei3n9x9axL2raAzLQe4CxsAIBH2yn7uA
    jp3nLmCLznMX0LY7J5Q67/K1fv+HH+J3v/+h8vSPHu2mn+Yh6ZQQn6+6OpLtpoPcBYyBANFA7iMk
    +36r4bDebIPxNncBWzTPXUDbdnY+bXFfRU/+fo8e7sWjh3vb+EDPP73och9Ed3+1ro2tKTQLAYI+
    GdWQ9wpvcxewRee5C2jb7k7xdbmImC8WRRNh17ff/q58FOLxo63ugZ6vfHTACSLGP+LZOQGCvjjI
    XUDHetd017F57gLaVhyJsYjYcjhaFSK2HB4iIi6GnRVWOsxdwNBpomxihJ+ojA5yF9Cx89wFbNll
    jPB8ENfOt/2Cv/ntD/HlF1kbK8+Xf1hExGz4jZQ0ZAQCtuM8dwEZXOQuoE1LF9Sa53j93/y2GIl4
    /Hiv24bJ8kbKsTnMXcDQCRAN5G5S7Pst0WH6LINynruADOa5C+jAVWTcFfXk8VYaJstun47EGEkj
    5X7uAobOLoxGBvzR6Z/93AV06ENMq//hxmXuAjow97m/w56MyRIgoHvz3AVkch4RX+cuok2PHu7+
    e+4aMrpYRLy4nRUGnR72cxcwdAJEEzZE2nSQu4AOzXMXQDt2d3f+LXcNGV0s/zDo6FA4zF3A0AkQ
    9MVB7gI6dJ67gEwuchfQtt3d2b/krgH6QoBowAAEFV3kLiCTi9wFtG02m/02dw1ZLQ87LN1fLCJm
    Ax+OIJ0AAd27yF0A7djJHiByb7ZICXwmQEC33m2ehKF48mQv6y6Mjx9/zPnyEbGq92EE3RDU4jwQ
    9MV+7gLoxFXuAuhO7vEQ8jIC0YRPT5ue5y6gI+e5C8hsHhEvchdBi0r6IJgeAaIB+QEm5yDni+f+
    zlmXFTRSTo8A0UjujzMDMM9dAK06yF1AXsWQg4EHIvRAQNcucxcALTkvf2qQG1MHuQsYOiMQAEOR
    ez29YthhwKMRz3IXMHQCRBO5P8wMwXnuAqAtn8JC2QmlYrBhghoEiAbkB4AlEsSk6IEAIMFi6f9M
    mQABQCMixTQJENAdZ2FkXGQEluiBaMKHifXmuQuAtmmk5IYA0YD8AEzTmpggQUyGXRgA1HJ/I8pm
    1ZQYgWjEhwXYntzfOGuvhbHhecbHCAQA1S1K7jM5RiCa8OEBJmjlaMNyI6Urc06CANGA/MAGB7kL
    gO64MufUCRDQHRfrKQ5lfZG7iLb88MOPB1kLGMSaWqSYCgEC6NJl7gJGJfOw52K24hwQy8+vfpiR
    EiAAKvr48ce/yvn6u7v96Xt3QikEiCY0QcCkLCIe565hMCSI0RMgGpAfgAmZ339II+WU9Wc8DIA+
    u6wykStzTocRiEZ8SGBKdmYTbwpdbG6kZDoECICKdnZml7lr6BONlNMmQABUlHvMcXArYwli1ASI
    Bha5v00AsitrpJQexk4TJQBJNm072baaBgECgOrKrsYpNUyOAAFAbStzw9KDdvWOlx4IgMHow9p4
    VV+DfocpEiAakKyBberDV866mKCRclrswgAgzWIpzJSkmj6EHbplBKIRHxGATSeUYpwECICKHj7Y
    vcj5+t//8DHny9e2WETMhInRsQsDgARlI68uozU1RiAa0EQJG13mLoB2peyZcLHvcRMggC7Ncxcw
    Kn3YaFnqcXBlzmmzCwOAxlYelbFYeZeRECAASFQjDkgQo2MXRgM+D8DEXEXE0/I9Fq7MOSUCRBMS
    BDAt84h4kTKD6DBedmEAUN2dHodNZ6RkvIxAAAxEH9bRla6FUXJGSieUGhcBopE+fJwBcijbOWGn
    xVQIEA2ID8BUbYoJGinHT4BoQoIApujOLopPJ5SqMDnjoYkSgHaUnETKxtY4CRAAVLYpC2x8XpgY
    DQECgJpcmXPK9EA04MMBbFUfvnSWmhnSext0Q4yJEQgA0iWeUKoP2Yd2GYFowicC4J6NJ5QK4xBj
    YAQCgCSLNT/VWAADZQSiEZ8CYNrSr8zJWAgQDYgPwOQ0SAQixbgIEAAD0YeNllur/rIzUsoIkyBA
    NNGHTzP020XuAsalD186RTKofC0MjZSjpYkS6NJF7gLYhvUnlEp9imEQIABoJP301tLDGNiF0YCP
    ADBJJbslVvy4dlaGzQgEAFVdrN1wcjXOSTEC0YQPBTAtF8tjCLVGG5YbKRcRM8MRg2UEAoAWuDLn
    1BiBABiKPqyFa/Y+3KcbYugEiAYWvfg0b9U8dwFADyWeUEp0GAe7MEhxmbsAIL+UTaeVl/lerLzL
    wBiBaMJvPjBJ1RspExbFwBiBIMVl7gKAPtNIOSVGIEgxz10ATFkfVsCzBieRWubKnMMnQDTQhw8z
    QA73Vv2uzDk5dmGQYp67AKAvqm9CaaQcJyMQTUztt36mBwK47/YgQ40hB6MUgyRAUNX7lMD0n//T
    l91VAvRWeRYo63qQHobKLgyqusxdAJDf4tP/lh9osCwGywhEAxM7E+V57gKAftFIOW1GIKjqIncB
    QN8sVtzbMGVZI+WktsfGQYCgqovcBQBDsf6EUoyDXRhNTOmzMLMLA7Lrw3fO7HqPxJrdEpv2WGik
    HAcBgiqSjsAAJqbs7JTLfRAVZmVYBIgGJrROnecuAOinygGgrKlSghgsPRBUcZ67AAbtXe4C6FL1
    RsqNS5rQVtkYGIGginnuAoB+jHpWHywoG1pwGa2xECCa6MOnuXtXMRMggGvXa/57jZSuzDk5AgSb
    nKcGpb/+L/+hm0qAXnJCqWkSIBqYyJko3+YuALiR/Ttnvm63RBWfpiwZvdhixtCb05AmSjY5z10A
    0BuXmyZYrPmpkuwZiaoECNZ5H85ACSxJWb+XT7tIXhb9YxdGE+P/7T/LXQDQQxUbKdMXqUliSIxA
    sM7b3AUAw3Bve+rORbNWXkyLQTMC0cDIPwfvZ3ZfAPesP7/DuinuTZm/kZIGBAjKnNUJSAd//bT1
    QoB+SVvBbw4cDV+ATAQIypzlLgC4ow/DniUXzXJlzukRIJrow4e5G9/GbPPhWgCbTiLlypzjJUCw
    yumIwxHQkZQrc7oa5/AJEA2MdB37bubiWdBLffjOWd8mWaORsuTBxSJiJlj0mgDRSB8+zq17U/dv
    9TfP9tusA+ixNhspDUIMk/NAsOxdOHU1sM7i/v1FyeMlPya+CH1lBKKJ8f2Ov8ldAPTcPHcBg5N4
    ZU6jEcNhBIIb34bRB9jkMncB/bF6C6rWtTJKRi/Gt402LkYgGhjRL/dVRLxusoD/erDfSiFAv20e
    IShrpKwxtmA4oteMQBBR7Lq4zFwDMDDtbES5MudQCRC8i4jT3EUAA9JxI6VIMQwCxLRdRcRx04XY
    fQEsc2XOadAD0cTwf/mPnbIaqM+VOadMgGhg4PnhFzNHXQA11D8TtStzjokA0chgI8S3EbOzNhZk
    9wVMVI0U4cqc4yJATM+3EXGSuwighj5ss6xZny8iYrbmapzL89a6Vga9IkBMS6vhwegDbFfP88Mt
    awNAxZBBvwkQTfTh01zd38fM4ZpAOza3Sboy59gJEA0MJD9cRcTJLOJtmws1+gDccGXOaXIeiHF7
    FxGH0XJ4ACas7GRRrsw5OQLEOF1FxC8i4igiLtpeuNEHoJbEE0qJDv1mF8a4XEVxWurTcG0LoAOr
    dzGkPLphuU4oNRgCRAOL/sTjDxFxFhGns47PLGn0AXLqw5dOyvU1qzdSbiRB9I4AMVxXUfQ2nIUz
    SgKZaaScHgFiWD5EERbeRobGSKMPQERsPI/D2hNK3f+xwkuJFH0kQDTS+XDiVRSB4fo2m3f9ghn9
    PHcBdOb1737/w3/7/oePf5WziNmKe7eeX7N++smTvX958GD3txExb7eqwZlH4mf13qr/TrBYzFb0
    PmzH5VZfbYQEiH55H8UH9Pz6z3m+UrbuPHcBdGYe0/pdHrPL8FnlmgDRQMMmyndRfKleRMR8NvOh
    BGA4BIhuvY/Pif0yPgeGizzlAEA7BIj2/DKKgHC59CcAjNJs0aOTGQAAw+BU1gBAMgECAEgmQAAA
    yQQIACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQABACQTIACAZAIEAJBM
    gAAAkgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAMgECAEgmQAAAyQQI
    ACCZAAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQABACQTIACAZAIEAJBMgAAA
    kgkQAEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAMgECAEgmQAAAyQQIACCZ
    AAEAJBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQABACQTIACAZAIEAJBMgAAAkgkQ
    AEAyAQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAMgECAEgmQAAAyQQIACCZAAEA
    JBMgAIBkAgQAkEyAAACSCRAAQDIBAgBIJkAAAMkECAAgmQABACQTIACAZAIEAJBMgAAAkgkQAEAy
    AQIASCZAAADJBAgAIJkAAQAkEyAAgGQCBACQTIAAAJIJEABAMgECAEj2/wENqiS8vZaZJQAAAABJ
    RU5ErkJggg==
    "
        id="image3721"
        x="0"
        y="0"
      />
    </svg>
  );
};

export default IdentityManagementLogo;
