import React, { ChangeEvent, useState, useContext, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ComponentLoading from 'components/ComponentLoading';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IUploadData, ICategories } from '../interfaces';
import { IConfigData } from '../interfaces';
import insertNewConfigData from '../services/insertNewConfigData';
import { SnackbarContext } from 'components/SnackbarProvider';
import CreateIdForPayload from '../utils/CreateIdForPayload';
import ReqMessagesList from '../utils/ReqMessagesList';

type UploadGraphDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setUploadData: (value: IUploadData) => void;
  setNow: (value: number) => void;
  cleanState: () => void;
  _categoriesList: ICategories[];
};

type TReq = { message: string };

export default function UploadRuleDialog({
  open,
  setOpen,
  setUploadData,
  setNow,
  _categoriesList,
  cleanState,
}: Readonly<UploadGraphDialogProps>): React.JSX.Element {
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [message, setMessage] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [category, setCategory] = useState('');
  const [scratchConfigCheckbox, setScratchConfigCheckbox] =
    useState<boolean>(false);
  const [newConfigFromScratch, setNewConfigFromScratch] = useState<
    IConfigData[]
  >([]);

  const [reqMessages, setReqMessages] = useState<TReq[]>([]);
  const createAlert = useContext(SnackbarContext);
  const handleDialogClose = () => {
    cleanLocalState();
    setOpen(false);
  };

  const handleCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const cleanLocalState = () => {
    setCategory('');
    setSelectedFile(undefined);
    setMessage('');
    setScratchConfigCheckbox(false);
    setReqMessages([]);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage('uploading...');
    setCreateLoading(true);
    setSelectedFile(event.target?.files?.[0]);
    setMessage('');
    setCreateLoading(false);
  };

  const handleUpload = (selectedFile: File) => {
    if (selectedFile) {
      if (!scratchConfigCheckbox) {
        handleConfigUpload(selectedFile);
      } else {
        handleNewConfigUpload(selectedFile);
      }
    }
  };

  const handleConfigUpload = (selectedFile: File) => {
    setMessage('');
    setCreateLoading(true);
    if (selectedFile && category) {
      const Reader = new FileReader();
      Reader.onload = (e) => {
        const content = e.target?.result as string;
        const jsonData = JSON.parse(content);
        jsonData.category = category;
        setUploadData(jsonData);
      };
      Reader.readAsText(selectedFile);
      cleanLocalState();
      setCreateLoading(false);
      setOpen(false);
      cleanState();
    } else {
      cleanLocalState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };

  const handleNewConfigUpload = (selectedFile: File) => {
    setMessage('Creating new configuration rules...');
    setReqMessages([]);
    const Reader = new FileReader();
    Reader.onload = (e) => {
      const content = e.target?.result as string;
      const jsonData = JSON.parse(content);
      const jsonDataWithIDs = CreateIdForPayload(jsonData, _categoriesList);
      setNewConfigFromScratch(jsonDataWithIDs);
    };
    Reader.readAsText(selectedFile);

    const messagesFromRequestPromise: Promise<TReq[]> = handleAlertAtRequest(
      insertNewConfigData,
      newConfigFromScratch,
    );
    messagesFromRequestPromise
      .then((messages) => {
        setReqMessages(messages);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          setMessage('Error inserting rules promise');
        }
      });

    setMessage('messages from server:');
    // cleanLocalState();
    // setOpen(false);
    // cleanState();
  };

  async function handleAlertAtRequest(
    action: Function,
    requestData: IConfigData[],
  ) {
    try {
      setCreateLoading(true);
      const msg = await action(requestData);
      setCreateLoading(false);
      return msg;
    } catch (_e) {
      setCreateLoading(false);
      setMessage(`${_e}`);
    }
  }

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Upload new set of <b>rules</b>:
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Checkbox />}
          label="Configuration file for more than one category"
          color="theme.pallete.secondary.main"
          onClick={() =>
            !scratchConfigCheckbox
              ? setScratchConfigCheckbox(true)
              : setScratchConfigCheckbox(false)
          }
        />
        {!scratchConfigCheckbox && (
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Category"
              onChange={(e) => handleCategory(e)}
              fullWidth
            >
              {_categoriesList.map((_: ICategories) => (
                <MenuItem key={_.categoryLabel} value={_.categoryLabel}>
                  {_.categoryLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!scratchConfigCheckbox && (
          <TextField
            autoFocus
            margin="dense"
            id="example"
            label="Example configuration file for one category"
            placeholder="Example json file"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={11}
            defaultValue={`{"data": [{
                "configKey": "client_id",
                "configValue": "config",
                "displayValue": "display",
                "enabled": false,
                "isSecret": false,
                "parentConfig": "parent #1",
                "role": "TURING_ADMIN",
                "subcategory": "test_subcategory",
                "label":"test_subcategory"
              }]} `}
          />
        )}
        {scratchConfigCheckbox && (
          <TextField
            autoFocus
            margin="dense"
            id="examplefromscratch"
            label="Example configuration file for more than one category"
            placeholder="Example json file"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={11}
            defaultValue={`[{"categoryLabel": "test_category",
              "data": [{
                "configKey": "client_id",
                "configValue": "config",
                "displayValue": "display",
                "enabled": false,
                "isSecret": false,
                "parentConfig": "parent #1",
                "role": "TURING_ADMIN",
                "subcategory": "test_subcategory",
                "label":"test_subcategory"
              }]}]`}
          />
        )}

        <Button
          fullWidth
          component="label"
          variant="outlined"
          color="secondary"
          disableElevation
          startIcon={selectedFile ? <CloudDoneIcon /> : <CloudUploadIcon />}
        >
          {selectedFile
            ? selectedFile.name + ' uploaded.'
            : "Upload '.json' file"}
          <VisuallyHiddenInput
            type="file"
            accept=".csv, .json"
            ref={inputRef}
            onChange={handleFileUpload}
          />
        </Button>

        <p style={{ color: '#CB2939' }}>{message}</p>
        {reqMessages.length > 0 ? (
          <ReqMessagesList payload={reqMessages} />
        ) : (
          ''
        )}

        {createLoading && <ComponentLoading />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={createLoading}
          onClick={() => handleUpload(selectedFile!)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
