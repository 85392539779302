import {
  ChildProcessStatus,
  IChildProcess,
  UploadStatus,
  UploasStatusResponse,
} from 'types';
import { camelize, decamelize } from './stringStyler';

export const getUploadStatusData = (data: UploasStatusResponse) => {
  const rawObj = data.Status.result[0];
  const childProcess = rawObj.ChildProcess.map((subTask, idx) => {
    let newObj: { [key: string]: string | number } = {};
    Object.keys(subTask).forEach((key) => {
      const objKey = key === 'TaskID' ? 'taskID' : camelize(decamelize(key));
      newObj[objKey] =
        rawObj.ChildProcess[idx][key as keyof ChildProcessStatus];
    });
    return newObj;
  });

  let obj = {
    fileName: rawObj.FileName,
    userID: rawObj.User_ID,
    userName: rawObj.User_Name,
    chunkSize: rawObj.ChunkSize,
    index: rawObj.Index,
    status: rawObj.Status,
    taskID: rawObj.TaskID,
    createdAt: rawObj.CreatedAt,
    updtatedAt: rawObj.UpdatedAt,
    childProcess: childProcess as unknown as IChildProcess[],
    totalFiles: rawObj.TotalFiles,
    processedFiles: rawObj.Processed_files,
  };
  Object.keys(obj).forEach((key) =>
    obj[key as keyof UploadStatus] === ''
      ? ((obj[key as keyof UploadStatus] as number | string | []) =
          'Unavailable')
      : {},
  );
  return obj;
};
