import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, IconButton, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { decode, encode } from 'html-entities';
import { AxiosError, AxiosResponse } from 'axios';

import { grey } from 'theme';
import { FloatingChatInput } from './ChatInput.styles';
import {
  ChatCompletionDTO,
  chatCompletionAPI,
  listIndexesAPI,
  searchAPI,
  searchAndGenerate,
  searchVectorsAPI,
} from 'api';
import { useAuthState } from 'components/AuthProvider';
import SecureTextField from 'components/SecureTextField';
import {
  AvailableChats,
  ChatCompletionRequest,
  INewDocument,
  OutputFormats,
  SearchAndGenerateRequest,
  SearchRequest,
  SearchVectorsRequest,
} from 'types';
import { camelize, decamelize, getTimeNow } from 'common/utils';
import PopoverList, { PopoverButtons } from 'components/PopoverList';
import ComponentLoading from 'components/ComponentLoading';
import Disclaimer from 'components/Disclaimer';
import axios from 'api/axios';
import { DEFAULT_INDEX } from 'components/Page/ChatPage/ChatPage';
import ChatEndpointFormTabs from 'components/SavedSearch/ChatEndpointFormTabs';

export type EndpointNames =
  | 'Chat Completion'
  | 'Search'
  | 'Search Vectors'
  | 'Search And Generate';
export interface Endpoint {
  name: EndpointNames;
  question?: string;
  prompt_title?: string;
  prompt_objective?: string;
  prompt_suffix?: string;
  prompt_template?: string;
  prompt_prefix?: string;
  index?: string | null;
  config?: string | string[];
  conv_length?: number;
  reset_context?: string;
  sessionid?: string;
  output?: string;
  query?: string;
  outpul_eval?: string;
  formattedjson?: boolean;
  primary_index?: string;
  secondary_index?: string;
  max_count?: number;
  chunk_size?: number;
  nb_sources?: number;
  show_vectors?: boolean;
  file_name?: null | string;
}

export type EndpointConfigs = Endpoint[];
interface ChatInputInputProps {
  sessionid: string;
  searchIndex: string | null;
  handleUserInput: (textInput: string) => void;
  setIndexInput: React.Dispatch<React.SetStateAction<string | null>>;
  setLoadingAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  setMessages: React.Dispatch<React.SetStateAction<ChatCompletionDTO[]>>;
  setAvailableChats: React.Dispatch<React.SetStateAction<AvailableChats[]>>;
}

const initialValues: PopoverButtons = {
  chatEndpoint: 'Search',
  outputFormat: 'Standard Paragraph',
};

export const INITIAL_ENDPOINT_CONFIGS: EndpointConfigs = [
  {
    name: 'Chat Completion',
    prompt_title: '',
    prompt_objective:
      'You are an intelligent chatbot designed to assist users by providing relevant data based on the context given and answering their questions accurately. You have access to a comprehensive database of information and can retrieve data from various sources to help users with their inquiries.',
    prompt_prefix: 'Read the given context and then answer the question.',
    prompt_suffix: '',
    index: '',
    config: ['show_source'],
    conv_length: 4000,
    reset_context: 'False',
    sessionid: '',
    show_vectors: false,
  },
  {
    name: 'Search',
    prompt_title: '',
    prompt_objective:
      'You are an intelligent chatbot designed to assist users by providing relevant data based on the context given and answering their questions accurately. You have access to a comprehensive database of information and can retrieve data from various sources to help users with their inquiries.',
    prompt_prefix: 'Read the given context and then answer the question.',
    prompt_suffix: '',
    index: '',
    config: ['show_source'],
    reset_context: 'False',
    show_vectors: false,
    file_name: null,
    nb_sources: 1,
  },
  {
    name: 'Search Vectors',
    output: '*',
    query: '@metadata:',
    index: '',
    outpul_eval: '',
    formattedjson: false,
  },
  {
    name: 'Search And Generate',
    query: '@metadata:',
    prompt_title: '',
    prompt_template:
      'User_Story_ID:<ID>,Title:<Title>,Description:<Description>\n',
    primary_index: 'default_doc_idx',
    secondary_index: 'default_doc_idx',
    prompt_objective:
      'You are an AI assistant that helps correlating test cases with user stories.',
    prompt_prefix:
      "Based on the User Story ID and Description.The objective is to Generate the test cases for similar user stories for implementing 'payment through the PayPal account' change.\n\n",
    prompt_suffix: '',
    max_count: 10,
    chunk_size: 2,
    outpul_eval: '',
    config: ['show_source'],
  },
];

const defaultEndpointConfigIndex = 1;

const outputFormats: OutputFormats = {
  standardParagraph: '',
  bulletedList: 'Return the list as a bulleted list',
  numberedList: 'Return the list as a numbered list',
  table: `Instead of using this format: 
\`\`\`
| File Type (column_header_1) | File Extension (column_header_2) |
|-----------|----------------|
| HTML (row_1, column_1) | .html (row_1, column_2) |
| JSON (row_2, column_1) | .json (row_2, column_2) |
\`\`\`

Use the following format (including "\`\`\`" symbols): 
  \`\`\`table
  [
      {
        <column_header_1>: <row_1, column_1>,
        <column_header_2>: <row_1, column_2>',
        ...
      },
      {
        <column_header_1>: <row_2, column_1>,
        <column_header_2>: <row_2, column_2>',
        ...
      },
      ...
    ]
  \`\`\``,
};

const ChatInput: React.FC<ChatInputInputProps> = ({
  sessionid,
  searchIndex,
  setMessages,
  setIndexInput,
  handleUserInput,
  setLoadingAnswer,
  setAvailableChats,
}) => {
  const { accessToken } = useAuthState();
  const [initialized, setInitialized] = useState(false);
  const [promptInput, setPromptInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [availableIndexes, setListIndex] = useState<string[]>([]);
  const [openEndpointForm, setOpenEndpointForm] = useState(false);
  const [popoverButtons, setPopoverButtons] = useState(initialValues);
  const [documents, setDocuments] = useState<string[] | null>(null);
  const [selectedConfigTab, setSelectedConfigTab] = useState(
    defaultEndpointConfigIndex,
  );
  const [endpoint, setEndpoint] = useState<Endpoint>(
    INITIAL_ENDPOINT_CONFIGS[defaultEndpointConfigIndex],
  );
  const [endpointConfigs, setEndpointConfigs] = useState<EndpointConfigs>(
    INITIAL_ENDPOINT_CONFIGS,
  );
  const disabledButtons = {
    chatEndpoint: false,
    outputFormat: endpoint.name === 'Search Vectors',
  };

  const getFileList = useCallback(() => {
    axios({
      method: 'get',
      url: `/listdocs/${searchIndex}`,
    })
      .then((response: AxiosResponse) => {
        const documentList: INewDocument[] = response.data;
        const documentNames = documentList.map((doc) => doc.doc_name);
        setDocuments(documentNames ?? null);
      })
      .catch((error: AxiosError) => {
        setDocuments(null);
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, [searchIndex]);

  const onError = (error: string | undefined) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: error ?? 'Error on ask Quasar++', type: 'error', source: '' },
    ]);
    setLoadingAnswer(false);
  };

  const onSuccess = (data: ChatCompletionDTO) => {
    data.id = crypto.randomUUID();
    data.timestamp = getTimeNow();
    setMessages((prevMessages) => [...prevMessages, data]);
    setAvailableChats((oldChats) =>
      oldChats.map((chat) => {
        if (chat.sessionid === sessionid) {
          return {
            ...chat,
            lastEdited: getTimeNow(),
            messages: [...chat.messages, data],
          }; // Q++ messages only
        }
        return chat;
      }),
    );
    setLoadingAnswer(false);
  };

  const getData = async () => {
    const removeEmptyOrNull = (obj: Endpoint) => {
      for (let key in obj) {
        const objKey = key as keyof Endpoint;
        if (
          obj[objKey] === '' ||
          obj[objKey] === null ||
          obj[objKey] === 'undefined'
        ) {
          delete obj[objKey];
        }
      }
      return obj;
    };

    const endpointData = { ...endpoint, index: searchIndex };
    const { name, show_vectors, ...cleanEndpointData } =
      removeEmptyOrNull(endpointData);

    let response: {
      data: ChatCompletionDTO | undefined;
      error: string | undefined;
    } = {
      data: undefined,
      error: undefined,
    };

    if (name === 'Chat Completion') {
      const { nb_sources, file_name, ...body } = {
        ...cleanEndpointData,
        sessionid,
        question: promptInput,
        config: cleanEndpointData['config']?.toString(),
      };
      const { data, error } = await chatCompletionAPI(
        accessToken,
        body as ChatCompletionRequest,
      );
      response = { data, error };
    }

    if (name === 'Search Vectors') {
      const body = {
        ...cleanEndpointData,
        query: promptInput,
      };
      const { data, error } = await searchVectorsAPI(
        accessToken,
        body as SearchVectorsRequest,
      );
      response = { data, error };
    }

    if (name === 'Search') {
      const body = {
        ...cleanEndpointData,
        // In Search API this property needs to be boolean type
        reset_context: cleanEndpointData['reset_context'] === 'True',
        config: cleanEndpointData['config']?.toString(),
        question: promptInput,
      };

      const { data, error } = await searchAPI(
        accessToken,
        body as SearchRequest,
      );
      response = { data, error };
    }

    if (name === 'Search And Generate') {
      const body = {
        ...cleanEndpointData,
        query: promptInput,
        config: cleanEndpointData['config']?.toString(),
      };
      const { data, error } = await searchAndGenerate(
        accessToken,
        body as SearchAndGenerateRequest,
      );
      response = { data, error };
    }
    return response;
  };

  const handleSubmit = async () => {
    setLoadingAnswer(true);
    handleUserInput(promptInput);
    try {
      const { data, error } = await getData();
      if (error || data?.content === '') return onError(error);
      if (data) onSuccess(data);
    } catch (_err) {
      onError(undefined);
    }
  };

  const includesQuery = (name: string) =>
    name === 'Search And Generate' || name === 'Search Vectors';

  const handleCloseForm = () => setOpenEndpointForm(false);

  const handleChange = (value: string | null) => {
    const newValue =
      endpoint.name === 'Chat Completion' && !value ? DEFAULT_INDEX : value;
    setIndexInput(newValue);
    setEndpoint({ ...endpoint, index: newValue });
  };

  const handleSelect = ({ name, value }: { name: string; value: string }) => {
    setPopoverButtons((prev) => ({ ...prev, [name]: value }));

    const isEndpointSelection = name === 'chatEndpoint';
    const isOutputFormatSelection = name === 'outputFormat';

    if (isEndpointSelection) {
      // Find selected config
      const selectedEndpoint = endpointConfigs.find((e) => e.name === value);
      if (!selectedEndpoint) return;

      const endpointIndex = INITIAL_ENDPOINT_CONFIGS.map(
        (endpoint) => endpoint.name,
      ).indexOf(selectedEndpoint.name);

      // Set config tab
      setSelectedConfigTab(endpointIndex);

      // Set endpoint config
      setEndpoint(selectedEndpoint);

      // Set prompt message if config includes query param
      if (includesQuery(value)) setPromptInput(selectedEndpoint.query ?? '');

      // Reset prompt input if config doesn't includes query param
      if (!includesQuery(value) && includesQuery(endpoint.name)) {
        setPromptInput('');
      }
    }
    // Set output format
    const isAllowedToUseFormat = endpoint.name !== 'Search Vectors';
    if (isOutputFormatSelection && isAllowedToUseFormat) {
      const formatKey = camelize(value) as keyof OutputFormats;
      setEndpoint((endpointConfig) => ({
        ...endpointConfig,
        prompt_suffix: outputFormats[formatKey],
      }));
    }
  };

  const updateEndpointConfig = (
    formData: Endpoint,
    disableQuerySync?: boolean,
  ) => {
    const isSearchAndGenerate = formData.name === 'Search And Generate';

    const updatedConfig: Endpoint = {
      ...formData,
      prompt_template: isSearchAndGenerate ? formData.prompt_template : '',
    };

    if (
      includesQuery(updatedConfig.name) &&
      updatedConfig.query &&
      !disableQuerySync
    ) {
      setPromptInput(updatedConfig.query);
    }

    setEndpoint(updatedConfig);
    setEndpointConfigs((prev) => {
      const newConfigArray = prev.map((endpoint) =>
        endpoint.name === updatedConfig.name ? updatedConfig : endpoint,
      );

      const prompts = newConfigArray
        .filter((i) => i.name !== 'Search Vectors')
        .map((i) => ({
          name: i.name,
          prompt_title: i.prompt_title || '',
          prompt_objective: i.prompt_objective,
          prompt_prefix: i.prompt_prefix,
          prompt_suffix: i.prompt_suffix,
          prompt_template: i.prompt_template || '',
        }));

      localStorage.setItem('endpointPrompts', JSON.stringify(prompts));
      return newConfigArray;
    });
  };

  const handleTextChange = (v: string) => {
    if (v === '\n') return;
    setPromptInput(v);
    if (includesQuery(endpoint.name)) {
      updateEndpointConfig({ ...endpoint, query: v }, true);
    }
  };

  useEffect(() => {
    if (!searchIndex) return setDocuments(null);
    getFileList();
  }, [searchIndex, getFileList]);

  useEffect(() => {
    if (initialized) return;
    setLoading(true);
    const initializeChat = async () => {
      const { data } = await listIndexesAPI(accessToken);
      if (!data) return setLoading(false);
      const indexesList = data.map(
        (entry: Record<'index_name', string>) => entry['index_name'],
      );
      setListIndex(indexesList);
    };
    const getInitialConfigState = () => {
      const rawStoredData = localStorage.getItem('endpointPrompts');
      if (!rawStoredData) return setLoading(false);
      const storedEnpointConfigs: {
        name: EndpointNames;
        prompt_title: string;
        prompt_objective: string;
        prompt_prefix: string;
        prompt_suffix: string;
        prompt_template?: string;
      }[] = JSON.parse(rawStoredData);

      const initialConfigState = INITIAL_ENDPOINT_CONFIGS.map((config, idx) => {
        let newConfig: Endpoint = config;
        storedEnpointConfigs.forEach(
          (storedConfig) =>
            config.name === storedConfig.name &&
            (newConfig = {
              ...config,
              ...{
                name: encode(decode(`${storedConfig.name}`)) as EndpointNames,
                prompt_title: encode(decode(`${storedConfig.prompt_title}`)),
                prompt_objective: encode(
                  decode(`${storedConfig.prompt_objective}`),
                ),
                prompt_prefix: encode(decode(`${storedConfig.prompt_prefix}`)),
                prompt_suffix: encode(decode(`${storedConfig.prompt_suffix}`)),
                ...(config.name === 'Search And Generate' && {
                  prompt_template: encode(
                    decode(`${storedConfig.prompt_template}`),
                  ),
                }),
              },
            }),
        );

        // Selects output format if prompt_suffix saved in local storage contains the same
        if (idx === defaultEndpointConfigIndex) {
          Object.keys(outputFormats).forEach((key) => {
            const formatKey = key as keyof OutputFormats;
            const isOutputFormatSelected =
              outputFormats[formatKey] === decode(newConfig.prompt_suffix);
            isOutputFormatSelected &&
              setPopoverButtons((prev) => ({
                ...prev,
                outputFormat: decamelize(formatKey),
              }));
          });
        }

        return newConfig;
      });
      setEndpointConfigs(initialConfigState);
      setEndpoint(initialConfigState[defaultEndpointConfigIndex]);
      setLoading(false);
    };
    initializeChat();
    getInitialConfigState();
    setInitialized(true);
  }, [initialized, accessToken]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const isComposing = event.nativeEvent.isComposing;
    if (
      event.code === 'Enter' &&
      searchIndex !== '' &&
      promptInput !== '' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !isComposing
    ) {
      event.preventDefault();
      handleSubmit();
      setPromptInput('');
    }
  };

  if (loading) return <ComponentLoading />;

  return (
    <>
      <FloatingChatInput gap={1} bgcolor={grey[200]} p={0}>
        <Autocomplete
          sx={{ m: 1, width: 300 }}
          id="autocomplete-index-select"
          value={
            searchIndex === null && endpoint.name === 'Chat Completion'
              ? DEFAULT_INDEX
              : searchIndex
          }
          options={availableIndexes}
          onChange={(e, value) => handleChange(value)}
          renderInput={(params) => (
            <SecureTextField
              {...params}
              label=""
              placeholder="Intelligent Index"
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              style={{ background: 'white', borderRadius: '5px' }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxHeight: '250px',
            overflowY: 'auto',
            paddingY: 1,
            alignItems: 'start',
          }}
        >
          <SecureTextField
            multiline
            fullWidth
            htmlEntitiesMode
            maxLength={5000}
            id="outlined-basic"
            variant="outlined"
            placeholder="Write prompts to harvest document insights."
            value={promptInput}
            onUpdate={handleTextChange}
            onKeyDown={handleKeyDown}
            sx={{
              background: 'white',
              borderRadius: '5px',
              minHeight: '56px',
              margin: '8px 0',
            }}
            InputProps={{
              style: {
                minHeight: '56px',
                padding: '0 9px',
                background: 'white',
              },
            }}
          />
        </Box>

        <IconButton
          color="primary"
          size="large"
          onClick={() => {
            if (searchIndex !== '' && promptInput !== '') {
              handleSubmit();
              setPromptInput('');
            }
          }}
        >
          <SendIcon />
        </IconButton>
      </FloatingChatInput>
      <PopoverList
        popoverButtons={popoverButtons}
        handleSelect={handleSelect}
        setOpenEndpointForm={setOpenEndpointForm}
        disabledButtons={disabledButtons}
      />
      <Disclaimer
        message="Generative AI output is a recommendation only and not a
        substitute for human judgement and diligence."
      />
      {openEndpointForm && (
        <ChatEndpointFormTabs
          open={openEndpointForm}
          chatEndpoint={endpoint}
          documents={documents}
          endpointConfigs={endpointConfigs}
          selectedTab={selectedConfigTab}
          handleCloseForm={handleCloseForm}
          updateEndpointConfig={updateEndpointConfig}
          handleSelect={handleSelect}
        />
      )}
    </>
  );
};

export default ChatInput;
