import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NothingToSeeHere from 'components/NothingToSeeHere';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { grey } from 'theme';

/**
 * Display a 'collection not found' message on pages where the route should include a valid collection
 */
interface CollectioNotFoundProps {
  getCollections: () => void;
}

export default function CollectionNotFound({
  getCollections,
}: CollectioNotFoundProps): React.JSX.Element {
  const { index } = useParams();
  const navigate = useNavigate();
  const handleAddCollection = () => navigate(`/`);

  return (
    <>
      <Grid container flexDirection="column">
        <Grid
          container
          item
          alignItems="center"
          bgcolor="white"
          px={3}
          pt={0}
          sx={{
            borderBottom: `1px solid ${grey[500]}`,
            boxSizing: 'border-box',
            height: '80px',
            alignItems: 'center',
          }}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h2">Collection Not Found</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        px={3}
        pt={3}
        sx={{
          background: '#F2F2F2',
          alignItems: 'center',
          justifyContent: 'center',
          height: 1,
          width: 1,
        }}
      >
        <NothingToSeeHere
          elementToAdd="collection"
          currentIndex={index}
          handleAddButton={handleAddCollection}
          handleRefreshButton={getCollections}
        />
      </Grid>
    </>
  );
}
