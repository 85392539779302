import TextareaAutosize, {
  TextareaAutosizeProps,
} from '@mui/material/TextareaAutosize';

import { useState } from 'react';
import { containsSpecialChars, invalidAPICharacter } from 'common/utils/common';
import { encode } from 'html-entities';
import { decode } from 'html-entities';
import { DEFAULT_MAX_CHARACTER_LIMIT } from 'components/SecureTextField/SecureTextField';
const DOMPurify = require('dompurify');

type CustomProps = {
  onUpdate?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value?: string | null;
  sanitizerMode?: boolean;
  htmlEntitiesMode?: boolean;
  restrictSomeCharacters?: boolean;
  maxLength?: number;
  customValidator?: (text: string) => string | undefined;
};
type SecureTextareaAutosizeProps = TextareaAutosizeProps & CustomProps;

export default function SecureTextareaAutosize({
  value,
  onUpdate,
  sanitizerMode,
  htmlEntitiesMode,
  restrictSomeCharacters,
  maxLength,
  customValidator,
  ...props
}: SecureTextareaAutosizeProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = event.target.value;
    const valueLength: number = inputValue.length;

    if (maxLength && valueLength > maxLength) {
      return setErrorMessage(
        `Length exceeded. The maximum length is ${maxLength}`,
      );
    }

    if (!maxLength && inputValue.length > DEFAULT_MAX_CHARACTER_LIMIT) {
      return setErrorMessage(
        `Length exceeded. The maximum length is ${DEFAULT_MAX_CHARACTER_LIMIT}`,
      );
    }

    const isNotValidString = containsSpecialChars(inputValue);
    if (isNotValidString && !sanitizerMode && !htmlEntitiesMode) {
      return setErrorMessage(
        `The following characters are not allowed: \\ / : * ” ’ < > |`,
      );
    }

    const isNotValidChar = invalidAPICharacter(inputValue);
    if (isNotValidChar && htmlEntitiesMode && restrictSomeCharacters) {
      return setErrorMessage(
        `The following characters are not allowed: \\ / " ' |`,
      );
    }

    if (customValidator && customValidator(inputValue)) {
      const error = customValidator(inputValue);
      if (error) return setErrorMessage(error);
    }

    let cleanValue: string = inputValue;
    if (sanitizerMode) cleanValue = DOMPurify.sanitize(`${inputValue}`);
    if (htmlEntitiesMode) cleanValue = encode(`${inputValue}`);
    if (onUpdate) onUpdate(cleanValue, event);
    return setErrorMessage(null);
  };

  return (
    <>
      <TextareaAutosize
        {...props}
        value={decode(value)}
        style={
          errorMessage
            ? {
                ...props.style,
                borderColor: 'red',
                // '&:focus': {
                //   borderColor: 'red',
                // },
              }
            : props.style
        }
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => handleChange(event)}
      />
      {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
    </>
  );
}
