import { Menu } from '@mui/material';
import { MenuItem } from '@mui/material';

import { ButtonStyled } from './ButtonGroup.styles';
import { useContext, useState } from 'react';
import React from 'react';
import { SnackbarContext } from 'components/SnackbarProvider';
import { Data } from '../UploadDataGridTable';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface StyledButtonProps {
  setData: React.Dispatch<React.SetStateAction<Data[] | undefined>>;
  subcategory: string;
  options: string[];
  fieldName: string;
  selectedRows: GridRowSelectionModel;
  setMetadatas: React.Dispatch<React.SetStateAction<string[]>>;
  data: Data[];
}

export default function StyledButton({
  setData,
  subcategory,
  options,
  fieldName,
  selectedRows,
  setMetadatas,
  data,
}: StyledButtonProps) {
  const createAlert = useContext(SnackbarContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSelect = (field: string) => {
    setData((prev: any) => {
      selectedRows.forEach((e: any) => {
        const exists = prev[e][fieldName].filter(
          (option: any) => option === field,
        );
        if (exists.length === 0) {
          if (prev[e][fieldName].length < 5) {
            prev[e][fieldName] = [...prev[e][fieldName], field];
          } else {
            createAlert({
              message: 'Maximum limit reached.',
              severity: 'warning',
            });
          }
        } else {
          prev[e][fieldName] = prev[e][fieldName].filter(
            (elem: string) => elem !== field,
          );
        }
      });
      setMetadatas([JSON.stringify(prev)]);
      return prev;
    });
  };

  return (
    <>
      <ButtonStyled onClick={handleClick}>{subcategory}</ButtonStyled>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {options.map((field: string) => {
          return (
            <MenuItem key={field} onClick={() => handleSelect(field)}>
              {field}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
