import * as React from 'react';

import { getTokenAPI } from 'api';
import config from 'config';
import { convertToken } from 'common/utils';

export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_FAIL = 'FETCH_AUTH_FAIL';

type AuthReducerAction =
  | {
      type: typeof FETCH_AUTH_SUCCESS;
      accessToken: string;
      expirationDate: number;
    }
  | {
      type: typeof FETCH_AUTH_FAIL;
      accessToken: null;
      expirationDate: null;
    };

interface AuthState {
  readonly accessToken: string;
  readonly expirationDate: number | null;
}

interface AuthAction {
  readonly refreshToken: () => Promise<string>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthStateContext = React.createContext<AuthState | undefined>(undefined);
const AuthActionContext = React.createContext<AuthAction | undefined>(
  undefined,
);

const initialState: AuthState = {
  accessToken: '',
  expirationDate: null,
};

const authReducer = (
  state: AuthState,
  action: AuthReducerAction,
): AuthState => {
  switch (action.type) {
    case FETCH_AUTH_SUCCESS: {
      return {
        ...state,
        accessToken: action.accessToken,
        expirationDate: action.expirationDate,
      };
    }
    case FETCH_AUTH_FAIL: {
      return {
        ...state,
        accessToken: '',
        expirationDate: null,
      };
    }

    default:
      return state;
  }
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer<typeof authReducer>(
    authReducer,
    initialState,
  );

  const isTokenValid = React.useCallback(
    () =>
      Boolean(
        state.expirationDate &&
          state.expirationDate - Date.now() > config.constant.refreshBuffer,
      ),
    [state.expirationDate],
  );

  const refreshToken = React.useCallback(async () => {
    if (isTokenValid()) {
      return state.accessToken;
    }

    const { data } = await getTokenAPI();
    if (!data.token) {
      return data.token;
    }
    const newToken = convertToken(data);
    localStorage.setItem('apiToken', newToken.accessToken);
    dispatch({
      type: FETCH_AUTH_SUCCESS,
      accessToken: newToken.accessToken,
      expirationDate: newToken.expirationDate,
    });
    return data.token;
  }, [isTokenValid, state.accessToken]);

  React.useEffect(() => {
    // calling refreshToken function here to proceed the getProperties function call
    // and load the app
    refreshToken();
    // Short token expire duration is 10 minutes. So, calling this refresh token function
    // before that. (i.e at 8 minutes once).
    const intervalId = setInterval(refreshToken, 1000 * 60 * 8);
    return () => clearInterval(intervalId);
  }, [refreshToken]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthActionContext.Provider value={{ refreshToken }}>
        {children}
      </AuthActionContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
};

const useAuthAction = () => {
  const context = React.useContext(AuthActionContext);
  if (context === undefined) {
    throw new Error('useAuthAction must be used within a AuthProvider');
  }
  return context;
};

export { useAuthState, useAuthAction };
export default AuthProvider;
