import { ChangeEvent, useState } from 'react';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Box, Typography } from '@mui/material';

import {
  amazonLogo,
  azureLogo,
  confluenceLogo,
  googleLogo,
  sharepointLogo,
} from 'images';
import {
  DivStyled,
  LabelStyled,
  ContentStyled,
  TextStyled,
} from './DragDropFile.styles';
import UploadSourceForm from 'components/UploadSourceForm';
import ComponentLoading from 'components/ComponentLoading';
import { ImportSource, SelectedSource, Source } from 'types';
import useMultiUploadForm from 'common/hooks/useMultiUploadForm';
import { useNavigate } from 'react-router-dom';

const importSources: ImportSource[] = [
  {
    name: 'Confluence',
    sourceType: 'confluence',
    icon: <img src={confluenceLogo} alt="Confluence logo" />,
  },
  {
    name: 'Sharepoint',
    sourceType: 'sharepoint',
    icon: <img src={sharepointLogo} alt="Sharepoint logo" />,
  },
  {
    name: 'Azure Blob',
    sourceType: 'azure_blob',
    icon: <img src={azureLogo} alt="Azure logo" />,
  },
  {
    name: 'Amazon S3',
    sourceType: 'aws_s3',
    icon: <img src={amazonLogo} alt="Amazon logo" />,
  },
  {
    name: 'Google Drive',
    sourceType: 'gdrive',
    icon: <img src={googleLogo} alt="Google logo" />,
  },
];

interface DragDropFileProps {
  /**
   * Disable the input so that file selection can not be changed.
   */
  index: string;
  /**
   * A function to handle processing the updated FileList provided by the inputs onChange event.
   */
  onFileDrop: (fileList: FileList) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
/**
 * The interface for dragging and dropping files to upload
 */
export default function DragDropFile({
  index,
  onFileDrop,
  handleChange,
}: DragDropFileProps): React.JSX.Element {
  const navigate = useNavigate();
  const [dragActive, setDragActive] = useState(false);
  const [selectedSource, setSelectedSource] = useState<SelectedSource | null>(
    null,
  );

  const {
    loading,
    formConfig,
    subCategories,
    handleCreateConfig,
    removeConfiguration,
  } = useMultiUploadForm({ index, isNewConfig: true });

  const handleSelectSource = (source: SelectedSource) => {
    setSelectedSource(source);
    handleCreateConfig({ source_type: source.sourceType });
  };

  const handleCloseForm = () => {};

  // handle drag events
  const handleDrag = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer?.files) {
      onFileDrop(event.dataTransfer.files);
    }
  };

  if (loading) return <ComponentLoading />;

  return (
    <>
      {selectedSource && subCategories && formConfig ? (
        Object.values(formConfig).map((config, idx) => {
          const inputs = config.map((i) => i.formInputs);
          const values = config.map((i) => i.formValues);
          const selectedSource = values.map((i) => i.source_type)[0] as Source;

          return (
            <UploadSourceForm
              key={index}
              isNewConfig
              index={index}
              inputs={inputs}
              initialFormValues={values}
              selectedSource={selectedSource}
              handleClose={handleCloseForm}
              handleCreateConfig={handleCreateConfig}
              removeConfiguration={() => removeConfiguration}
            />
          );
        })
      ) : (
        <ContentStyled>
          <input
            type="file"
            accept={
              '.ppt, .pdf, .docx, .csv, .txt, .rtf, .html, .xlsx, .wav, .flac, .mp3, .jpeg, .jpg, .png, .zip'
            }
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
            hidden
          />
          <LabelStyled
            htmlFor="input-file-upload"
            onDragEnter={(event) => handleDrag(event)}
            className={`${dragActive ? 'drag-active' : ''}`}
          >
            <Inventory2OutlinedIcon
              sx={{ color: '#B3B2B5', width: '65px', height: '65px' }}
            />
            <TextStyled fontSize="20px" color="#03000F" fontHeight="500">
              Upload a document
            </TextStyled>
            {subCategories && subCategories.length === 0 ? (
              <>
                <TextStyled>
                  Drag and drop documents, or{' '}
                  <span style={{ color: '#7500C0' }}>browse</span> documents to
                  add to collection.
                </TextStyled>
                <TextStyled>
                  See{' '}
                  <span
                    style={{ color: '#7500C0' }}
                    onClick={() => navigate(`/config`)}
                  >
                    Configuration
                  </span>{' '}
                  to be able to upload from Confluence, Sharepoint, Azure Blob,
                  or Amazon S3.
                </TextStyled>
              </>
            ) : (
              <>
                <TextStyled>
                  Drag and drop documents, or{' '}
                  <span style={{ color: '#7500C0' }}>browse</span> documents, or
                  import from:
                </TextStyled>

                <Box display={'flex'} flexDirection={'row'} gap={3}>
                  {importSources.map((source) => (
                    <Box
                      key={source.name}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSelectSource({
                          name: source.name,
                          sourceType: source.sourceType,
                        });
                      }}
                    >
                      <Box
                        height={80}
                        width={80}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderRadius={2}
                        sx={{ backgroundColor: '#f0f0f0' }}
                      >
                        {source.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        fontSize={14}
                        fontWeight={'bold'}
                      >
                        {source.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </>
            )}
            <TextStyled color="#4F4B53">
              Supported files:
              <span style={{ fontWeight: '500' }}>
                {' '}
                PPT, PDF, DOCX, CSV, TXT, RTF, HTML, XLSX, WAV, FLAC, MP3, JEPG,
                PNG, ZIP{' '}
              </span>
            </TextStyled>
            <TextStyled color="#4F4B53">Maximum size: 100 MB </TextStyled>
            {dragActive && (
              <DivStyled
                id="drag-file-element"
                onDragEnter={(event) => handleDrag(event)}
                onDragLeave={(event) => handleDrag(event)}
                onDragOver={(event) => handleDrag(event)}
                onDrop={(event) => handleDrop(event)}
              />
            )}
          </LabelStyled>
        </ContentStyled>
      )}
    </>
  );
}
