import { Box, Grid, Paper, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

import { Job } from 'types';
import { Column, PropertyContent, PropertyName, Title } from './JobCard.styles';

interface JobCardProps {
  job: Job;
}

const JobCard = ({ job }: JobCardProps) => {
  const navigate = useNavigate();
  const editJob = () =>
    navigate(`/job-upload/${job.hash}`, { state: { editJob: true } });

  return (
    <Grid item xs={12} sm={6} md={4} xl={3} component={Box} onClick={editJob}>
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '160px',
          padding: 2,
          cursor: 'pointer',
          boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.01)',
            boxShadow:
              '0px 5px 5px -4px rgba(0, 0, 0, 0.2), 0px 5px 6px 0px rgba(0, 0, 0, 0.14), 0px 3px 10px 0px rgba(0, 0, 0, 0.12)',
            '& .hover-text': {
              display: 'block',
            },
            '& .normal-text': {
              display: 'none',
            },
          },
          '& .hover-text, .arrow-icon': {
            display: 'none',
          },
          '&:hover .hover-text': {
            display: 'inline-block',
          },
          '&:hover  .arrow-icon': {
            display: 'inline-block',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Title>{job.name ?? job.task}</Title>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {job.created && (
              <Box sx={{ display: 'flex', gap: '12px' }}>
                <Column>
                  <PropertyName>Created</PropertyName>
                </Column>
                <Column>
                  <PropertyContent>{job.created}</PropertyContent>
                </Column>
              </Box>
            )}
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <Column>
                <PropertyName>Template</PropertyName>
              </Column>
              <Column>
                <PropertyContent>{job.template_name}</PropertyContent>
              </Column>
            </Box>
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <Column>
                <PropertyName>Collections</PropertyName>
              </Column>
              <Column>
                <PropertyContent>
                  {job.input_collections.toString()}
                </PropertyContent>
              </Column>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '24px',
            justifyContent: 'end',
            mt: 2,
          }}
        >
          <Typography className="hover-text">Edit Job</Typography>
          <ArrowForwardIcon className="arrow-icon" />
        </Box>
      </Paper>
    </Grid>
  );
};

export default JobCard;
