import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

interface ErrorMSG {
  status?: number;
  statusText?: string;
}

export default function ErrorPage({
  status,
  statusText,
}: ErrorMSG): React.JSX.Element {
  return (
    <Grid
      container
      gap={1}
      xs="auto"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ height: '100vh' }}
    >
      <>
        <Typography variant="h2">Error</Typography>
        <br />
        <Typography sx={{ maxWidth: '60ch' }}>
          <p>error handling information</p>

          {status && statusText ? (
            <>
              <p>Status: {status}</p>
              <p>Status Message: {statusText}</p>
            </>
          ) : (
            <p>unknown error</p>
          )}
        </Typography>
      </>
    </Grid>
  );
}
