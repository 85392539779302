import React, { useContext, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import GrainOutlined from '@mui/icons-material/GrainOutlined';

import { CollectionContext } from 'components/CollectionProvider';
import Avatar from '@mui/material/Avatar';
import { SideMenuStyled } from './SideMenu.styles';

type NavItemProps = {
  to: string;
  text: string;
  icon: React.ReactElement;
  collapse: boolean;
};

function NavItem({
  to,
  text,
  icon,
  collapse,
}: NavItemProps): React.JSX.Element {
  const match = useMatch(to);
  const color = match !== null ? 'primary' : 'secondary';
  const activeBorder = {
    ...(match
      ? {
          borderRight: '0.25rem solid #7500C0',
          pr: 2.5,
        }
      : {}),
  };
  return (
    <Grid item>
      {collapse ? (
        <IconButton
          component={Link}
          to={to}
          aria-label={text}
          sx={{ px: 3, borderRadius: 0, ...activeBorder }}
          color={color}
          size="large"
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          component={Link}
          to={to}
          color={color}
          sx={{
            px: 3,
            justifyContent: 'start',
            borderRadius: 0,
            ...activeBorder,
          }}
          startIcon={icon}
          fullWidth
        >
          {text}
        </Button>
      )}
    </Grid>
  );
}

type CollectionItemProps = {
  to: string;
  text: string;
  collapse: boolean;
};

function CollectionItem({
  to,
  text,
  collapse,
}: CollectionItemProps): React.JSX.Element {
  const match = useMatch(to);
  const color = match !== null ? 'primary' : 'secondary';
  const activeBorder = {
    ...(match
      ? {
          borderRight: '0.25rem solid #7500C0',
          pr: 2.5,
        }
      : {}),
  };

  return (
    <Grid item>
      {collapse ? (
        <IconButton
          component={Link}
          to={to}
          aria-label={text}
          sx={{ px: 3, borderRadius: 0, ...activeBorder }}
          color={color}
          size="large"
        >
          <Avatar sx={{ width: 24, height: 24, textTransform: 'uppercase' }}>
            {text.toString().charAt(0)}
          </Avatar>
        </IconButton>
      ) : (
        <Button
          component={Link}
          to={to}
          color={color}
          sx={{
            width: '305px',
            px: 3,
            justifyContent: 'start',
            borderRadius: 0,
            ...activeBorder,
          }}
          startIcon={
            <Avatar sx={{ width: 24, height: 24, textTransform: 'uppercase' }}>
              {text.toString().charAt(0)}
            </Avatar>
          }
          fullWidth
        >
          {text}
        </Button>
      )}
    </Grid>
  );
}

function GraphItem({
  to,
  text,
  collapse,
}: CollectionItemProps): React.JSX.Element {
  const match = useMatch(to);
  const color = match !== null ? 'primary' : 'secondary';
  const activeBorder = {
    ...(match
      ? {
          borderRight: '0.25rem solid #7500C0',
          pr: 2.5,
        }
      : {}),
  };

  return (
    <Grid item>
      {collapse ? (
        <IconButton
          component={Link}
          to={to}
          aria-label={text}
          sx={{ px: 3, borderRadius: 0, ...activeBorder }}
          color={color}
          size="large"
        >
          <Avatar sx={{ width: 24, height: 24, textTransform: 'uppercase' }}>
            {text.toString().charAt(0)}
          </Avatar>
        </IconButton>
      ) : (
        <Button
          component={Link}
          to={to}
          color={color}
          sx={{
            width: '305px',
            px: 3,
            justifyContent: 'start',
            borderRadius: 0,
            ...activeBorder,
          }}
          startIcon={
            <Avatar sx={{ width: 24, height: 24, textTransform: 'uppercase' }}>
              {text.toString().charAt(0)}
            </Avatar>
          }
          fullWidth
        >
          {text}
        </Button>
      )}
    </Grid>
  );
}

/**
 * The navigation menu that appears on the left hand side of the site
 */
export default function SideMenu(): React.JSX.Element {
  const { collections, loadingCollections } = useContext(CollectionContext);
  //const { graphs, loadingGraphs } = useContext(GraphContext);
  const [collapse, setCollapse] = useState(true);

  const toggleCollapse = () => {
    setCollapse((_collapse) => {
      return !_collapse;
    });
  };

  return (
    <SideMenuStyled container minWidth={collapse ? undefined : '19.125rem'}>
      <NavItem
        to="/"
        text="Collections"
        icon={<Inventory2OutlinedIcon />}
        collapse={collapse}
      />
      <NavItem
        to="/collection/default_doc_idx"
        text="Knowledge Base"
        icon={<TopicOutlinedIcon />}
        collapse={collapse}
      />
      <Divider />
      <NavItem
        to="/graphs"
        text="Graphs"
        icon={<GrainOutlined />}
        collapse={collapse}
      />
      <Divider />
      <NavItem
        to="/ask-quasar"
        text="Chat Sessions"
        icon={<ChatOutlinedIcon />}
        collapse={collapse}
      />
      <NavItem
        to="/taskgen-pro"
        text="TaskGen Pro"
        icon={<FileCopyOutlinedIcon />}
        collapse={collapse}
      />
      <NavItem
        to="/uploads-status"
        text="Uploads Status"
        icon={<UploadIcon />}
        collapse={collapse}
      />
      <NavItem
        to="/config"
        text="Configuration"
        icon={<SettingsOutlinedIcon />}
        collapse={collapse}
      />
      <Grid
        item
        container
        flexGrow={1}
        justifyContent="flex-end"
        alignSelf="flex-end"
        alignItems="end"
        px={2}
      >
        <IconButton
          color="secondary"
          aria-label="expand/collapse button"
          onClick={toggleCollapse}
        >
          {collapse ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </Grid>
    </SideMenuStyled>
  );
}
