import React from 'react';

export const DocxIcon = () => {
  return (
    <svg viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M23.0444 8.00279C22.1424 8.00193 21.2775 7.64863 20.6397 7.02045C20.0019 6.39228 19.6431 5.54054 19.6423 4.65216V3.02e-07H4.44159C3.85839 -0.000213138 3.28085 0.112713 2.74198 0.332366C2.20311 0.552018 1.71346 0.874087 1.301 1.28015C0.888542 1.68621 0.561351 2.16833 0.33812 2.69895C0.11489 3.22958 -4.02679e-08 3.79831 0 4.37268V30.7323C-4.02679e-08 31.3067 0.11489 31.8755 0.33812 32.4061C0.561351 32.9367 0.888542 33.4188 1.301 33.8249C1.71346 34.231 2.20311 34.553 2.74198 34.7726C3.28085 34.9923 3.85839 35.1053 4.44159 35.105H22.6897C23.8672 35.105 24.9965 34.6443 25.8292 33.8243C26.6618 33.0043 27.1296 31.892 27.1296 30.7323V8.00279H23.0444Z"
          fill="#0072FF"
        />
      </g>
      <path
        d="M27.1299 8.00279H23.0447C22.1427 8.00193 21.2779 7.64863 20.64 7.02045C20.0022 6.39228 19.6435 5.54054 19.6426 4.65216V0L27.1299 8.00279Z"
        fill="#0072FF"
      />
      <path
        d="M21.5473 13.6057H4.51355C4.3499 13.6057 4.19295 13.5417 4.07723 13.4277C3.96151 13.3138 3.89649 13.1592 3.89649 12.998C3.89627 12.9181 3.91208 12.8389 3.94299 12.765C3.9739 12.691 4.01932 12.6238 4.07664 12.5672C4.13397 12.5106 4.20205 12.4657 4.27702 12.4351C4.352 12.4044 4.43238 12.3887 4.51355 12.3887H21.5473C21.7114 12.3887 21.8688 12.4529 21.9848 12.5672C22.1008 12.6814 22.166 12.8364 22.166 12.998C22.1656 13.1593 22.1002 13.3139 21.9842 13.4279C21.8682 13.5418 21.7111 13.6057 21.5473 13.6057Z"
        fill="#0072FF"
      />
      <path
        d="M21.5473 17.2405H4.51355C4.3499 17.2405 4.19295 17.1765 4.07723 17.0625C3.96151 16.9485 3.89649 16.794 3.89649 16.6328C3.89627 16.5528 3.91208 16.4736 3.94299 16.3997C3.9739 16.3258 4.01932 16.2586 4.07664 16.202C4.13397 16.1454 4.20205 16.1005 4.27702 16.0699C4.352 16.0392 4.43238 16.0234 4.51355 16.0234H21.5473C21.7114 16.0234 21.8688 16.0876 21.9848 16.2019C22.1008 16.3162 22.166 16.4712 22.166 16.6328C22.1656 16.7941 22.1002 16.9487 21.9842 17.0626C21.8682 17.1765 21.7111 17.2405 21.5473 17.2405Z"
        fill="#0072FF"
      />
      <path
        d="M21.5473 20.8753H4.51355C4.43238 20.8753 4.352 20.8595 4.27702 20.8289C4.20205 20.7982 4.13397 20.7533 4.07664 20.6967C4.01932 20.6401 3.9739 20.5729 3.94299 20.499C3.91208 20.4251 3.89627 20.3459 3.89649 20.2659C3.89649 20.1047 3.96151 19.9502 4.07723 19.8362C4.19295 19.7222 4.3499 19.6582 4.51355 19.6582H21.5473C21.7111 19.6582 21.8682 19.7222 21.9842 19.8361C22.1002 19.95 22.1656 20.1046 22.166 20.2659C22.166 20.4275 22.1008 20.5825 21.9848 20.6968C21.8688 20.8111 21.7114 20.8753 21.5473 20.8753Z"
        fill="#0072FF"
      />
      <path
        d="M14.8255 24.51H4.51355C4.43238 24.51 4.352 24.4943 4.27702 24.4636C4.20205 24.433 4.13397 24.388 4.07664 24.3314C4.01932 24.2748 3.9739 24.2077 3.94299 24.1338C3.91208 24.0598 3.89627 23.9806 3.89649 23.9007C3.89649 23.7395 3.96151 23.5849 4.07723 23.4709C4.19295 23.357 4.3499 23.293 4.51355 23.293H14.8255C14.9892 23.293 15.1461 23.357 15.2619 23.4709C15.3776 23.5849 15.4426 23.7395 15.4426 23.9007C15.4428 23.9806 15.427 24.0598 15.3961 24.1338C15.3652 24.2077 15.3198 24.2748 15.2624 24.3314C15.2051 24.388 15.137 24.433 15.0621 24.4636C14.9871 24.4943 14.9067 24.51 14.8255 24.51Z"
        fill="#0072FF"
      />
      <path
        d="M30.3506 27.7227H9.72008C8.25665 27.7227 7.07031 28.891 7.07031 30.3323V36.39C7.07031 37.8313 8.25665 38.9997 9.72008 38.9997H30.3506C31.8141 38.9997 33.0004 37.8313 33.0004 36.39V30.3323C33.0004 28.891 31.8141 27.7227 30.3506 27.7227Z"
        fill="#0072FF"
      />
      <path
        d="M10.0939 34.6729V35.8542H8.85156V34.6729H10.0939Z"
        fill="white"
      />
      <path
        d="M14.8699 34.6757C14.6629 35.0448 14.351 35.3464 13.9724 35.5434C13.546 35.7582 13.0719 35.8649 12.5931 35.8538H10.7188V30.9677H12.5931C13.0722 30.9562 13.5469 31.0605 13.9757 31.2715C14.3531 31.4645 14.6642 31.763 14.8699 32.1295C15.0833 32.5195 15.1907 32.9572 15.1818 33.4002C15.1903 33.8446 15.0829 34.2838 14.8699 34.6757ZM13.5797 34.4304C13.7138 34.2948 13.8177 34.1331 13.8848 33.9557C13.9518 33.7783 13.9806 33.5891 13.9691 33.4002C13.9806 33.2115 13.952 33.0224 13.8849 32.8453C13.8178 32.6681 13.7139 32.5068 13.5797 32.3716C13.2791 32.112 12.8865 31.98 12.4875 32.0043H11.9199V34.796H12.4875C12.8862 34.8201 13.2785 34.6887 13.5797 34.4304Z"
        fill="white"
      />
      <path
        d="M19.4932 31.2138C19.8707 31.4249 20.1827 31.7333 20.3957 32.1059C20.619 32.4993 20.7328 32.9438 20.7257 33.3944C20.734 33.8462 20.6201 34.2921 20.3957 34.6862C20.1831 35.0605 19.8704 35.3702 19.4915 35.5816C19.1058 35.7985 18.6685 35.9106 18.2244 35.9066C17.7802 35.9106 17.343 35.7985 16.9573 35.5816C16.5784 35.3702 16.2657 35.0605 16.0531 34.6862C15.8286 34.2921 15.7148 33.8462 15.7231 33.3944C15.7145 32.9436 15.8285 32.4987 16.0531 32.1059C16.2668 31.7332 16.5793 31.4249 16.9573 31.2138C17.343 30.9968 17.7802 30.8847 18.2244 30.8888C18.669 30.8849 19.1068 30.9971 19.4932 31.2138ZM17.2823 32.3674C17.0389 32.6542 16.9151 33.0212 16.9358 33.3944C16.915 33.7661 17.0389 34.1317 17.2823 34.4165C17.4008 34.5434 17.5462 34.6432 17.7081 34.7089C17.87 34.7746 18.0445 34.8045 18.2194 34.7967C18.3938 34.8038 18.5676 34.7735 18.7289 34.7079C18.8901 34.6422 19.035 34.5428 19.1533 34.4165C19.3979 34.1321 19.523 33.7666 19.5031 33.3944C19.5229 33.0228 19.3991 32.6577 19.1566 32.3723C19.0381 32.2453 18.8928 32.1455 18.7309 32.0798C18.5689 32.0141 18.3944 31.9841 18.2194 31.9921C18.045 31.9842 17.8708 32.0137 17.709 32.0785C17.5472 32.1433 17.4016 32.2419 17.2823 32.3674Z"
        fill="white"
      />
      <path
        d="M25.2334 31.4102C25.6519 31.7455 25.9365 32.2157 26.0369 32.7377H24.7665C24.6808 32.5214 24.5307 32.3356 24.3359 32.2048C24.1305 32.0731 23.8895 32.0057 23.6446 32.0114C23.4828 32.0063 23.322 32.0379 23.1747 32.1039C23.0273 32.1698 22.8974 32.2683 22.7949 32.3916C22.5652 32.6811 22.4482 33.0419 22.4649 33.4089C22.4481 33.7743 22.5652 34.1336 22.7949 34.4212C22.8982 34.5433 23.0284 34.6405 23.1757 34.7056C23.3229 34.7706 23.4833 34.8017 23.6446 34.7965C23.8896 34.8029 24.1308 34.7355 24.3359 34.6032C24.5296 34.4747 24.6796 34.2918 24.7665 34.0783H26.0369C25.9348 34.5988 25.6505 35.0675 25.2334 35.4026C24.7846 35.7401 24.2302 35.9125 23.666 35.8901C23.2215 35.8994 22.7826 35.7904 22.3956 35.5749C22.0334 35.3675 21.7395 35.0617 21.5492 34.6942C21.3441 34.2911 21.2417 33.845 21.2505 33.3942C21.2417 32.9435 21.3442 32.4974 21.5492 32.0943C21.7395 31.7267 22.0334 31.4209 22.3956 31.2136C22.7826 30.998 23.2215 30.8891 23.666 30.8983C24.2331 30.8835 24.7876 31.0646 25.2334 31.4102Z"
        fill="white"
      />
      <path
        d="M29.721 35.8539L28.6749 34.333L27.7774 35.8539H26.4014L27.9886 33.3385L26.3535 30.9678H27.7774L28.7954 32.4399L29.6715 30.9678H31.0409L29.4752 33.4279L31.1449 35.8539H29.721Z"
        fill="white"
      />
    </svg>
  );
};

export default DocxIcon;
