import SendIcon from '@mui/icons-material/Send';
import WestIcon from '@mui/icons-material/West';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { summarizeDocumentAPI } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { grey } from 'theme';

import axios from 'api/axios';
import { useAuthState } from 'components/AuthProvider';
import { Collection } from 'components/CollectionProvider';
import ComponentLoading from 'components/ComponentLoading';
import SecureTextField from 'components/SecureTextField';
import DeleteCollectionDialog, {
  DeleteCollectionButton,
} from 'components/DeleteCollectionDialog';
import MetadataSettings from 'components/MetadataSettings';
import MultiuploadSettings from 'components/MultiuploadSettings';
import Permissions from 'components/Permissions';
import { SnackbarContext } from 'components/SnackbarProvider';
import { GetSummaryRequest } from 'types';

type CollectionSettingsProps = {
  collection: Collection;
  getCollections: () => void;
};

/**
 * Displays the settings for a specific collection, currently a static placeholder
 */
export default function CollectionSettings({
  collection,
  getCollections,
}: Readonly<CollectionSettingsProps>): React.JSX.Element {
  const createAlert = useContext(SnackbarContext);
  const [deleteCollectionDialogOpen, setDeleteCollectionDialogOpen] =
    useState(false);
  const [indexName, setIndexName] = useState(collection?.index_name ?? '');
  const [description, setDescription] = useState(collection?.description ?? '');
  const [loading, setLoading] = useState(false);
  const [loadingReqSuggestion, setLoadingReqSuggestion] =
    useState<boolean>(false);
  const { accessToken } = useAuthState();

  const isInfoUpdated =
    (collection?.index_name !== indexName && indexName) ||
    (collection?.description !== description && description);

  const handleSummarizeClick = async () => {
    const body: GetSummaryRequest = {
      index: indexName,
      file_name: '',
      options: {
        output: 'dynamic_indexing',
        word_limit: '1000',
        mode: 'map_reduce',
      },
      save_index: 'False',
      process_type: 'sync',
    };
    setLoadingReqSuggestion(true);
    const { data, error } = await summarizeDocumentAPI(accessToken, body);
    if (data) {
      createAlert({
        message: `Generation of description might take time based on index size. Please check after few mins`,
        severity: 'success',
      });

      type TDataResponse = {
        summary_data: {
          output_summary: string;
        };
      };

      let output_summary = '';
      try {
        const {
          summary_data: { output_summary: summary },
        } = data as TDataResponse;
        output_summary = summary;
        setDescription(output_summary);
      } catch (error) {
        console.error('Error parsing summary data:', error);
        output_summary = '';
      }
      setLoadingReqSuggestion(false);
    } else {
      const unauthorizedMessage =
        'The server could not verify that you are authorized to access the URL requested.';
      if (error?.includes(unauthorizedMessage)) {
        createAlert({
          message: `Access denied`,
          severity: 'error',
        });
      } else {
        createAlert({
          message: `Something went wrong (error on request)`,
          severity: 'error',
        });
      }
    }
    setLoadingReqSuggestion(false);
  };

  const updateDescription = () => {
    setLoading(true);
    axios({
      method: 'patch',
      url: '/createindex',
      data: {
        index: indexName,
        description: description,
      },
    })
      .then((response: AxiosResponse) => {
        if (response.data.Status.toLowerCase() === 'success') {
          getCollections();
          setLoading(false);
          createAlert({
            message: `Collection updated`,
            severity: 'success',
          });
        } else {
          setLoading(false);
          createAlert({
            message: `Unable to update '${collection?.index_name} collection'`,
            severity: 'error',
          });
        }
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        setLoading(false);
        createAlert({
          message: `Unable to update '${collection?.index_name} collection'`,
          severity: 'error',
        });
      });
  };

  return (
    <Grid container flexDirection="column" minHeight="100%" alignItems="center">
      <Grid
        container
        item
        alignItems="center"
        px={3}
        pt={3}
        pb={2}
        sx={{ borderBottom: `1px solid ${grey[500]}` }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography variant="h2">
            Settings for {collection?.index_name} collection
          </Typography>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }} textAlign="right">
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to={`/collection/${collection?.index_name}`}
            startIcon={<WestIcon />}
          >
            Back to documents
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        flexGrow={1}
        py={2}
        px={4}
        sx={{ height: 'calc(100vh - 220px)', overflow: 'auto', width: '100%' }}
      >
        <Typography variant="h3" my={3}>
          General
        </Typography>
        <Divider sx={{ mb: 1 }} />
        <SecureTextField
          margin="normal"
          id="name"
          label="Collection name"
          hiddenLabel
          type="text"
          fullWidth
          value={indexName}
          variant="outlined"
          placeholder={collection?.index_name}
          onUpdate={(value) => {
            setIndexName(value);
          }}
        />
        <SecureTextField
          margin="normal"
          id="description"
          label="Description"
          maxLength={3000}
          type="text"
          fullWidth
          variant="outlined"
          value={description}
          placeholder={collection?.description}
          onUpdate={(_) => {
            setDescription(_);
          }}
          multiline
        />
        <Stack direction="row" spacing={2}>
          <div>
            <Button
              disabled={loading || !isInfoUpdated}
              variant="contained"
              color="secondary"
              onClick={updateDescription}
            >
              Save Description
            </Button>
          </div>
          <div>
            <Button
              onClick={handleSummarizeClick}
              variant="outlined"
              color="secondary"
              disabled={loadingReqSuggestion}
            >
              {loadingReqSuggestion ? (
                <span>
                  requesting... <CircularProgress size={15} color="inherit" />
                </span>
              ) : (
                <>
                  <span>GenAI Description</span> <SendIcon />
                </>
              )}
            </Button>
          </div>
        </Stack>
        <Box sx={{ m: 1 }}>{loading && <ComponentLoading />}</Box>
        <MetadataSettings collection={collection.index_name} />
        <Typography variant="h3" mt={6} mb={3}>
          Source
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <MultiuploadSettings index={collection.index_name} />
        {collection.index_name && <Permissions index={collection.index_name} />}
        <Typography variant="h3" mt={6} mb={3}>
          Delete collection
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Typography mb={3}>
          Delete this collection and all of its documents and extracted text
          stored within it. This action cannot be undone.
        </Typography>
        <DeleteCollectionButton setOpen={setDeleteCollectionDialogOpen} />
        <DeleteCollectionDialog
          open={deleteCollectionDialogOpen}
          setOpen={setDeleteCollectionDialogOpen}
          collection={collection?.index_name}
        />
      </Grid>
    </Grid>
  );
}
