import { styled } from '@mui/system';

export const Container = styled('div')<{ backgroundColor: string }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  background-color: ${(props) => props.backgroundColor};
`;

export const Content = styled('div')`
  display: flex;
  padding: 16px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const Title = styled('div')`
  color: var(--grey-grey-1000, #03000f);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Sub Header/1 */
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`;
