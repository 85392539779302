import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SnackbarContext } from 'components/SnackbarProvider';
import ComponentLoading from 'components/ComponentLoading';
import { Typography } from '@mui/material';

import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

type UploadGraphDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  subcategoryToDelList: string[];
  setSubCategoryToDel: (value: string[]) => void;
  handleFilterAndDeleteSubcategories: (value: string[]) => void;
};

export default function UploadRuleDialog({
  open,
  setOpen,
  setSubCategoryToDel,
  subcategoryToDelList,
  handleFilterAndDeleteSubcategories,
}: Readonly<UploadGraphDialogProps>): React.JSX.Element {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const cols: GridColDef[] = [
    {
      field: 'string',
      headerName: 'SUBCATEGORIES',
      width: 150,
      editable: false,
      headerAlign: 'center',
      renderCell: (params) => params.row,
    },
  ];

  const handleDelSubCategory = () => {
    setLoading(true);
    //setSubCategoryToDel(selectedRows as string[]);
    handleFilterAndDeleteSubcategories(selectedRows as string[]);
    setLoading(false);
    setOpen(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Delete <b>rule(s)</b>:
      </DialogTitle>
      <DialogContent>
        <Typography>Select the subcategory to be deleted</Typography>
        <DataGrid
          rows={subcategoryToDelList}
          columns={cols}
          getRowId={(row) => row}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            setSelectedRows(ids);
          }}
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeader': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        />
        <p style={{ color: '#CB2939' }}>{message}</p>
        {loading && <ComponentLoading />}
        <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
          As {subcategoryToDelList} has mandatory fields, all rules from the
          choosen subcategory will be deleted.
        </Typography>
        <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
          Deleting subcategory may not be undone
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={loading}
          onClick={() => handleDelSubCategory()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
