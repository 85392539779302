export const isValidExtension = (
  filename: string,
  allowedExtensions?: string[],
): boolean => {
  const defaultAllowedExtensions = [
    'html',
    'json',
    'txt',
    'pdf',
    'docx',
    'rtf',
    'pptx',
    'csv',
    'xlsx',
    'xls',
    'zip',
    'png',
    'jpg',
    'jpeg',
    'mp3',
    'wav',
    'flac',
  ];
  const validFailes =
    allowedExtensions && allowedExtensions.length > 0
      ? allowedExtensions
      : defaultAllowedExtensions;
  const extension = getFileExtension(filename);
  return validFailes.includes(extension);
};

export const getFileExtension = (fileName: string) =>
  fileName.split('.').pop()?.replace(/\s/g, '') || ''; //empty not allowed

export const isObjectEmpty = (object: {}) => {
  return Object.keys(object).length === 0 && object.constructor === Object;
};

export function containsSpecialChars(str: string) {
  const specialChars = /[\\/:*"'<>|]/;
  return specialChars.test(str);
}

export function invalidAPICharacter(str: string) {
  const specialChars = /[\\/"'|]/;
  return specialChars.test(str);
}

export function containsSpecialCharsAndSpace(str: string) {
  const specialChars = /[\\/:*"'<>|\b \b]/;
  return specialChars.test(str);
}
