import React, { useContext, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { SnackbarContext } from 'components/SnackbarProvider';
import SecureTextField from 'components/SecureTextField';
import {
  Card,
  CardHeader,
  HeaderTitle,
  ColumnTitle,
  CardMenu,
  MenuItem,
  CardFooter,
  FooterAction,
} from 'components/MetadataCard';
import { deleteMetadata } from 'components/MetadataProvider';

type Column = {
  key: number;
  status: boolean;
  name: string;
  items: string[];
  edit: boolean;
  mandatory: boolean;
};

type MetadataCardFormProps = {
  column: Column;
  handleCancel: (key: number) => void;
  handleSave: (column: Column) => void;
};

export default function EditableMetadataCard({
  column,
  handleCancel,
  handleSave,
}: MetadataCardFormProps): React.JSX.Element {
  const [editedColumn, setEditedColumn] = useState<Column>(column);
  const [itemsToDelete, setItemsToDelete] = useState<{
    name: string;
    values: string[];
  }>();
  const createAlert = useContext(SnackbarContext);

  const handleDeleteItem = (itemToRemove: string) => {
    setItemsToDelete((prev) => {
      prev
        ? prev.values.push(itemToRemove)
        : (prev = { name: editedColumn.name, values: [itemToRemove] });
      return prev;
    });
    setEditedColumn((prev) => {
      const filteredItems = prev.items.filter((item) => item !== itemToRemove);
      return { ...prev, items: filteredItems };
    });
  };

  const updateColumnTitle = (newTitle: string) => {
    setEditedColumn((prev) => {
      return { ...prev, name: newTitle };
    });
  };

  const addNewTag = () => {
    setEditedColumn((prev) => {
      let newItems = prev.items;
      newItems.push('');
      return { ...prev, items: newItems };
    });
  };

  const updateItemName = ({
    newTitle,
    idx,
  }: {
    newTitle: string;
    idx: number;
  }) => {
    setEditedColumn((prev) => {
      const newItems = prev.items.map((item, index) => {
        if (index === idx) return newTitle;
        return item;
      });
      return { ...prev, items: newItems };
    });
  };

  const diseableSave = useMemo(
    () => editedColumn.items.includes(''),
    [editedColumn],
  );

  return (
    <Card>
      <CardHeader>
        <HeaderTitle>{`Edit ${column.name} `}</HeaderTitle>
        <IconButton onClick={addNewTag}>
          <AddOutlinedIcon />
        </IconButton>
      </CardHeader>
      <CardMenu>
        <ColumnTitle>
          <SecureTextField
            id={`${editedColumn.key}-title`}
            label="Column"
            type="text"
            fullWidth
            variant="outlined"
            value={editedColumn.name}
            sx={{ marginBottom: 1 }}
            onUpdate={(value) => updateColumnTitle(value)}
          />
        </ColumnTitle>
        {editedColumn.items.map((item, idx) => (
          <MenuItem key={idx}>
            <SecureTextField
              id={`${idx}`}
              label="Tag"
              type="text"
              fullWidth
              variant="outlined"
              value={item}
              sx={{ marginBottom: 1 }}
              onUpdate={(value) => updateItemName({ newTitle: value, idx })}
            />
            <IconButton onClick={() => handleDeleteItem(item)}>
              <RemoveOutlinedIcon />
            </IconButton>
          </MenuItem>
        ))}
      </CardMenu>
      <CardFooter>
        <FooterAction
          color="error"
          startIcon={<ClearOutlinedIcon />}
          type="button"
          onClick={() => handleCancel(editedColumn.key)}
        >
          Cancel
        </FooterAction>
        <FooterAction
          type="button"
          onClick={async () => {
            // handleSave(editedColumn);
            if (itemsToDelete) {
              try {
                await deleteMetadata(itemsToDelete);
                handleSave(editedColumn);
                createAlert({
                  message: 'Metadata saved with success.',
                  severity: 'success',
                });
              } catch (err) {
                createAlert({
                  message: 'Error on save metadata.',
                  severity: 'error',
                });
              }
            } else {
              handleSave(editedColumn);
            }
          }}
          startIcon={<CheckOutlinedIcon />}
          disabled={diseableSave}
        >
          Save
        </FooterAction>
      </CardFooter>
    </Card>
  );
}
