import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

type TReq = { message: string };

export default function ReqMessagesList({
  payload,
}: {
  payload: TReq[];
}): React.JSX.Element {
  const ShowPropsVals = payload.map((_msg: TReq) => (
    <ListItem key={_msg.message} alignItems="flex-start" disablePadding>
      <ListItemText>
        <b>{_msg.message}</b>
      </ListItemText>
    </ListItem>
  ));

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        border: 1,
        borderRadius: 3,
        borderColor: 'black',
        spacing: 0,
        fontSize: '9px',
      }}
    >
      {ShowPropsVals}
    </List>
  );
}
