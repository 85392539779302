import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Checkbox,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Add, Delete, InputOutlined } from '@mui/icons-material';
import useMultiUploadForm from 'common/hooks/useMultiUploadForm';
import { validateInput } from 'common/utils/urlValidator';
import SecureTextField from 'components/SecureTextField';
import React from 'react';
import { FormInputs, FormValues, Source, SourceType } from 'types';
import { ButtonStyled, Title } from './UploadSourceForm.styles';

interface UploadSourceFormProps {
  index: string;
  inputs: FormInputs[];
  isNewConfig?: boolean;
  selectedSource: Source;
  initialFormValues: FormValues[];
  handleClose: () => void;
  handleCreateConfig?: ({
    source_type,
    prevFormValues,
  }: {
    source_type: SourceType;
    prevFormValues?: FormValues[] | undefined;
  }) => void;
  removeConfiguration: ({
    key,
    source_type,
  }: {
    key: string;
    source_type: string;
  }) => void;
  removeEmptyConfiguration?: ({
    key,
    source_type,
  }: {
    key: string;
    source_type: string;
  }) => void;
}

export default function UploadSourceForm({
  index,
  inputs,
  isNewConfig,
  selectedSource,
  initialFormValues,
  handleCreateConfig,
  removeConfiguration,
  removeEmptyConfiguration,
}: UploadSourceFormProps): React.JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues[]>(initialFormValues);
  const [formValid, setFormValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { validateConfig, updateConfig, isSaveEnabled, getEmptyConfig } =
    useMultiUploadForm({
      index,
      isNewConfig,
    });

  useEffect(() => {
    setFormValues(initialFormValues);
    validateForm();
  }, [initialFormValues]);

  const validateForm = () => {
    let isValid = true;
    formValues.forEach((input) => {
      Object.keys(input).forEach((name) => {
        if (
          name !== 'pull_interval' &&
          name !== 'page_title' &&
          name !== 'source_type' &&
          name !== 'index_name' &&
          name !== 'source_folder' &&
          name !== 'enabled' &&
          name !== 'access_token' &&
          input[name as keyof FormValues] === ''
        ) {
          isValid = false;
        }
      });
    });
    setFormValid(isValid);
  };

  const handleCancel = () => {
    if (isNewConfig) {
      navigate(-1);
    } else {
      navigate('', { replace: true });
    }
    setFormValues(initialFormValues);
  };

  const handleAddConfig = () => {
    const newConfig = getEmptyConfig({
      index,
      source_type: formValues[0]?.source_type as SourceType,
    });

    if (newConfig.length > 0) {
      // Se incluye un key único en la nueva configuración.
      const newElement = {
        ...newConfig[0],
        enabled: false,
        key: crypto.randomUUID(),
      };
      setFormValues((prev) => [...prev, newElement]);
    }

    handleCreateConfig &&
      handleCreateConfig({
        source_type: formValues[0]?.source_type as SourceType,
        prevFormValues: formValues,
      });
  };

  const handleChange = ({
    e,
    key,
  }: {
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent;
    key: string;
  }) => {
    const { name, value } = e.target;
    setFormValues((prev) =>
      prev.map((input) =>
        input.key === key ? { ...input, [name]: value } : input,
      ),
    );
    validateForm();
  };

  const handleBooleanChange = ({
    //remove when enable input is needed
    e,
    key,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    key: string;
  }) => {
    const { name, checked } = e.target;
    setFormValues((prev) =>
      prev.map((input) =>
        input.key === key ? { ...input, [name]: checked } : input,
      ),
    );
    validateForm();
  };

  const handleTextChange = ({
    cleanValue,
    e,
    key,
  }: {
    cleanValue: string;
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent;
    key: string;
  }) => {
    const { name } = e.target;
    setFormValues((prev) =>
      prev.map((input) =>
        input.key === key ? { ...input, [name]: cleanValue } : input,
      ),
    );
    validateForm();
  };

  const getInputValue = ({
    key,
    inputName,
  }: {
    key: string;
    inputName: keyof FormValues;
  }) => {
    let value: string | number | undefined | boolean;

    formValues.forEach((input, index) =>
      input.key === key ? (value = formValues[index][inputName]) : undefined,
    );

    return value;
  };

  const handleValidation = async () => {
    await validateConfig(formValues);
    setIsDisabled(true); // Disable inputs after successful validation
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updatedConfig: FormValues[] = formValues.map((input) => ({
      ...input,
      enabled: true,
    }));
    await updateConfig(updatedConfig);
    navigate(-1);
  };

  const paddingStyle = isNewConfig ? 5 : 0;
  const heightStyle = isNewConfig ? 'calc(100vh - 138px)' : undefined;
  const setOverflow = isNewConfig ? 'auto' : 'none';
  const inputsWidth = isNewConfig ? 3 : 1.5;

  const removeEmptyConfig = (key: string) => {
    setFormValues((prev) => {
      const newValues = prev.filter((input) => input.key !== key);
      return [...newValues];
    });
  };

  const EnabledSwitch = () => {
    return (
      <Grid item xs={12} sm={6} md={3} lg={inputsWidth}>
        <FormGroup>
          <Title>Enabled</Title>
          <FormControlLabel
            control={<Switch id="enabled" checked={true} />}
            label=""
          />
        </FormGroup>
      </Grid>
    );
  };

  return (
    <Grid
      container
      gap={4}
      wrap="nowrap"
      boxSizing={'border-box'}
      flexDirection={'column'}
      sx={{ overflow: setOverflow }}
      padding={paddingStyle}
      height={heightStyle}
    >
      <Grid item>
        <Title sx={{ mb: 0 }}>Configure {selectedSource}</Title>
      </Grid>
      <Grid
        container
        item
        display="flex"
        flexDirection="column"
        gap={3}
        wrap="nowrap"
        component={'form'}
        onSubmit={handleSubmit}
      >
        <Grid
          item
          paddingY={4}
          paddingX={3}
          sx={{ backgroundColor: '#FAFAFA' }}
        >
          {inputs.map((configInputs, idx) => (
            <React.Fragment key={configInputs.key}>
              <Grid container spacing={2} height={'fit-content'}>
                {configInputs.inputs.map((input) => (
                  <Grid
                    key={input.key}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={inputsWidth}
                    sx={{
                      display:
                        input.name === 'enabled'
                          ? 'none'
                          : input.name === 'source_type' ||
                              input.name === 'index_name' ||
                              (input.name === 'source_folder' &&
                                selectedSource.toLowerCase() === 'confluence')
                            ? 'none'
                            : 'block',
                    }}
                  >
                    {input.type && !input.options ? (
                      <>
                        {input.name !== 'enabled' && (
                          <Title>{input.label}</Title>
                        )}

                        {input.type === 'text' && (
                          <SecureTextField
                            required={
                              input.id === 'source_folder_id' ||
                              input.id === 'access_token_id'
                                ? false
                                : true
                            }
                            htmlEntitiesMode={input.id === 'source_folder_id'}
                            fullWidth
                            id={input.id}
                            name={input.name}
                            type={input.type}
                            variant="outlined"
                            placeholder={input.label}
                            disabled={
                              input.name === 'source_type' ||
                              input.name === 'index_name' ||
                              isDisabled
                            }
                            onUpdate={(v, e) =>
                              handleTextChange({
                                e,
                                cleanValue: v,
                                key: configInputs.key,
                              })
                            }
                            value={
                              getInputValue({
                                key: configInputs.key,
                                inputName: input.name,
                              }) as string
                            }
                            error={
                              !!validateInput({
                                type: input.type,
                                value: input.name,
                              })
                            }
                            maxLength={
                              input.name === 'access_token' ? 1000 : 300
                            }
                          />
                        )}

                        {input.type === 'url' && (
                          <SecureTextField
                            sanitizerMode
                            required={
                              input.id === 'source_folder_id' ||
                              input.id === 'access_token_id'
                                ? false
                                : true
                            }
                            fullWidth
                            id={input.id}
                            name={input.name}
                            type={input.type}
                            variant="outlined"
                            maxLength={2000}
                            placeholder={input.label}
                            disabled={
                              input.name === 'source_type' ||
                              input.name === 'index_name' ||
                              isDisabled
                            }
                            onUpdate={(v, e) =>
                              handleTextChange({
                                e,
                                cleanValue: v,
                                key: configInputs.key,
                              })
                            }
                            value={
                              getInputValue({
                                key: configInputs.key,
                                inputName: input.name,
                              }) as string
                            }
                            error={
                              !!validateInput({
                                type: input.type,
                                value: input.name,
                              })
                            }
                          />
                        )}

                        {/* {input.type === 'boolean' && ( //remove when enable input is needed
                          <Switch
                            name={input.name}
                            color="default"
                            checked={
                              getInputValue({
                                key: configInputs.key,
                                inputName: input.name,
                              }) as boolean
                            }
                            onChange={(e) =>
                              handleBooleanChange({ e, key: configInputs.key })
                            }
                          />
                        )} */}

                        {input.type === 'number' && (
                          <TextField
                            required={
                              input.id === 'source_folder_id' ||
                              input.id === 'access_token_id'
                                ? false
                                : true
                            }
                            fullWidth
                            id={input.id}
                            name={input.name}
                            type={input.type}
                            variant="outlined"
                            placeholder={input.label}
                            disabled={isDisabled}
                            onChange={(e) =>
                              handleChange({ e, key: configInputs.key })
                            }
                            value={getInputValue({
                              key: configInputs.key,
                              inputName: input.name,
                            })}
                            error={
                              !!validateInput({
                                type: input.type,
                                value: input.name,
                              })
                            }
                            helperText={' '}
                            inputProps={
                              input.id === 'pull_interval_id'
                                ? { min: 0, max: 24 }
                                : {}
                            }
                            InputProps={
                              input.id === 'pull_interval_id'
                                ? {
                                    endAdornment: Number(
                                      getInputValue({
                                        key: configInputs.key,
                                        inputName: input.name,
                                      }) !== '',
                                    ) ? (
                                      <InputAdornment
                                        sx={{
                                          position: 'absolute',
                                          marginLeft: '30px',
                                        }}
                                        position="start"
                                      >
                                        <span>
                                          {Number(
                                            getInputValue({
                                              key: configInputs.key,
                                              inputName: input.name,
                                            }),
                                          ) === 1
                                            ? 'hour'
                                            : 'hours'}
                                        </span>
                                      </InputAdornment>
                                    ) : (
                                      <></>
                                    ),
                                  }
                                : {}
                            }
                          />
                        )}

                        {input.type === 'boolean' && (
                          <>
                            {(selectedSource.toLowerCase() === 'sharepoint' ||
                              selectedSource.toLowerCase() === 'aws_s3') &&
                              input.name === 'pull_entire_structure' && (
                                <Grid
                                  key={`pull_entire_structure_${configInputs.key}`}
                                  sx={{ display: 'inline-block' }}
                                >
                                  <Checkbox
                                    id={input.id}
                                    name={input.name}
                                    value={getInputValue({
                                      key: configInputs.key,
                                      inputName: input.name,
                                    })}
                                    checked={
                                      !!getInputValue({
                                        key: configInputs.key,
                                        inputName: input.name,
                                      })
                                    }
                                    onChange={(e) =>
                                      handleBooleanChange({
                                        e,
                                        key: configInputs.key,
                                      })
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        fontSize: 32,
                                        justifySelf: 'right',
                                      },
                                    }}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              )}
                            {selectedSource.toLowerCase() === 'sharepoint' &&
                              input.name === 'keep_folder_path' && (
                                <Grid
                                  key={`keep_folder_path_${configInputs.key}`}
                                  sx={{
                                    display: 'inline-block',
                                    marginRight: 2,
                                  }}
                                >
                                  <Checkbox
                                    id={input.id}
                                    name={input.name}
                                    value={getInputValue({
                                      key: configInputs.key,
                                      inputName: input.name,
                                    })}
                                    checked={
                                      !!getInputValue({
                                        key: configInputs.key,
                                        inputName: input.name,
                                      })
                                    }
                                    onChange={(e) =>
                                      handleBooleanChange({
                                        e,
                                        key: configInputs.key,
                                      })
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        fontSize: 32,
                                        justifySelf: 'right',
                                      },
                                    }}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Title>{input.label}</Title>
                        <FormControl fullWidth sx={{ paddingBottom: 2 }}>
                          <Select
                            displayEmpty
                            required
                            id={input.id}
                            name={input.name}
                            variant="outlined"
                            onChange={(e) =>
                              handleChange({ e, key: configInputs.key })
                            }
                            value={
                              getInputValue({
                                key: configInputs.key,
                                inputName: input.name,
                              }) as string
                            }
                            renderValue={(selected) =>
                              selected && selected.length > 0 ? (
                                selected
                              ) : (
                                <div style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                                  Configuration
                                </div>
                              )
                            }
                            disabled={isDisabled} // Disable Select if form is disabled
                          >
                            {input.options?.map((option) => (
                              <MenuItem value={option} key={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                ))}
                {!isNewConfig && <EnabledSwitch />}
              </Grid>
              <Grid
                item
                xs={12}
                width={'100%'}
                display={'flex'}
                alignItems={'end'}
                justifyContent={'end'}
              >
                {/* delete empty configuration when triggering add config button  */}

                {/* {idx > 0 && (
                  <Grid
                    item
                    xs={12}
                    width={'100%'}
                    display={'flex'}
                    alignItems={'end'}
                    justifyContent={'end'}
                  >
                    <Button
                      id="deleteMultisource"
                      type="button"
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        console.log('Eliminando configuración con key:', configInputs.key);
                        removeEmptyConfig(configInputs.key);
                      }}                                          
                    >
                      <Delete />
                    </Button>
                  </Grid>
                )} */}
                {/* delete from configuration page */}
                {!isNewConfig && (
                  <Button
                    id="deleteMultisource"
                    type="button"
                    color="secondary"
                    variant="outlined"
                    onClick={() =>
                      removeConfiguration({
                        key: configInputs.key,
                        source_type: selectedSource,
                      })
                    }
                  >
                    <Delete />
                  </Button>
                )}
              </Grid>
              {idx !== inputs.length - 1 && <Divider sx={{ mt: 2, mb: 3 }} />}
            </React.Fragment>
          ))}
          {isNewConfig && (
            <ButtonStyled onClick={handleAddConfig} disabled={isDisabled}>
              <Add
                sx={{ color: '#333333', fontWeight: 'bold', fontSize: '26px' }}
              />
              <Typography
                sx={{ color: '#333333', fontWeight: 'bold', fontSize: '14px' }}
              >
                Add configuration
              </Typography>
            </ButtonStyled>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          width={'100%'}
          display={'flex'}
          alignItems={'end'}
          justifyContent={'end'}
        >
          <Box display="flex" gap={2}>
            <Button color="secondary" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleValidation}
              disabled={!formValid}
            >
              Validate
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!isSaveEnabled}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
