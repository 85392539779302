import { IToken, IShortToken } from 'types';

const convertToken = (token: IToken): IShortToken<number> => {
  const newToken: IShortToken<number> = {
    accessToken: token.token,
    expirationDate: 0,
  };
  const dateSplit = token?.expirationDate?.split('-');
  newToken.expirationDate = new Date(
    `${dateSplit[2].substring(0, 4)}-${dateSplit[1]}-${
      dateSplit[0]
    }${dateSplit[2].substring(4)}`,
  ).getTime();
  return newToken;
};

export default convertToken;
