import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';

export const SideMenuStyled = styled(Grid)`
  background-color: #fafafa;
  border-right: 1px solid #b3b2b5;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: 24px;
  padding-top: 24px;
  padding-bottom: 16px;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;
