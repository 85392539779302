import React, { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ComponentLoading from 'components/ComponentLoading';
import { grey } from 'theme';
import GraphList from 'components/Page/Graphs/GraphList';
import { GraphIndustryContext } from 'components/GraphIndustryProvider';
import SecureTextField from 'components/SecureTextField';
import UploadGraphDialog, { UploadGraphButton } from './UploadGraphDialog';

import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { IconButton } from '@mui/material';
import AddGraphConfigDialog, {
  AddGraphConfigButton,
} from './AddGraphConfigDialog';
import ConfigUserPermissions, {
  IConfigUserPermissions,
} from './RBAC/ConfigUserPermissions';

export default function GraphsPage(): React.JSX.Element {
  const { graphsIndustries, loadingGraphs } = useContext(GraphIndustryContext);
  const [uploadGraphDialogOpen, setUploadGraphDialogOpen] = useState(false);
  const [addGraphConfigDialogOpen, setAddGraphConfigDialogOpen] =
    useState(false);
  const [searchGraphDialogOpen, setSearchGraphDialogOpen] = useState(false);
  const [itemsToList, setItemsToList] = useState(graphsIndustries);
  const [searchValue, setSearchValue] = useState<string>('');
  const [userPermissions, setUserPermissions] =
    useState<IConfigUserPermissions>({});

  const filterItems = (searchValue: string) => {
    if (!searchValue) return graphsIndustries;

    const searchTerm = searchValue.toLowerCase();
    return graphsIndustries.filter((graphIndustry) => {
      const graphName = graphIndustry.graph_name.toLowerCase();
      return graphName.includes(searchTerm);
    });
  };

  const permissions = ConfigUserPermissions();

  useEffect(() => {
    if (graphsIndustries.length !== 0) setItemsToList(graphsIndustries);
    setUserPermissions(permissions);
  }, [graphsIndustries]);

  return (
    <>
      <Grid container flexDirection="column" minHeight="100%">
        <Grid
          container
          item
          alignItems="center"
          bgcolor="white"
          boxSizing={'border-box'}
          px={3}
          pt={0}
          sx={{
            borderBottom: `1px solid ${grey[500]}`,
            boxSizing: 'border-box',
            height: '80px',
          }}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h2">Graphs</Typography>
          </Grid>
          <Grid flexGrow={1} display={'flex'} flexDirection={'row'}>
            {!loadingGraphs ? (
              <>
                <SecureTextField
                  fullWidth
                  color="secondary"
                  variant="standard"
                  placeholder="Type here to search for a Graph..."
                  value={searchValue}
                  onUpdate={(value) => setSearchValue(value)}
                  onKeyUp={(event) =>
                    event.key === 'Enter' &&
                    setItemsToList(filterItems(searchValue))
                  }
                />
                <IconButton
                  onClick={() => setItemsToList(filterItems(searchValue))}
                >
                  <SearchOutlined color="secondary" />
                </IconButton>
              </>
            ) : null}
          </Grid>
          <Grid item sx={{ flexGrow: 1 }} textAlign="right">
            {/* <AddGraphConfigButton setOpen={setAddGraphConfigDialogOpen} /> */}
            &nbsp;
            <UploadGraphButton setOpen={setUploadGraphDialogOpen} />
          </Grid>
        </Grid>
        <Grid
          item
          flexGrow={1}
          bgcolor={grey[200]}
          py={2}
          px={4}
          sx={{ height: 'calc(100vh - 204px)', overflowY: 'auto' }}
        >
          {' '}
          {loadingGraphs ? (
            <ComponentLoading />
          ) : (
            <GraphList graphs={itemsToList} />
          )}
        </Grid>
      </Grid>

      <AddGraphConfigDialog
        open={addGraphConfigDialogOpen}
        setOpen={setAddGraphConfigDialogOpen}
      />

      <UploadGraphDialog
        open={uploadGraphDialogOpen}
        setOpen={setUploadGraphDialogOpen}
      />
    </>
  );
}
