import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';

import { GenericObject } from 'types';
import { StyledChip } from 'components/FileTable/FileTable.styles';
import SecureTextField from 'components/SecureTextField';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: lighten(theme.palette.primary.light, 0),
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
  backgroundColor: lighten(theme.palette.primary.light, 0.8),
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
  fontSize: 16,
  fontFamily: 'Graphik',
}));

const GroupItems = styled('ul')({
  padding: 0,
});

interface GroupedAutocompleteProps {
  value: [];
  error: boolean;
  disabled: boolean;
  groupBy: string;
  helperText: string;
  getOptionLabel: string;
  options: GenericObject[];
  onChange: (value: GenericObject[]) => void;
}

const GroupedAutocomplete = ({
  value,
  groupBy,
  disabled,
  error,
  helperText,
  getOptionLabel,
  options,
  onChange,
}: GroupedAutocompleteProps) => {
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      disableCloseOnSelect
      options={options}
      groupBy={(option) => option[groupBy]}
      getOptionLabel={(option) => option[getOptionLabel]}
      isOptionEqualToValue={(option, value) =>
        option[getOptionLabel] === value[getOptionLabel]
      }
      sx={{ paddingBottom: 2, margin: '8px 0px 4px' }}
      value={value}
      onChange={(e, value) => onChange(value)}
      id="grouped-autocomplete"
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[getOptionLabel]}>
            {option[getOptionLabel]}
          </li>
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <StyledChip
            {...getTagProps({ index })}
            key={option[getOptionLabel]}
            label={option[getOptionLabel]}
          />
        ));
      }}
      renderInput={(params) => (
        <SecureTextField
          {...params}
          label={'Select the metadata'}
          error={error}
          helperText={error ? helperText : undefined}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

export default GroupedAutocomplete;
