import React, { useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { CollectionContext } from 'components/CollectionProvider';
import CollectionSettings from 'components/CollectionSettings';
import ComponentLoading from 'components/ComponentLoading';
import CollectionNotFound from 'components/CollectionNotFound';

/**
 * The individual collection page component which should render all components to be displayed specific to this page
 */
export default function CollectionPage(): React.JSX.Element {
  const { loadingCollections, collectionsInfo, getCollections } =
    useContext(CollectionContext);
  const { index } = useParams();
  const currentCollection =
    collectionsInfo &&
    collectionsInfo.find((item) => item.index_name === index);

  if (loadingCollections || currentCollection === null) {
    // Show the loading interface
    return <ComponentLoading />;
  }

  if (currentCollection === undefined) {
    // Show the no documents interface
    return <CollectionNotFound getCollections={getCollections} />;
  }

  return (
    <CollectionSettings
      collection={currentCollection!}
      getCollections={getCollections}
    />
  );
}
