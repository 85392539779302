import React, { useEffect, useRef, useContext } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { UserProperties } from 'components/AppWrapper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MessageBox, MessageContainer } from './MessageList.styles';
import { ChatCompletionDTO } from 'api';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { pdfStyles } from 'components/PdfView';
import ThreeDotsLoading from '../ThreeDotsLoading';
import QuasarChatAvatarIcon from './QuasarChatAvatarIcon';
import { ChatContext } from 'components/Page/ChatPage';
import Message from 'components/Message';
import Disclaimer from 'components/Disclaimer';
import MessageSource from 'components/MessageSource';

interface MessageListInputProps {
  searchIndex?: string | null;
  messages: ChatCompletionDTO[];
  loadingAnswer?: boolean;
  displayCheckbox?: boolean;
}
const styleSelected = {
  border: '#A100FF solid 1px',
  borderRadius: '8px',
  ':hover': { cursor: 'pointer' },
};
const styleUnselected = {
  border: 'none',
  ':hover': { cursor: 'pointer' },
};

const MessageList: React.FC<MessageListInputProps> = ({
  messages,
  searchIndex,
  loadingAnswer,
  displayCheckbox,
}) => {
  const messageListRef = useRef(null);
  const user = useContext(UserProperties);
  const chatContext = useContext(ChatContext);
  const allMessagesToReview = chatContext?.selectedMessages;
  const messagesToReview = chatContext?.messagesToReview;
  const setMessagesToReview = chatContext?.setMessagesToReview;

  const checkIsSelected = (id: string): boolean => {
    const result = messagesToReview?.find((elem) => {
      return elem.id === id;
    });
    return !!result;
  };

  const toggleMessage = (
    message: string,
    isSelected: boolean,
    id?: string,
  ): void => {
    if (isSelected) {
      setMessagesToReview &&
        setMessagesToReview((prev) =>
          prev?.filter((elem) => {
            return id ? elem.id !== id : elem.message !== message;
          }),
        );
    } else {
      setMessagesToReview &&
        setMessagesToReview((prev) => {
          if (prev && allMessagesToReview) {
            let addItem = allMessagesToReview.find((elem) => {
              return id ? elem.id === id : elem.message === message;
            });
            let obj = addItem ? [...prev, addItem] : prev;
            return obj;
          }
          return prev;
        });
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      const element = messageListRef.current as HTMLElement;
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 100); // Adjust the delay as needed
    }
  }, [messages]);

  return (
    <MessageContainer ref={messageListRef}>
      <Disclaimer
        message="GenWizard uses Generative AI technology for all of its responses and
          may generate inaccurate content."
      />

      {messages &&
        messages.map((message, index) =>
          message.type === 'user' ? (
            <Box
              key={`user-message-with-index-${index}`}
              sx={{ display: 'flex', alignItems: 'flex-start' }}
              mb={4}
              mt={3}
            >
              <Avatar
                alt={user?.username}
                sx={{
                  width: 30,
                  height: 30,
                  mr: 2,
                  backgroundColor: '#CCCBCE',
                  fontSize: '12px',
                  fontWeight: 600,
                  color: '#3B3944',
                  fontFamily: 'Graphik',
                }}
                title={user?.username}
              >
                {user?.username[0].toUpperCase()}
              </Avatar>
              <MessageBox user={1}>
                <p>{decode(message.content)}</p>
              </MessageBox>
            </Box>
          ) : (
            <Box
              key={`quasar-message-with-index-${index}`}
              sx={{ display: 'flex', alignItems: 'flex-start' }}
              mb={4}
              mt={3}
              width={'calc(100% - 46px)'}
            >
              <Avatar
                alt="Q++ Agent"
                sx={{ width: 30, height: 30, mr: 2 }}
                style={{
                  backgroundColor: '#FFFFFF',
                }}
                title="Q++ Agent"
              >
                <QuasarChatAvatarIcon />
              </Avatar>
              <MessageBox
                user={0}
                p={3}
                onClick={() => {
                  if (displayCheckbox)
                    toggleMessage(
                      message.content,
                      checkIsSelected(message?.id || ''),
                      message.id,
                    );
                }}
                sx={
                  checkIsSelected(message?.id || '') && displayCheckbox
                    ? styleSelected
                    : styleUnselected
                }
              >
                <Message rawMessage={message} />

                {index > 1 && (
                  <>
                    <Divider
                      sx={{
                        mt: 4,
                        display: message.type !== 'error' ? 'flex' : 'none',
                      }}
                    />

                    <Box
                      sx={{
                        display: message.type !== 'error' ? 'flex' : 'none',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                      }}
                    >
                      <Accordion
                        sx={{
                          p: 0,
                          width: '100%',
                          border: 'none',
                          boxShadow: 'none',
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="primary" />}
                          id="accordion-header"
                          sx={{ p: 0 }}
                        >
                          <Box>
                            <IconButton
                              color="secondary"
                              size="large"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(
                                  decode(message.content),
                                );
                              }}
                            >
                              <ContentCopyIcon color="primary" />
                            </IconButton>

                            <PDFDownloadLink
                              document={
                                <Document>
                                  <Page size="A4" style={pdfStyles.page}>
                                    <View style={pdfStyles.section}>
                                      <Text>{decode(message.content)}</Text>
                                    </View>
                                  </Page>
                                </Document>
                              }
                              fileName="Qusar_Chat_Message.pdf"
                            >
                              <IconButton
                                color="secondary"
                                size="large"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <SaveAltIcon />
                              </IconButton>
                            </PDFDownloadLink>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <h3>Show Source</h3>
                          {message.source ? (
                            <MessageSource
                              index={searchIndex}
                              source={message.source}
                            />
                          ) : (
                            'No Source: This response was did not return stored data, but may have been created inferring from the data.'
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </>
                )}
              </MessageBox>
            </Box>
          ),
        )}
      {loadingAnswer && (
        <>
          <Box
            key={`quasar-message-with-index-${new Date()}`}
            sx={{ display: 'flex', alignItems: 'flex-start' }}
            mb={4}
            mt={3}
          >
            <Avatar
              alt="Q++ Agent"
              sx={{ width: 30, height: 30, mr: 2 }}
              style={{
                fontSize: '12px',
                fontWeight: '600',
                color: '#000000',
                backgroundColor: '#FFFFFF',
              }}
              title="Q++ Agent"
            >
              <QuasarChatAvatarIcon />
            </Avatar>
            <ThreeDotsLoading />
          </Box>
        </>
      )}
    </MessageContainer>
  );
};

export default MessageList;
