import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  // IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import InfoIcon from '@mui/icons-material/Info';
// import OpenInFullIcon from '@mui/icons-material/OpenInFull';
// import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import SecureTextField from 'components/SecureTextField';
import {
  SavedSearch,
  useSavedSearches,
} from 'common/utils/savedSearchesContext';
import { Endpoint } from 'components/ChatView/ChatInput/ChatInput';
import InputWithTooltip from 'components/InputWithTooltip';
import SecureNumberField from 'components/SecureNumberField';

type Inputs = {
  id: string;
  name: keyof Endpoint;
  tooltip?: string;
  label: string;
  type: string;
  options?: { [key: string]: string[] };
}[];

const MAX_NB_SOURCES = 10;
const MIN_NB_SOURCES = 1;

const inputs: Inputs = [
  {
    id: 'question_id',
    name: 'question',
    label: 'Question',
    type: 'text',
  },
  {
    id: 'prompt_title_id',
    name: 'prompt_title',
    label: 'Prompt Title',
    type: 'text',
    tooltip: 'Add a name to identify the prompts in the library.',
  },
  {
    id: 'prompt_objective_id',
    name: 'prompt_objective',
    label: 'Prompt Objective',
    type: 'text',
    tooltip: 'Describe the objective of the prompt.',
  },
  {
    id: 'prompt_prefix_id',
    name: 'prompt_prefix',
    label: 'Prompt Prefix',
    type: 'text',
    tooltip: 'Describe the context of the prompt.',
  },
  {
    id: 'prompt_suffix_id',
    name: 'prompt_suffix',
    label: 'Prompt Suffix',
    type: 'text',
    tooltip:
      'Add a prompt suffix to further reinforce and/or refine your prompt.',
  },
  {
    id: 'prompt_template_id',
    name: 'prompt_template',
    label: 'Prompt Template',
    type: 'text',
    tooltip:
      'Add a prompt template to further reinforce and/or refine your prompt.',
  },
  {
    id: 'config_id',
    name: 'config',
    label: 'Config',
    type: 'menu',
    options: {
      default: ['show_source', 'show_sop', 'use_case'],
      searchVectors: ['vector_result_only'],
    },
    tooltip: '',
  },
  {
    id: 'nb_sources_id',
    name: 'nb_sources',
    label: 'Number of sources',
    type: 'slider',
    tooltip:
      'Choose the number of sources you would like the answer to contain.',
  },
  {
    id: 'file_name_id',
    name: 'file_name',
    label: 'File Name',
    type: 'autocomplete',
    options: {
      fileName: [],
    },
    tooltip: 'Select a file to make the source of information more specific.',
  },
  {
    id: 'output_id',
    name: 'output',
    label: 'Output',
    type: 'text',
  },
  {
    id: 'query_id',
    name: 'query',
    label: 'Query',
    type: 'text',
  },
  {
    id: 'outpul_eval_id',
    name: 'outpul_eval',
    label: 'Outpul Eval',
    type: 'text',
  },
  {
    id: 'formattedjson_id',
    name: 'formattedjson',
    label: 'Formatted JSON',
    type: 'boolean',
  },
  {
    id: 'primary_index_id',
    name: 'primary_index',
    label: 'Primary Index',
    type: 'text',
  },
  {
    id: 'secondary_index_id',
    name: 'secondary_index',
    label: 'Secondary Index',
    type: 'text',
  },
  {
    id: 'max_count_id',
    name: 'max_count',
    label: 'Max Count',
    type: 'number',
  },
  {
    id: 'chunk_size_id',
    name: 'chunk_size',
    label: 'Chunk Size',
    type: 'number',
  },
  {
    id: 'reset_context_id',
    name: 'reset_context',
    label: 'Reset Context',
    type: 'boolean',
    tooltip: 'Reset the context of this chat session after prompt is sent.',
  },
  {
    id: 'show_vectors_id',
    name: 'show_vectors',
    label: 'Show Vectors',
    type: 'boolean',
    tooltip: '',
  },
];

export interface PromptData {
  id?: string;
  name: string;
  prompt_title: string;
  prompt_objective: string;
  prompt_prefix: string;
  prompt_suffix: string;
  prompt_template?: string;
  userName: string;
}

interface ChatEndpointsFormProps {
  createPrompt?: boolean;
  chatEndpoint: Endpoint;
  documents?: string[] | null;
  handleCloseForm: () => void;
  updateEndpointConfig?: (formData: Endpoint) => void;
}

const ChatEndpointsForm = ({
  createPrompt,
  chatEndpoint,
  documents,
  handleCloseForm,
  updateEndpointConfig = () => {},
}: ChatEndpointsFormProps) => {
  const { addSavedSearch, chatModalEndpoint, savePromptData, selectedOption } =
    useSavedSearches();
  const [formData, setFormData] = useState<Endpoint>(chatEndpoint);
  const [savePrompts, setSavePrompts] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSavePrompts(e.target.checked);
  };

  const hanldeSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateEndpointConfig(formData);

    if (savePrompts || createPrompt) {
      const searchToSave: SavedSearch = {
        id: formData.name,
        name: formData.name,
        prompt_objective: formData?.prompt_objective,
        prompt_suffix: formData.prompt_suffix,
        prompt_title: formData.prompt_title,
        prompt_prefix: formData.prompt_prefix,
        prompt_template: formData.prompt_template,
      };
      addSavedSearch(searchToSave);

      if (selectedOption) {
        await savePromptData(selectedOption, searchToSave);
      } else {
        console.error('No API type selected');
      }
    }

    handleCloseForm();
  };

  const handleChange = ({
    cleanValue,
    e,
  }: {
    cleanValue?: string | number;
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent;
  }) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: cleanValue ?? value }));
  };

  const handleMenuChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setFormData((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleBooleanInputChange = ({
    e,
    inputName,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    inputName: keyof Endpoint;
  }) => {
    const { name, checked } = e.target;
    const config = checked ? ['vector_result_only'] : ['show_source'];

    if (inputName === 'reset_context') {
      const reset_context = checked ? 'True' : 'False';

      return setFormData((prev) => ({
        ...prev,
        [name]: reset_context,
      }));
    }

    // Set new config option
    if (inputName === 'show_vectors') {
      setFormData((prev) => ({ ...prev, config }));
    }
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChangeAutocomplete = (value: string | null, name: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSlider = (
    event: Event,
    newValue: number | number[],
    name: string,
  ) => {
    if (typeof newValue === 'number') {
      setFormData((prev) => ({ ...prev, [name]: newValue }));
    }
  };

  // const handleFullWidth = () => {
  //   setFullWidth((prev) => !prev);
  // };

  useEffect(() => {
    setFormData({
      ...chatEndpoint,
      config: chatEndpoint.config || [],
      ...(chatModalEndpoint as Endpoint),
    });
  }, [chatEndpoint, chatModalEndpoint]);

  return (
    // <Dialog
    //   open={true}
    //   onClose={handleCloseForm}
    //   PaperProps={{
    //     component: 'form',
    //     onSubmit: hanldeSubmit,
    //   }}
    //   fullWidth
    //   maxWidth={fullWidth ? 'lg' : 'md'}
    // >
    <form onSubmit={hanldeSubmit}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          justifyContent: 'space-between',
        }}
      ></Box>

      {/* <DialogContent> */}
      {inputs.map((input) => (
        <Fragment key={input.id}>
          {Object.keys(chatEndpoint).map(
            (endpointProperty) =>
              endpointProperty === input.name && (
                <Fragment key={input.id}>
                  {input.type === 'text' && (
                    <InputWithTooltip
                      tooltip={input.tooltip}
                      label={input.label}
                    >
                      <SecureTextField
                        maxLength={5000}
                        htmlEntitiesMode
                        multiline
                        fullWidth
                        id={input.id}
                        margin="dense"
                        name={input.name}
                        placeholder={input.label}
                        type={input.type}
                        onUpdate={(v, e) => handleChange({ cleanValue: v, e })}
                        sx={{
                          paddingBottom: 2,
                          mb: 1,
                          '& .MuiInputBase-input': { padding: 0 },
                        }}
                        value={formData[input.name] as string}
                      />
                    </InputWithTooltip>
                  )}

                  {input.name === 'prompt_suffix' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="prompt_objective_checkbox"
                          checked={savePrompts}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label={
                        <span style={{ fontWeight: 'bold' }}>
                          Save to Prompt Library
                        </span>
                      }
                      labelPlacement="end"
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    />
                  )}
                  {input.type === 'menu' && (
                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: 2, margin: '8px 0px 8px' }}
                    >
                      <InputWithTooltip
                        tooltip={input.tooltip}
                        label={input.label}
                      >
                        <Select
                          required
                          multiple
                          id={input.id}
                          name={input.name}
                          onChange={handleMenuChange}
                          labelId={`${input.id}-label`}
                          value={formData[input.name] as string[]}
                          input={<OutlinedInput label={input.label} />}
                        >
                          {input.options &&
                            input.options[
                              chatEndpoint.name !== 'Search Vectors' &&
                              !formData['show_vectors']
                                ? 'default'
                                : 'searchVectors'
                            ].map((item) => (
                              <MenuItem key={crypto.randomUUID()} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </InputWithTooltip>
                    </FormControl>
                  )}
                  {input.type === 'autocomplete' &&
                    chatEndpoint.name === 'Search' &&
                    documents && (
                      <FormControl
                        fullWidth
                        sx={{ paddingBottom: 2, margin: '8px 0px 8px' }}
                      >
                        <InputWithTooltip
                          tooltip={input.tooltip}
                          label={input.label}
                        >
                          <Autocomplete
                            id={input.id}
                            options={documents}
                            value={formData[input.name] as string}
                            onChange={(e, value) =>
                              handleChangeAutocomplete(value, input.name)
                            }
                            renderInput={(params) => (
                              <SecureTextField
                                {...params}
                                variant="outlined"
                                label={input.label}
                              />
                            )}
                          />
                        </InputWithTooltip>
                      </FormControl>
                    )}
                  {input.type === 'slider' &&
                    chatEndpoint.name === 'Search' &&
                    formData['config']?.includes('show_source') && (
                      <Box sx={{ pb: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Graphik',
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '125%',
                            }}
                          >
                            Number of Sources: {formData[input.name]}
                          </Typography>

                          <Tooltip title={input.tooltip} arrow>
                            <InfoIcon sx={{ height: '16px', width: '16px' }} />
                          </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Slider
                            step={1}
                            defaultValue={1}
                            min={MIN_NB_SOURCES}
                            max={MAX_NB_SOURCES}
                            value={formData[input.name] as number}
                            onChange={(e, v) =>
                              handleChangeSlider(e, v, input.name)
                            }
                            color="primary"
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            sx={{ width: 'calc(100% - 20px)' }}
                          />
                        </Box>
                      </Box>
                    )}
                  {input.type === 'number' && (
                    <SecureNumberField
                      fullWidth
                      id={input.id}
                      margin="dense"
                      name={input.name}
                      label={input.label}
                      onUpdate={(v, e) => handleChange({ cleanValue: v, e })}
                      sx={{ paddingBottom: 2 }}
                      value={formData[input.name] as number}
                    />
                  )}

                  {input.type === 'boolean' && (
                    <FormGroup>
                      <FormControlLabel
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'Graphik',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '125%',
                              }}
                            >
                              {input.label}
                            </Typography>

                            {input.tooltip && (
                              <Tooltip title={input.tooltip} arrow>
                                <InfoIcon
                                  sx={{ height: '16px', width: '16px' }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        }
                        labelPlacement="end"
                        sx={{
                          justifyContent: 'start',
                          margin: 0,
                          height: 50,
                        }}
                        control={
                          <Checkbox
                            name={input.name}
                            color="primary"
                            checked={
                              input.name === 'reset_context'
                                ? formData[input.name] === 'True'
                                : (formData[input.name] as boolean)
                            }
                            onChange={(e) =>
                              handleBooleanInputChange({
                                inputName: input.name,
                                e,
                              })
                            }
                          />
                        }
                      />
                    </FormGroup>
                  )}
                </Fragment>
              ),
          )}
        </Fragment>
      ))}
      {/* </DialogContent> */}
      <DialogActions>
        <Button onClick={handleCloseForm} color="secondary">
          Cancel
        </Button>
        <Button type="submit" color="secondary" variant="contained">
          Save
        </Button>
      </DialogActions>
      {/* </Dialog> */}
    </form>
  );
};

export default ChatEndpointsForm;
