import { AxiosError, AxiosResponse } from 'axios';
import PrivReq from 'api/axios';
import { IConfigData, ISubCategories } from '../interfaces';
import { ConsoleLine } from 'mdi-material-ui';

type TReq = {
  message: string;
  clientsPayload: IConfigData[];
};

export default async function getClientsListRules(
  categoryID: string,
  clients: ISubCategories[],
): Promise<TReq[]> {
  if (clients === null || clients.length === 0) {
    return [
      { message: 'No data to send. Please, try again.', clientsPayload: [] },
    ];
  }

  const clientsPayload: IConfigData[] = [];

  const getClientsListRules = async () => {
    const reqs: Promise<TReq[]>[] = clients.map(async (confPayload) => {
      try {
        await PrivReq({
          method: 'get',
          url: `/getconfigurable/${categoryID}/${confPayload.id}`,
        }).then((response: AxiosResponse) => {
          if (!clientsPayload.includes({ ...response.data })) {
            clientsPayload.push(...response.data);
          }
        });
        return [
          {
            message: `fetched clients labels and roles successfully.`,
            clientsPayload,
          },
        ];
      } catch (error) {
        if (error instanceof AxiosError) {
          return [
            {
              message: `${error.message}`,
              clientsPayload: [],
            },
          ];
        } else {
          throw error;
        }
      }
    });
    const results = await Promise.allSettled(reqs);
    return results.flatMap((result) =>
      result.status === 'fulfilled' ? result.value : [],
    );
  };
  try {
    return await getClientsListRules();
  } catch (error) {
    if (error instanceof Error) {
      return [{ message: 'Internal server error.', clientsPayload: [] }];
    } else {
      throw error;
    }
  }
}
