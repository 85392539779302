import React, { useState } from 'react';
import ChatInput from '../ChatView/ChatInput';
import MessageList from '../ChatView/MessageList';

import { ChatCompletionDTO } from 'api';
import { AvailableChats } from 'types';
import { DEFAULT_INDEX } from 'components/Page/ChatPage/ChatPage';

interface ChatProps {
  sessionid: string;
  messages: ChatCompletionDTO[];
  displayGenerateDocument: boolean;
  handleUserInput: (textInput: string) => void;
  setMessages: React.Dispatch<React.SetStateAction<ChatCompletionDTO[]>>;
  setAvailableChats: React.Dispatch<React.SetStateAction<AvailableChats[]>>;
}

export default function Chat({
  messages,
  sessionid,
  displayGenerateDocument,
  setMessages,
  handleUserInput,
  setAvailableChats,
}: ChatProps): React.JSX.Element {
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [searchIndex, setIndexInput] = useState<null | string>(DEFAULT_INDEX);

  return (
    <>
      <MessageList
        messages={messages}
        searchIndex={searchIndex}
        loadingAnswer={loadingAnswer}
        displayCheckbox={displayGenerateDocument}
      />
      <ChatInput
        sessionid={sessionid}
        searchIndex={searchIndex}
        setMessages={setMessages}
        setIndexInput={setIndexInput}
        handleUserInput={handleUserInput}
        setLoadingAnswer={setLoadingAnswer}
        setAvailableChats={setAvailableChats}
      />
    </>
  );
}
