import { Box, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SnackbarContext } from 'components/SnackbarProvider';
import { GetFrequentTasksResponse, FormData } from 'types';
import ComponentLoading from 'components/ComponentLoading';
import { getFequentTask } from 'api';
import SomethingWentWrong from 'components/SomethingWentWrong';
import { TEMPLATE_ID } from 'components/Page/TaskUploadPage/TaskUploadPage';

interface FrequentTaskProps {
  list: 'tasks' | 'jobs';
  prepopulateForm: ({
    newFormValues,
    idsArray,
    disableInputs,
  }: {
    newFormValues: FormData;
    idsArray: number[];
    disableInputs?: number[];
  }) => void;
}

export default function FrequentTasks({
  list,
  prepopulateForm,
}: FrequentTaskProps): React.JSX.Element {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const createAlert = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [frequentTasks, setFrequentTasks] =
    useState<GetFrequentTasksResponse | null>(null);

  const onTaskSelect = (hash: string) => {
    if (!frequentTasks) return;

    if (list === 'jobs') {
      return navigate(`/job-upload/${hash}`, { state: { editJob: false } });
    }

    const idsArray: number[] = [];
    const emptyFiles: number[] = [];

    const newFormValues = frequentTasks
      .filter((item) => item.hash === hash)
      .map((item) => {
        let formState: FormData = { name: item.name ?? '', task: item.task };
        if (list === 'tasks') {
          Object.keys(item.input_files).forEach((fileName, idx) => {
            const inputId = idx;
            idsArray.push(inputId);

            const isFileEmpty = item.input_files[fileName] === '';
            if (isFileEmpty) emptyFiles.push(inputId);

            formState[`input${inputId}`] = fileName;
            // Using undefined since file content is a path not a file
            // formState[`file${inputId}`] = task.input_files[fileName];
            formState[`file${inputId}`] = undefined;
            if (inputId === TEMPLATE_ID) {
              formState[`checkbox${inputId}`] = isFileEmpty;
              formState[`input${inputId}`] = fileName ?? 'Template';
            }
          });
        }
        return formState;
      })[0];

    const disableInputs = emptyFiles.length > 0 ? emptyFiles : undefined;

    prepopulateForm({ idsArray, newFormValues, disableInputs });
  };

  const getFrequentTasks = useCallback(() => {
    const onError = () => {
      createAlert({
        message: `There was a problem trying to get the frequent ${list === 'tasks' ? 'task' : 'job'} list`,
        severity: 'error',
      });
      setError(true);
    };

    const onLoading = (loading: boolean) => setLoading(loading);

    getFequentTask({ list, onSuccess: setFrequentTasks, onError, onLoading });
  }, [list, createAlert]);

  useEffect(() => getFrequentTasks(), [getFrequentTasks]);

  if (loading)
    return (
      <Box minWidth={'300px'} height={'100%'}>
        <ComponentLoading />
      </Box>
    );

  if (error)
    return (
      <SomethingWentWrong
        missingElement={list}
        handleRefresh={getFrequentTasks}
      />
    );

  return (
    <Box
      sx={{
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Graphik',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '150%',
          textAlign: 'center',
        }}
      >
        Frequent {list === 'tasks' ? 'Tasks' : 'Jobs'}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Graphik',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '150%',
          textAlign: 'center',
          letterSpacing: '0.4px',
        }}
      >
        Select a frequent {list === 'tasks' ? 'task' : 'job'} to populate the
        fields.
      </Typography>
      <Box sx={{ padding: '0px 16px' }}>
        {frequentTasks &&
          frequentTasks.map((task, idx) => (
            <Box
              sx={{
                padding: '12px 16px',
                borderBottom: '1px solid #E5E5E5',
                display: 'flex',
                gap: '4px',
                ['&:hover']: { color: 'blue' },
                cursor: 'pointer',
              }}
              key={idx}
            >
              <Typography
                sx={{
                  fontFamily: 'Graphik',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '125%',
                }}
              >{`${idx + 1}. `}</Typography>
              <Typography
                sx={{
                  fontFamily: 'Graphik',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '125%',
                  cursor: 'pointer',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                }}
                onClick={() => onTaskSelect(task.hash)}
              >
                {task.name ?? task.task}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
}
