import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, LinearProgress } from '@mui/material';

import { byteSizeConverter, getFileExtension } from 'common/utils';
import { FileIcons, Files } from 'types';
import {
  DocxIcon,
  PdfIcon,
  TxtIcon,
  ZipIcon,
  CsvIcon,
  HtmlIcon,
  XslIcon,
  PngIcon,
} from './Assets';

import {
  Modal,
  DialogHeaderStyled,
  HeaderTitleStyled,
  HeaderIconsStyled,
  DialogContentStyled,
  DialogItemStyled,
  ItemIconStyled,
  ItemContentStyled,
  ItemTitleStyled,
  ItemDescriptionStyled,
  ItemProgressBarStyled,
} from './UploadProgressModal.styles';

import { ConfirmationDialog } from 'components/UI';

interface FileListProps {
  files: Files;
  openUploadDialog: boolean;
  refresh: (files: Files | undefined) => void;
  removeFile: (key: string) => void;
}

const fileIcons = {
  docx: <DocxIcon />,
  xls: <XslIcon />,
  xlsx: <XslIcon />,
  txt: <TxtIcon />,
  csv: <CsvIcon />,
  html: <HtmlIcon />,
  pdf: <PdfIcon />,
  zip: <ZipIcon />,
};

const getFileIcon = (fileName: string): JSX.Element => {
  let Icon = <PngIcon />;
  const extension = getFileExtension(fileName);
  if (!extension) return Icon;
  Icon = fileIcons[extension as keyof FileIcons] ?? Icon;
  return Icon;
};

export default function UploadProgressModal({
  openUploadDialog,
  files,
  removeFile,
  refresh,
}: FileListProps): React.JSX.Element {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openUploadProgressModal, setOpenUploadProgressModal] = useState(true);
  const filesArr = Object.values(files);
  const uploadPendingFiles = filesArr.filter(
    (file) => file.upload_status !== 'Success',
  ).length;
  const decodePendingFiles = filesArr.filter(
    (file) => file.processed === 'started',
  ).length;
  const decodeCompletedFiles = filesArr.filter(
    (file) => file.processed === 'completed',
  ).length;

  const removeAllFiles = () => {
    filesArr.forEach((file) => removeFile(file.file.name));
  };

  const uploadProgress = (percentage: number, fileSize: number) => {
    return byteSizeConverter((percentage * fileSize) / 100);
  };

  const handleCloseUpload = () => {
    if (uploadPendingFiles > 0) return setOpenConfirmationModal(true);
    removeAllFiles();
  };

  const getInfo = (file: any) => {
    let status: string = '';
    switch (file.processed) {
      case 'started':
        status = 'Processing file...';
        break;
      case 'completed':
        status = 'Completed.';
        break;
      case 'cancelled':
        status = 'Cancelled processing.';
        break;
      default:
        status = 'Error on process file.';
    }
    return status;
  };

  return (
    <>
      {openUploadDialog && (
        <Modal>
          <DialogHeaderStyled>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {uploadPendingFiles > 0 && (
                <HeaderTitleStyled>
                  {uploadPendingFiles} Pending Uploading File
                  {uploadPendingFiles === 1 ? '' : 's'}
                </HeaderTitleStyled>
              )}
              {(decodePendingFiles > 0 || decodeCompletedFiles > 0) && (
                <HeaderTitleStyled>
                  {decodePendingFiles} Pending Decoding File
                  {decodePendingFiles === 1 ? '' : 's'}
                </HeaderTitleStyled>
              )}
            </Box>
            <HeaderIconsStyled>
              <IconButton
                aria-label="expand"
                onClick={() => setOpenUploadProgressModal((isOpen) => !isOpen)}
              >
                {openUploadProgressModal ? (
                  <ExpandMoreIcon
                    sx={{ width: '36px', height: '36px', color: 'white' }}
                  />
                ) : (
                  <ExpandLessIcon
                    sx={{ width: '36px', height: '36px', color: 'white' }}
                  />
                )}
              </IconButton>
              <IconButton aria-label="delete" onClick={handleCloseUpload}>
                <CloseIcon
                  sx={{ width: '24px', height: '24px', color: 'white' }}
                />
              </IconButton>
            </HeaderIconsStyled>
          </DialogHeaderStyled>
          {openUploadProgressModal && (
            <DialogContentStyled>
              {filesArr.map((file) => (
                <DialogItemStyled key={file.file.name}>
                  {file.file.name && (
                    <ItemIconStyled>
                      {getFileIcon(file.file.name)}
                    </ItemIconStyled>
                  )}
                  <ItemContentStyled>
                    <ItemTitleStyled>
                      <div style={{ width: '460px', overflow: 'hidden' }}>
                        {file.file.name}
                      </div>
                      {file.upload_status &&
                      file.upload_status !== 'Success' ? (
                        <IconButton
                          aria-label="refresh"
                          type="button"
                          onClick={() => refresh(files)}
                          disabled={
                            file.upload_status ===
                            'Unfilled mandatory metadata.'
                          }
                        >
                          <RefreshIcon sx={{ width: '24px', height: '24px' }} />
                        </IconButton>
                      ) : (
                        <>
                          {file.upload_status !== 'Success' && (
                            <IconButton
                              aria-label="delete"
                              onClick={() => removeFile(file.file.name)}
                            >
                              <CloseIcon
                                sx={{ width: '24px', height: '24px' }}
                              />
                            </IconButton>
                          )}
                        </>
                      )}
                    </ItemTitleStyled>
                    {file.upload_progress ? (
                      <>
                        {file.upload_status ? (
                          <>
                            {file.upload_status !== 'Success' ? (
                              <>
                                <ItemDescriptionStyled
                                  style={{ color: '#CB2939' }}
                                >
                                  Upload failed: {file.upload_status}
                                </ItemDescriptionStyled>
                                <ItemProgressBarStyled>
                                  <LinearProgress
                                    variant="determinate"
                                    value={file.upload_progress}
                                    color="error"
                                  />
                                </ItemProgressBarStyled>
                              </>
                            ) : (
                              <ItemDescriptionStyled
                                style={{
                                  color:
                                    file.processed === 'completed' ||
                                    file.processed === 'started'
                                      ? '#3C8703'
                                      : '#CB2939',
                                }}
                              >
                                {getInfo(file)}
                              </ItemDescriptionStyled>
                            )}
                          </>
                        ) : (
                          <>
                            <ItemDescriptionStyled>
                              {`${uploadProgress(
                                file.upload_progress,
                                file.file.size,
                              )} of ${byteSizeConverter(file.file.size)} (${
                                file.upload_progress
                              }%)`}
                            </ItemDescriptionStyled>
                            <ItemProgressBarStyled>
                              <LinearProgress
                                variant="determinate"
                                value={file.upload_progress}
                                color="inherit"
                              />
                            </ItemProgressBarStyled>
                          </>
                        )}
                      </>
                    ) : (
                      <ItemDescriptionStyled>
                        {byteSizeConverter(file.file.size)}
                      </ItemDescriptionStyled>
                    )}
                  </ItemContentStyled>
                </DialogItemStyled>
              ))}
            </DialogContentStyled>
          )}
        </Modal>
      )}
      <ConfirmationDialog
        content={
          'Your document upload is not complete. Are you sure you want to cancel?'
        }
        title=" Cancel upload"
        rightButtonProps={{
          name: 'Cancel',
          onClick: () => setOpenConfirmationModal(false),
        }}
        leftButtonProps={{ name: 'Delete', onClick: removeAllFiles }}
        open={openConfirmationModal}
      />
    </>
  );
}
