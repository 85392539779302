import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { grey } from 'theme';
import { DocumentStyled } from './Document.styles';
import { IChunkList } from 'components/Page/DocumentPage';
import ProcessedFile from 'components/ProcessedFile';
import SourceFile from 'components/SourceFile';
import DocumentTopBar from 'components/DocumentTopBar';

type DocumentProps = {
  /**
   * Document chunk data provided by the BE API.
   */
  chunkList: IChunkList;
  /**
   * Document name.
   */
  doc_name: string;
  /**
   * The collection the document belongs to.
   */
  index: string;
};

/**
 * Display a preview and chunk data of a specific document.
 */

export default function Document({
  chunkList,
  doc_name,
  index,
}: DocumentProps): React.JSX.Element {
  const dragWrapperRef = useRef<HTMLDivElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [leftPanelPercentWidth, setLeftPanelPercentWidth] = useState(50);
  const [fullscreen, setFullscreen] = useState('both');
  const [pdfPage, setPdfPage] = useState(0);

  const handleDragStart = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (dragWrapperRef?.current && dragActive) {
      const offsetMouseX = event.clientX - dragWrapperRef.current.offsetLeft;
      const mouseHorizontalDragPercentage =
        (offsetMouseX / dragWrapperRef.current.offsetWidth) * 100;
      setLeftPanelPercentWidth(
        Math.max(Math.min(mouseHorizontalDragPercentage, 80), 20),
      );
    }
  };

  const handleDragEnd = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setDragActive(false);
  };

  if (chunkList === undefined) {
    return <Typography>Unable to load document</Typography>;
  }

  return (
    <DocumentStyled>
      <DocumentTopBar doc_name={doc_name} index={index} />
      <Grid
        container
        flexWrap="nowrap"
        flexGrow="1"
        onMouseMove={(event) => handleMove(event)}
        onMouseUp={(event) => handleDragEnd(event)}
        ref={dragWrapperRef}
        sx={{ height: '500px' }}
      >
        {fullscreen !== 'chunkFile' && (
          <div style={{ width: `${leftPanelPercentWidth}%`, height: '100%' }}>
            <SourceFile
              doc_name={doc_name}
              index={index}
              chunkList={chunkList}
              fullscreen={fullscreen}
              setLeftPanelPercentWidth={setLeftPanelPercentWidth}
              setPdfPage={setPdfPage}
              setFullscreen={setFullscreen}
            />
          </div>
        )}
        {fullscreen === 'both' && (
          <Grid
            item
            onMouseDown={(event) => handleDragStart(event)}
            width="10px"
            sx={{
              background: `linear-gradient(90deg, ${grey[100]}, ${grey[300]})`,
              cursor: 'col-resize',
            }}
          />
        )}
        {fullscreen !== 'sourceFile' && (
          <div
            style={{ width: `${100 - leftPanelPercentWidth}%`, height: '100%' }}
          >
            <ProcessedFile
              doc_name={doc_name}
              index={index}
              chunkList={chunkList}
              fullscreen={fullscreen}
              pdfPage={pdfPage}
              setLeftPanelPercentWidth={setLeftPanelPercentWidth}
              setFullscreen={setFullscreen}
            />
          </div>
        )}
      </Grid>
    </DocumentStyled>
  );
}
