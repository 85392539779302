import React, { useMemo, useState, useEffect, useContext } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { SnackbarContext } from 'components/SnackbarProvider';
import AddColumnDialog from 'components/AddColumnDialog';
import { MetadataCard as NewMetadatacard } from './Metadatacard';
import ComponentLoading from 'components/ComponentLoading';

import {
  saveMetadata,
  getAllMetadatas,
  updateMetadata,
} from 'components/MetadataProvider';

type MetadataSettingsProps = {};

export type Column = {
  key: number;
  status: boolean;
  name: string;
  items: string[];
  edit: boolean;
};

export type IMetadataSettings = Column[];

const initialState: IMetadataSettings = [
  {
    key: 0,
    status: true,
    name: 'Category',
    items: ['Item 1', 'Item 2', 'Item 3'],
    edit: false,
  },
  {
    key: 1,
    status: true,
    name: 'Industry',
    items: ['Item 1', 'Item 2'],
    edit: false,
  },
  {
    key: 2,
    status: false,
    name: 'Sub-Industry',
    items: ['Item 1'],
    edit: false,
  },
];

export default function MetadataPage({}: MetadataSettingsProps): React.JSX.Element {
  const navigate = useNavigate();
  const [tab, setTab] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [metadataSettings, setMetadataSettings] = useState<IMetadataSettings>(
    [],
  );
  const [newItem, setNewItem] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false);

  const createAlert = useContext(SnackbarContext);
  // const [metadataToDelete, setMetadataToDelete] = useState<
  //   { name: string; value: string[] }[]
  // >([]);

  const [expand, setExpand] = useState(false);

  const activeTabs = useMemo(
    () => metadataSettings.filter((column) => column.status).length,
    [metadataSettings],
  );

  useEffect(() => {
    setLoadingMetadata(true);
    getAllMetadatas(true, '')
      .then((response: any) => {
        setMetadataSettings(response);
        setLoadingMetadata(false);
      })
      .catch((err) => {
        setLoadingMetadata(false);
      });
  }, [newItem]);

  const handleAddColumn = (newColumnName: string | null) => {
    if (!newColumnName) return;
    // setNewItem(true);
    let key = metadataSettings.length;
    setMetadataSettings((prev) => {
      const newColumn = {
        key,
        status: true,
        name: newColumnName,
        items: [''],
        edit: true,
      };
      return [...prev, newColumn];
    });
  };

  const handleChangeTab = (newTab: boolean) => {
    setTab((prev) => {
      if (prev === newTab) return prev;
      return newTab;
    });
  };

  const handleEditColumn = ({
    key,
    status,
  }: {
    key: number;
    status: boolean;
  }) => {
    setMetadataSettings((prev) =>
      prev.map((column) => {
        if (column.key === key) {
          return { ...column, edit: status };
        }
        return column;
      }),
    );
  };

  const updateList = () => {
    setLoadingMetadata(true);

    getAllMetadatas(true, '')
      .then((response: any) => {
        setMetadataSettings(response);
        setLoadingMetadata(false);
      })
      .catch((err) => {
        setLoadingMetadata(false);
      });
  };

  const handleDeleteItem = (key: number) => {
    let objectToDelete = metadataSettings.find((item) => item.key === key);

    setMetadataSettings((prev) => {
      return prev.filter((column) => column.key !== key);
    });

    // if (objectToDelete?.name) deleteMetadata(objectToDelete.name);
  };

  const handleCancel = (key: number) => {
    metadataSettings.forEach((column) => {
      if (column.key !== key) return;
      const isEmpty = (currentValue: string) => currentValue === '';
      const itContainsEmptyTag = column.items.every(isEmpty);
      if (itContainsEmptyTag) return handleDeleteItem(key);
    });
    handleEditColumn({ key, status: false });
  };

  const handleSave = async (updatedColumn: Column) => {
    let exists: boolean = false;
    setMetadataSettings((prev) =>
      prev.map((column) => {
        if (column.key === updatedColumn.key) {
          exists = true;
          return { ...updatedColumn, edit: false };
        }
        return column;
      }),
    );
    //Code to save on API
    try {
      !newItem
        ? await updateMetadata(updatedColumn.name, updatedColumn.items)
        : await saveMetadata(updatedColumn.name, updatedColumn.items);

      createAlert({
        message: 'Metadata saved with success.',
        severity: 'success',
      });
      updateList();
    } catch (err) {
      const metadatas: any = await getAllMetadatas(true);
      setMetadataSettings(metadatas);
      createAlert({
        message: 'Error on save metadata.',
        severity: 'error',
      });
    }
  };

  if (loadingMetadata) return <ComponentLoading />;
  return (
    <>
      <Grid container overflow={'auto'}>
        <Grid
          item
          container
          display={'flex'}
          flexDirection={'row'}
          height={'70px'}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h3" my={3}>
            Metadata
          </Typography>
        </Grid>
        <Divider sx={{ mb: 1 }} />
        <Typography mb={3}>Manage metadata columns and tags.</Typography>
        <Grid container item justifyContent={'right'}>
          <Button
            onClick={() => {
              handleAddColumn('New Item');
            }}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
          >
            New Metadata
          </Button>
        </Grid>
        <Grid
          margin={1}
          border={'lightgray solid 1px'}
          container
          item
          overflow={'auto'}
          position={'relative'}
          height={'58vh'}
          sx={{
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '5px',
              background: '#888',
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '5px',
            },
            '&::-webkit-scrollbar-button:vertical:start:increment': {
              display: 'none',
            },
            '&::-webkit-scrollbar-button:vertical:end:decrement': {
              display: 'none',
            },
          }}
        >
          <Grid container item overflow={'auto'} position={'absolute'}>
            {metadataSettings.map((item) => (
              <NewMetadatacard
                key={crypto.randomUUID()}
                title={item.name}
                items={item.items}
                edit={item.edit}
                updateList={updateList}
              />
            ))}
          </Grid>
        </Grid>
        <AddColumnDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleAddColumn={handleAddColumn}
        />
      </Grid>
    </>
  );
}
