import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material';
import SecureTextField from 'components/SecureTextField';

type Columns = {
  name: string;
  inputValue?: string;
};

const columns: readonly Columns[] = [
  { name: 'Category' },
  { name: 'Sub-Category' },
  { name: 'Industry' },
  { name: 'Sub-Industry' },
];

interface SearchColumnInputProps {
  inputValue: string | null;
  setInputValue: React.Dispatch<React.SetStateAction<string | null>>;
}

const filter = createFilterOptions<Columns>();

export default function SearchColumnInput({
  inputValue,
  setInputValue,
}: SearchColumnInputProps) {
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | Columns | null,
  ) => {
    if (typeof newValue === 'string') return;
    if (newValue && newValue.inputValue) {
      return setInputValue(newValue.inputValue);
    }
    if (newValue) setInputValue(newValue.name);
  };

  const getFilterOptions = (
    options: Columns[],
    params: FilterOptionsState<Columns>,
  ) => {
    const filtered = filter(options, params);
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `+ Create new "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const getOptionLabel = (option: string | Columns) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.name;
  };

  return (
    <div style={{ paddingTop: '2rem' }}>
      <Autocomplete
        value={inputValue}
        onChange={handleChange}
        filterOptions={getFilterOptions}
        id="columns-search"
        options={columns}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <SecureTextField {...params} label="Search" />}
      />
    </div>
  );
}
