import { AxiosError, AxiosResponse } from 'axios';
import PrivReq from 'api/axios';
import { IConfigData } from '../interfaces';
import { CheckForEmptyValues, CheckRoleFieldForInvalidChars } from '../utils';
import isValidAllScrubData from './isValidAllScrubData';

type TPostData = {
  category: string;
  categoryID: string;
  rows: IConfigData[];
};

export default function updateConfigData({
  category,
  categoryID,
  rows,
}: TPostData) {
  type TReq = {
    message: string;
    status?: number;
  };

  const req: TReq = { message: '...' };

  const outRegex: IConfigData[] = rows.map((obj: IConfigData) => ({
    ...obj,
    configValue: `${obj?.configValue?.toString()}`,
  }));

  const confPayload = {
    category,
    id: categoryID,
    data: outRegex,
  };

  const isRowsWithoutEmptyValues: boolean = CheckForEmptyValues(rows);

  const isRoleFieldWithoutInvalidChar: boolean =
    CheckRoleFieldForInvalidChars(rows);

  const postData = async () => {
    if (isRowsWithoutEmptyValues && isRoleFieldWithoutInvalidChar) {
      await PrivReq({
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        method: 'post',
        url: `/updateconfigurable/update`,
        data: confPayload,
      })
        .then((response: AxiosResponse) => {
          req.message = response.data.status || 'Success on saving data';
        })
        .catch((error) => {
          if (error.response) {
            req.status = error.response.status;
            req.message = error.response.data.status;
          } else {
            req.message = 'Could not retrieve error from server';
          }

          req.message = error.response.data.status;
        });
    } else if (!isRowsWithoutEmptyValues) {
      req.message = 'Field with empty value';
    } else if (!isRoleFieldWithoutInvalidChar) {
      req.message = ' -Role- field with invalid char';
    }
    return req;
  };
  return postData();
}
