import {
  Box,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Typography,
  IconButton,
  Tooltip,
  Autocomplete,
  Chip,
} from '@mui/material';
import { Fragment, useContext } from 'react';
import InfoIcon from '@mui/icons-material/Info';

import FileInput from 'components/FileInput';
import {
  FileInput as IFileInput,
  GenericInputs,
  FormData,
  GenericInput,
} from 'types';
import SecureTextField from 'components/SecureTextField';
import AddIcon from '@mui/icons-material/Add';
import { Close } from 'mdi-material-ui';
import { CollectionContext } from 'components/CollectionProvider';
import ComponentLoading from 'components/ComponentLoading';
import { overwriteInputName } from 'components/Page/JobUploadPage/JobUploadPage';
import { getNewInputId } from 'common/utils';
import { SnackbarContext } from 'components/SnackbarProvider';
import { SOURCE_INPUT_PREFIX } from 'components/Page/TaskUploadPage/TaskUploadPage';
import DeleteJobSection from 'components/DeleteJobSection';

const ALLOWED_EXTENSIONS = [
  'txt',
  'pdf',
  'docx',
  'md',
  'zip',
  'py',
  'js',
  'html',
  'css',
  'java',
  'cpp',
  'h',
  'c',
  'rb',
  'go',
  'php',
  'swift',
  'ts',
  'scala',
  'pl',
  'sh',
  'bat',
  'r',
  'sql',
  'xml',
  'json',
  'yaml',
  'yml',
  'vhdl',
  'lua',
  'd',
  'vala',
  'aspx',
  'jsp',
  'xaml',
  'groovy',
  'erl',
  'clj',
  'ex',
  'exs',
  'ml',
  'mli',
  'lisp',
  'cl',
  'coffee',
  'less',
  'sass',
  'scss',
  'make',
  'cmake',
  'sln',
  'vcxproj',
  'pro',
  'qmake',
  'cson',
  'hbs',
  'mustache',
  'handlebars',
  'jsm',
  'jsx',
  'rs',
  'kt',
  'dart',
  'asm',
  'pl',
  'v',
  'ninja',
  'lua',
  'scm',
  'xpl',
  'julia',
  'n',
  'rexx',
  'pike',
  'idl',
  'f90',
  'f95',
  'for',
  'r',
  'coffeescript',
  'hlsl',
  'glsl',
  'cobol',
];

const MAXIMUM_CHARACTER_LIMIT = 1000;

interface CustomFormProps {
  formData: FormData;
  inputs: GenericInputs;
  initialFormState: FormData;
  page: 'tasks' | 'jobs';
  editJob?: boolean;
  hash?: string;
  cancelButtonRef: React.RefObject<HTMLInputElement>;
  submitButtonRef: React.RefObject<HTMLInputElement>;
  defaultFormValues: FormData;
  handleClose: () => void;
  removeInput: (selectedInputs: string[]) => void;
  addInput: (newInputId: number) => void;
  handleSubmit: (formData: FormData) => void;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  disableFileInput: (checkbox: { [key: string]: boolean }) => void;
}

const TaskForm = ({
  page,
  inputs,
  editJob,
  hash,
  formData,
  initialFormState,
  cancelButtonRef,
  submitButtonRef,
  defaultFormValues,
  removeInput,
  addInput,
  handleClose,
  handleSubmit,
  setFormData,
  disableFileInput,
}: CustomFormProps) => {
  const createAlert = useContext(SnackbarContext);
  const { collections, loadingCollections } = useContext(CollectionContext);
  const isJobPage = page === 'jobs';

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(formData);
  };

  const handleCancel = () => {
    setFormData(initialFormState);
    handleClose();
  };

  const handleChange = (
    value: string,
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
  ) => {
    const { name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeAutocomplete = (value: string[], name: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    allowDisableFileInput?: boolean,
  ) => {
    const { name, checked } = e.target;
    allowDisableFileInput && disableFileInput({ [name]: checked });
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const onSaveFile = (files: IFileInput) => {
    setFormData((prev) => ({ ...prev, ...files }));
  };

  const onRemoveFile = (fileInputKey: string) => {
    setFormData((prev) => ({ ...prev, [fileInputKey]: undefined }));
  };

  const onAddInput = () => {
    const currentInputs = inputs
      .filter((input) => input.id.includes('input'))
      .map((input) => input.name);
    const lastInputName = currentInputs.slice(-1)[0];
    const newInputId = getNewInputId(lastInputName);
    if (!newInputId) return;

    const inputLimitExceeded = newInputId > 9;
    if (inputLimitExceeded) {
      createAlert({
        message: 'Maximum input limit has been reached',
        severity: 'error',
      });
      return;
    }

    const newFormValues: FormData = {};
    Object.keys(defaultFormValues).forEach(
      (key) =>
        (newFormValues[`${key}${newInputId}`] =
          defaultFormValues[key as keyof typeof defaultFormValues]),
    );

    setFormData((prev) => ({ ...prev, ...newFormValues }));
    addInput(newInputId);
  };

  const onRemoveInput = (input: GenericInput) => {
    const inputId = +input.name.slice(-1);
    const keysToDelete = Object.keys(defaultFormValues).map(
      (key) => `${key}${inputId}`,
    );
    setFormData((prev) => {
      keysToDelete.forEach((keyToDelete) => delete prev[keyToDelete]);
      return prev;
    });
    removeInput(keysToDelete);
  };

  if (loadingCollections) return <ComponentLoading />;
  if (!collections) return <div>Something went wrong!</div>;

  return (
    <Box
      component={'form'}
      onSubmit={onSubmit}
      sx={{ backgroundColor: '#FAFAFA' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Grid container>
          {inputs.map((input) => (
            <Fragment key={input.id}>
              {(input.type === 'text' || input.type === 'multiselect') && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        sx={{
                          fontFamily: 'Graphik',
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '125%',
                        }}
                      >
                        {input.label}
                      </Typography>

                      <Tooltip title={input.tooltip} arrow>
                        <InfoIcon sx={{ height: '16px', width: '16px' }} />
                      </Tooltip>
                    </Box>

                    {input.label.includes(SOURCE_INPUT_PREFIX) &&
                      input.label !== `${SOURCE_INPUT_PREFIX} 1` && (
                        <Tooltip title="Delete input" arrow>
                          <IconButton onClick={() => onRemoveInput(input)}>
                            <Close />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Box>

                  {input.type === 'multiselect' && collections && isJobPage && (
                    <Autocomplete
                      disableCloseOnSelect
                      disabled={input.disabled}
                      multiple
                      value={formData[input.name] as unknown as []}
                      onChange={(e, value) =>
                        handleChangeAutocomplete(value, input.name)
                      }
                      sx={{ paddingBottom: '44px', margin: '8px 0px 4px' }}
                      id="collections-autocomplete"
                      options={collections}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <SecureTextField
                          {...params}
                          label={input.label}
                          name={input.name}
                          errorDetected={input.error}
                          helperText={input.helperText}
                          maxLength={MAXIMUM_CHARACTER_LIMIT}
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        );
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                            variant="outlined"
                            color="primary"
                          />
                        ));
                      }}
                    />
                  )}

                  {input.type === 'text' && (
                    <SecureTextField
                      disabled={input.disabled}
                      fullWidth
                      multiline={input.multiline}
                      maxLength={MAXIMUM_CHARACTER_LIMIT}
                      id={input.id}
                      margin="dense"
                      variant="outlined"
                      name={input.name}
                      label={input.label}
                      type={input.type}
                      onUpdate={(v, e) => handleChange(v, e)}
                      value={formData[input.name] as string}
                      errorDetected={input.error}
                      helperText={input.helperText}
                      sx={{
                        paddingBottom: 2,
                        '& .MuiInputBase-input': {
                          padding: input.multiline ? 0 : undefined,
                        },
                      }}
                    />
                  )}
                </Grid>
              )}
              {input.type === 'file' && (
                <Grid item xs={6} sm={6} lg={6} sx={{ marginBottom: '44px' }}>
                  <FileInput
                    id={input.name}
                    file={formData[input.name] as File}
                    fileName={formData['fileName'] as string}
                    onSaveFile={onSaveFile}
                    onRemoveFile={onRemoveFile}
                    disabled={!!input.disabled}
                    allowedExtensions={ALLOWED_EXTENSIONS}
                    errorDetected={input.error}
                    helperText={input.helperText}
                  />
                </Grid>
              )}
              {input.type === 'checkbox' && input.label === 'Generate' && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginBottom: '44px',
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={input.disabled}
                          name={input.name}
                          color="secondary"
                          onChange={(e) => handleCheckboxChange(e, true)}
                          checked={formData[input.name] as boolean}
                        />
                      }
                      label={input.label}
                    />
                  </FormGroup>
                </Grid>
              )}
              {isJobPage &&
                input.label !== 'Generate' &&
                input.type === 'checkbox' && (
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    sx={{
                      display: 'flex',
                      gap: 3,
                      flexDirection: 'column',
                    }}
                  >
                    {input.name === overwriteInputName && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Graphik',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '125%',
                          }}
                        >
                          Action
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        gap: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={input.disabled}
                              name={input.name}
                              color="secondary"
                              onChange={handleCheckboxChange}
                              checked={formData[input.name] as boolean}
                            />
                          }
                          label={input.label}
                        />
                      </FormGroup>
                      <Typography
                        sx={{
                          fontFamily: 'Graphik',
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '18px',
                          letterSpacing: '0.4px',
                          paddingBottom: 0.5,
                        }}
                      >
                        {input.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Fragment>
          ))}
        </Grid>

        {!isJobPage && (
          <Button
            color="secondary"
            onClick={onAddInput}
            startIcon={<AddIcon />}
            type="button"
          >
            Add input
          </Button>
        )}

        {isJobPage && editJob && hash && (
          <DeleteJobSection hash={hash} jobName={formData['name'] as string} />
        )}
      </Box>
      <Box sx={{ padding: 3, display: 'none' }}>
        <input onClick={handleCancel} type="button" ref={cancelButtonRef} />
        <input type="submit" ref={submitButtonRef} />
      </Box>
    </Box>
  );
};

export default TaskForm;
