import { IConfigData } from '../interfaces';
import { containsSpecialCharsAndSpace } from 'common/utils/common';

export default function CheckRoleFieldForInvalidChars(
  data: IConfigData[],
): boolean {
  const required = ['role'];
  const doNotHaveInvalidChars = data.every((obj) =>
    required.every((prop) => containsSpecialCharsAndSpace(obj[prop]) === false),
  );

  return doNotHaveInvalidChars;
}
