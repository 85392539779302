import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { containsSpecialChars, invalidAPICharacter } from 'common/utils/common';
import { encode } from 'html-entities';
import { decode } from 'html-entities';
const DOMPurify = require('dompurify');

export const DEFAULT_MAX_CHARACTER_LIMIT = 1000;

type CustomProps = {
  onUpdate?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value?: string | null;
  errorDetected?: number;
  helperText?: string;
  sanitizerMode?: boolean;
  htmlEntitiesMode?: boolean;
  restrictSomeCharacters?: boolean;
  maxLength?: number;
  customValidator?: (text: string) => string | undefined;
};
type SecureTextFieldProps = TextFieldProps & CustomProps;

export default function SecureTextField({
  value,
  maxLength,
  helperText,
  errorDetected,
  sanitizerMode,
  htmlEntitiesMode,
  restrictSomeCharacters,
  onUpdate,
  customValidator,
  ...props
}: SecureTextFieldProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = event.target.value;
    const valueLength: number = inputValue.length;

    if (maxLength && valueLength > maxLength) {
      return setErrorMessage(
        `Length exceeded. The maximum length is ${maxLength}`,
      );
    }

    if (!maxLength && inputValue.length > DEFAULT_MAX_CHARACTER_LIMIT) {
      return setErrorMessage(
        `Length exceeded. The maximum length is ${DEFAULT_MAX_CHARACTER_LIMIT}`,
      );
    }

    const isNotValidString = containsSpecialChars(inputValue);
    if (isNotValidString && !sanitizerMode && !htmlEntitiesMode) {
      return setErrorMessage(
        `The following characters are not allowed: \\ / : * ” ’ < > |`,
      );
    }

    const isNotValidChar = invalidAPICharacter(inputValue);
    if (isNotValidChar && htmlEntitiesMode && restrictSomeCharacters) {
      return setErrorMessage(
        `The following characters are not allowed: \\ / " ' |`,
      );
    }

    if (customValidator && customValidator(inputValue)) {
      const error = customValidator(inputValue);
      if (error) return setErrorMessage(error);
    }

    let cleanValue: string = inputValue;
    if (sanitizerMode) cleanValue = DOMPurify.sanitize(`${inputValue}`);
    if (htmlEntitiesMode) cleanValue = encode(`${inputValue}`);
    if (onUpdate) onUpdate(cleanValue, event);
    return setErrorMessage(null);
  };

  useEffect(() => {
    if (!helperText) return;
    setErrorMessage(errorDetected ? helperText : null);
  }, [errorDetected, helperText]);

  return (
    <TextField
      {...props}
      value={decode(value)}
      error={errorMessage ? true : false}
      helperText={errorMessage}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => handleChange(event)}
    />
  );
}
