import { styled } from '@mui/system';

export const TextStyled = styled('div')<{
  color?: string;
  fontSize?: string;
  fontHeight?: string;
}>`
  color: ${(props) => props.color ?? '#000'};
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Sub Header/1 */
  font-size: ${(props) => props.fontSize ?? '16px'};
  font-family: Graphik;
  font-style: normal;
  font-weight: ${(props) => props.fontHeight ?? '400'};
  line-height: 125%; /* 20px */
`;

export const ContentStyled = styled('div')`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  height: calc(100vh - 138px);
  box-sizing: border-box;
  overflow-y: auto;
`;

export const DivStyled = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '1rem',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}));

export const LabelStyled = styled('label')(({ theme }) => ({
  cursor: 'pointer',
  flexGrow: 1,
  justifyContent: 'center',
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  flex: '1 0 0',
  alignSelf: 'stretch',
  alignItems: 'center',
  borderWidth: '2px',
  borderColor: '#b3b2b5',
  backgroundColor: '#ffffff',
  padding: '16px 36px',
  position: 'relative',
  transition: 'all 150ms',
  '&.drag-active': {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#f7ebff',
  },
  '&.disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderColor: '#b3b2b5',
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'default',
  },
}));
