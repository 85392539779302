import React, { createContext, useCallback, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { StyledLink } from './SnackProvider.styles';

interface CreateAlertProps {
  severity?: 'error' | 'warning' | 'info' | 'success';
  message: string;
  link?: { text: string; route: string };
}

interface AlertProps extends CreateAlertProps {
  key: number;
}

type SnackbarContextProps = (alert: CreateAlertProps) => void;

export const SnackbarContext = createContext<SnackbarContextProps>(
  (alert: CreateAlertProps) => {},
);

type SnackbarProviderProps = {
  /**
   * The page component that will be rendered inside the global Page component template
   */
  children: React.JSX.Element;
};

/**
 * Manages displaying snackbar alerts for the app
 */
export default function SnackbarProvider({
  children,
}: SnackbarProviderProps): React.JSX.Element {
  const [alerts, setAlerts] = useState<readonly AlertProps[]>([]);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertProps | undefined>(undefined);

  useEffect(() => {
    if (alerts.length && !alert) {
      // Set a new snack when we don't have an active one
      setAlert({ ...alerts[0] });
      setAlerts((prev) => prev.slice(1));
      setOpen(true);
    } else if (alerts.length && alert && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [alerts, alert, open]);

  const createAlert = useCallback(
    ({ message, severity, link }: CreateAlertProps) => {
      setAlerts((prev) => [
        ...prev,
        { severity, message, key: new Date().getTime(), link },
      ]);
    },
    [],
  );

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setAlert(undefined);
  };

  return (
    <SnackbarContext.Provider value={createAlert}>
      {children}
      <Snackbar
        key={alert ? alert.key : undefined}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <Alert
          onClose={handleClose}
          severity={alert?.severity}
          sx={{ width: '100%' }}
        >
          {alert?.message}
          {alert?.link && (
            <StyledLink color="white" href={alert.link.route}>
              {alert.link.text}
            </StyledLink>
          )}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
