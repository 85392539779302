import React, { useContext, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import axios from 'api/axios';
import { CollectionContext } from 'components/CollectionProvider';
import { useNavigate } from 'react-router-dom';

type DeleteCollectionDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  collection: string;
};

type CollectionInformations = {
  docCount: number;
  chunkCount: number;
  collecitonSize: number;
};

/**
 * Shows a dialog that will call the API to create a new collection
 */
export default function DeleteCollectionDialog({
  open,
  setOpen,
  collection,
}: DeleteCollectionDialogProps): React.JSX.Element {
  const navigate = useNavigate();
  const [collectionDocCount, setCollectionDocCount] =
    useState<CollectionInformations>({
      docCount: 0,
      chunkCount: 0,
      collecitonSize: 0,
    });
  const { removeCollection, loadingCollections } =
    useContext(CollectionContext);

  const handleDelete = async () => {
    await removeCollection(collection);
    setOpen(false);
    navigate(`/`);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `/listdocs/${collection}`,
    })
      .then((response: AxiosResponse) => {
        let totalSize = 0;
        let totalChunks = 0;
        response.data.forEach(
          (elem: {
            chunk_count: number;
            size: { split: (arg0: string) => [any, any] };
          }) => {
            totalChunks += Number(elem.chunk_count);
            let [val, type] = elem.size.split(' ');
            val = Number(val);
            switch (type) {
              case 'B':
                val = val * 0.000001;
                break;
              case 'KB':
                val = val * 0.0009765625;
                break;
              case 'GB':
                val = val * 1024;
                break;
              default:
                val = val;
            }
            totalSize += val;
          },
        );
        totalSize = Number(totalSize.toFixed(2));

        setCollectionDocCount({
          chunkCount: totalChunks,
          collecitonSize: totalSize,
          docCount: response.data.length,
        });
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        setCollectionDocCount({
          chunkCount: 0,
          collecitonSize: 0,
          docCount: 0,
        });
      });
  }, [collection]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
      <DialogTitle>Delete collection</DialogTitle>
      <DialogContent>
        <Typography mb={3}>
          Are you sure you want to delete this collection and all of its related
          documents?
        </Typography>
        <Typography mb={3} color="error">
          This action cannot be undone.
        </Typography>
        <Grid container p={1} gap={2} alignItems="center">
          <Grid item flexShrink={1}>
            <FolderDeleteOutlinedIcon sx={{ fontSize: 80 }} />
          </Grid>
          <Grid item flexGrow={1}>
            <Typography mb={1}>Collection name: {collection}</Typography>
            {/* TODO: No way to get total file size for a collection at the moment */}
            <Typography mb={1}>
              Size: {collectionDocCount.collecitonSize}MB (
              {collectionDocCount.docCount} documents)
            </Typography>
            {/* TODO: No way to get total chunk count for a collection at the moment */}
            <Typography>{`Chunk count: ${collectionDocCount.chunkCount}`}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          disabled={loadingCollections}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type DeleteCollectionButtonProps = {
  setOpen: (value: boolean) => void;
};

/**
 * An optional styled button to use to open the DeleteCollectionDialog component
 */
export function DeleteCollectionButton({
  setOpen,
}: DeleteCollectionButtonProps): React.JSX.Element {
  return (
    <Button
      variant="outlined"
      color="error"
      startIcon={<DeleteOutlineIcon />}
      onClick={() => setOpen(true)}
    >
      Delete collection
    </Button>
  );
}
