import { AxiosError, AxiosResponse } from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import axios from 'api/axios';
import { SnackbarContext } from 'components/SnackbarProvider';
import { CollectionsDetails } from 'types';

export type Collection = {
  index_name: string;
  description: string;
  num_of_docs: number;
  num_of_chunks: number;
  last_update_time: string;
};

export type Collections = string[];

type CollectionContextProps = {
  /**
   * An array containg all the collections available from the BE.
   */
  collections: Collections | null;
  /**
   * If the API request to load all available collections is in progress.
   */
  loadingCollections: boolean;
  /**
   * An array containg all the collections information available from the BE. To be used on landing page.
   */
  collectionsInfo: Collection[] | null;
  collectionsDetails: CollectionsDetails[] | null;
  /**
   * A function that will trigger the Collection Provider to load collections from the BE and update the array of collections.
   */
  getCollections: () => Promise<void>;
  /**
   * A function that will remove the specified collection from the list of collections in the Collection Provider.
   */
  removeCollection: (collection: string) => void;
};

export const CollectionContext = createContext<CollectionContextProps>({
  collections: [],
  collectionsInfo: [],
  collectionsDetails: [],
  loadingCollections: true,
  getCollections: async () => {},
  removeCollection: (_) => {},
});

type CollectionProviderProps = {
  /**
   * The page component that will be rendered inside the global Page component template
   */
  children: React.JSX.Element;
};

/**
 * Provides the list of available collections to the app
 */
export default function CollectionProvider({
  children,
}: CollectionProviderProps): React.JSX.Element {
  const createAlert = useContext(SnackbarContext);
  const [collections, setCollections] = useState<Collections | null>(null);
  const [collectionsInfo, setCollectionsInfo] = useState<Collection[] | null>(
    null,
  );
  const [collectionsDetails, setCollectionsDetails] = useState<
    null | CollectionsDetails[]
  >(null);
  const [loadingCollections, setLoadingCollections] = useState(true);

  const getCollections = useCallback(async () => {
    setLoadingCollections(true);
    await axios({
      method: 'get',
      url: '/listindexes/details',
    })
      .then((response: AxiosResponse) => {
        setCollectionsDetails(response.data);
        setCollections(response.data.map((e: any) => e.index_name).sort());
        setCollectionsInfo((_collectionsInfo) => {
          let collectionsArr: Collection[] = [];
          if (typeof response.data === 'object') {
            collectionsArr = response.data.map((e: any) => {
              return {
                index_name: e.index_name,
                description: e.description,
                num_of_docs: e.num_of_docs,
                num_of_chunks: e.num_of_chunks,
                last_update_time: e.last_update_time,
              };
            });
          }
          let sortedCollections = collectionsArr.sort((a, b) => {
            let nameA = a.index_name.toLowerCase();
            let nameB = b.index_name.toLowerCase();

            return nameA < nameB ? -1 : nameB < nameA ? 1 : 0;
          });
          return sortedCollections.sort();
        });
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      })
      .finally(() => setLoadingCollections(false));
  }, []);

  const removeCollection = useCallback(
    async (collection: string) => {
      setLoadingCollections(true);
      await axios({
        method: 'post',
        url: '/clearentireindex',
        data: {
          index: collection,
        },
      })
        .then((_response: AxiosResponse) => {
          createAlert({
            message: `Collection '${collection}' deleted`,
            severity: 'success',
          });
          getCollections();
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Unable to delete collection '${collection}'`,
            severity: 'error',
          });
          setLoadingCollections(false);
        });
    },
    [createAlert, getCollections],
  );

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  return (
    <CollectionContext.Provider
      value={{
        collections,
        collectionsInfo,
        collectionsDetails,
        loadingCollections,
        getCollections,
        removeCollection,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
}

export function search_vector(
  text: string,
  index: string | undefined,
  tag: string = '',
  controller?: AbortController,
) {
  return new Promise((resolve) => {
    const endpoint = '/searchvectors';
    const query = text === '*' || tag === '' ? text : `@${tag}:${text}`;
    axios({
      method: 'post',
      url: endpoint,
      data: {
        query,
        index,
        config: 'show_source',
        output: '<metadata:*>',
      },
      signal: controller?.signal,
    })
      .then((response) => {
        let data = response.data;
        let files: any = [];
        data.forEach((elem: { metadata: { file_name: string } }) => {
          files.push(elem.metadata.file_name);
        });

        files = files.filter(
          (item: string, index: number) => files.indexOf(item) === index,
        );
        resolve(files);
      })
      .catch((error: AxiosResponse) => {
        resolve(undefined);
      });
  });
}
