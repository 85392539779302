import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { listIndexesAPI } from 'api';
import { useAuthState } from 'components/AuthProvider';
import ComponentLoading from 'components/ComponentLoading';
import SecureTextField from 'components/SecureTextField';
import { FilesContext } from 'components/FilesProvider';
import { ChatContext } from 'components/Page/ChatPage';
import { ReviewDocumentModalCard } from './ReviewDocumentModalCard';
import { DEFAULT_INDEX } from 'components/Page/ChatPage/ChatPage';

type ReviewDocumentModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  docName: string;
  setDocName: React.Dispatch<React.SetStateAction<string>>;
  selectedIndex: string;
  setSelectedIndex: React.Dispatch<React.SetStateAction<string>>;
  handleOpenDocumentGenerator: () => void;
};

const ReviewDocumentModal = ({
  open,
  docName,
  selectedIndex,
  setOpen,
  setDocName,
  setSelectedIndex,
  handleOpenDocumentGenerator,
}: ReviewDocumentModalProps) => {
  const { accessToken } = useAuthState();
  const context = useContext(ChatContext);
  const messagesToReview = context?.messagesToReview;
  const setMessagesToReview = context?.setMessagesToReview;
  const [indexList, setIndexList] = useState<string[]>([]);
  const { setCurrentIndex } = useContext(FilesContext);

  const handleClose = () => setOpen(false);

  const handleSelect = (value: string) => {
    setCurrentIndex(value);
    setSelectedIndex(value);
  };

  const removeItem = (message: string) => {
    setMessagesToReview &&
      setMessagesToReview((prev) =>
        prev?.filter((elem) => elem.message !== message),
      );
  };

  useEffect(() => {
    if (messagesToReview?.length === 0) setOpen(false);
  }, [messagesToReview, setOpen]);

  useEffect(() => {
    const initializeChat = async () => {
      const { data } = await listIndexesAPI(accessToken);
      if (!data) return;
      const indexesList = data.map(
        (entry: Record<'index_name', string>) => entry['index_name'],
      );
      setIndexList(indexesList);
    };
    initializeChat();
  }, [accessToken]);

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose}>
      {indexList.length === 0 ? (
        <Box px={30} py={20}>
          <ComponentLoading />
        </Box>
      ) : (
        <>
          <DialogTitle
            fontFamily={'Graphik'}
            fontSize={24}
            fontWeight={600}
            lineHeight={'120%'}
            mb={4}
            pb={0}
          >
            Review Selected Responses
          </DialogTitle>
          <DialogContent>
            <Box width={800}>
              <Box height={300} sx={{ overflowY: 'auto' }}>
                {messagesToReview &&
                  messagesToReview.map((item) => (
                    <Box padding={1} key={item.id}>
                      <ReviewDocumentModalCard
                        key={crypto.randomUUID()}
                        message={item}
                        removeFunction={removeItem}
                      />
                    </Box>
                  ))}
              </Box>
              <Divider sx={{ width: '100%', alignSelf: 'center', my: 3 }} />
              <InputLabel sx={{ fontSize: 16 }}>Index</InputLabel>
              <Autocomplete
                fullWidth
                id="autocomplete-index-select"
                value={selectedIndex}
                options={indexList}
                onChange={(e, value) => handleSelect(value ?? DEFAULT_INDEX)}
                renderInput={(params) => (
                  <SecureTextField
                    {...params}
                    label=""
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    style={{ background: 'white', borderRadius: '5px' }}
                  />
                )}
              />
              <InputLabel sx={{ fontSize: 16, mt: 1 }}>
                Document Name
              </InputLabel>
              <SecureTextField
                required
                fullWidth
                variant="outlined"
                margin="none"
                value={docName}
                onUpdate={(v) => setDocName(v)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpenDocumentGenerator}
              disabled={!selectedIndex || docName === ''}
            >
              Generate Document
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ReviewDocumentModal;
