import { styled } from '@mui/system';

export const UploadTable = styled('div')`
  display: flex;
  width: 100%;
  height: calc(100% - 81px);
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  overflow-y: auto;
`;
