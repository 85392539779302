import { Paper, styled } from '@mui/material';

export const CardContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const PropertyText = styled('div')`
  align-self: self-end;
  color: #03000f;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.16;
  margin: 8px;
`;

export const ContentMessage = styled('div')`
  display: flex;
  flex-grow: 1;
`;

export const ExpandButton = styled('div')`
  :hover {
    cursor: pointer;
  }
`;
