import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

type DraftDetectedDialogProps = {
  openDraftDialog: boolean;
  handleDiscardDraft: () => void;
  handleUseDraft: () => void;
};

/**
 * Shows a dialog that will call the API to create a new collection
 */
export default function DraftDetectedDialog({
  openDraftDialog,
  handleDiscardDraft,
  handleUseDraft,
}: DraftDetectedDialogProps): React.JSX.Element {
  return (
    <Dialog open={openDraftDialog} fullWidth maxWidth="xs">
      <DialogTitle>Draft detected</DialogTitle>
      <DialogContent>
        <Typography mb={3}>
          A draft of this file has been detected. Would you like to continue
          editing it?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDiscardDraft}
        >
          Discard Draft
        </Button>
        <Button variant="contained" color="primary" onClick={handleUseDraft}>
          Continue Editing
        </Button>
      </DialogActions>
    </Dialog>
  );
}
