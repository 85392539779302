import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from 'components/SnackbarProvider';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'api/axios';

import { GenericInputs, FormData, CreateAsyncTaskRequest } from 'types';
import { getLastInputId, toBase64 } from 'common/utils';
import DocGenUpload from 'components/DocGenUpload';

const defaultFormValues = { input: '', file: undefined };

export const TEMPLATE_ID = 0;

export const SOURCE_INPUT_PREFIX = 'Source';

const TOOLTIPS = {
  name: 'Name your job (optional).',
  task: 'Describe the output such as type of document, sections details, tables, diagrams.',
  template: 'Describe what type of document is the template.',
  sourceInput: 'Describe the type of document provides knowledge context.',
};

const createInput = (
  newInputId: number,
  disableInputs?: number[],
): GenericInputs => {
  const isTemplateInput = newInputId === TEMPLATE_ID;
  const inputs = [
    {
      id: `input_${newInputId}_id`,
      name: `input${newInputId}`,
      label: `${isTemplateInput ? 'Template' : `${SOURCE_INPUT_PREFIX} ${newInputId}`}`,
      type: 'text',
      multiline: true,
      tooltip: `${isTemplateInput ? TOOLTIPS.template : TOOLTIPS.sourceInput}`,
      required: true,
      helperText: `Plese provide a ${isTemplateInput ? 'template' : 'file'} name`,
    },
    {
      id: `file_${newInputId}_id`,
      name: `file${newInputId}`,
      label: `File ${newInputId}`,
      type: 'file',
      disabled: disableInputs?.includes(newInputId),
      required: true,
      helperText: 'Plese provide a file',
    },
  ];

  const generateInput = {
    id: `checkbox_${TEMPLATE_ID}_id`,
    name: `checkbox${TEMPLATE_ID}`,
    label: 'Generate',
    type: 'checkbox',
  };

  const taskInput = {
    id: 'task_id',
    name: 'task',
    label: 'Task',
    type: 'text',
    multiline: true,
    tooltip: TOOLTIPS.task,
    required: true,
    helperText: 'Plese provide a task description',
  };

  const nameInput = {
    id: 'name_id',
    name: 'name',
    label: 'Name',
    type: 'text',
    multiline: true,
    tooltip: TOOLTIPS.name,
  };

  const initialInputs = [nameInput, taskInput];
  const isFirstInput = newInputId === TEMPLATE_ID;
  return isFirstInput ? [...initialInputs, ...inputs, generateInput] : inputs;
};

const initialFormState = {
  name: '',
  task: '',
  [`input${TEMPLATE_ID}`]: 'template',
  [`checkbox${TEMPLATE_ID}`]: false,
  [`file${TEMPLATE_ID}`]: undefined,
  [`input${TEMPLATE_ID + 1}`]: '',
  [`file${TEMPLATE_ID + 1}`]: undefined,
};

const initialInputs: GenericInputs = [
  ...createInput(TEMPLATE_ID),
  ...createInput(TEMPLATE_ID + 1),
];

export default function TaskUploadPage(): React.JSX.Element {
  const navigate = useNavigate();
  const createAlert = useContext(SnackbarContext);
  const [formInputs, setFormInputs] = useState(initialInputs);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => navigate(`/taskgen-pro`), [navigate]);

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      setLoading(true);

      const currentInputs = formInputs
        .filter((input) => input.id.includes('input'))
        .map((input) => input.name);
      const lastInputName = currentInputs.slice(-1)[0];
      const lastInputId = getLastInputId(lastInputName);
      if (!lastInputId) return;
      const idsArray = Array.from(Array(lastInputId + 1).keys());
      const inputs = await Promise.all(
        idsArray.map(async (id) => {
          const nameKey = `input${id}`;
          const checkboxKey = `checkbox${id}`;
          const fileKey = `file${id}`;
          const isVariableAString = (propertie: string | File) =>
            typeof propertie === 'string' || propertie instanceof String;
          const file = formData[fileKey] as File;
          const base64File = formData[checkboxKey]
            ? ''
            : isVariableAString(file)
              ? file
              : await toBase64(file);

          const newInput = {
            name: formData[nameKey] as string,
            file_name: formData[checkboxKey] ? 'generate' : file.name,
            file: base64File as string,
          };
          return newInput;
        }),
      );

      const template = inputs.slice(0, TEMPLATE_ID + 1)[0];
      const input_data = inputs.slice(TEMPLATE_ID + 1);

      const body: CreateAsyncTaskRequest = {
        process_type: 'async', //optional
        name: formData['name'] as string,
        task_description: formData['task'] as string,
        template,
        input_data,
      };

      await axios({
        method: 'post',
        url: '/createasynctask',
        data: body,
      })
        .then((_response: AxiosResponse) => {
          createAlert({
            message: `Task created successfully`,
            severity: 'success',
          });
          handleClose();
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Unable to create the task`,
            severity: 'error',
          });
        })
        .finally(() => setLoading(false));
    },
    [formInputs, handleClose, createAlert],
  );

  return (
    <DocGenUpload
      page={'tasks'}
      loading={loading}
      formInputs={formInputs}
      defaultFormValues={defaultFormValues}
      initialFormState={initialFormState}
      createInput={createInput}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      setFormInputs={setFormInputs}
    />
  );
}
