import React from 'react';
import {
  ProcessingDocumentStyled,
  ProcessingDocumentTitleStyled,
  ProcessingDocumentSubtitleStyled,
} from './ProcessingDocument.styles';

export default function ProcessingDocument(): React.JSX.Element {
  return (
    <ProcessingDocumentStyled>
      <ProcessingDocumentTitleStyled>
        Your document is still processing
      </ProcessingDocumentTitleStyled>
      <ProcessingDocumentSubtitleStyled>
        Once complete, your extracted data will appear here. Please check back
        later.
      </ProcessingDocumentSubtitleStyled>
    </ProcessingDocumentStyled>
  );
}
