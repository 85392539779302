import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ComponentLoading from 'components/ComponentLoading';
import MessageAlertDialog from '../../../MessageAlertDialog';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { grey } from 'theme';
import {
  IConfigData,
  IUploadData,
  ICategories,
  ISubCategories,
  IEssentials,
  IRequest,
  IConfigUserPermissions,
} from './interfaces';
import Box from '@mui/material/Box';
import { SnackbarContext } from 'components/SnackbarProvider';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import CancelIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

import {
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowSelectionModel,
  GridRowEditStopReasons,
  useGridApiRef,
  GridRowsProp,
  GridEditSingleSelectCell,
  GridEditSingleSelectCellProps,
  useGridApiContext,
} from '@mui/x-data-grid';

import {
  MessageBoard,
  GeneralCols,
  ClientsCols,
  CreateGeneralCols,
  ScrubCols,
} from './utils';
import { generateSessionId } from 'common/utils';

import {
  DeleteSubCategoryDialog,
  UploadRuleDialog,
  InfoAboutRuleDialog,
  AlertConfirmationDialog,
  UploadPluginDialog,
  AddRowDialog,
} from './dialogs';

import {
  getCategories,
  getSubCategories,
  getSubCategoryContent,
  getEssentialData,
  deleteConfigData,
  getClients,
  updateConfigData,
  insertConfigData,
  getClientsListRules,
} from './services';

import { ConfigurationContext } from './ConfigurationProvider/ConfigurationProvider';

import { CategoriesList, TypesList } from './components';

import ConfigUserPermissions from './RBAC/ConfigUserPermissions';

import { InfoOutlined } from '@mui/icons-material';

export default function ConfigGeneral(): React.JSX.Element {
  /**
   * objects and arrays
   */
  const [now, setNow] = useState(Date.now());
  const [rows, setRows] = useState<IConfigData[]>([]);
  const [categoryList, setCategoryList] = useState<ICategories[]>([]);
  const [subcategoryList, setSubCategoryList] = useState<ISubCategories[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [configCols, setConfigCols] = useState<GridColDef[]>([]);
  const [essentialData, setEssentialData] = useState<IEssentials>({
    subcategory: '',
    essentials: [],
    type: {
      default: [],
    },
  });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [uploadData, setUploadData] = useState<IUploadData>({
    category: '',
    subcategory: '',
    data: [],
  });

  const [subcategoryToDel, setSubCategoryToDel] = useState<string[]>([]);
  const [subcategoryToDelList, setSubcategoryToDelList] = useState<string[]>(
    [],
  );

  const [clients, setClients] = useState<ISubCategories[]>([]);
  const [ClientsList, setClientsList] = useState<IConfigData[]>([]);
  const [userPermissions, setUserPermissions] =
    useState<IConfigUserPermissions>({});
  /**
   * strings states
   */

  const [alertMSG, setAlertMSG] = useState('');
  const [category, setCategory] = useState('');
  const [showCategoryTitle, setShowCategoryTitle] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [categoryID, setCategoryID] = useState('');
  const [subcategory, setSubCategory] = useState<string>('');
  const [subcategoryID, setSubCategoryID] = useState<string>('');

  /**
   * boolean states
   */

  const [showAlert, setShowAlert] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [allowSubCategoryContent, setAllowSubCategoryContent] =
    useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [disallowSaveBTN, setDisallowSaveBTN] = useState<boolean>(true);
  const [disallowAddRuleBTN, setDisallowAddRuleBTN] = useState<boolean>(true);
  const [disallowAddConfigBTN, setDisallowAddConfigBTN] =
    useState<boolean>(false);
  const [showMsgBoard, setShowMsgBoard] = useState(false);
  const [urgentConfirmation, setUrgentConfirmation] = useState<boolean>(false);
  const [showAlertConfirmationDialog, setShowAlertConfirmationDialog] =
    useState<boolean>(false);

  const [showDelSubCategoryDialog, setShowDelSubCategoryDialog] =
    useState<boolean>(false);

  const [showInfoAboutRuleDialog, setShowInfoAboutRuleDialog] =
    useState<boolean>(false);

  const [showUploadPluginDialog, setShowUploadPluginDialog] =
    useState<boolean>(false);

  const [isInsertNewData, setIsInsertNewData] = useState<boolean>(false);

  const [showAddRowDialog, setShowAddRowDialog] = useState<boolean>(false);
  /**
   * ******************************
   */

  /**
   * Context
   */

  const createAlert = useContext(SnackbarContext);

  const { categoriesList, msgFromConfProvider, updateAll } =
    useContext(ConfigurationContext);

  const ref = useRef();

  const apiRef = useGridApiRef();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (apiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    apiRef.current = {};
  }

  const ClientsFetch = async () => {
    try {
      const _id: string[] = categoryList
        .filter((item: ICategories) => {
          if (item.categoryLabel === 'clients') {
            return item.id;
          }
        })
        .map((item: ICategories) => item.id);

      if (_id.length > 0 && clients.length === 0) {
        const { clients, status } = await getClients(_id[0]);
        if (clients.length > 0) {
          setClients(clients);

          // (async()=>{

          // })();

          const clientsObj = await getClientsListRules(_id[0], clients);
          const { message, clientsPayload } = clientsObj[0];
          setClientsList(clientsPayload);

          //TO-DO change this to a clever solution
          //const clientsListObj: IConfigData[] = clientsObject[0].clientsPayload;
          //const clientsObj = {clientsPayload}

          setTimeout(() => {
            createAlert({
              message: status,
              severity: 'info',
            });
          }, 1000);
        }
      }
    } catch (_e) {
      if (process.env.NODE_ENV === 'development') {
        setAlertMSG('Error fetching clients category data');
      }
    }
  };

  const listcat: string[] = clients.map((_: ISubCategories) => _.label);

  function CustomTypeEditComponent(props: GridEditSingleSelectCellProps) {
    const apiRef = useGridApiContext();

    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'role',
        value: '',
      });
    };

    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  const cols_actions: GridColDef[] = [
    {
      field: 'isSecret',
      description: 'isSecret',
      headerName: '',
      width: 100,
      editable: userPermissions?.isQuasarAdmin,
      type: 'boolean',
      align: 'center',
      valueGetter: (params) => params.row.isSecret,
      renderCell: (params) => (params.value ? <Lock /> : <LockOpen />),
      headerAlign: 'left',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode: boolean = memoHandleEditMode(id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'inherit',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          //  <GridActionsCellItem
          //    icon={<DeleteIcon />}
          //    label="Delete"
          //    onClick={handleDeleteClick()}
          //    color="inherit"
          //  />,
        ];
      },
    },
  ];

  const _cols_scrub: GridColDef[] = ScrubCols({
    userPermission: userPermissions?.isQuasarAdmin || false,
    listcat: listcat,
    ClientsList,
  });

  const _cols_general: GridColDef[] = GeneralCols({
    userPermission: userPermissions?.isQuasarAdmin || false,
    listcat: listcat,
    ClientsList,
  });

  const _cols_create_general: GridColDef[] = CreateGeneralCols({
    userPermission: userPermissions?.isQuasarAdmin || false,
    listcat: listcat,
    ClientsList,
  });

  const _cols_clients: GridColDef[] = ClientsCols({
    userPermission: userPermissions?.isQuasarAdmin || false,
  });

  const cols_general: GridColDef[] = _cols_general.concat(cols_actions);
  const cols_create_general: GridColDef[] =
    _cols_create_general.concat(cols_actions);
  const cols_scrub: GridColDef[] = _cols_scrub.concat(cols_actions);
  const cols_clients: GridColDef[] = _cols_clients.concat(cols_actions);

  const permissions = ConfigUserPermissions();

  // const updateRowModesToView = (rowModesModel: GridRowModesModel) => {
  //   return Object.fromEntries(
  //     Object.entries(rowModesModel).map(([id, mode]) => [
  //       id,
  //       {
  //         mode: GridRowModes.View,
  //       },
  //     ]),
  //   );
  // };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        apiRef.current?.startRowEditMode({
          id,
          fieldToFocus: 'subcategory',
        });
      });
    },
    [],
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDisallowAddRuleBTN(false);
        apiRef.current?.stopRowEditMode({
          id,
        });
      });
    },
    [],
  );

  async function handleAlertAtRequest(action: Function, requestData: IRequest) {
    try {
      const { message, status } = await action(requestData);
      createAlert({
        message,
        severity: 'info',
      });

      if (!!status && (status === 200 || status === 201)) {
        SubCategories();
      }
    } catch (_e) {
      createAlert({
        message: `Could not handle (send) request: ${_e}`,
        severity: 'error',
      });
    }
  }
  const handleRequestService = async ({
    request,
    category,
    categoryID,
    content,
    rows,
  }: IRequest) => {
    setSending(true);
    setDisallowSaveBTN(true);
    if (request === 'update') {
      await handleAlertAtRequest(updateConfigData, {
        category,
        categoryID,
        rows,
      });
      setDisallowSaveBTN(true);
      setDisallowAddRuleBTN(false);
    } else if (request === 'delete') {
      await handleAlertAtRequest(deleteConfigData, { category, content });
    } else if (request === 'insert') {
      await handleAlertAtRequest(insertConfigData, {
        category,
        categoryID,
        rows,
      });
    }
    setSending(false);
  };

  const handleDeleteClick = () => {
    if (urgentConfirmation === true) {
      if (
        rows.length > 1 &&
        !!essentialData.type.default &&
        essentialData.type.default.length < 2
      ) {
        //setRows(rows.filter((row) => !selectedRows.includes(row.configID)));
        const deleteData = rows.filter((row) =>
          selectedRows.includes(row.configID),
        );
        const content: {} = deleteData;

        handleRequestService({
          request: 'delete',
          category,
          categoryID,
          content,
          rows,
        });
      } else {
        const uniqueSubCategoriesDict = new Set(
          rows.map(({ subcategory: _ }) => _),
        );
        const uniqueSubCategoriesArray = Array.from(uniqueSubCategoriesDict);
        setSubcategoryToDelList(uniqueSubCategoriesArray as string[]);
        setShowDelSubCategoryDialog(true);
      }
      //setDisallowSaveBTN(false);
      setUrgentConfirmation(false);
    }
  };

  const handleFilterAndDeleteSubcategories = (
    _categoriesListToDel: string[],
  ) => {
    const filteredData = rows.filter((item) =>
      _categoriesListToDel.includes(item.subcategory as string),
    );
    //setRows(rows.filter((row) => !filteredData.includes(row)));
    const deleteData = rows.filter((row) =>
      selectedRows.includes(row.configID),
    );
    const content: {} = deleteData;
    handleRequestService({
      request: 'delete',
      category,
      categoryID,
      content,
      rows,
    });
    //setDisallowSaveBTN(false);
  };

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        apiRef.current?.stopRowEditMode({ id, ignoreModifications: true });
      });
    },
    [],
  );

  const processRowUpdate = (newRow: IConfigData) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(
      rows.map((row) => (row.configID === newRow.configID ? updatedRow : row)),
    );
    setDisallowSaveBTN(false);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setTimeout(() =>
      setRowModesModel({ ...rowModesModel, ...newRowModesModel }),
    );
  };

  const handleChangeCategory = (value: string, categoryTitle?: string) => {
    setShowCategoryTitle('');
    setCategoryID(value);
    !!categoryTitle && categoryTitle.length > 1
      ? setShowCategoryTitle(categoryTitle)
      : setShowCategoryTitle('');
    categoryList.filter((item: ICategories) => {
      if (item.id === value) {
        setCategory(item.categoryLabel);
      }
    });
  };

  const handleChangeSubCategory = (
    event: React.SyntheticEvent,
    value: string,
  ) => {
    setSubCategoryID(value);
    subcategoryList.filter((item: ISubCategories) => {
      if (item.id === value) {
        setSubCategory(item.label);
      }
    });
  };

  const handleChangeSelectedType = (value: string) => {
    setSelectedType(value);
  };

  const handleAddRuleWithoutType = () => {
    if (category === 'clients') {
      setConfigCols(cols_clients);
    }

    const newConfig: IConfigData[] = [];
    if (!!essentialData.essentials && essentialData.essentials.length > 0) {
      for (const item of essentialData.essentials) {
        const newRow: IConfigData = {
          configKey: item,
          configID: generateSessionId(),
          configValue: '',
          displayValue: '',
          enabled: true,
          isSecret: false,
          parentConfig: '',
          role: '',
          subcategory: essentialData.subcategory,
        };

        newConfig.push(newRow);

        const id = newRow.configID;
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.View,
            fieldToFocus: 'subcategory',
          },
        }));
      }
    } else {
      const newRow: IConfigData = {
        configKey: '',
        configID: generateSessionId(),
        configValue: '',
        displayValue: '',
        enabled: true,
        isSecret: false,
        parentConfig: '',
        role: '',
        subcategory: essentialData.subcategory,
      };
      newConfig.push(newRow);
      const id = newRow.configID;
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: {
          mode: GridRowModes.View,
          fieldToFocus: 'subcategory',
        },
      }));
    }

    //setRows((oldRows) => [...oldRows, ...newConfig]);
    setAllowSubCategoryContent(false);
    setSubCategory(essentialData.subcategory);
    setRows(() => [...newConfig]);
    //setDisallowSaveBTN(false);
    setDisallowAddRuleBTN(false);
  };

  const handleAddRuleWithType = (selectedType: string) => {
    const isSelectedType: boolean = selectedType.length > 0;
    const isValidSelectedType: boolean =
      !!isSelectedType &&
      essentialData &&
      essentialData.type &&
      essentialData.type?.[selectedType].length > 0;

    const newConfig: IConfigData[] = [];

    if (category === 'clients') {
      setConfigCols(cols_clients);
    }

    if (!!essentialData.type.default && isValidSelectedType) {
      for (const item of essentialData.type?.[selectedType]) {
        const newRow: IConfigData = {
          configKey: item,
          configID: generateSessionId(),
          configValue: '',
          displayValue: '',
          enabled: true,
          isSecret: false,
          parentConfig: 'client_01',
          role: '',
          subcategory: essentialData.subcategory,
        };

        newConfig.push(newRow);

        const id = newRow.configID;
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.View,
            fieldToFocus: 'subcategory',
          },
        }));
      }
    } else {
      const newRow: IConfigData = {
        configKey: '',
        configID: generateSessionId(),
        configValue: '',
        displayValue: '',
        enabled: true,
        isSecret: false,
        parentConfig: '',
        role: '',
        subcategory: essentialData.subcategory,
      };
      newConfig.push(newRow);
      const id = newRow.configID;
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: {
          mode: GridRowModes.View,
          fieldToFocus: 'subcategory',
        },
      }));
    }

    //setRows((oldRows) => [...oldRows, ...newConfig]);
    setAllowSubCategoryContent(false);
    setSubCategory(essentialData.subcategory);
    setRows(() => [...newConfig]);
    //setDisallowSaveBTN(false);
    setDisallowAddRuleBTN(false);
  };

  const handleAddRule = (selectedType: string) => {
    setIsInsertNewData(true);

    if (!!selectedType && selectedType.length > 0) {
      handleAddRuleWithType(selectedType);
    } else {
      handleAddRuleWithoutType();
    }
  };

  const handleAddRow = (
    displayValue: string,
    configKey: string,
    configValue: string,
  ) => {
    const newConfig: IConfigData[] = [];
    const newRow: IConfigData = {
      configKey,
      configID: generateSessionId(),
      configValue,
      displayValue,
      enabled: true,
      isSecret: false,
      parentConfig: '',
      role: '',
      subcategory,
    };
    newConfig.push(newRow);
    const id = newRow.configID;
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: {
        mode: GridRowModes.View,
        fieldToFocus: 'subcategory',
      },
    }));
    setRows((oldRows) => [...oldRows, ...newConfig]);
    //setDisallowSaveBTN(false);
    setDisallowAddRuleBTN(false);
  };

  const handleUploadedData = () => {
    if (rows.length === 0) {
      const { category: _category } = uploadData;
      // const { subcategory: _subcategory } = uploadData;
      setRows([]);
      setConfigCols(cols_create_general);
      const { data } = uploadData;
      setCategory(_category);
      setRows(data.map((obj) => ({ ...obj, configID: generateSessionId() })));
      //setSubCategory(_subcategory);
      setDisallowAddRuleBTN(true);
      setDisallowSaveBTN(false);
      //   setUploadData({category: "", subcategory: "", data: []});
    }
  };

  const cleanState = () => {
    setRows([]);
    setCategory('');
    setShowCategoryTitle('');
    setSubCategory('');
    setCategoryID('');
    setSubCategoryID('');
    setSubCategoryList([]);
    setConfigCols([]);
    setClients([]);
    setEssentialData({ subcategory: '', essentials: [], type: {} });
    setDisallowSaveBTN(true);
    setDisallowAddRuleBTN(true);
    setDisallowAddConfigBTN(false);
    setUploadData({ category: '', subcategory: '', data: [] });
    setAlertMSG('');
    setRowModesModel({});
    setSelectedType('');
    setNow(Date.now());
  };

  const EssentialData = async () => {
    setAlertMSG('');
    const { essentialData, status } = await getEssentialData(
      categoryID,
      subcategoryID,
    );
    if (
      (!!essentialData.essentials && essentialData.essentials.length > 0) ||
      (!!essentialData.type &&
        !!essentialData.type.default &&
        essentialData.type.default.length > 0)
    ) {
      setTimeout(() => {
        createAlert({
          message: `Type data loaded for ${category} / ${subcategory}.`,
          severity: 'success',
        });
      }, 1000);
      setEssentialData(essentialData);
    } else if (category === 'scrub') {
      setTimeout(() => {
        createAlert({
          message: status,
          severity: 'info',
        });
      }, 1000);
      setEssentialData(essentialData);
    } else {
      setTimeout(() => {
        createAlert({
          message: `No essential data.`,
          severity: 'info',
        });
      }, 1000);
    }
  };

  const createNewConfigWithType = (selectedType: string) => {
    setLoading(true);
    setShowMsgBoard(false);

    const newConfig: IConfigData[] = [];

    if (!!essentialData.type && essentialData.type?.[selectedType].length > 0) {
      for (const item of essentialData.type?.[selectedType]) {
        const newRow: IConfigData = {
          configKey: item,
          configID: generateSessionId(),
          configValue: '',
          displayValue: '',
          enabled: true,
          isSecret: false,
          parentConfig: 'client_01',
          role: '', // ClientsList?.[0][0].role,
          subcategory,
        };
        newConfig.push(newRow);

        const id = newRow.configID;

        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.View, fieldToFocus: 'subcategory' },
        }));
      }
    } else if (category === 'scrub') {
      const newScrubConf: IConfigData = {
        configKey: '',
        configID: generateSessionId(),
        configValue: '',
        displayValue: '',
        enabled: true,
        isSecret: false,
        parentConfig: '',
        role: '',
        subcategory,
      };

      const id = newScrubConf.configID;

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.View, fieldToFocus: 'subcategory' },
      }));

      newConfig.push(newScrubConf);
    }

    if (newConfig.length > 0) {
      setTimeout(() => {
        createAlert({
          message: `Creating configuration for ${subcategory}.`,
          severity: 'info',
        });
        setLoading(false);
        if (category === 'clients') {
          setConfigCols(cols_clients);
        } else if (category === 'scrub') {
          setConfigCols(cols_scrub);
        } else {
          setConfigCols(cols_create_general);
        }
        setRows(newConfig);
        setDisallowAddRuleBTN(true);
      }, 2000);
    } else {
      setTimeout(() => {
        createAlert({
          message: `No essential data loaded.
           Cant create a new configuration for ${subcategory}.`,
          severity: 'error',
        });
        setLoading(false);
      }, 2000);
    }
  };

  const createNewConfigWithoutType = () => {
    setLoading(true);
    setShowMsgBoard(false);

    const newConfig: IConfigData[] = [];

    if (!!essentialData.essentials && essentialData.essentials.length > 0) {
      for (const item of essentialData.essentials) {
        const newRow: IConfigData = {
          configKey: item,
          configID: generateSessionId(),
          configValue: '',
          displayValue: '',
          enabled: true,
          isSecret: false,
          parentConfig: '',
          role: '',
          subcategory,
        };
        newConfig.push(newRow);

        const id = newRow.configID;

        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.View, fieldToFocus: 'subcategory' },
        }));
      }
    }
    if (newConfig.length > 0) {
      setTimeout(() => {
        createAlert({
          message: `Creating configuration for ${subcategory}.`,
          severity: 'info',
        });
        setLoading(false);
        setConfigCols(cols_create_general);
        setRows(newConfig);
        setDisallowAddRuleBTN(true);
      }, 2000);
    } else if (category === 'scrub') {
      const newScrubConf: IConfigData = {
        configKey: '',
        configID: generateSessionId(),
        configValue: '',
        displayValue: '',
        enabled: true,
        isSecret: false,
        parentConfig: '',
        role: '',
        subcategory,
      };

      const id = newScrubConf.configID;

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.View, fieldToFocus: 'subcategory' },
      }));

      setTimeout(() => {
        createAlert({
          message: `Creating configuration for ${subcategory}.`,
          severity: 'info',
        });
        setLoading(false);
        setConfigCols(cols_scrub);
        setRows([newScrubConf]);
        setDisallowAddRuleBTN(true);
      }, 2000);
    } else {
      setTimeout(() => {
        createAlert({
          message: `No essential data loaded.
           Cant create a new configuration for ${subcategory} without types.`,
          severity: 'error',
        });
        setLoading(false);
      }, 2000);
    }
  };

  const createNewConfig = () => {
    setIsInsertNewData(true);

    const showTypesOptions: boolean =
      !!essentialData.type &&
      Object.keys(essentialData.type).filter((key) =>
        Array.isArray(essentialData.type[key]),
      ).length > 1;

    if (category === 'clients') {
      setConfigCols(cols_clients);
    }

    if (!!selectedType && selectedType.length > 1) {
      createNewConfigWithType(selectedType);
    } else if (
      !!essentialData.type &&
      Object.keys(essentialData.type).filter((key) =>
        Array.isArray(essentialData.type[key]),
      ).length > 1
    ) {
      createAlert({
        message: 'You should choose a type to create this subcategory',
        severity: 'info',
      });
    } else {
      createNewConfigWithoutType();
    }
  };
  const configState = () => {
    setRows([]);
    setLoading(true);
    setAlertMSG('');
    setSelectedType('');
    ClientsFetch();
  };

  const SubCategoryContent = async () => {
    configState();
    setShowMsgBoard(false);
    EssentialData();
    setRowModesModel({});
    setDisallowAddConfigBTN(true);

    setIsInsertNewData(false);

    const { subCategoryContent, status } = await getSubCategoryContent(
      categoryID,
      subcategoryID,
    );

    if (
      subCategoryContent &&
      subCategoryContent.length > 0 &&
      allowSubCategoryContent
    ) {
      setTimeout(() => {
        if (category === 'scrub') {
          setConfigCols(cols_scrub);
        } else if (category === 'clients') {
          setConfigCols(cols_clients);
        } else {
          setConfigCols(cols_general);
        }
        setRows(subCategoryContent);
        setDisallowAddRuleBTN(false);
        createAlert({
          message: `Loading configuration data for ${subcategory}.`,
          severity: 'success',
        });
      }, 1000);
    } else {
      setTimeout(() => {
        setRows([]);
        setAlertMSG(
          `No configuration data loaded for ${category} / ${subcategory}.`,
        );
        setShowMsgBoard(true);
        setDisallowAddRuleBTN(true);
        createAlert({
          message: status,
          severity: 'info',
        });
      }, 1000);
    }
    setLoading(false);
  };

  const Categories = async () => {
    configState();
    setCategoryList([]);
    setSubCategoryList([]);
    setEssentialData({ subcategory: '', essentials: [], type: {} });
    setCategory('');
    setSubCategory('');

    //const { categories, status } = await getCategories();

    if (categoriesList && categoriesList.length > 0) {
      setTimeout(() => {
        setCategoryList(categoriesList);
        setLoading(false);
        // createAlert({
        //   message: msgFromConfProvider,
        //   severity: 'success',
        // });
      }, 1000);
    } else {
      setLoading(false);
      setTimeout(() => {
        setAlertMSG('Could not get categories list from server.');
        updateAll();
        setNow(Date.now());
        createAlert({
          message: 'loading categories',
          severity: 'info',
        });
      }, 1000);
    }
  };

  const SubCategories = async () => {
    configState();
    setSubCategoryList([]);
    setEssentialData({ subcategory: '', essentials: [], type: {} });
    setSubCategory('');

    const { subCategories, status } = await getSubCategories(categoryID);

    if (subCategories && subCategories.length > 0) {
      setSubCategoryList(subCategories);
      loadFirstSubCategory(subCategories);
      setTimeout(() => {
        createAlert({
          message: status,
          severity: 'success',
        });
      }, 1000);
    } else {
      setLoading(false);
      setTimeout(() => {
        setAlertMSG(status);
        createAlert({
          message: `No subcategories loaded for ${category}.`,
          severity: 'info',
        });
      }, 1000);
    }
    setLoading(false);
  };

  const loadFirstSubCategory = (subCategories: ISubCategories[]) => {
    if (subCategories.length > 0) {
      setSubCategoryID(subCategories[0].id);
      setSubCategory(subCategories[0].label);
    }
  };

  function handleEditMode(id: GridRowId) {
    const isInEditMode: boolean =
      apiRef.current?.getRowMode(id) === GridRowModes.Edit;
    return isInEditMode;
  }

  const memoHandleEditMode = useCallback(handleEditMode, []);

  useEffect(() => {
    setUserPermissions(permissions);
    Categories();
  }, [now]);

  useEffect(() => {
    if (categoryID.length > 0 && uploadData.data.length < 1) {
      SubCategories();
    }
  }, [category]);

  useEffect(() => {
    if (subcategoryList.length > 0 && uploadData.data.length < 1) {
      SubCategoryContent();
    }
    setAllowSubCategoryContent(true);
    setDisallowAddRuleBTN(true);
    setDisallowSaveBTN(true);
  }, [subcategory]);

  useEffect(() => {
    if (subcategoryList.length > 0) {
      setDisallowAddRuleBTN(false);
    }

    if (
      !!essentialData.type &&
      Object.keys(essentialData.type).filter((key) =>
        Array.isArray(essentialData.type[key]),
      ).length === 1 &&
      !!essentialData.type?.default &&
      essentialData.type?.default.length > 0
    ) {
      setSelectedType('default');
    }
  }, [essentialData]);

  useEffect(() => {
    if (uploadData.data.length > 0) {
      handleUploadedData();
    }
  }, [uploadData]);

  useEffect(() => {
    handleDeleteClick();
  }, [urgentConfirmation]);

  return (
    <Grid container height="100%" width="100%" flexDirection="column">
      <Grid
        item
        container
        wrap="nowrap"
        borderBottom="1px solid #B3B2B5"
        height="80px"
        px={3}
        boxSizing={'border-box'}
        alignItems="center"
        justifyContent="space-between"
        // alignItems="center"
        flexDirection="row"
      >
        <Grid item xs={8} flexDirection="row" justifyContent="flex-start">
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <div>
              <Typography color={grey[1000]} variant="h2">
                Configuration
              </Typography>
            </div>
          </Stack>
        </Grid>

        <Box sx={{ float: 'right' }}>
          {userPermissions?.ALLOW_DOWNLOAD_RULES_BTN && rows.length > 0 && (
            <IconButton
              size="large"
              color="secondary"
              disabled={rows.length < 1}
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify({
                  categoryLabel: category,
                  id: categoryID,
                  data: rows,
                }),
              )}`}
              download={`${category}_${subcategory}.json`}
            >
              <FileDownloadOutlined color="secondary" />
            </IconButton>
          )}

          {userPermissions?.isQuasarAdmin && (
            <Button
              disabled={disallowAddConfigBTN}
              variant="contained"
              color="primary"
              onClick={() => setShowUploadDialog(true)}
            >
              Add Config
            </Button>
          )}
        </Box>
      </Grid>
      <Divider />

      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        sx={{ height: 'calc(90vh - 393px)' }}
      >
        <Grid item container flexDirection="row" xs={2}>
          <CategoriesList
            categories={categoryList}
            handleChangeCategory={handleChangeCategory}
          />

          {categoryList.length > 0 && <Divider orientation="vertical" />}
        </Grid>

        <Grid
          xs={10}
          item
          container
          flexDirection="column"
          justifyContent="space-between"
          //alignItems="center"
          //height="100%"
          // flexGrow={1}
          //bgcolor={grey[200]}
          py={2}
          px={1}
        >
          <Grid container flexDirection="row" justifyContent="space-between">
            <Grid item>
              <h2>
                {showCategoryTitle ? `${showCategoryTitle.toUpperCase()}` : ''}
                {/* {subcategory ? ` / ${subcategory} rules` : ''} */}
              </h2>
            </Grid>

            <Grid item flexDirection="row">
              {rows.length > 0 && (
                <>
                  <Tooltip
                    title="Get info about this rule"
                    placement="bottom-start"
                  >
                    <IconButton
                      size="large"
                      color="secondary"
                      onClick={() =>
                        setShowInfoAboutRuleDialog(!showInfoAboutRuleDialog)
                      }
                    >
                      <InfoOutlined color="secondary" />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {userPermissions?.isQuasarAdmin && rows.length > 0 && (
                <>
                  <Tooltip title="Delete this rule" placement="bottom-start">
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={() => {
                        setShowAlertConfirmationDialog(true);
                      }}
                      disabled={selectedRows.length < 1}
                    >
                      <DeleteOutlined color="secondary" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            {subcategoryList.length > 0 && (
              <Tabs
                value={subcategoryID}
                onChange={handleChangeSubCategory}
                variant="scrollable"
                scrollButtons="auto"
                // allowScrollButtonsMobile
                aria-label="rules tabs list"
              >
                {subcategoryList.map((_: ISubCategories) => (
                  <Tab key={_.id} label={_.label} value={_.id} />
                ))}
              </Tabs>
            )}
          </Grid>

          <Grid
            item
            //flexGrow={2}
            width="100%"
            sx={{ height: 'calc(90vh - 304px)', overflowY: 'auto' }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              {loading && <ComponentLoading />}

              {rows.length > 0 && (
                <DataGrid
                  rows={rows}
                  columns={configCols}
                  apiRef={apiRef}
                  getRowId={(row: IConfigData) => row.configID}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    columns: {
                      columnVisibilityModel: {
                        actions: !!userPermissions?.isQuasarAdmin,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  onRowSelectionModelChange={(ids) => {
                    setSelectedRows(ids);
                  }}
                  rowSelectionModel={selectedRows}
                  disableRowSelectionOnClick={true}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  density="comfortable"
                  sx={{
                    '& .MuiDataGrid-columnHeader': {
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      //resize: 'block',
                      // backgroundColor: 'rgba(0, 0, 0, 0.11)',
                      fontSmooth: 'always',
                      color: '#000000',
                      //fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      //whiteSpace: 'normal',
                      //padding: '0px 0px 0px 16px',
                    },
                    // '& .MuiDataGrid-cell': {
                    //   fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    //   fontWeight: 'bold',
                    //   fontSize: '15px',
                    // },
                  }}
                />
              )}
              {showMsgBoard && (
                <MessageBoard
                  subcategory={subcategory}
                  alertMSG={alertMSG}
                  createNewConfig={createNewConfig}
                  userPermissions={userPermissions}
                  essentialData={essentialData}
                  handleChangeSelectedType={handleChangeSelectedType}
                  selectedType={selectedType}
                />
              )}
            </Box>
          </Grid>

          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            padding="1px"
            margin="1px"
          >
            <Grid item xs={6}>
              {userPermissions?.isQuasarAdmin && subcategory.length > 0 && (
                <Stack direction="row" spacing={1}>
                  {/* {!!essentialData.type &&
                    Object.keys(essentialData.type).filter((key) =>
                      Array.isArray(essentialData.type[key]),
                    ).length > 1 && (
                      <TypesList
                        typesObject={essentialData.type}
                        selectedType={selectedType}
                        handleChangeSelectedType={handleChangeSelectedType}
                      />
                    )} */}
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ border: 0 }}
                    onClick={() => handleAddRule(selectedType)}
                    disabled={disallowAddRuleBTN}
                  >
                    <b>Add subcategory</b>
                  </Button>

                  {category !== 'clients' ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ border: 0 }}
                      onClick={() => setShowAddRowDialog(!showAddRowDialog)}
                      disabled={disallowAddRuleBTN && rows.length < 1}
                    >
                      <b>Add row</b>
                    </Button>
                  ) : (
                    ''
                  )}

                  {selectedType === 'plugin' && rows.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ border: 0 }}
                      onClick={() =>
                        setShowUploadPluginDialog(!showUploadPluginDialog)
                      }
                    >
                      Upload plugin
                    </Button>
                  )}
                </Stack>
              )}
            </Grid>

            <Grid item flexDirection="row" justifyContent="flex-end">
              <Stack direction="row" spacing={1}>
                {sending && <ComponentLoading />}

                {rows.length > 0 && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => cleanState()}
                  >
                    Cancel
                  </Button>
                )}

                {!!userPermissions?.isQuasarAdmin && rows.length > 0 && (
                  <Button
                    disabled={disallowSaveBTN}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      uploadData.data.length > 0 || isInsertNewData
                        ? handleRequestService({
                            request: 'insert',
                            category,
                            categoryID,
                            rows,
                          })
                        : handleRequestService({
                            request: 'update',
                            category,
                            categoryID,
                            rows,
                          });
                    }}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AddRowDialog
        open={showAddRowDialog}
        setOpen={setShowAddRowDialog}
        subcategory={subcategory}
        handleAddRow={handleAddRow}
      />

      <UploadPluginDialog
        open={showUploadPluginDialog}
        setOpen={setShowUploadPluginDialog}
      />

      <AlertConfirmationDialog
        setOpen={setShowAlertConfirmationDialog}
        open={showAlertConfirmationDialog}
        setUrgentConfirmation={setUrgentConfirmation}
      />

      <InfoAboutRuleDialog
        setOpen={setShowInfoAboutRuleDialog}
        open={showInfoAboutRuleDialog}
        category={category}
        categoryTitle={showCategoryTitle}
        subcategory={subcategory}
      />

      <DeleteSubCategoryDialog
        setSubCategoryToDel={setSubCategoryToDel}
        subcategoryToDelList={subcategoryToDelList}
        setOpen={setShowDelSubCategoryDialog}
        open={showDelSubCategoryDialog}
        handleFilterAndDeleteSubcategories={handleFilterAndDeleteSubcategories}
      />

      <UploadRuleDialog
        open={showUploadDialog}
        setOpen={setShowUploadDialog}
        setUploadData={setUploadData}
        setNow={setNow}
        _categoriesList={categoryList}
        cleanState={cleanState}
      />

      {alertMSG && (
        <MessageAlertDialog
          message={alertMSG}
          open={showAlert}
          setOpen={setShowAlert}
        />
      )}
    </Grid>
  );
}
