import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import TaskForm from 'components/TaskForm';
import { FormData, GenericInputs } from 'types';
import FrequentTasks from 'components/FrequentTasks';
import LoadingButton from 'components/UI/LoadingButton';

interface DocGenUploadProps {
  page: 'tasks' | 'jobs';
  loading: boolean;
  editJob?: boolean;
  hash?: string;
  formInputs: GenericInputs;
  initialFormState: FormData;
  defaultFormValues: FormData;
  handleClose: () => void;
  handleSubmit: (formData: FormData) => Promise<void>;
  setFormInputs: React.Dispatch<React.SetStateAction<GenericInputs>>;
  createInput: (newInputId: number, disableInputs?: number[]) => GenericInputs;
}

export default function DocGenUpload({
  page,
  loading,
  editJob,
  hash,
  formInputs,
  initialFormState,
  defaultFormValues,
  handleClose,
  handleSubmit,
  createInput,
  setFormInputs,
}: DocGenUploadProps): React.JSX.Element {
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const cancelButtonRef = useRef<HTMLInputElement>(null);

  const disableFileInput = (checkbox: { [name: string]: boolean }) => {
    const name = Object.keys(checkbox)[0];
    const isActive = checkbox[name];
    const id = name.slice(-1);
    setFormInputs((prev) =>
      prev.map((input) =>
        input.name.includes(`file${id}`)
          ? { ...input, disabled: isActive }
          : input,
      ),
    );
  };

  const disableAllInputs = useCallback(
    (disabled: boolean) => {
      setFormInputs((prev) => prev.map((input) => ({ ...input, disabled })));
    },
    [setFormInputs],
  );

  const prepopulateForm = ({
    newFormValues,
    idsArray,
    disableInputs,
  }: {
    newFormValues: FormData;
    idsArray: number[];
    disableInputs?: number[];
  }) => {
    const replaceFormData = () => setFormData(newFormValues);

    const replaceFormInputs = () => {
      const newFormInputs = idsArray.flatMap((newInputId) =>
        createInput(newInputId, disableInputs),
      );
      setFormInputs(newFormInputs);
    };

    replaceFormData();
    replaceFormInputs();
  };

  const addInput = (newInputId: number) => {
    setFormInputs((prev) => {
      const newInput = createInput(newInputId);
      return [...prev, ...newInput];
    });
  };

  const removeInput = (selectedInputs: string[]) => {
    setFormInputs((prev) =>
      prev.filter((input) => !selectedInputs.includes(input.name)),
    );
  };

  const showInputError = useCallback(
    (inputName: string, errorDetected: boolean) => {
      setFormInputs((prev) =>
        prev.map((input) =>
          input.name === inputName
            ? {
                ...input,
                error: errorDetected
                  ? input['error']
                    ? ++input['error']
                    : 1
                  : 0,
              }
            : input,
        ),
      );
    },
    [setFormInputs],
  );

  const validateInputs = useCallback(
    (formData: FormData) => {
      // fileName property it's only being used to store the file name provisionally
      const { fileName, ...necessaryData } = formData;

      const inputNames = Object.keys(necessaryData);

      const inputValidityStatus = inputNames.map((name) => {
        const input = formInputs.find((input) => input.name === name);
        if (!input) return false; // Input not found, so return false

        const isInputRequired = input.required;
        const isInputDisabled = input.disabled;
        if (!isInputRequired || isInputDisabled) return true; // No need to validate if not required or disabled

        const inputContent = necessaryData[name];
        const arrayInput = Array.isArray(inputContent)
          ? inputContent
          : [inputContent]; // Handle array input
        const isInputEmpty =
          inputContent === '' ||
          inputContent === undefined ||
          arrayInput.length === 0;

        showInputError(name, isInputEmpty); // Display error if input is empty
        return !isInputEmpty; // Return true if input is valid (not empty)
      });

      const areValidInputs = inputValidityStatus.every((i) => i);
      if (areValidInputs) handleSubmit(formData);
    },
    [formInputs, handleSubmit, showInputError],
  );

  useEffect(() => {
    disableAllInputs(loading);
  }, [loading, disableAllInputs]);

  useEffect(() => {
    setFormData(initialFormState);
  }, [initialFormState]);

  return (
    <Grid container height="100%" flexDirection="column" wrap="nowrap">
      <Grid
        item
        container
        wrap="nowrap"
        borderBottom="1px solid #B3B2B5"
        height="80px"
        padding={3}
        pb={2}
        boxSizing={'border-box'}
        justifyContent={'space-between'}
      >
        <Grid
          container
          alignItems="center"
          bgcolor="white"
          sx={{ display: 'flex', gap: 1 }}
        >
          <IconButton sx={{ color: '#03000F' }} onClick={handleClose}>
            <KeyboardBackspaceIcon sx={{ width: '24px', height: '24px' }} />
          </IconButton>
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h2">TaskGen Pro</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'end', gap: 1 }} wrap="nowrap">
          <Button
            variant={'outlined'}
            color={'secondary'}
            type="button"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={'contained'}
            color={'secondary'}
            type="submit"
            onClick={() => submitButtonRef.current?.click()}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sx={{
          flexWrap: 'nowrap',
          display: 'flex',
          flexGrow: 1,
          overflowY: 'auto',
          height: 'calc(100vh - 204px)',
        }}
      >
        <Grid
          item
          xs={8}
          sm={8}
          lg={8}
          sx={{
            borderRight: '1px solid #B3B2B5',
            padding: 3,
            overflowY: 'auto',
            backgroundColor: '#FAFAFA',
          }}
        >
          <TaskForm
            page={page}
            inputs={formInputs}
            hash={hash}
            editJob={editJob}
            formData={formData}
            initialFormState={initialFormState}
            cancelButtonRef={cancelButtonRef}
            submitButtonRef={submitButtonRef}
            defaultFormValues={defaultFormValues}
            addInput={addInput}
            handleClose={handleClose}
            handleSubmit={validateInputs}
            setFormData={setFormData}
            removeInput={removeInput}
            disableFileInput={disableFileInput}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          lg={4}
          sx={{
            padding: '24px 16px',
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FrequentTasks list={page} prepopulateForm={prepopulateForm} />
        </Grid>
      </Grid>
    </Grid>
  );
}
