import React, { createContext, useReducer, useState, useCallback } from 'react';
import { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';
import axios from 'api/axios';
import { Files, IChildProcess, Job } from 'types';
import { INewDocument } from 'types';
import useUploadStatus from 'common/hooks/useUploadStatus';
import { isObjectEmpty } from 'common/utils/common';
import useUpdateJobs, { Item } from 'common/hooks/useUpdateJobs';
export interface State {
  importDisabled: boolean;
  files?: Files;
  index?: string;
  teste?: boolean;
}

const initialState: State = { importDisabled: true };

type FilesContextProps = {
  state: State;
  openUploadDialog: boolean;
  currentIndex: string | undefined;
  documents: INewDocument[];
  mandatoryMetadatas: string[];
  linkedJobs: Job[] | null;
  itemStatus: Item | null;
  setDocuments: React.Dispatch<React.SetStateAction<INewDocument[]>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<string>>;
  dispatch: React.Dispatch<Action>;
  removeFile: (key: string) => void;
  enableImport: () => void;
  uploadFiles: (files: Files | undefined, funcTeste?: any) => void;
  setMetadatas: React.Dispatch<React.SetStateAction<string[]>>;
  getFileList: (index: string) => Promise<void>;
  setMandatoryMetadatas: React.Dispatch<React.SetStateAction<string[]>>;
  updateJobUpdatesAvailableList: (newItem: Item) => void;
};

export const FilesContext = createContext<FilesContextProps>({
  state: { importDisabled: true },
  openUploadDialog: false,
  currentIndex: '',
  documents: [],
  mandatoryMetadatas: [],
  linkedJobs: null,
  itemStatus: null,
  setDocuments: () => {},
  setCurrentIndex: () => {},
  dispatch: () => {},
  removeFile: () => {},
  enableImport: () => {},
  uploadFiles: () => {},
  setMetadatas: () => {},
  getFileList: async () => {},
  setMandatoryMetadatas: () => {},
  updateJobUpdatesAvailableList: () => {},
});

type FilesProviderProps = {
  children: React.JSX.Element;
};

interface ActionsSimple {
  type: 'enableImport' | 'disableImport';
}

interface ActionAddFiles {
  type: 'addFiles';
  payload: Files;
}

interface ActionRemoveFile {
  type: 'removeFile';
  payload: string;
}

interface ActionUploadProgress {
  type: 'uploadProgress';
  payload: { name: string; upload: number };
}

interface ActionUploadComplete {
  type: 'uploadComplete';
  payload: {
    name: string;
    status?: string;
    taskID: string;
    processed?: string;
  };
}

interface ActionUpdateDecodedProcess {
  type: 'updateDecodedProcess';
  payload: {
    status?: string;
    taskID: string;
    processed?: string;
  };
}

interface ActionUploadError {
  type: 'uploadError';
  payload: { name: string; status: string };
}

interface ActionIndexChange {
  type: 'indexChange';
  payload: { index?: string };
}

interface ActionResetState {
  type: 'resetState';
}

interface ActionMandatoryMetadata {
  type: 'mandatoryMetadata';
  payload: { name: string; status: string };
}

export type Action =
  | ActionsSimple
  | ActionAddFiles
  | ActionRemoveFile
  | ActionUploadProgress
  | ActionUploadComplete
  | ActionUpdateDecodedProcess
  | ActionUploadError
  | ActionIndexChange
  | ActionResetState
  | ActionMandatoryMetadata;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'enableImport':
      return {
        ...state,
        importDisabled: false,
      };
    case 'disableImport':
      return {
        ...state,
        importDisabled: true,
      };
    case 'addFiles':
      if (!action.payload) return { ...state };
      return {
        ...state,
        files: { ...state.files, ...action.payload },
      };
    case 'removeFile':
      const removeFileState = { ...state };
      delete removeFileState.files?.[action.payload];
      if (
        removeFileState.files &&
        Object.values(removeFileState.files).length === 0
      ) {
        removeFileState.importDisabled = true;
        removeFileState.files = undefined;
      }
      return removeFileState;
    case 'uploadProgress':
      const uploadProgressState = { ...state };
      if (uploadProgressState.files?.[action.payload.name]) {
        uploadProgressState.files[action.payload.name].upload_progress =
          action.payload.upload;
      }
      return uploadProgressState;
    case 'uploadComplete':
      const uploadCompleteState = { ...state };
      if (uploadCompleteState.files?.[action.payload.name]) {
        uploadCompleteState.files[action.payload.name].upload_status =
          action.payload.status;
        uploadCompleteState.files[action.payload.name].taskID =
          action.payload.taskID;
        uploadCompleteState.files[action.payload.name].processed =
          action.payload.processed;
      }
      return uploadCompleteState;
    case 'updateDecodedProcess':
      const lastState = { ...state };
      const files = state.files;
      if (!files) return lastState;
      const filesStatus = Object.values(files);

      const fileName = filesStatus.filter(
        (f) => f.taskID === action.payload.taskID,
      )[0].file.name;
      if (!fileName) return lastState;

      if (lastState.files?.[fileName]) {
        lastState.files[fileName].upload_status = action.payload.status;
        lastState.files[fileName].taskID = action.payload.taskID;
        lastState.files[fileName].processed = action.payload.processed;
      }
      return lastState;
    case 'uploadError':
      const uploadErrorState = { ...state };
      if (uploadErrorState.files?.[action.payload.name]) {
        uploadErrorState.files[action.payload.name].upload_status =
          action.payload.status;
      }
      return uploadErrorState;
    case 'indexChange':
      return { ...state, index: action.payload.index };
    case 'resetState':
      return { importDisabled: true };
    case 'mandatoryMetadata':
      const mandatoryMetadataError = { ...state };
      if (mandatoryMetadataError.files?.[action.payload.name]) {
        mandatoryMetadataError.files[action.payload.name].upload_status =
          action.payload.status;
        mandatoryMetadataError.files[action.payload.name].upload_progress = 1;
      }
      return mandatoryMetadataError;
    default:
      throw Error('Unknown action.');
  }
}

export default function FilesProvider({
  children,
}: FilesProviderProps): React.JSX.Element {
  const { removeTaskID, getUploadStatus, getPendingTaskIDList } =
    useUploadStatus();
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [documents, setDocuments] = useState<INewDocument[]>([]);
  const [currentIndex, setCurrentIndex] = useState('');
  const [metadatas, setMetadatas] = useState<string[]>([]);
  const [mandatoryMetadatas, setMandatoryMetadatas] = useState<string[]>([]);
  const { updateUploadedFileList } = useUploadStatus();
  const { linkedJobs, itemStatus, updateJobUpdatesAvailableList } =
    useUpdateJobs({ currentIndex });

  const getFileList = useCallback(async (index: string) => {
    await axios({
      method: 'get',
      url: `/listdocs/${index}`,
    })
      .then((response: AxiosResponse) => {
        setDocuments((_documents) => {
          let documentsArr: INewDocument[] = [];
          if (typeof response.data === 'object') {
            documentsArr = response.data;
            documentsArr = documentsArr.map((doc) => {
              return {
                ...doc,
                id: doc.doc_name,
              };
            });
          }
          return documentsArr;
        });
      })
      .catch((error: AxiosError) => {
        setDocuments([]);
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, []);

  const addZippedFiles = useCallback(
    (childProcess: IChildProcess[]) => {
      const filesProcessed: Files = {};
      childProcess.forEach((subTask) => {
        const files = state.files;
        if (!files) return;
        const isFileAlreadyListed = Object.keys(files).includes(
          subTask.fileName,
        );
        if (isFileAlreadyListed) return;
        const fileStatus = {
          file: {
            name: subTask.fileName,
            lastModified: 0,
            webkitRelativePath: '',
            size: 0,
            type: '',
          } as File,
          processed: 'started',
          taskID: subTask.taskID,
          upload_progress: 100,
          upload_status: 'Success',
        };

        filesProcessed[subTask.fileName] = fileStatus;
      });
      if (isObjectEmpty(filesProcessed)) return;
      dispatch({ type: 'addFiles', payload: filesProcessed });
    },
    [state.files],
  );

  const processingStatusCheck = useCallback(
    (index: string) => {
      const pendingTaskIDList = getPendingTaskIDList(index);
      if (!pendingTaskIDList) return;

      let updatedFiles: string[] = [];
      // Set jobs not ready to be updated when new files are uploaded
      const newItem: Item = {
        index,
        updateAvailable: true,
        readyToBeUpdated: false,
      };
      updateJobUpdatesAvailableList(newItem);

      pendingTaskIDList.forEach(async (taskID, idx) => {
        const { uploadStatus, error } = await getUploadStatus({ taskID });
        if (error || !uploadStatus) return;

        const zippedFiles =
          uploadStatus.childProcess.length > 0 && uploadStatus.childProcess;

        const evaluateStatus = (
          status: IChildProcess,
          isZippedFile?: boolean,
        ) => {
          let data = {
            status: 'Success',
            processed: '',
            taskID: status.taskID,
          };

          // Status check only when end of list is reached
          const isLastItem = pendingTaskIDList.length - 1 === idx;

          if (status.status === 'Started' || status.status === 'InProgress') {
            if (!isLastItem || isZippedFile) return;
            return setTimeout(() => {
              processingStatusCheck(index);
            }, 4000);
          }

          if (status.status === 'Completed') {
            data.processed = 'completed';
            getFileList(index);
          } else data.processed = 'error';

          if (!isZippedFile) {
            removeTaskID({
              index,
              selectedTaskID: taskID,
              key: 'filesPendingProcessing',
            });

            // Set jobs ready to be updated when all files are decoded
            updatedFiles = [taskID, ...updatedFiles];
            const isReadyToBeUpdated =
              updatedFiles.length === pendingTaskIDList.length;
            const newItem: Item = {
              index,
              updateAvailable: true,
              readyToBeUpdated: isReadyToBeUpdated,
            };
            updateJobUpdatesAvailableList(newItem);
          }
          dispatch({
            type: 'updateDecodedProcess',
            payload: data,
          });
        };

        const evaluateChildProcessStatus = (zippedFiles: IChildProcess[]) => {
          zippedFiles.forEach((subTask) => evaluateStatus(subTask, true));
        };

        if (zippedFiles) {
          addZippedFiles(zippedFiles);
          evaluateChildProcessStatus(zippedFiles);
        }

        evaluateStatus({
          fileName: uploadStatus.fileName,
          chunkSize: uploadStatus.chunkSize,
          index: uploadStatus.index,
          status: uploadStatus.status,
          taskID: uploadStatus.taskID,
          createdAt: uploadStatus.createdAt,
          updatedAt: uploadStatus.updtatedAt,
        });
      });
    },
    [
      dispatch,
      getFileList,
      removeTaskID,
      addZippedFiles,
      getUploadStatus,
      getPendingTaskIDList,
      updateJobUpdatesAvailableList,
    ],
  );

  const getUploadProgress = (event: AxiosProgressEvent, name: string) => {
    const percentCompleted = event.total
      ? Math.round((event.loaded * 100) / event.total)
      : undefined;
    if (typeof percentCompleted === 'number') {
      dispatch({
        type: 'uploadProgress',
        payload: { name, upload: percentCompleted },
      });
    }
  };

  const uploadFile = useCallback(
    ({
      body,
      fileName,
      isLastFile,
    }: {
      fileName: string;
      isLastFile: boolean;
      body: {
        file: Blob;
        index: string;
        metadata?: string;
        mandatory_metadata?: string;
      };
    }) => {
      axios({
        method: 'post',
        url: '/upload',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: body,
        onUploadProgress: (event) => getUploadProgress(event, fileName),
      })
        .then((response: AxiosResponse) => {
          dispatch({
            type: 'uploadComplete',
            payload: {
              name: fileName,
              status: response.data.status,
              taskID: response.data.TaskID,
              processed: 'started',
            },
          });
          const params = {
            index: body.index,
            newTaskID: response.data.TaskID,
          };
          updateUploadedFileList({ ...params, key: 'filesPendingProcessing' });
          updateUploadedFileList({ ...params, key: 'uploadedFiles' });
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          dispatch({
            type: 'uploadError',
            payload: {
              name: fileName,
              status: `Something went wrong`,
            },
          });
        })
        .finally(() => isLastFile && processingStatusCheck(body.index));
    },
    [processingStatusCheck, updateUploadedFileList],
  );

  const uploadFiles = (files: Files | undefined) => {
    let metadata =
      (metadatas && metadatas[0] && JSON.parse(metadatas[0])) || [];

    let metadataNames: string[] =
      (metadata && metadata[0] && Object.keys(metadata[0])) || [];

    setOpenUploadDialog(true);

    if (files) {
      dispatch({ type: 'disableImport' });
      const newFiles = Object.values(files);
      newFiles.forEach(async (fileObj, idx) => {
        const {
          file,
          upload_progress: upload,
          upload_status: status,
        } = fileObj;

        let metadataFinalObj: any = [];
        let mandatoryMetadataFinalObj: any = [];

        metadataNames.forEach((elem) => {
          if (elem !== 'name') {
            mandatoryMetadatas.includes(elem)
              ? mandatoryMetadataFinalObj.push({
                  [elem]: metadata.find(
                    (e: { name: string }) => e.name === file.name,
                  )[elem],
                })
              : metadataFinalObj.push({
                  [elem]: metadata.find(
                    (e: { name: string }) => e.name === file.name,
                  )[elem],
                });
          }
        });

        if (upload === undefined || status !== 'Success') {
          dispatch({
            type: 'uploadProgress',
            payload: { name: file.name, upload: 0 },
          });

          let body: {
            file: Blob;
            index: string;
            metadata?: string;
            mandatory_metadata?: string;
          } = {
            file,
            index: currentIndex,
          };

          if (metadataFinalObj.length > 0)
            body['metadata'] = JSON.stringify(metadataFinalObj);

          if (mandatoryMetadataFinalObj.length > 0)
            body['mandatory_metadata'] = JSON.stringify(
              mandatoryMetadataFinalObj,
            );

          let mandatoryUnFilled: string[] = [];

          mandatoryMetadatas.forEach((e) => {
            if (
              mandatoryMetadataFinalObj &&
              mandatoryMetadataFinalObj.find(
                (el: { [x: string]: any }) => el && el[e] && el[e].length < 1,
              )
            )
              mandatoryUnFilled.push(e);
          });

          if (mandatoryUnFilled.length === 0) {
            const existingFile =
              documents.filter(
                (doc) => doc.doc_name.toLowerCase() === file.name.toLowerCase(),
              ).length > 0;
            const data = {
              body,
              fileName: file.name,
              isLastFile: idx === newFiles.length - 1,
            };
            uploadFile(data);
            // existingFile ? ()=>{} : uploadFile(data);
          } else {
            dispatch({
              type: 'mandatoryMetadata',
              payload: {
                name: file.name,
                status: 'Unfilled mandatory metadata.',
              },
            });
          }
        }
      });
    }
  };

  const removeFile = useCallback(
    (key: string) => dispatch({ type: 'removeFile', payload: key }),
    [],
  );

  const enableImport = useCallback(
    () => dispatch({ type: 'enableImport' }),
    [],
  );

  return (
    <FilesContext.Provider
      value={{
        state,
        openUploadDialog,
        currentIndex,
        documents,
        mandatoryMetadatas,
        linkedJobs,
        itemStatus,
        setDocuments,
        setCurrentIndex,
        dispatch,
        removeFile,
        getFileList,
        enableImport,
        uploadFiles,
        setMetadatas,
        setMandatoryMetadatas,
        updateJobUpdatesAvailableList,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
}
