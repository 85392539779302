import { GridColDef } from '@mui/x-data-grid';
import {
  containsSpecialCharsAndSpace,
  containsSpecialChars,
} from 'common/utils/common';
interface IGeneralCols {
  userPermission: boolean;
}
export default function ClientsCols({
  userPermission,
}: Readonly<IGeneralCols>): GridColDef[] {
  const handleInvalidChars = (value: string) => {
    const isNotValidString: boolean = containsSpecialCharsAndSpace(value);
    if (!!isNotValidString) {
      return `not allowed: \\/:*”’<>| space`;
    } else return value;
  };

  return [
    {
      field: 'subcategory',
      headerName: 'SUBCATEGORY',
      width: 175,
      editable: false,
      type: 'string',
      headerAlign: 'left',
    },
    {
      field: 'displayValue',
      headerName: 'DISPLAY VALUE',
      width: 151,
      editable: userPermission,
      type: 'string',
      headerAlign: 'left',
    },
    {
      field: 'configKey',
      headerName: 'ACTUAL KEY',
      width: 151,
      editable: false,
      type: 'string',
      headerAlign: 'left',
    },
    {
      field: 'configValue',
      headerName: 'CONFIG VALUE',
      width: 151,
      editable: userPermission,
      type: 'string',
      valueGetter: (params) => params?.value ?? '',
      renderCell: (params) =>
        !!params?.row.isSecret ? '*******' : (params?.value.toString() ?? ''),
      headerAlign: 'left',
    },
    {
      field: 'role',
      headerName: 'ROLE',
      width: 217,
      editable: userPermission,
      headerAlign: 'left',
      valueGetter: (params) => handleInvalidChars(params?.value),
      type: 'string',
    },
  ];
}
