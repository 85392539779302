import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface IPayload {
  [key: string]: string;
}

export default function PropsValsList({
  payload,
}: {
  payload: IPayload;
}): React.JSX.Element {
  const ShowPropsVals = Object.entries(payload).map(([key, value]) => (
    <ListItem key={key} alignItems="flex-start" disablePadding>
      <ListItemText>
        <b>{key}</b>: {value}
      </ListItemText>
    </ListItem>
  ));

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'fixed',
        bottom: 70,
        border: 1,
        borderRadius: 3,
        borderColor: 'black',
        spacing: 0,
      }}
    >
      {ShowPropsVals && ShowPropsVals.length > 0 ? (
        ShowPropsVals
      ) : (
        <p>Loading...</p>
      )}
    </List>
  );
}
