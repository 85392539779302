import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { UserProperties } from 'components/AppWrapper';
import { userRoles } from 'types';
import Page, { UnauthorizedPage } from 'components/Page';

interface PrivateRouteProps {
  children: React.JSX.Element;
  allowedUsers: userRoles[];
  privateRoute?: string;
}

const PrivateRoute = ({
  children,
  allowedUsers,
  privateRoute,
}: PrivateRouteProps): JSX.Element => {
  const user = useContext(UserProperties);
  const { index } = useParams();
  const isOpenRoute = index && index !== privateRoute;
  const isAuthorized = user.role && allowedUsers.includes(user.role);

  return (
    <>
      {isAuthorized || isOpenRoute ? (
        { ...children }
      ) : (
        <Page page={<UnauthorizedPage />} />
      )}
    </>
  );
};

export default PrivateRoute;
