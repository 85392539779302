import React from 'react';
import { IOrderedContent } from 'components/Page/DocumentPage';
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document as File,
  StyleSheet,
} from '@react-pdf/renderer';
import { decode } from 'html-entities';

type PdfViewProps = {
  currentChunk: IOrderedContent[];
  getDocument?: boolean;
};

export const pdfStyles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100%',
  },
  page: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export default function PdfView({
  currentChunk,
  getDocument,
}: PdfViewProps): React.JSX.Element {
  const document = (
    <File pageLayout="oneColumn">
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.section}>
          {currentChunk.map((data, idx) => (
            <React.Fragment key={idx}>
              {data.src && <Image src={data.src} />}
              {data.text && (
                <Text>{getDocument ? data.text : decode(data.text)}</Text>
              )}
            </React.Fragment>
          ))}
        </View>
      </Page>
    </File>
  );
  if (getDocument) return document;
  return <PDFViewer style={pdfStyles.viewer}>{document}</PDFViewer>;
}
