import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function DocGenPageHeader(): React.JSX.Element {
  return (
    <Grid
      container
      wrap="nowrap"
      borderBottom="1px solid #B3B2B5"
      height="80px"
      px={3}
      boxSizing={'border-box'}
      alignItems="center"
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
    >
      <Typography variant="h2">TaskGen Pro</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          color="primary"
          variant="outlined"
          component={Link}
          to={`/task-upload`}
        >
          Create Task
        </Button>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={`/job-upload`}
          startIcon={<AddIcon />}
        >
          Create Job
        </Button>
      </Box>
    </Grid>
  );
}
