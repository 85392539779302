import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

/**
 * This shows a horizontally and vertically centred loading spinner to use when a component is loading content to display
 */
export default function ComponentLoading(): React.JSX.Element {
  return (
    <Box height={'100%'}>
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        sx={{ minHeight: '100%', minWidth: '100%' }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    </Box>
  );
}
