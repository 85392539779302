import { createTheme, ThemeOptions } from '@mui/material/styles';
import grey from './colors/grey';
import purple from './colors/purple';
import type {} from '@mui/x-data-grid/themeAugmentation';

const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: purple['core2'],
      light: purple['core1'],
      dark: purple['core3'],
    },
    secondary: {
      main: '#333333',
      light: '#4b4b4b',
      dark: '#000000',
    },
    error: {
      main: '#cb2939',
      light: '#e98d96',
      dark: '#aa2230',
    },
    warning: {
      main: '#ed7012',
      light: '#ff9a62',
      dark: '#d0471b',
    },
    info: {
      main: '#0041f0',
      light: '#90abf2',
      dark: '#002ea8',
    },
    success: {
      main: '#3c8703',
      light: '#9cc952',
      dark: '#366313',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
    white: '#FFFFFF',
  },
  typography: {
    fontFamily: 'Graphik',
    color: grey[1000],
    h1: {
      fontSize: '1.125rem', // 18px
      lineHeight: '1.125rem', // 18px
      fontWeight: '600',
    },
    h2: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.5rem', // 24px
      fontWeight: '700',
    },
    h3: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.5625rem', // 25px
      fontWeight: '500',
    },
    h4: {
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '120%' /* 28.8px */,
      letterSpacing: '0.16px',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: '400',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%',
      letterSpacing: '0.4px',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: 1,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '2rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 2rem 2rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 2rem 2rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: 0,
          // paddingTop: '0.53125rem',
          // paddingBottom: '0.53125rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          borderRadius: '12px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standard: ({ theme }: { theme: any }) => ({
          backgroundColor: grey[1000],
          color: theme.palette.white,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'dark',
        },
      },
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  easing: {
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },
};

const theme = createTheme(themeOptions as ThemeOptions);

export { grey, purple };
export default theme;
