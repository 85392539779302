import { FilterValues, Filters, PopoverFilters } from 'types';
import { camelize, decamelize } from './stringStyler';

const getTableFilters = (tableFilters?: PopoverFilters) => {
  if (!tableFilters) {
    return { filters: undefined, initialFilterValues: undefined };
  }

  const filters: Filters = Object.keys(tableFilters).map((key) => {
    const title = decamelize(key);
    const options = tableFilters[key].options.map((option) => ({
      label: option,
      name: camelize(option),
    }));
    const multiselect = tableFilters[key].multiselect;
    const filter = { title, options, multiselect };
    return filter;
  });

  const initialFilterValues: FilterValues = Object.fromEntries(
    filters.map((filter) => [
      filter.title,
      Object.fromEntries(filter.options.map((option) => [option.name, false])),
    ]),
  );

  return { filters, initialFilterValues };
};

export default getTableFilters;
