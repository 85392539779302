import React, { useContext, useState } from 'react';
import {
  DialogActions,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Button,
  Grid,
  Typography,
  InputAdornment,
  TableCell,
  Chip,
} from '@mui/material';
import { Search, EditOutlined } from '@mui/icons-material';
import ConfirmationDialog from 'components/UI/ConfirmationDialog';
import { DeleteOutline } from 'mdi-material-ui';
import { useSavedSearches } from 'common/utils/savedSearchesContext';
import { SavedSearch } from 'common/utils/savedSearchesContext';
import SecureTextField from 'components/SecureTextField';
import ExpandableTableCell from './ExpandableTableCell';
import axios from 'api/axios';
import { showError } from 'api';
import { ConfigUserPermissions } from 'components/Page/ConfigPage/ConfigGeneral/RBAC';

interface SavedSearchesModalProps {
  open: boolean;
  onClose?: () => void;
  handleCreateChatFromSavedSearch?: (savedSearch: SavedSearch) => void;
  selectedChatEndpoint?: string;
}

type SelectableItem = string;

export const SavedSearchesModal: React.FC<SavedSearchesModalProps> = ({
  open,
  onClose,
}) => {
  const {
    savedSearches,
    deleteSavedSearch,
    updateSavedSearch,
    setModalChatEndpoint,
    endpointType,
    handleDeleteAllPrompts,
  } = useSavedSearches();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState<SelectableItem | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editMode, setEditMode] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const userPermissions = ConfigUserPermissions();
  const [editValues, setEditValues] = useState<{
    prompt_title: string;
    prompt_prefix: string;
    prompt_objective: string;
    prompt_suffix: string;
    prompt_template: string;
  }>({
    prompt_title: '',
    prompt_prefix: '',
    prompt_objective: '',
    prompt_suffix: '',
    prompt_template: '',
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const openConfirmationDialog = () => {
    setIsDeleteAll(true);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteAllPrompts();
    setConfirmDialogOpen(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleItemSelection = (id: string) => {
    setSelected(selected === id ? null : id);
  };

  const promptTitleFilteredSearches = searchTerm.trim()
    ? savedSearches.filter((search) =>
        search.prompt_title?.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : savedSearches;

  const handleRunSelectedSearch = () => {
    if (selected) {
      const search = savedSearches.find((item) => item.id === selected);
      if (search) {
        setModalChatEndpoint({
          prompt_title: search.prompt_title,
          prompt_objective: search.prompt_objective,
          prompt_suffix: search.prompt_suffix,
          prompt_prefix: search.prompt_prefix,
          prompt_template: search.prompt_template,
        });
        onClose && onClose();
      }
    }
  };

  const handleEditSelected = () => {
    if (selected) {
      const search = savedSearches.find((search) => search.id === selected);
      if (search) {
        setEditMode(selected as string);
        setEditValues({
          prompt_title: search.prompt_title || '',
          prompt_prefix: search.prompt_prefix || '',
          prompt_objective: search.prompt_objective || '',
          prompt_suffix: search.prompt_suffix || '',
          prompt_template: search.prompt_template || '',
        });
      }
    }
  };

  const handleInputChange = (value: string, field: string) => {
    setEditValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent, field: string) => {
    if (e.key === 'Enter') {
      const order = [
        'prompt_title',
        'prompt_objective',
        'prompt_prefix',
        'prompt_suffix',
        'prompt_template',
      ];
      const currentIndex = order.indexOf(field);
      if (currentIndex !== -1 && currentIndex < order.length - 1) {
        const nextField = order[currentIndex + 1];
        (document.getElementById(nextField) as HTMLInputElement)?.focus();
      } else if (currentIndex === order.length - 1) {
        saveEditValues();
      }
    }
  };

  const updateSavedPrompt = async (
    promptTitle: string,
    apiType: string,
    updatedData: SavedSearch,
  ) => {
    try {
      const { id, ...payloadWithoutId } = updatedData;

      const url = `/prompt/${apiType}/save`;

      await axios.post(url, {
        prompt_title: promptTitle,
        ...payloadWithoutId,
      });
    } catch (error) {
      showError(error);
    }
  };

  const saveEditValues = () => {
    if (editMode) {
      const updatedSearch: SavedSearch = {
        id: editMode,
        prompt_title: editValues.prompt_title,
        prompt_prefix: editValues.prompt_prefix,
        prompt_objective: editValues.prompt_objective,
        prompt_suffix: editValues.prompt_suffix,
        prompt_template: editValues.prompt_template,
      };

      updateSavedSearch(editMode, updatedSearch); //updates in the context
      updateSavedPrompt(editValues.prompt_title, endpointType, updatedSearch); // updates the prompt in the backend

      setEditMode(null);
      setEditValues({
        prompt_title: '',
        prompt_objective: '',
        prompt_suffix: '',
        prompt_prefix: '',
        prompt_template: '',
      });
      setSelected(null);
    }
  };

  return (
    <>
      {/* <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth> */}
      <form>
        {/* <DialogContent> */}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          marginBottom="16px"
        >
          <Grid container sx={{ justifyContent: 'flex-end' }} spacing={1}>
            <Grid item>
              <IconButton onClick={handleEditSelected}>
                <EditOutlined />
              </IconButton>
            </Grid>
            {userPermissions.isQuasarAdmin && (
              <Grid item>
                {savedSearches.length > 0 && (
                  <IconButton onClick={openConfirmationDialog}>
                    <DeleteOutline />
                  </IconButton>
                )}
              </Grid>
            )}
            <ConfirmationDialog
              open={confirmDialogOpen}
              title="Delete confirmation"
              content="Are you sure to delete all prompts?"
              leftButtonProps={{
                name: 'Delete prompts',
                onClick: handleConfirmDelete,
                type: 'button',
              }}
              rightButtonProps={{
                name: 'Cancel',
                onClick: () => setConfirmDialogOpen(false),
              }}
            />
            <Grid item>
              <SecureTextField
                variant="outlined"
                size="small"
                placeholder="Search by Prompt Title"
                value={searchTerm}
                onUpdate={(v) => setSearchTerm(v)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'none', borderBottom: '1px solid transparent' }}
        >
          <Table>
            <TableHead sx={{ fontWeight: 'bold' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                  Prompt Title
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                  Prompt Objective
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                  Prompt Prefix
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                  Prompt Suffix
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                  Prompt Template
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: 'hidden' }}>
              {promptTitleFilteredSearches
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((search) => (
                  <TableRow
                    key={search.id}
                    onClick={() => handleItemSelection(search.id)}
                    selected={selected === search.id}
                    sx={{
                      cursor: 'pointer',
                      border:
                        selected === search.id
                          ? '1.5px solid #a100ff'
                          : 'inherit',
                      backgroundColor:
                        selected === search.id ? 'white !important' : 'inherit',
                      padding: '16px',
                    }}
                  >
                    <ExpandableTableCell>
                      {editMode === search.id ? (
                        <SecureTextField
                          id="prompt_title"
                          value={editValues.prompt_title}
                          onUpdate={(v) => handleInputChange(v, 'prompt_title')}
                          onKeyDown={(e) => handleKeyDown(e, 'prompt_title')}
                          autoFocus
                        />
                      ) : (
                        <>
                          <Typography>{search.prompt_title}</Typography>
                          <Typography sx={{ fontSize: '12px' }}>
                            added by {search.user_name}
                          </Typography>
                          {search.name && (
                            <Chip
                              variant="outlined"
                              color="primary"
                              label={search.name}
                              size="small"
                              sx={{ marginTop: '8px' }}
                            />
                          )}
                        </>
                      )}
                    </ExpandableTableCell>
                    <ExpandableTableCell>
                      {editMode === search.id ? (
                        <SecureTextField
                          id="prompt_objective"
                          value={editValues.prompt_objective}
                          onUpdate={(v) =>
                            handleInputChange(v, 'prompt_objective')
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, 'prompt_objective')
                          }
                        />
                      ) : (
                        search.prompt_objective
                      )}
                    </ExpandableTableCell>
                    <ExpandableTableCell>
                      {editMode === search.id ? (
                        <SecureTextField
                          id="prompt_prefix"
                          value={editValues.prompt_prefix}
                          onUpdate={(v) =>
                            handleInputChange(v, 'prompt_prefix')
                          }
                          onKeyDown={(e) => handleKeyDown(e, 'prompt_prefix')}
                        />
                      ) : (
                        search.prompt_prefix
                      )}
                    </ExpandableTableCell>
                    <ExpandableTableCell>
                      {editMode === search.id ? (
                        <SecureTextField
                          id="prompt_suffix"
                          value={editValues.prompt_suffix}
                          onUpdate={(v) =>
                            handleInputChange(v, 'prompt_suffix')
                          }
                          onKeyDown={(e) => handleKeyDown(e, 'prompt_suffix')}
                        />
                      ) : (
                        search.prompt_suffix
                      )}
                    </ExpandableTableCell>
                    <ExpandableTableCell>
                      {editMode === search.id ? (
                        <SecureTextField
                          id="prompt_template"
                          value={editValues.prompt_template}
                          onUpdate={(v) =>
                            handleInputChange(v, 'prompt_template')
                          }
                          onKeyDown={(e) => handleKeyDown(e, 'prompt_template')}
                        />
                      ) : (
                        search.prompt_template || 'N/A'
                      )}
                    </ExpandableTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={promptTitleFilteredSearches.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* </DialogContent> */}
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRunSelectedSearch}
            disabled={!selected}
          >
            Use Prompt
          </Button>
        </DialogActions>
        {/* </Dialog> */}
      </form>
      {/* {addConfigOpen && (
        <ChatEndpointsForm
          createPrompt={true}
          chatEndpoint={chatEndpoint}
          handleCloseForm={handleCloseForm}
          updateEndpointConfig={updateChatEndpoint}
        />
      )} */}
    </>
  );
};

export default SavedSearchesModal;
