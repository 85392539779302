import React, { useMemo, useState, useEffect, useContext } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { SnackbarContext } from 'components/SnackbarProvider';
import EditableMetadataCard from 'components/EditableMetadataCard';
import AddColumnDialog from 'components/AddColumnDialog';
import {
  MetadataTabs,
  Tab,
  TabContent,
  BadgeNumber,
  MetadataCards,
} from './MetadataSettings.styles';
import MetadataCard from '../MetadataCard/MetadataCard';
import {
  saveMetadata,
  getAllMetadatas,
  updateMetadata,
} from 'components/MetadataProvider';
import ComponentLoading from 'components/ComponentLoading';
import { MAX_METADATA_LIMIT } from 'components/CreateCollectionDialog';

type MetadataSettingsProps = {
  collection: string;
};

export type Column = {
  key: number;
  status: boolean;
  name: string;
  items: string[];
  edit: boolean;
  mandatory: boolean;
};

export type IMetadataSettings = Column[];

const initialState: IMetadataSettings = [
  {
    key: 0,
    status: true,
    name: 'Category',
    items: ['Item 1', 'Item 2', 'Item 3'],
    edit: false,
    mandatory: false,
  },
  {
    key: 1,
    status: true,
    name: 'Industry',
    items: ['Item 1', 'Item 2'],
    edit: false,
    mandatory: false,
  },
  {
    key: 2,
    status: false,
    name: 'Sub-Industry',
    items: ['Item 1'],
    edit: false,
    mandatory: false,
  },
];

export default function MetadataSettings({
  collection,
}: MetadataSettingsProps): React.JSX.Element {
  const navigate = useNavigate();
  const [tab, setTab] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [metadataSettings, setMetadataSettings] = useState<IMetadataSettings>(
    [],
  );
  const [newItem, setNewItem] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const createAlert = useContext(SnackbarContext);
  // const [metadataToDelete, setMetadataToDelete] = useState<
  //   { name: string; value: string[] }[]
  // >([]);

  const activeTabs = useMemo(
    () => metadataSettings.filter((column) => column.status).length,
    [metadataSettings],
  );

  const isMetadataLimitReached = useMemo(
    () => activeTabs >= MAX_METADATA_LIMIT,
    [activeTabs],
  );

  useEffect(() => {
    setLoading(true);
    getAllMetadatas(true, collection)
      .then((response: any) => {
        setMetadataSettings(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const updateList = () => {
    setLoading(true);

    getAllMetadatas(true, collection)
      .then((response: any) => {
        setMetadataSettings(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleAddColumn = (newColumnName: string | null) => {
    if (!newColumnName) return;
    setNewItem(true);
    setMetadataSettings((prev) => {
      const newColumn = {
        key: metadataSettings.length,
        status: true,
        name: newColumnName,
        items: [''],
        edit: true,
        mandatory: false,
      };
      return [...prev, newColumn];
    });
  };

  const handleChangeTab = (newTab: boolean) => {
    setTab((prev) => {
      if (prev === newTab) return prev;
      return newTab;
    });
  };

  const handleEditColumn = ({
    key,
    status,
  }: {
    key: number;
    status: boolean;
  }) => {
    setMetadataSettings((prev) =>
      prev.map((column) => {
        if (column.key === key) {
          return { ...column, edit: status };
        }
        return column;
      }),
    );
  };

  const handleDeleteItem = (key: number) => {
    let objectToDelete = metadataSettings.find((item) => item.key === key);

    setMetadataSettings((prev) => {
      return prev.filter((column) => column.key !== key);
    });

    // if (objectToDelete?.name) deleteMetadata(objectToDelete.name);
  };

  const handleCancel = (key: number) => {
    metadataSettings.forEach((column) => {
      if (column.key !== key) return;
      const isEmpty = (currentValue: string) => currentValue === '';
      const itContainsEmptyTag = column.items.every(isEmpty);
      if (itContainsEmptyTag) return handleDeleteItem(key);
    });
    handleEditColumn({ key, status: false });
  };

  const handleSave = async (updatedColumn: Column) => {
    let exists: boolean = false;
    setMetadataSettings((prev) =>
      prev.map((column) => {
        if (column.key === updatedColumn.key) {
          exists = true;
          return { ...updatedColumn, edit: false };
        }
        return column;
      }),
    );
    //Code to save on API
    try {
      !newItem
        ? await updateMetadata(updatedColumn.name, updatedColumn.items)
        : await saveMetadata(updatedColumn.name, updatedColumn.items);

      createAlert({
        message: 'Metadata saved with success.',
        severity: 'success',
      });
    } catch (err) {
      const metadatas: any = await getAllMetadatas(true);
      setMetadataSettings(metadatas);
      createAlert({
        message: 'Error on save metadata.',
        severity: 'error',
      });
    }
  };

  if (loading) {
    return <ComponentLoading />;
  }

  return (
    <>
      <Typography variant="h3" my={3}>
        Metadata
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Typography mb={3}>
        Manage metadata columns and tags for this collection below.
      </Typography>
      <MetadataTabs>
        <Tab active={tab} onClick={() => handleChangeTab(true)}>
          <TabContent>
            <div className="title">Selected</div>
            <div className="badge">
              <BadgeNumber>{`${activeTabs}`}</BadgeNumber>
            </div>
          </TabContent>
        </Tab>
        <Tab active={!tab} onClick={() => handleChangeTab(false)}>
          <TabContent
            sx={{
              width: '100%',
            }}
          >
            <div></div>
            <div className="title">Available</div>
            <div
              style={{
                alignSelf: 'center',
                justifySelf: 'center',

                display: '',
              }}
            >
              <Button
                disabled={tab}
                sx={{
                  alignSelf: 'center',
                  justifySelf: 'center',
                  height: '15px',
                }}
                onClick={() => {
                  navigate('/metadata');
                }}
              >
                Manage metadata
              </Button>
            </div>
          </TabContent>
        </Tab>
      </MetadataTabs>
      <MetadataCards>
        {metadataSettings.map((column) => (
          <React.Fragment key={column.key}>
            {tab === column.status && (
              <>
                {column.edit ? (
                  <EditableMetadataCard
                    column={column}
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                  />
                ) : (
                  <MetadataCard
                    column={column}
                    setMetadataSettings={setMetadataSettings}
                    index={collection}
                    updateList={updateList}
                    isMetadataLimitReached={isMetadataLimitReached}
                    setLoading={setLoading}
                  />
                )}
              </>
            )}
          </React.Fragment>
        ))}
        {tab && <></>}
      </MetadataCards>
      <AddColumnDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleAddColumn={handleAddColumn}
      />
    </>
  );
}
