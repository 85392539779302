import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SnackbarContext } from 'components/SnackbarProvider';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'api/axios';

import {
  GenericInputs,
  FormData,
  CreateAsyncJobRequest,
  Job,
  DocGenFile,
} from 'types';
import { toBase64 } from 'common/utils';
import DocGenUpload from 'components/DocGenUpload';
import { titleCase } from 'common/utils/stringStyler';
import { getFequentTask } from 'api';

export const overwriteInputName = 'overwrite';
export const bypassInputName = 'bypass';

const defaultFormValues = {
  collection: [] as [],
  template: '',
  checkbox: false,
  file: undefined,
};

const TOOLTIPS = {
  name: 'Name your job (optional).',
  task: 'Describe the output such as type of document, sections details, tables, diagrams.',
  template: 'Describe what type of document is the template.',
  collections: 'Select the collections that will be used as inputs.',
};

const createInput = (
  newInputId: number,
  disableInputs?: number[],
): GenericInputs => {
  const inputs = [
    {
      id: 'name_id',
      name: 'name',
      label: 'Name',
      type: 'text',
      multiline: true,
      tooltip: TOOLTIPS.name,
    },
    {
      id: 'task_id',
      name: 'task',
      label: 'Task',
      type: 'text',
      multiline: true,
      tooltip: TOOLTIPS.task,
      required: true,
      helperText: 'Plese provide a task description',
    },
    {
      id: `template_${newInputId}_id`,
      name: `template${newInputId}`,
      label: `Template`,
      type: 'text',
      tooltip: TOOLTIPS.template,
      required: true,
      helperText: 'Plese provide a template name',
    },
    {
      id: `file_${newInputId}_id`,
      name: `file${newInputId}`,
      label: `File ${newInputId}`,
      type: 'file',
      disabled: disableInputs?.includes(newInputId),
      required: true,
      helperText: 'Plese provide a file',
    },
    {
      id: `checkbox_${newInputId}_id`,
      name: `checkbox${newInputId}`,
      label: 'Generate',
      type: 'checkbox',
    },
    {
      id: `collection__id`,
      name: `collection`,
      label: `Collections`,
      type: 'multiselect',
      tooltip: TOOLTIPS.collections,
      required: true,
      helperText: 'Plese select at least one source collection',
    },
    {
      id: `${overwriteInputName}_id`,
      name: overwriteInputName,
      label: titleCase(overwriteInputName),
      type: 'checkbox',
      description:
        'Checking this box will overwrite any existing sources in the job data with the provided collections.',
    },
    {
      id: `${bypassInputName}_id`,
      name: bypassInputName,
      label: titleCase(bypassInputName),
      type: 'checkbox',
      description:
        'Checking this box will create or update the job without running the starting tasks.',
    },
  ];

  return inputs;
};

const initialFormState: FormData = {
  task: '',
  name: '',
  template1: 'Template',
  collection: [] as [],
  checkbox1: false,
  [overwriteInputName]: false,
  [bypassInputName]: false,
  file1: undefined,
};

const initialInputs: GenericInputs = createInput(1);

export default function JobUploadPage(): React.JSX.Element {
  const navigate = useNavigate();
  const { hash } = useParams();
  const { state } = useLocation();
  const createAlert = useContext(SnackbarContext);
  const [formInputs, setFormInputs] = useState(initialInputs);
  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(true);
  const [originalJobData, setOriginalJobData] =
    useState<null | CreateAsyncJobRequest>(null);

  const handleClose = useCallback(() => navigate(`/taskgen-pro`), [navigate]);

  const getPayload = useCallback(
    (
      newObj: CreateAsyncJobRequest,
      originalObj: CreateAsyncJobRequest,
    ): CreateAsyncJobRequest => {
      const payload: CreateAsyncJobRequest = {
        hash: newObj.hash, // Include the hash property by default
      };

      // Include overwrite and bypass_initialise_task if they are true
      if (newObj.overwrite) {
        payload.overwrite = newObj.overwrite;
      }
      if (newObj.bypass_initialise_task) {
        payload.bypass_initialise_task = newObj.bypass_initialise_task;
      }

      // Properties to omit in the comparison
      const propertiesToOmit = ['overwrite', 'bypass_initialise_task'];

      // Function to validate input_collections equality
      function validateInputCollections(
        arr1: string[],
        arr2: string[],
      ): boolean {
        return arr1.sort().join(',') === arr2.sort().join(',');
      }

      Object.keys(newObj).forEach((key) => {
        if (propertiesToOmit.includes(key)) return;

        // Specific validation for input_collections
        if (key === 'input_collections') {
          if (
            !validateInputCollections(
              newObj[key] as string[],
              originalObj[key] as string[],
            )
          ) {
            payload[key] = newObj[key];
          }
          return;
        }

        // Detailed comparison of the template
        if (key === 'template') {
          let templateChanged = false;
          Object.keys(newObj[key] as DocGenFile).forEach((templateKey) => {
            if (
              (newObj[key] as DocGenFile)[templateKey as keyof DocGenFile] !==
              (originalObj[key] as DocGenFile)[templateKey as keyof DocGenFile]
            ) {
              templateChanged = true;
            }
          });
          if (templateChanged) payload[key] = newObj[key];
          return;
        }

        // General comparison of other properties
        const objKey = key as keyof CreateAsyncJobRequest;
        if (newObj[objKey] !== originalObj[objKey]) {
          payload[objKey] = newObj[objKey] as undefined;
        }
      });

      return payload;
    },
    [],
  );

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      setLoading(true);

      const inputs = await Promise.all(
        [1].map(async (id) => {
          const nameKey = `template${id}`;
          const checkboxKey = `checkbox${id}`;
          const fileKey = `file${id}`;
          const isVariableAString = (propertie: string | File) =>
            typeof propertie === 'string' || propertie instanceof String;
          const file = formData[fileKey] as File;
          const base64File = formData[checkboxKey]
            ? ''
            : isVariableAString(file)
              ? file
              : await toBase64(file);
          const newInput = {
            name: formData[nameKey] as string,
            file_name: formData[checkboxKey]
              ? 'generate'
              : (file.name ?? formData['fileName']),
            file: base64File as string,
          };
          return newInput;
        }),
      );

      const body: CreateAsyncJobRequest = {
        task_description: formData['task'] as string,
        name: formData['name'] as string,
        input_collections: formData['collection'] as string[],
        template: inputs[0],
        overwrite: formData[overwriteInputName] as boolean, // Provisional value
        bypass_initialise_task: formData[bypassInputName] as boolean, // Provisional value
      };

      const data =
        state?.editJob && originalJobData
          ? getPayload({ ...body, hash }, originalJobData)
          : body;

      await axios({
        method: 'post',
        url: '/createasyncjob',
        data,
      })
        .then((_response: AxiosResponse) => {
          createAlert({
            message: `Job ${body.hash ? 'updated' : 'created'} successfully`,
            severity: 'success',
          });
          navigate(`/taskgen-pro`);
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Unable to ${body.hash ? 'update' : 'create'} the job`,
            severity: 'error',
          });
        })
        .finally(() => setLoading(false));
    },
    [state, hash, originalJobData, navigate, getPayload, createAlert],
  );

  const prepopulateForm = useCallback(() => {
    const onError = () => {
      createAlert({
        message: `There was a problem trying to get the job data`,
        severity: 'error',
      });
    };

    const onSuccess = (data: Job[]) => {
      const selectedJob = data.find((job) => job.hash === hash);
      if (!selectedJob) return onError();
      let newFormState: FormData = {
        ...initialFormState,
        task: selectedJob.task,
        name: selectedJob.name ?? selectedJob.task,
        template1: selectedJob.template_name,
        collection: selectedJob.input_collections as [],
        file1: selectedJob.template_file,
        checkbox1: !selectedJob.template_file,
      };

      // Use file name if it's provided
      if (!!selectedJob.template_file) {
        newFormState['fileName'] = selectedJob.template_file_path;
      } else {
        // Disable file input if file is provided
        setFormInputs((prev) =>
          prev.map((input) =>
            input.name === 'file1' ? { ...input, disabled: true } : input,
          ),
        );
      }

      setFormState(newFormState);

      // Save original Job data to know in wich data has been modified
      const originalJobData: CreateAsyncJobRequest = {
        task_description: selectedJob.task,
        name: selectedJob.name,
        input_collections: selectedJob.input_collections as [],
        template: {
          name: selectedJob.template_name,
          file_name: selectedJob.template_file_path,
          file: selectedJob.template_file,
        },
      };

      setOriginalJobData(originalJobData);
      setLoading(false);
    };

    getFequentTask({
      list: 'jobs',
      onSuccess,
      onError,
    });
  }, [hash, createAlert]);

  useEffect(() => {
    hash ? prepopulateForm() : setLoading(false);
  }, [hash, prepopulateForm]);

  return (
    <DocGenUpload
      page={'jobs'}
      loading={loading}
      hash={hash}
      editJob={state?.editJob}
      defaultFormValues={defaultFormValues}
      formInputs={formInputs}
      initialFormState={formState}
      createInput={createInput}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      setFormInputs={setFormInputs}
    />
  );
}
