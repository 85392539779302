import { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import SelectedResponseCard from '../SelectedResponseCard';
import { ChatCompletionDTO } from 'api';
import { AvailableChats } from 'types';
import { ChatContext } from 'components/Page/ChatPage';

type GenerateDocumentType = {
  display: boolean;
  messages: ChatCompletionDTO[];
  chatName: string;
};

const GenerateDocument = (props: GenerateDocumentType) => {
  const context = useContext(ChatContext);
  let availableChats: AvailableChats[] | undefined = context?.availableChats;
  const messagesToReview = context?.messagesToReview;
  const setMessagesToReview = context?.setMessagesToReview;

  const removeItem = (id: string) => {
    setMessagesToReview &&
      setMessagesToReview((prev) => {
        prev = prev?.filter((elem) => elem.id !== id);
        return prev;
      });
  };

  useEffect(() => {}, [availableChats]);

  return (
    <Grid
      item
      display={!props.display ? 'none' : ''}
      sx={{
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
        height: 'fullHeight',
        width: 'fullWidth',
        maxWidth: 400,
        minWidth: 400,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Typography
        sx={{
          width: '398px',
          textAlign: 'center',
          color: '#03000F',
          fontFamily: 'Graphik',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '150%',

          marginTop: '24px',
        }}
      >
        Selected Responses
      </Typography>

      {messagesToReview &&
        messagesToReview.map((elem) => {
          return (
            <SelectedResponseCard
              key={crypto.randomUUID()}
              message={{ message: elem.message, timestamp: elem.timestamp }}
              chatName={elem.chatName}
              removeItem={() => removeItem(elem.id)}
            />
          );
        })}
    </Grid>
  );
};

export default GenerateDocument;
