import React from 'react';

export const HtmlIcon = () => {
  return (
    <svg viewBox="0 0 200 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M139.67 49.24C134.203 49.2347 128.961 47.0606 125.095 43.1947C121.229 39.3288 119.055 34.0871 119.05 28.62V0H26.9099C19.7747 -4.92665e-07 12.9316 2.83379 7.88525 7.87823C2.83894 12.9227 0.00265152 19.7648 0 26.9V189.12C0.0079507 196.252 2.84659 203.089 7.89233 208.129C12.9381 213.169 19.7781 216 26.9099 216H137.51C141.045 216.001 144.545 215.306 147.811 213.954C151.077 212.603 154.045 210.621 156.545 208.122C159.045 205.623 161.028 202.656 162.381 199.39C163.734 196.125 164.43 192.625 164.43 189.09V49.24H139.67Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M164.43 49.24H139.67C134.203 49.2347 128.961 47.0606 125.095 43.1947C121.229 39.3288 119.055 34.0871 119.05 28.62V0L164.43 49.24Z"
        fill="#00C650"
      />
      <path
        d="M183.94 170.6H58.8997C50.03 170.6 42.8398 177.79 42.8398 186.659V223.939C42.8398 232.809 50.03 240 58.8997 240H183.94C192.809 240 200 232.809 200 223.939V186.659C200 177.79 192.809 170.6 183.94 170.6Z"
        fill="#00C650"
      />
      <path d="M67.9998 213.37V220.64H60.4697V213.37H67.9998Z" fill="white" />
      <path
        d="M97.8201 190.57V220.64H90.5V208.09H79.1001V220.64H71.8301V190.57H79.1001V202.22H90.5V190.57H97.8201Z"
        fill="white"
      />
      <path
        d="M124.44 190.57V196.35H116.28V220.64H109V196.35H100.92V190.57H124.44Z"
        fill="white"
      />
      <path
        d="M161.32 190.78V220.64H154V202L147.54 220.67H141.24L134.74 201.96V220.67H127.46V190.81H136.35L144.48 211.61L152.43 190.81L161.32 190.78Z"
        fill="white"
      />
      <path
        d="M173.611 215.07H183.311V220.64H166.311V190.57H173.591L173.611 215.07Z"
        fill="white"
      />
      <path
        d="M41.6393 114.19C41.6283 113.759 41.7622 113.336 42.0194 112.99C42.3228 112.6 42.6876 112.262 43.0995 111.99C43.7225 111.665 44.3673 111.385 45.0294 111.15L46.9594 110.35L54.6793 107.1C55.7527 106.6 57.7527 105.767 60.6793 104.6L64.3495 103.1L65.6693 102.63L65.8993 102.53C65.9619 102.516 66.027 102.516 66.0895 102.53C66.2373 102.464 66.3975 102.429 66.5595 102.43C66.8691 102.414 67.1788 102.46 67.4706 102.564C67.7624 102.669 68.0306 102.831 68.2594 103.04C68.4502 103.224 68.6024 103.445 68.7072 103.689C68.8119 103.932 68.8671 104.195 68.8695 104.46V107C68.8606 107.473 68.7107 107.932 68.4393 108.32C68.1672 108.757 67.7547 109.088 67.2694 109.26L51.4994 115.71L67.2694 122.16C67.7376 122.351 68.1429 122.67 68.4393 123.08C68.7211 123.451 68.8725 123.904 68.8695 124.37V126.91C68.8634 127.451 68.6441 127.969 68.2594 128.35C68.0345 128.566 67.7675 128.734 67.475 128.842C67.1825 128.95 66.8709 128.997 66.5595 128.98C66.3986 128.979 66.2393 128.949 66.0895 128.89L65.6693 128.75L64.3495 128.28L60.6793 126.77L54.6793 124.28L46.9594 121.03L45.0294 120.23C44.3994 119.98 43.7595 119.7 43.0995 119.38C42.6887 119.189 42.3365 118.892 42.0797 118.519C41.8229 118.147 41.6707 117.712 41.6393 117.26V114.19Z"
        fill="#00C650"
      />
      <path
        d="M73.2202 131.7C72.5968 131.721 71.9876 131.511 71.5103 131.11C71.2978 130.945 71.1254 130.735 71.0059 130.494C70.8864 130.253 70.8229 129.988 70.8203 129.72C70.8203 129.34 71.9502 126.72 74.2102 121.72L77.5103 114.47L78.2102 112.96L83.5303 101.15L85.4102 97.0597L85.7903 96.1197C85.936 95.7727 86.11 95.4383 86.3103 95.1197C86.5742 94.6867 86.9484 94.3316 87.3948 94.0908C87.8411 93.8501 88.3435 93.7324 88.8503 93.7497H91.2002C91.8266 93.7283 92.4388 93.9384 92.9202 94.3397C93.1308 94.5053 93.3014 94.7163 93.4192 94.957C93.5369 95.1976 93.5989 95.4618 93.6003 95.7297C92.7998 98.0615 91.858 100.342 90.7803 102.56L90.2603 103.69L86.2603 112.44L80.9402 124.25L79.0603 128.35L78.6802 129.29C78.5457 129.665 78.3749 130.027 78.1702 130.37C77.8868 130.796 77.5016 131.144 77.0493 131.384C76.597 131.623 76.092 131.745 75.5803 131.74L73.2202 131.7Z"
        fill="#00C650"
      />
      <path
        d="M97.93 129C97.6123 129.02 97.2938 128.974 96.9945 128.866C96.6951 128.757 96.4213 128.589 96.19 128.37C95.9969 128.183 95.8434 127.959 95.7386 127.712C95.6338 127.464 95.5799 127.198 95.5802 126.93V124.39C95.5756 123.929 95.7154 123.477 95.9801 123.1C96.2792 122.68 96.6971 122.36 97.18 122.18L112.99 115.73L97.18 109.28C96.6985 109.067 96.2888 108.72 96.0001 108.28C95.7345 107.898 95.5884 107.445 95.5802 106.98V104.4C95.5805 104.132 95.6365 103.868 95.745 103.623C95.8535 103.379 96.0119 103.159 96.2101 102.98C96.6818 102.56 97.2991 102.341 97.93 102.37C98.0795 102.366 98.2277 102.4 98.3602 102.47C98.4462 102.455 98.5342 102.455 98.6202 102.47L98.8302 102.57C98.7602 102.57 99.1902 102.69 100.1 103.04L103.82 104.54C103.627 104.453 105.627 105.286 109.82 107.04C112.107 107.98 114.727 109.093 117.68 110.38C117.87 110.44 118.12 110.54 118.44 110.66L119.52 111.09C120.154 111.316 120.766 111.597 121.35 111.93C121.772 112.119 122.132 112.423 122.39 112.807C122.647 113.19 122.793 113.638 122.81 114.1V117.2C122.806 117.626 122.666 118.039 122.41 118.38C122.133 118.77 121.771 119.092 121.35 119.32C120.79 119.6 120.11 119.9 119.35 120.22L117.35 121.02L99.93 128.31C99.43 128.56 99.1301 128.72 99.0401 128.78L98.8502 128.92L97.93 129Z"
        fill="#00C650"
      />
    </svg>
  );
};

export default HtmlIcon;
