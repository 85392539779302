import { Inputs, FormValues, InputNames } from 'types';

const inputs: Inputs = [
  {
    key: `${crypto.randomUUID()}`,
    id: 'name_id',
    name: 'config_name',
    label: 'Name',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'source_id',
    name: 'source_type',
    label: 'Source',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'pull_interval_id',
    name: 'pull_interval',
    label: 'Pull Frequency',
    type: 'number',
    pattern: '[0-24]',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'source_folder_id',
    name: 'source_folder',
    label: 'Source Folder',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'index_name_id',
    name: 'index_name',
    label: 'Collection',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'subcategory_id',
    name: 'subcategory',
    label: 'Configuration',
    options: [],
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'region_id',
    name: 'region',
    label: 'Region',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'bucket_name_id',
    name: 'bucket_name',
    label: 'Bucket Name',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'container_name_id',
    name: 'container_name',
    label: 'Container Name',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'base_url_id',
    name: 'base_url',
    label: 'Base URL',
    type: 'url',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'space_key_id',
    name: 'space_key',
    label: 'Space Key',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'page_title_id',
    name: 'page_title',
    label: 'Page Title',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'access_token_id',
    name: 'access_token',
    label: 'Access Token',
    type: 'text',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'is_enabled_id',
    name: 'enabled',
    label: 'Enabled',
    type: 'boolean',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'keep_folder_path_id',
    name: 'keep_folder_path',
    label: 'Keep Folder Path',
    type: 'boolean',
  },
  {
    key: `${crypto.randomUUID()}`,
    id: 'pull_entire_structure_id',
    name: 'pull_entire_structure',
    label: 'Pull Entire Structure',
    type: 'boolean',
  },
];

const configs: FormValues[] = [
  {
    key: `${crypto.randomUUID()}`,
    config_name: '',
    source_type: 'aws_s3',
    pull_interval: 0,
    region: '',
    bucket_name: '',
    source_folder: '',
    index_name: '',
    subcategory: '',
    enabled: true,
    verify: true,
    pull_entire_structure: false,
  },
  {
    key: `${crypto.randomUUID()}`,
    config_name: '',
    source_type: 'azure_blob',
    pull_interval: 0,
    container_name: '',
    source_folder: '',
    index_name: '',
    subcategory: '',
    enabled: true,
    verify: true,
  },
  {
    key: `${crypto.randomUUID()}`,
    config_name: '',
    source_type: 'confluence',
    pull_interval: 0,
    base_url: '',
    space_key: '',
    page_title: '',
    source_folder: '',
    index_name: '',
    subcategory: '',
    enabled: true,
    verify: true,
  },
  {
    key: `${crypto.randomUUID()}`,
    config_name: '',
    source_type: 'sharepoint',
    pull_interval: 0,
    base_url: '',
    source_folder: '',
    index_name: '',
    subcategory: '',
    enabled: true,
    verify: true,
    keep_folder_path: false,
    pull_entire_structure: false,
  },
  {
    key: `${crypto.randomUUID()}`,
    config_name: '',
    source_type: 'gdrive',
    pull_interval: 0,
    index_name: '',
    source_folder: '',
    subcategory: '',
    enabled: true,
    access_token: '',
    verify: true,
  },
];

export const getEmptyConfig = ({
  source_type,
  index,
}: {
  source_type: string;
  index: string;
}): FormValues[] => {
  const selectedConfig = configs
    .filter((c) => c.source_type === source_type)
    .map((c) => ({
      ...c,
      index_name: index,
      key: crypto.randomUUID(),
    }))[0];
  return [selectedConfig];
};

export const getFormInputs = ({
  subCategories,
  initialValues,
}: {
  subCategories: string[];
  initialValues: FormValues;
}) => {
  const selectedInputNames = Object.keys(initialValues) as InputNames;
  const selectedInputs: Inputs = inputs
    .filter((i) => selectedInputNames.includes(i.name))
    .map((i) => {
      if (i.name === 'subcategory') {
        const sourceType = initialValues.source_type;
        const options = subCategories.filter(
          (option) =>
            option.startsWith(`${sourceType}_`) ||
            option === 'multisource_custom_01',
        );
        return { ...i, options };
      }
      return i;
    });

  const formInputs = { key: initialValues.key, inputs: selectedInputs };
  return formInputs;
};
