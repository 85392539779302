const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      if (!result) return reject();
      const searchTerm = ';base64,';
      const searchTermLength = searchTerm.length;
      const indexOfFirst = result.indexOf(searchTerm);
      const stringStart = indexOfFirst + searchTermLength;
      const base64String = result.slice(stringStart);
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
export default toBase64;
