/**
 * Converts a raw byte size number to a nicely formatted size with max of 2 decimal places, e.g. 3.14MB.
 */
export default function byteSizeConverter(bytes: number) {
  const kilobyteUnit = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes === 0) return '0 Byte';

  const i = Math.floor(Math.log(bytes) / Math.log(kilobyteUnit));
  const convertedSize = `${parseFloat(
    (bytes / Math.pow(kilobyteUnit, i)).toFixed(2),
  )} ${sizes[i]}`;

  return convertedSize;
}
