import React, { useRef } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { IConfigUserPermissions, IEssentials } from '../interfaces';
import { TypesList } from '../components';
import { Typography, Grid } from '@mui/material';

type TMsgBoard = {
  alertMSG: string;
  createNewConfig: () => void;
  subcategory: string;
  userPermissions: IConfigUserPermissions;
  essentialData: IEssentials;
  handleChangeSelectedType: (value: string) => void;
  selectedType: string;
};

export const MessageBoard = (props: TMsgBoard): React.JSX.Element => {
  // const userPermissions = ConfigUserPermissions();

  const {
    alertMSG,
    createNewConfig,
    subcategory,
    userPermissions,
    essentialData,
    handleChangeSelectedType,
    selectedType,
  } = props;

  const showTypesOptions: boolean =
    !!essentialData.type &&
    Object.keys(essentialData.type).filter((key) =>
      Array.isArray(essentialData.type[key]),
    ).length > 1;

  return (
    <Stack height="100%" alignItems="center" justifyContent="space-evenly">
      <Typography variant="h2">
        {!alertMSG
          ? 'There is no configuration data here, try to select a category/subcategory or create a new one'
          : alertMSG}
      </Typography>

      {subcategory.length > 1 && userPermissions?.isQuasarAdmin && (
        <>
          {!!showTypesOptions ? (
            <>
              <Typography variant="caption">
                Choose a type below and click button to create {subcategory}:
              </Typography>

              <TypesList
                typesObject={essentialData.type}
                selectedType={selectedType}
                handleChangeSelectedType={handleChangeSelectedType}
              />
            </>
          ) : (
            ''
          )}
          <Button
            sx={{ padding: '3' }}
            variant="contained"
            color="primary"
            onClick={() => createNewConfig()}
          >
            Create configuration for {subcategory}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default MessageBoard;
