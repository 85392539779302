const getTimeNow = () => {
  const currentDate = new Date();
  const mediumTime = new Intl.DateTimeFormat('en', {
    timeStyle: 'medium',
    dateStyle: 'medium',
  });
  return mediumTime.format(currentDate);
};

export default getTimeNow;
