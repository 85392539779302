import axios from 'axios';

const devDomain = process.env.REACT_APP_DOMAIN;
const prodDomain = process.env.REACT_APP_DOMAIN;

const environment = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

const env: any = {};
if (environment !== 'dev') {
  try {
    axios('/env.txt').then((result) => {
      const split = result.data
        .split('\n')
        .map((line: string) => line.split('='));
      split.forEach((line: any[]) => {
        env[line[0]] = line[1];
      });
    });
  } catch (_err) {
    env['REACT_APP_DOMAIN'] = process.env.REACT_APP_DOMAIN;
  }
}

const urlConfig = window.urlConfig ?? {
  gateway: 'atr-gateway',
  mlGateway: '',
  tickets: 'atr',
  chatbot: 'chatbot',
  aqe: 'kb-workflow',
  knowledgebase: 'kb',
  'identity-management': 'identity-management',
  'machine-learning': 'ml',
  wfm: 'wfm',
  ata: 'ata',
};

const getConfig = async () => {
  const env: any = {};
  if (environment !== 'dev') {
    try {
      const result = await axios('/env.txt');
      const split = result.data
        .split('\n')
        .map((line: string) => line.split('='));
      split.forEach((line: any[]) => {
        env[line[0]] = line[1];
      });
    } catch (_err) {
      env['REACT_APP_DOMAIN'] = prodDomain;
    }
  }

  const config = {
    environment,
    urls: {
      host: environment === 'dev' ? devDomain : env['REACT_APP_DOMAIN'],
      base:
        environment === 'dev'
          ? `${devDomain}/atr-gateway`
          : `${env['REACT_APP_DOMAIN']}/${urlConfig.gateway}`,
      api: {
        auth: 'identity-management/api/v1/auth',
        authSAML: 'identity-management',
        'ticket-management': 'ticket-management/api/v1',
        'identity-management': 'identity-management/api/v1',
        'search-engine': 'search-engine/api/v1',
        automation: 'automation/api/v1',
        'knowledge-base': 'knowledgebase/api/v1',
        ml: 'ml/api/v1',
        'ticket-templates': 'templates/api/v1',
        quasar: 'api/v2/acnopenai',
        quasarV3: 'api/v3/acnopenai',
      },
      app: {
        tickets: urlConfig.tickets,
        chatbot: urlConfig.chatbot,
        'identity-management': urlConfig['identity-management'],
        'machine-learning': urlConfig['machine-learning'],
        aqe: urlConfig.aqe,
        wfm: urlConfig.wfm,
        ata: urlConfig.ata,
      },
      redirect: {
        signin: `identity-management/signin`,
        localSignin: `identity-management/signin?loginMethod=local`,
        signout: 'identity-management/signout',
      },
    },
    constant: {
      minLoadingTime: 300,
      refreshBuffer: 120000,
      auth: {
        username: process.env.REACT_APP_LOCAL_USERNAME,
        password: process.env.REACT_APP_LOCAL_PASSWORD,
      },
    },
  } as const;
  return config;
};

export default await getConfig();
