import { styled } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';

export const DialogTitleStyled = styled(DialogTitle)`
  color: var(--Black, #0f172a);
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 25px */
  padding-bottom: 0;
`;
