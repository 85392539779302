import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const environment = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

axiosInstance.interceptors.request.use(async (config) => {
  const env: any = {};
  if (environment !== 'dev') {
    try {
      const result = await axios('/env.txt');
      const split = result.data
        .split('\n')
        .map((line: string) => line.split('='));
      split.forEach((line: any[]) => {
        env[line[0]] = line[1];
      });
    } catch (_err) {
      env['REACT_APP_DOMAIN'] = process.env.REACT_APP_DOMAIN;
    }
  }

  let url =
    environment !== 'dev'
      ? env['REACT_APP_DOMAIN']
      : process.env.REACT_APP_DOMAIN;

  config.baseURL = url + '/api/v2/acnopenai';
  config.headers['apiToken'] = localStorage.getItem('apiToken');

  return config;
});

export default axiosInstance;
