import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import axios from 'api/axios';

interface useMetadataFileProps {
  index: string;
  doc_name?: string;
}

type File = {
  doc_name: string;
  chunk_count: number;
  last_update_time: string;
  metadata?: string;
};

function useMetadataFile({ index, doc_name }: useMetadataFileProps) {
  const [metadata, setMetadata] = useState<string[]>();
  const [mandatoryMetadata, setMandatoryMetadata] = useState<string[]>();

  const getFileMetadata = useCallback(() => {
    axios({
      method: 'get',
      url: `/listdocs/${index}`,
    })
      .then((response: AxiosResponse) => {
        if (!response.data) return setMetadata([]);
        const actualFile = response.data.filter(
          (file: File) => file.doc_name === doc_name,
        );
        const metadataString = actualFile[0].metadata;
        if (!metadataString) return setMetadata([]);
        metadataString.name = doc_name;
        const metadata = JSON.stringify(metadataString);
        setMetadata([`[${metadata}]`]);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, [index, doc_name]);

  const getMandatoryMetadata = useCallback(() => {
    axios({
      method: 'get',
      url: `createindex/${index}`,
    })
      .then((response: AxiosResponse) => {
        if (!response.data.mandatory_metadata) return setMandatoryMetadata([]);
        const mandatoryMetadataArr = Object.keys(
          response.data.mandatory_metadata,
        );

        mandatoryMetadataArr.length > 0
          ? setMandatoryMetadata(mandatoryMetadataArr)
          : setMandatoryMetadata([]);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, [index]);

  useEffect(() => {
    if (!index) return;
    if (!metadata && doc_name) getFileMetadata();
    if (!mandatoryMetadata) getMandatoryMetadata();
  }, [
    index,
    doc_name,
    metadata,
    mandatoryMetadata,
    getFileMetadata,
    getMandatoryMetadata,
  ]);

  return { metadata, mandatoryMetadata };
}

export default useMetadataFile;
