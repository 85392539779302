import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export const IndexButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  borderRadius: '1rem',
}));

export const SelectIndexButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  borderRadius: '1rem',
  overflow: 'hidden',
}));

export const HeaderStyled = styled('div')`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  box-sizing: border-box;
  height: 74px;
  border-bottom: 0.5px solid var(--grey-grey-500, #b3b2b5);
`;

export const HeaderActionsStyled = styled('div')`
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const HeaderPageControlStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HeaderInfoStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

export const HeaderRootStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 15px */
`;

export const HeaderTitleStyled = styled('div')`
  color: #000;
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Sub Header/2 */
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;

export const BrowseButtonStyled = styled(Button)`
  color: var(--core-purples-core-purple-2, #7500c0);
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;
