import { Button, Divider, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useCallback, useContext, useState } from 'react';
import { ConfirmationDialog } from 'components/UI';
import { SnackbarContext } from 'components/SnackbarProvider';
import axios from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

interface DeleteJobSectionProps {
  hash?: string;
  jobName: string;
}

const DeleteJobSection = ({ hash, jobName }: DeleteJobSectionProps) => {
  const createAlert = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleDeleteJob = useCallback(async () => {
    setLoading(true);

    const data = { hash, delete_job: true };

    await axios({
      method: 'post',
      url: '/createasyncjob',
      data,
    })
      .then((_response: AxiosResponse) => {
        createAlert({
          message: `Job deleted successfully`,
          severity: 'success',
        });
        navigate(`/taskgen-pro`);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        createAlert({
          message: `Unable to delete the job`,
          severity: 'error',
        });
      })
      .finally(() => setLoading(false));
  }, [hash, navigate, createAlert]);

  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Graphik',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '125%',
          mt: '48px',
          mb: 2,
        }}
      >
        Delete job
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography mb={2}>
        Delete this job and all of its documents. This action cannot be undone.
      </Typography>
      <Button
        variant="outlined"
        color="error"
        startIcon={<DeleteOutlineIcon />}
        sx={{ width: '250px' }}
        onClick={handleOpenDialog}
      >
        Delete Job
      </Button>

      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        title={`Delete "${jobName}" job`}
        leftButtonProps={{
          name: 'Delete',
          disabled: loading,
          onClick: handleDeleteJob,
        }}
        rightButtonProps={{
          name: 'Cancel',
          disabled: loading,
          onClick: handleCloseDialog,
        }}
        content={
          <>
            <p>Are you sure you want to delete this job?</p>
            <br />
            <p style={{ color: 'red' }}>
              This action is permanent and cannot be undone'
            </p>
          </>
        }
      />
    </>
  );
};

export default DeleteJobSection;
