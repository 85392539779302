import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Files, INewDocument } from 'types';
import UploadProgressModal from '../Upload/UploadProgressModal';
import FilesTable from 'components/FileTable';
import ComponentLoading from 'components/ComponentLoading';
import { ListContainerStyled } from './DocumentList.styles';
import { FilesContext } from 'components/FilesProvider';
import NothingToSeeHere from 'components/NothingToSeeHere';

interface DocumentListProps {
  index: string;
  collection: string;
  loadingFiles: boolean;
  loadingSearch: boolean;
  files: INewDocument[];
  selectedRows: string[];
  getFileList: () => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  handleSelection: (doc_name: string) => void;
}
/**
 * The DocumentList component which should display document list and document related functionalities like Move,Delete etc.
 */
export default function DocumentList({
  index,
  files,
  collection,
  selectedRows,
  loadingFiles,
  loadingSearch,
  getFileList,
  setSelectedRows,
  handleSelection,
}: DocumentListProps): React.JSX.Element {
  const navigate = useNavigate();
  const { state, currentIndex, openUploadDialog, dispatch, uploadFiles } =
    useContext(FilesContext);

  const handleAddDocument = () => {
    dispatch({ type: 'resetState' });
    navigate(`/collection/${collection}/upload`);
  };

  const refreshCollection = () => {
    // Get the most updated file list
    getFileList();

    // Close upload modal if all file status are completed
    const files: Files | undefined = state.files;
    if (!files) return;
    const filesArr = Object.values(files);
    const uploadPendingFiles = filesArr.filter(
      (file) => file.upload_status !== 'Success',
    ).length;
    if (uploadPendingFiles > 0) return;
    filesArr.forEach((file) =>
      dispatch({ type: 'removeFile', payload: file.file.name }),
    );
  };

  useEffect(() => {
    if (index === currentIndex) return;
    dispatch({ type: 'resetState' });
    localStorage.removeItem('filesPendingProcessing');
  }, [index, currentIndex, dispatch]);

  if (loadingFiles || loadingSearch) return <ComponentLoading />;

  return (
    <ListContainerStyled>
      {files.length > 0 ? (
        <FilesTable
          rows={files}
          collection={collection}
          selectedRows={selectedRows}
          getFileList={refreshCollection}
          setSelectedRows={setSelectedRows}
          handleSelection={handleSelection}
        />
      ) : (
        <NothingToSeeHere
          elementToAdd="document"
          currentIndex={index}
          handleAddButton={handleAddDocument}
          handleRefreshButton={refreshCollection}
        />
      )}
      {state.files && (
        <UploadProgressModal
          files={state.files}
          openUploadDialog={openUploadDialog}
          refresh={uploadFiles}
          removeFile={(key) => dispatch({ type: 'removeFile', payload: key })}
        />
      )}
    </ListContainerStyled>
  );
}
