import * as React from 'react';
import { useState, useEffect, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ChatInput from './ChatInput';
import { chatCompletionAPI, ChatCompletionDTO } from 'api';
import { getTimeNow, generateSessionId } from 'common/utils';
import { useAuthState } from 'components/AuthProvider';
import { ChatCompletionRequest } from 'types';
import MessageList from '../../../ChatView/MessageList';
import PropsValsList from './PropsValsList';
import ConfigUserPermissions, {
  IConfigUserPermissions,
} from '../GraphsPage/RBAC/ConfigUserPermissions';

interface IDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  content: {};
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IMessage {
  type: string;
  content: string;
}

interface IDefaultChat {
  sessionid: void;
  name: string;
  description: string;
  lastEdited: void;
  messages: IMessage[];
}

const DEFAULT_CHAT = {
  sessionid: generateSessionId(),
  name: 'New Chat',
  description: 'Description of chat, generated by Q++',
  lastEdited: getTimeNow(),
  messages: [
    {
      type: 'text',
      content: 'Welcome to Quasar++ ChatBot!',
    },
  ],
};

export default function EditNodeDialog({
  content,
  open,
  setOpen,
}: Readonly<IDialogProps>) {
  const initContentPayloadState: string[] = [];

  if (content === '') {
    content = initContentPayloadState;
  }

  const [payload, setPayload] = useState({});
  const { accessToken } = useAuthState();
  const [availableChats, setAvailableChats] = useState([DEFAULT_CHAT]);
  const [messages, setMessages] = useState<ChatCompletionDTO[]>([]);
  const [chatName, setChatName] = useState<string>('');
  const [sessionid, setSessionId] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [userPermissions, setUserPermissions] =
    useState<IConfigUserPermissions>({});

  const permissions = ConfigUserPermissions();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAPICall = async (searchIndex: string, textInput: string) => {
    const requestData: ChatCompletionRequest = {
      index: searchIndex,
      question: textInput,
      sessionid: sessionid,
      reset_context: 'False',
      config: 'show_source',
      context_length: 3000,
      conv_length: 4000,
      prompt_objective:
        'You are an AI assistant that helps users by answering their questions.',
      prompt_prefix: 'Read the given context and then answer the question.',
    };

    const { data } = await chatCompletionAPI(accessToken, requestData);

    if (data) {
      setMessages((prevMessages) => [...prevMessages, data]);
      setAvailableChats((oldChats) =>
        oldChats.map((chat) => {
          if (chat.sessionid === sessionid) {
            return {
              ...chat,
              lastEdited: getTimeNow(),
              messages: [...chat.messages, data],
            }; // Q++ messages only
          }
          return chat;
        }),
      );
    }
  };

  const handleUserInput = (textInput: string) => {
    setMessages((messages) => [
      ...messages,
      { type: 'user', content: textInput },
    ]);
    setAvailableChats((oldChats) =>
      oldChats.map((chat) => {
        if (chat.sessionid === sessionid) {
          return {
            ...chat,
            lastEdited: getTimeNow(),
            messages: [...chat.messages, { type: 'user', content: textInput }],
          }; // user messages only
        }
        return chat;
      }),
    );
  };

  const handleSubmit = async (searchIndex: string, textInput: string) => {
    handleUserInput(textInput);
    await handleAPICall(searchIndex, textInput);
  };

  useEffect(() => {
    if (content && typeof content === 'object') {
      setPayload(content);
    }
  });

  useEffect(() => {
    setUserPermissions(permissions);
  }, []);

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        View/Edit Node
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        style={{ overflowX: 'hidden' }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          View Node
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ overflowX: 'hidden' }}>
          {content && typeof content !== 'object' && <>{content}</>}

          <Typography gutterBottom></Typography>

          {content && typeof content === 'object' && (
            <PropsValsList payload={payload} />
          )}
        </DialogContent>
        <DialogActions>
          {userPermissions?.ALLOW_EDIT_NODE_BTN && (
            <Button
              variant="contained"
              autoFocus
              onClick={handleClose}
              color="secondary"
            >
              Edit Node
            </Button>
          )}
        </DialogActions>
        <div
          style={{
            padding: '7px',
            overflowY: 'auto',
          }}
        >
          <MessageList messages={messages} />
        </div>

        <ChatInput handleSubmit={handleSubmit} />
      </BootstrapDialog>
    </React.Fragment>
  );
}
