import React from 'react';

export const ZipIcon = () => {
  return (
    <svg viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M23.0455 8.00279C22.1433 8.0015 21.2784 7.64785 20.6406 7.01942C20.0028 6.39099 19.6441 5.53908 19.6432 4.65056V0H4.44015C3.26254 0 2.13318 0.460686 1.30049 1.28073C0.467803 2.10076 0 3.21299 0 4.3727V30.7323C0.00174873 31.8909 0.470319 33.0015 1.30282 33.8201C2.13532 34.6387 3.26369 35.0985 4.44015 35.0985H22.6908C23.8673 35.0985 24.9956 34.6387 25.8281 33.8201C26.6606 33.0015 27.1292 31.8909 27.1309 30.7323V8.00279H23.0455Z"
          fill="#3B3944"
        />
      </g>
      <path
        d="M27.1303 8.00279H23.0449C22.1426 8.0015 21.2778 7.64785 20.6399 7.01942C20.0021 6.39099 19.6435 5.53908 19.6426 4.65056V0L27.1303 8.00279Z"
        fill="#3B3944"
      />
      <path
        d="M30.3499 27.7227H9.71826C8.25476 27.7227 7.06836 28.891 7.06836 30.3323V36.39C7.06836 37.8313 8.25476 38.9997 9.71826 38.9997H30.3499C31.8134 38.9997 32.9998 37.8313 32.9998 36.39V30.3323C32.9998 28.891 31.8134 27.7227 30.3499 27.7227Z"
        fill="#3B3944"
      />
      <path
        d="M15.2385 34.6729V35.8542H13.9961V34.6729H15.2385Z"
        fill="white"
      />
      <path
        d="M17.1047 34.9001H19.2942V35.854H15.7715V34.9554L17.9264 31.9151H15.7995V30.9678H19.281V31.8664L17.1047 34.9001Z"
        fill="white"
      />
      <path d="M21.2298 30.9678V35.854H20.0352V30.9678H21.2298Z" fill="white" />
      <path
        d="M23.2581 34.1396V35.854H22.0586V30.9678H23.9957C24.5847 30.9678 25.0352 31.1108 25.3437 31.4C25.5 31.5508 25.622 31.7324 25.7019 31.9329C25.7818 32.1334 25.8178 32.3483 25.8074 32.5635C25.8126 32.8477 25.7408 33.1281 25.5995 33.3759C25.4554 33.6183 25.2419 33.8133 24.9857 33.9365C24.6764 34.0797 24.3373 34.1493 23.9957 34.1396H23.2581ZM24.5913 32.5635C24.5913 32.1356 24.3504 31.9189 23.8686 31.9135H23.2581V33.1842H23.8686C24.3504 33.1896 24.5913 32.9827 24.5913 32.5635Z"
        fill="white"
      />
      <path
        d="M13.5629 24.7833C13.008 24.7824 12.4761 24.5648 12.0839 24.1783C11.6917 23.7917 11.4711 23.2677 11.4707 22.7212V20.6592H15.6568V22.7212C15.6563 23.268 15.4356 23.7922 15.043 24.1788C14.6504 24.5654 14.1181 24.7828 13.5629 24.7833ZM12.5416 21.7138V22.7212C12.5416 22.9881 12.6491 23.2441 12.8406 23.433C13.0321 23.6219 13.2919 23.7282 13.5629 23.7287C13.8341 23.7282 14.094 23.622 14.2858 23.4331C14.4775 23.2443 14.5855 22.9883 14.5859 22.7212V21.7138H12.5416Z"
        fill="#3B3944"
      />
      <path
        d="M11.4696 20.6611H13.5684V18.5942H11.4696V20.6611Z"
        fill="#3B3944"
      />
      <path
        d="M13.5633 18.5957H15.6621V16.5288H13.5633V18.5957Z"
        fill="#3B3944"
      />
      <path
        d="M11.4696 16.5303H13.5684V14.4634H11.4696V16.5303Z"
        fill="#3B3944"
      />
      <path
        d="M13.5633 14.4648H15.6621V12.3979H13.5633V14.4648Z"
        fill="#3B3944"
      />
    </svg>
  );
};

export default ZipIcon;
