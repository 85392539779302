import React from 'react';
import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { IEssentials } from '../interfaces';

export default async function getEssentialData(
  categoryID: string,
  subcategoryID: string,
): Promise<{
  essentialData: IEssentials;
  status: string;
}> {
  return await PrivReq({
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    method: 'get',
    url: `/getconfigurable/${categoryID}/${subcategoryID}/essentials`,
  })
    .then((response: AxiosResponse) => {
      return {
        essentialData: response.data,
        status: 'success returning essential data',
      };
    })
    .catch((error: AxiosError) => {
      return {
        essentialData: { subcategory: '', essentials: [], type: {} },
        status: 'error returning essential data',
      };
    });
}
