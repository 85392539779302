import { styled } from '@mui/system';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';

export const StyledTitle = styled(DialogTitle)`
  display: flex;
  padding: 16px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--Core-Core-Purple-1, #a100ff);
  font-family: Graphik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.16px;
`;

export const StyledContent = styled(DialogContent)`
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--grey-grey-9333333, #333);
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;

  span {
    font-weight: 700;
  }
`;

export const StyledActions = styled(DialogActions)`
  display: flex;
  padding: 16px 8px 8px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
