import { IPermission } from 'types';

const QUASAR_ADMIN_PERMISSIONS: string[] = [
  'REDIS_READ',
  'REDIS_WRITE',
  'MONGO_READ',
  'MONGO_WRITE',
  'CELERY_READ',
  'CELERY_WRITE',
  'METADATA_ACCESS',
  'LLM_ACCESS',
  'API_ACCESS',
];

const QUASAR_USER_PERMISSIONS: string[] = [
  'REDIS_READ',
  'REDIS_WRITE',
  'MONGO_READ',
  'MONGO_WRITE',
  'LLM_ACCESS',
  'API_ACCESS',
];

const QUASAR_READ_PERMISSIONS: string[] = [
  'REDIS_READ',
  'MONGO_READ',
  'LLM_ACCESS',
  'API_ACCESS',
];

const getUserRole = (userPermissions: IPermission[]) => {
  const permissions = userPermissions.map((permission) => permission.name);
  const checker = (rolePermissions: string[]) =>
    rolePermissions.every((v) => permissions.includes(v));

  const isQuasarAdmin = checker(QUASAR_ADMIN_PERMISSIONS);
  const isQuasarUser = checker(QUASAR_USER_PERMISSIONS);
  const isQuasarReader = checker(QUASAR_READ_PERMISSIONS);

  if (isQuasarAdmin) return 'ADMIN';
  if (isQuasarUser) return 'USER';
  if (isQuasarReader) return 'READ';
};

export default getUserRole;
