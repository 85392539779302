export const QuasarLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_7007_29496)">
        <path
          d="M36 0.5H4C2.067 0.5 0.5 2.067 0.5 4V36C0.5 37.933 2.067 39.5 4 39.5H36C37.933 39.5 39.5 37.933 39.5 36V4C39.5 2.067 37.933 0.5 36 0.5Z"
          fill="#A632F5"
          stroke="#A632F5"
        />
        <path
          d="M30.1186 17.2637H26.7687C26.4163 17.2637 26.1306 17.5494 26.1306 17.9018C26.1306 18.2542 26.4163 18.5398 26.7687 18.5398H30.1186C30.471 18.5398 30.7567 18.2542 30.7567 17.9018C30.7567 17.5494 30.471 17.2637 30.1186 17.2637Z"
          fill="white"
        />
        <path
          d="M14.9641 17.2637H9.54035C9.18795 17.2637 8.90227 17.5494 8.90227 17.9018C8.90227 18.2542 9.18795 18.5398 9.54035 18.5398H14.9641C15.3165 18.5398 15.6022 18.2542 15.6022 17.9018C15.6022 17.5494 15.3165 17.2637 14.9641 17.2637Z"
          fill="white"
        />
        <path
          d="M13.5284 19.8164H10.0189C9.66652 19.8164 9.38084 20.1021 9.38084 20.4545C9.38084 20.8069 9.66652 21.0926 10.0189 21.0926H13.5284C13.8808 21.0926 14.1665 20.8069 14.1665 20.4545C14.1665 20.1021 13.8808 19.8164 13.5284 19.8164Z"
          fill="white"
        />
        <path
          d="M14.9641 22.3691H10.0189C9.66652 22.3691 9.38084 22.6548 9.38084 23.0072C9.38084 23.3596 9.66652 23.6453 10.0189 23.6453H14.9641C15.3165 23.6453 15.6022 23.3596 15.6022 23.0072C15.6022 22.6548 15.3165 22.3691 14.9641 22.3691Z"
          fill="white"
        />
        <path
          d="M27.7258 14.7109H23.5783C23.2259 14.7109 22.9402 14.9966 22.9402 15.349C22.9402 15.7014 23.2259 15.9871 23.5783 15.9871H27.7258C28.0782 15.9871 28.3639 15.7014 28.3639 15.349C28.3639 14.9966 28.0782 14.7109 27.7258 14.7109Z"
          fill="white"
        />
        <path
          d="M15.7616 14.7109H11.6141C11.2617 14.7109 10.976 14.9966 10.976 15.349C10.976 15.7014 11.2617 15.9871 11.6141 15.9871H15.7616C16.114 15.9871 16.3997 15.7014 16.3997 15.349C16.3997 14.9966 16.114 14.7109 15.7616 14.7109Z"
          fill="white"
        />
        <path
          d="M10.019 14.7109H9.22135C8.86895 14.7109 8.58327 14.9966 8.58327 15.349C8.58327 15.7014 8.86895 15.9871 9.22135 15.9871H10.019C10.3714 15.9871 10.657 15.7014 10.657 15.349C10.657 14.9966 10.3714 14.7109 10.019 14.7109Z"
          fill="white"
        />
        <path
          d="M8.42373 19.8164H7.62612C7.27372 19.8164 6.98804 20.1021 6.98804 20.4545C6.98804 20.8069 7.27372 21.0926 7.62612 21.0926H8.42373C8.77614 21.0926 9.06182 20.8069 9.06182 20.4545C9.06182 20.1021 8.77614 19.8164 8.42373 19.8164Z"
          fill="white"
        />
        <path
          d="M8.42373 22.3691H7.62612C7.27372 22.3691 6.98804 22.6548 6.98804 23.0072C6.98804 23.3596 7.27372 23.6453 7.62612 23.6453H8.42373C8.77614 23.6453 9.06182 23.3596 9.06182 23.0072C9.06182 22.6548 8.77614 22.3691 8.42373 22.3691Z"
          fill="white"
        />
        <path
          d="M6.03089 19.8164H5.23328C4.88088 19.8164 4.5952 20.1021 4.5952 20.4545C4.5952 20.8069 4.88088 21.0926 5.23328 21.0926H6.03089C6.3833 21.0926 6.66898 20.8069 6.66898 20.4545C6.66898 20.1021 6.3833 19.8164 6.03089 19.8164Z"
          fill="white"
        />
        <path
          d="M4.27617 20.4545C4.27617 20.1021 3.99049 19.8164 3.63808 19.8164C3.28568 19.8164 3 20.1021 3 20.4545C3 20.8069 3.28568 21.0926 3.63808 21.0926C3.99049 21.0926 4.27617 20.8069 4.27617 20.4545Z"
          fill="white"
        />
        <path
          d="M6.669 23.0072C6.669 22.6548 6.38332 22.3691 6.03091 22.3691C5.67851 22.3691 5.39283 22.6548 5.39283 23.0072C5.39283 23.3596 5.67851 23.6453 6.03091 23.6453C6.38332 23.6453 6.669 23.3596 6.669 23.0072Z"
          fill="white"
        />
        <path
          d="M8.58324 17.9018C8.58324 17.5494 8.29756 17.2637 7.94515 17.2637C7.59275 17.2637 7.30707 17.5494 7.30707 17.9018C7.30707 18.2542 7.59275 18.5398 7.94515 18.5398C8.29756 18.5398 8.58324 18.2542 8.58324 17.9018Z"
          fill="white"
        />
        <path
          d="M33.19 14.8574H30.3186C30.0984 14.8574 29.9198 15.036 29.9198 15.2562C29.9198 15.4765 30.0984 15.655 30.3186 15.655H33.19C33.4102 15.655 33.5888 15.4765 33.5888 15.2562C33.5888 15.036 33.4102 14.8574 33.19 14.8574Z"
          fill="black"
        />
        <path
          d="M35.6012 17.4746H32.7298C32.5095 17.4746 32.331 17.6532 32.331 17.8734C32.331 18.0937 32.5095 18.2722 32.7298 18.2722H35.6012C35.8214 18.2722 36 18.0937 36 17.8734C36 17.6532 35.8214 17.4746 35.6012 17.4746Z"
          fill="black"
        />
        <path
          d="M31.3555 13.8197V16.691C31.3555 16.9113 31.5341 17.0898 31.7543 17.0898C31.9746 17.0898 32.1531 16.9113 32.1531 16.691V13.8197C32.1531 13.5994 31.9746 13.4209 31.7543 13.4209C31.5341 13.4209 31.3555 13.5994 31.3555 13.8197Z"
          fill="black"
        />
        <path
          d="M33.7595 16.4271V19.2985C33.7595 19.5187 33.938 19.6973 34.1583 19.6973C34.3786 19.6973 34.5571 19.5187 34.5571 19.2985V16.4271C34.5571 16.2068 34.3786 16.0283 34.1583 16.0283C33.938 16.0283 33.7595 16.2068 33.7595 16.4271Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4461 15.988C14.8067 13.611 17.3703 12 20.2916 12C23.2129 12 25.7765 13.611 27.1371 15.988H26.4496C26.0972 15.988 25.8115 16.2737 25.8115 16.6261C25.8115 16.9785 26.0972 17.2642 26.4496 17.2642H27.7231C28.0134 18.0827 28.1715 18.9622 28.1715 19.8768C28.1715 21.4777 27.7258 22.8474 26.8074 24.2034L27.4229 25.1605L27.7519 25.672L28.1246 26.1974L28.8762 27.3938H28.1246H27.2472H26.6091C26.3961 27.389 26.1306 27.3938 26.1306 27.3938H26.0797L24.9621 25.8783C23.8175 27.4736 21.8906 27.7536 20.2916 27.7536C16.8283 27.7536 13.8677 25.4893 12.8184 22.3687H16.2647C17.0934 23.7145 18.5795 24.6035 20.2916 24.6035C21.0244 24.6035 21.7076 24.4272 22.3239 24.1354L23.2592 23.5653L22.0628 21.9091H24.0075C24.2375 21.9096 24.2518 21.9095 24.2899 21.9094C24.3204 21.9093 24.3663 21.9091 24.5502 21.9091C24.8426 21.2926 25.0214 20.6099 25.0214 19.8768C25.0214 17.2481 22.9215 15.1501 20.2916 15.1501C17.6617 15.1501 15.5649 17.2481 15.5649 19.8768C15.5649 20.2978 15.6187 20.7052 15.7198 21.0926H12.5062C12.444 20.6961 12.4117 20.29 12.4117 19.8768L12.412 19.8173H14.4855C14.8379 19.8173 15.1236 19.5316 15.1236 19.1792C15.1236 18.8268 14.8379 18.5411 14.4855 18.5411H12.5259C12.6017 18.1022 12.7142 17.6755 12.8601 17.2642H14.645C14.9974 17.2642 15.2831 16.9785 15.2831 16.6261C15.2831 16.2737 14.9974 15.988 14.645 15.988H13.4461Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7007_29496">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuasarLogo;
