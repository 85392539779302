import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ITypeColor } from 'types';

type listedTypesColors = {
  types_and_colors: ITypeColor[];
};

export default function TypesList({
  types_and_colors,
}: Readonly<listedTypesColors>): React.JSX.Element {
  const TypesListItem = types_and_colors.map((typeColor) => (
    <ListItem
      key={typeColor.type}
      value={typeColor.type}
      alignItems="flex-start"
      disablePadding
      sx={{
        m: 1,
      }}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: typeColor.color,
            width: 24,
            height: 24,
          }}
        >
          &nbsp;
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <b>{typeColor.type}</b>
      </ListItemText>
    </ListItem>
  ));

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 170,
        bgcolor: 'background.paper',
        position: 'fixed',
        bottom: 70,
        border: 1,
        borderRadius: 3,
        borderColor: 'black',
        spacing: 0,
      }}
    >
      {TypesListItem && TypesListItem.length > 0 ? (
        TypesListItem
      ) : (
        <p>Loading...</p>
      )}
    </List>
  );
}
