import { styled } from '@mui/system';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

export const CustomDialogTitle = styled(DialogTitle)({
  fontSize: 18,
  fontWeight: 'bold',
});

export const CustomDialogContent = styled(DialogContent)({
  fontSize: 15,
  fontWeight: 200,
  color: 'black',
  padding: 0,
});

export const Modal = styled('div')`
  display: flex;
  width: 607px;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
  justify-content: flex-end;

  position: absolute;
  bottom: 13px;
  right: 650px;
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
`;

export const DialogHeaderStyled = styled('div')`
  display: flex;
  height: 64px;
  padding: 0px 16px 0px 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  background: var(--grey-grey-1000, #03000f);
`;

export const HeaderTitleStyled = styled('div')`
  display: flex;
  color: var(--grey-white, #fff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Sub Header/2 */
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  align-items: center;
`;

export const HeaderIconsStyled = styled('div')`
  display: flex;
  width: 170px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const DialogContentStyled = styled('div')`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #4f4b53;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;

export const DialogItemStyled = styled('div')`
  display: flex;
  width: 575px;
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const ItemIconStyled = styled('div')`
  display: flex;
  width: 33px;
  height: 39px;
`;

export const ItemContentStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

export const ItemTitleStyled = styled('div')`
  height: 24px;
  color: var(--grey-grey-1000, #03000f);
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Body/3 */
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.16px;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  gap: 2px;
  align-self: stretch;
`;

export const ItemDescriptionStyled = styled('div')`
  color: var(--grey-grey-600, #837f89);
  font-feature-settings:
    'clig' off,
    'liga' off;
  /* Caption/1 */
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.4px;
`;

export const ItemProgressBarStyled = styled('div')`
  width: 100%;
  margin-top: 7px;
`;
