import { useContext } from 'react';
import { UserProperties } from 'components/AppWrapper';
import { IConfigUserPermissions } from '../interfaces';

export default function ConfigUserPermissions(): IConfigUserPermissions {
  const user = useContext(UserProperties);

  const uniquePermissions = new Set(user?.permissions.map(({ name }) => name));
  const permissionsArray: string[] = Array.from(uniquePermissions).map(
    (name, index) => name,
  );

  const QUASAR_ADMIN_PERMISSIONS: string[] = [
    'REDIS_READ',
    'REDIS_WRITE',
    'MONGO_READ',
    'MONGO_WRITE',
    'CELERY_READ',
    'CELERY_WRITE',
    'METADATA_ACCESS',
    'LLM_ACCESS',
    'API_ACCESS',
  ];

  const QUASAR_USER_PERMISSIONS: string[] = [
    'REDIS_READ',
    'REDIS_WRITE',
    'MONGO_READ',
    'MONGO_WRITE',
    'LLM_ACCESS',
    'API_ACCESS',
  ];

  const QUASAR_READ_PERMISSIONS: string[] = [
    'REDIS_READ',
    'MONGO_READ',
    'LLM_ACCESS',
    'API_ACCESS',
  ];

  const checker = (rolePermissions: string[]) =>
    rolePermissions.every((v) => permissionsArray.includes(v));

  const isQuasarAdmin: boolean = checker(QUASAR_ADMIN_PERMISSIONS);
  const isQuasarUser: boolean = checker(QUASAR_USER_PERMISSIONS);
  const isQuasarReader: boolean = checker(QUASAR_READ_PERMISSIONS);

  const ALLOW_WRITE: boolean = permissionsArray.includes('MONGO_WRITE');

  const ConfigUserPermissionsObject: IConfigUserPermissions = {
    isQuasarAdmin,
    isQuasarUser,
    isQuasarReader,
    ALLOW_ADD_CONFIG_BTN: ALLOW_WRITE,
    ALLOW_ADD_RULE_BTN: ALLOW_WRITE,
    ALLOW_SAVE_BTN: ALLOW_WRITE,
    ALLOW_EDIT_DATAGRID_ROWS: ALLOW_WRITE,
    ALLOW_DOWNLOAD_RULES_BTN: isQuasarAdmin || isQuasarUser,
  };

  return { ...ConfigUserPermissionsObject };
}
