import { styled } from '@mui/system';

export const SourceDocumentStyled = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
  background-color: white;
  width: 100%;
`;

export const SidebarStyled = styled('div')`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-right: 0.5px solid var(--grey-grey-500, #b3b2b5);
  background: var(--grey-white, #fff);
  box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.1);
  min-width: 16vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;

export const ThumbnailStyled = styled('div')<{ selected: boolean }>`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => (props.selected ? '#F7EBFF' : '#FFF')};
`;

export const ThumbnailImageStyled = styled('div')`
  width: 10vh;
`;

export const ThumbnailNumberStyled = styled('div')`
  align-self: stretch;
  color: #000;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.4px;
`;

export const FileStyled = styled('div')`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  justify-content: center;

  & iframe {
    width: '100%';
    height: '100%';
  }
`;
