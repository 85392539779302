import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/system';

export const TableCellStyled = styled(TableCell)`
  padding: 10px;
  transition: all 0.3s ease;
`;

export const ExpandableContent = styled('div')<{ expanded: boolean }>(
  ({ expanded }) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: expanded ? 'unset' : 6,
    maxHeight: expanded ? 'none' : '7.5em',
    transition: 'max-height 0.3s ease',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    maxWidth: '200px',
    padding: '8px',
  }),
);
