import * as React from 'react';
import { useState, useEffect } from 'react';
import PropsValsList from './PropsValsList';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ChatInput from './ChatInput';
import { chatCompletionAPI, ChatCompletionDTO } from 'api';
import { getTimeNow, generateSessionId } from 'common/utils';
import { useAuthState } from 'components/AuthProvider';
import MessageList from '../../../ChatView/MessageList';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import MessageAlertDialog from '../../../MessageAlertDialog/MessageAlertDialog';
import NodeChildDialog from './NodeChildDialog';
import { List } from '@mui/material';
import { string } from 'prop-types';
import { IChildNode, INode, IGraphContext } from './interfaces';
import Divider from '@mui/material/Divider';

interface IDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  content: [];
  node: INode;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ContextDialog({
  node,
  content,
  open,
  setOpen,
}: Readonly<IDialogProps>) {
  interface IShowNodeChildDialogProps {
    show: boolean;
    action: string;
  }
  const [payload, setPayload] = useState([{}]);
  const [alertMSG, setAlertMSG] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showNodeChildDialog, setShowNodeChildDialog] =
    useState<IShowNodeChildDialogProps>({
      show: false,
      action: '',
    });

  const initContentPayloadState: string[] = [];

  //setPayload(['Item one','Item two','Item three','Item four','Item five'])
  const handleClose = () => {
    setOpen(false);
  };

  const handleContextClick = async (query: string) => {
    try {
      //setLoadingGraphs(true);
      var reqData = {
        node: '9494ba8db604286042085430921;finance',
        graph_name: 'Graphtest01',
        type: 'domain',
        query: 'Get use case for finanae domain',
      };
      reqData.query = query;
      var rdata = JSON.stringify(reqData);

      const resp = await PrivReq({
        withCredentials: true,
        method: 'post',
        url: '/graphcontextquery',
        data: rdata,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setAlertMSG(`${resp.data}`);
      setShowAlert(true);
      //setContextData(resp.data)
      //setLoadingGraphs(false);
    } catch (_e) {
      //setAlertMSG(`${_e}`);
      //setShowAlert(true);
    }
  };

  const contextListClicked = (e: any) => {
    let query = '';
    for (var index in content) {
      if (index == e) {
        query = Object(content[index]).description;
        handleContextClick(query);
        break;
      }
    }
  };

  useEffect(() => {
    if (content && typeof content === 'object') {
      //let datacontent:any[] = [{name:'summary',action:'getsummary'},{name:'usecase',action:'getusecase'}]
      //setPayload(datacontent);
    }
  }, []);

  return (
    <React.Fragment>
      <>
        {alertMSG ? (
          <MessageAlertDialog
            message={alertMSG}
            open={showAlert}
            setOpen={setShowAlert}
            downloadBTN={true}
            downloadFileNAME="genai_context_graph_message.txt"
          />
        ) : (
          ''
        )}
      </>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        //fullWidth
        style={{ overflowX: 'hidden' }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Action List
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ overflowX: 'hidden' }}>
          <Box sx={{ maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItemButton>
                  <ListItemText
                    primary="Duplicate Node"
                    onClick={() =>
                      setShowNodeChildDialog({
                        show: true,
                        action: 'Duplicate',
                      })
                    }
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Create Child Node"
                    onClick={() =>
                      setShowNodeChildDialog({
                        show: true,
                        action: 'Create',
                      })
                    }
                  />
                </ListItemButton>
                <Divider />
                {content && content.length > 0
                  ? content.map((item, index) => (
                      <ListItemButton
                        key={item}
                        alignItems="flex-start"
                        onClick={() => contextListClicked(index)}
                      >
                        <ListItemText>{Object(item).description}</ListItemText>
                      </ListItemButton>
                    ))
                  : ''}
              </List>
            </nav>
          </Box>
          <NodeChildDialog
            typeAction={showNodeChildDialog.action}
            open={showNodeChildDialog.show}
            setOpen={setShowNodeChildDialog}
            content={content}
            node={node}
          />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
