const getColor = ({
  status,
  column,
  statusList,
}: {
  status: string;
  column: string;
  statusList: {
    started?: string;
    succes: string;
    inProgress?: string;
    cancelled?: string;
    error: string;
  };
}) => {
  let color = 'rgba(0, 0, 0, 0.08)';
  if (statusList.started && status === statusList.started) {
    color = '#0000b5';
  }
  if (status === statusList.succes) color = '#3C8703';
  if (statusList.inProgress && status === statusList.inProgress) {
    color = '#460073';
  }
  if (statusList.cancelled && status === statusList.cancelled) {
    color = '#e69e19';
  }
  if (status === statusList.error) color = '#AA2230';

  if (column === 'outputCollection' && status !== statusList.succes) {
    color = 'rgba(0, 0, 0, 0.08)';
  }
  return color;
};

export default getColor;
