import { useCallback, useEffect, useState } from 'react';

// import axios from 'api/axios';
// import { AxiosError, AxiosResponse } from 'axios';
// import { getUploadStatusData } from 'common/utils/getUploadStatusData';
// import { SnackbarContext } from 'components/SnackbarProvider';
import { getFequentTask } from 'api';
import { Job } from 'types';

const LOCAL_STORAGE_KEY = 'jobUpdatesAvailable';

export type Item = {
  index: string;
  updateAvailable: boolean;
  readyToBeUpdated: boolean;
};

type JobUpdatesAvailable = Item[];

interface UseUpdateJobsProps {
  currentIndex: string;
}

function useUpdateJobs({ currentIndex }: UseUpdateJobsProps) {
  // const createAlert = useContext(SnackbarContext);
  const [linkedJobs, setLinkedJobs] = useState<Job[] | null>(null);
  const [itemStatus, setItemStatus] = useState<Item | null>(null);

  const getJobUpdatesAvailableList = useCallback(() => {
    let jobUpdatesAvailableList: JobUpdatesAvailable | undefined = undefined;
    const rawList = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!rawList) return jobUpdatesAvailableList;
    jobUpdatesAvailableList = JSON.parse(rawList);
    if (jobUpdatesAvailableList?.length === 0) return undefined;
    return jobUpdatesAvailableList;
  }, []);

  const saveOnLocalStorage = useCallback((list: JobUpdatesAvailable) => {
    const value = JSON.stringify(list);
    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  }, []);

  const updateItemStatus = useCallback(
    (newItem?: Item) => {
      let itemStatus = {
        index: currentIndex,
        updateAvailable: false,
        readyToBeUpdated: false,
      };
      if (newItem) {
        itemStatus = newItem;
      } else {
        const jobUpdatesAvailableList = getJobUpdatesAvailableList();
        if (jobUpdatesAvailableList) {
          const filteredItem = jobUpdatesAvailableList.find(
            (i) => i.index === currentIndex,
          );
          if (filteredItem) itemStatus = filteredItem;
        }
      }
      setItemStatus(itemStatus);
    },
    [currentIndex, getJobUpdatesAvailableList],
  );

  const updateJobUpdatesAvailableList = useCallback(
    (newItem: Item) => {
      updateItemStatus(newItem);

      const jobUpdatesAvailableList = getJobUpdatesAvailableList();

      if (!jobUpdatesAvailableList) {
        return saveOnLocalStorage([newItem]);
      }

      const isItemInlcuded = !!jobUpdatesAvailableList.find(
        (i) => i.index === newItem.index,
      );

      const updatedList = jobUpdatesAvailableList.map((i) =>
        i.index === newItem.index ? newItem : i,
      );

      const newList = isItemInlcuded
        ? updatedList
        : [...jobUpdatesAvailableList, newItem];

      saveOnLocalStorage(newList);
    },
    [updateItemStatus, saveOnLocalStorage, getJobUpdatesAvailableList],
  );

  const getJobList = useCallback(() => {
    const onSuccess = (jobs: Job[]) => {
      const filteredJobs = jobs.filter((job) =>
        job.input_collections.includes(currentIndex),
      );
      const isfilteredJobsEmpty = filteredJobs.length < 1;
      if (isfilteredJobsEmpty) return;
      setLinkedJobs(filteredJobs);
    };

    getFequentTask({ list: 'jobs', onSuccess });
  }, [currentIndex]);

  useEffect(() => getJobList(), [getJobList]);
  useEffect(() => updateItemStatus(), [currentIndex, updateItemStatus]);

  return {
    linkedJobs,
    itemStatus,
    updateJobUpdatesAvailableList,
  };
}

export default useUpdateJobs;
