import { useCallback, useContext, useState } from 'react';

import axios from 'api/axios';
import { UploadStatus, UploadedFileList } from 'types';
import { AxiosError, AxiosResponse } from 'axios';
import { getUploadStatusData } from 'common/utils/getUploadStatusData';
import { SnackbarContext } from 'components/SnackbarProvider';

type LocalStorageKey = 'uploadedFiles' | 'filesPendingProcessing';

function useUploadStatus() {
  const createAlert = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const getTaskIDList = useCallback(({ key }: { key: LocalStorageKey }) => {
    let {
      uploadedFileList,
      taskIDList,
    }: { uploadedFileList?: UploadedFileList; taskIDList?: string[] } = {};
    const rawTaskIDList = localStorage.getItem(key);
    if (!rawTaskIDList) return { uploadedFileList, taskIDList };
    uploadedFileList = JSON.parse(rawTaskIDList);
    if (uploadedFileList?.length === 0) return { uploadedFileList, taskIDList };
    taskIDList = uploadedFileList?.flatMap((item) => item.taskIDs);
    return { uploadedFileList, taskIDList };
  }, []);

  const getPendingTaskIDList = useCallback(
    (index: string) => {
      const { uploadedFileList } = getTaskIDList({
        key: 'filesPendingProcessing',
      });
      if (!uploadedFileList) return;
      const pendingTaskIDList = uploadedFileList
        .filter((i) => i.index === index)
        .flatMap((i) => i.taskIDs);
      if (pendingTaskIDList.length === 0) return;
      return pendingTaskIDList;
    },
    [getTaskIDList],
  );

  const saveOnLocalStorage = useCallback(
    ({
      list,
      key,
    }: {
      list: {
        index: string;
        taskIDs: string[];
      }[];
      key: LocalStorageKey;
    }) => {
      const value = JSON.stringify(list);
      localStorage.setItem(key, value);
    },
    [],
  );

  const removeTaskID = useCallback(
    ({
      key,
      index,
      selectedTaskID,
    }: {
      key: LocalStorageKey;
      index: string;
      selectedTaskID: string;
    }) => {
      const { uploadedFileList } = getTaskIDList({ key });
      const isItemInlcuded = uploadedFileList
        ?.map((item) => item.index)
        .includes(index);

      if (!uploadedFileList || !isItemInlcuded) return;

      const updatedUploadedFileList = uploadedFileList.map((item) =>
        item.index === index
          ? {
              ...item,
              taskIDs: item.taskIDs.filter(
                (taskID) => taskID !== selectedTaskID,
              ),
            }
          : item,
      );
      saveOnLocalStorage({ list: updatedUploadedFileList, key });
    },
    [getTaskIDList, saveOnLocalStorage],
  );

  const updateUploadedFileList = useCallback(
    ({
      index,
      newTaskID,
      key,
    }: {
      index: string;
      newTaskID: string;
      key: LocalStorageKey;
    }) => {
      const { uploadedFileList } = getTaskIDList({ key });

      const isItemInlcuded = uploadedFileList
        ?.map((item) => item.index)
        .includes(index);

      const newList = { index, taskIDs: [newTaskID] };

      const updatedUploadedFileList = !uploadedFileList
        ? [newList]
        : isItemInlcuded
          ? uploadedFileList.map((item) =>
              item.index === index
                ? { ...item, taskIDs: [...item.taskIDs, newTaskID] }
                : item,
            )
          : [...uploadedFileList, newList];

      saveOnLocalStorage({ list: updatedUploadedFileList, key });
    },
    [getTaskIDList, saveOnLocalStorage],
  );

  const getUploadStatus = useCallback(
    async ({
      taskID,
      controller,
    }: {
      taskID: string;
      controller?: AbortController;
    }) => {
      setLoading(true);

      let {
        uploadStatus,
        error,
      }: { uploadStatus?: UploadStatus; error?: AxiosError } = {};
      await axios({
        method: 'get',
        signal: controller?.signal,
        url: `uploadstatus/taskid/${taskID}/formattedjson`,
      })
        .then((response: AxiosResponse) => {
          const data = response.data;
          uploadStatus = getUploadStatusData(data);
        })
        .catch((error: AxiosError) => {
          if (error.code === 'ERR_CANCELED') return;
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Failed to get file status with task ID: "${taskID}"`,
            severity: 'error',
          });
        });
      setLoading(false);
      return { uploadStatus, error };
    },
    [createAlert],
  );

  return {
    loading,
    removeTaskID,
    getTaskIDList,
    getUploadStatus,
    getPendingTaskIDList,
    updateUploadedFileList,
  };
}

export default useUploadStatus;
