import React, { useEffect, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { AxiosResponse, AxiosError } from 'axios';
import axios from 'api/axios';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { IChunkList } from 'components/Page/DocumentPage';
import ComponentLoading from 'components/ComponentLoading';

import {
  SourceDocumentStyled,
  SidebarStyled,
  ThumbnailStyled,
  ThumbnailImageStyled,
  ThumbnailNumberStyled,
  FileStyled,
} from './SourceFile.styles';

import { PDFDocument } from 'pdf-lib';

import * as pdfjsLib from 'pdfjs-dist';

type SourceFileProps = {
  chunkList: IChunkList;
  doc_name: string;
  index: string;
  fullscreen: string;
  setPdfPage: React.Dispatch<React.SetStateAction<number>>;
  setFullscreen: React.Dispatch<React.SetStateAction<string>>;
  setLeftPanelPercentWidth: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * Display a preview and chunk data of a specific document.
 */

const PDFJS = require('pdfjs-dist/webpack');

// pdfjsLib.GlobalWorkerOptions.workerSrc =
//   "../../build/webpack/pdf.worker.bundle.js";

export default function SourceFile({
  doc_name,
  index,
  fullscreen,
  setPdfPage,
  setFullscreen,
  setLeftPanelPercentWidth,
}: SourceFileProps): React.JSX.Element {
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [thumbnails, setThumbnails] = useState<
    { src: string; selected: boolean }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [pdfDoc, setPdfDoc] = useState<PDFDocument>();

  const getPdfThumbnails = useCallback(async ({ url }: { url: string }) => {
    const images = [];
    const pdf = await pdfjsLib.getDocument(url).promise;
    const canvas = document.createElement('canvas');
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      if (context === null) return;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      let isSelected = i === 0 ? true : false;
      const image = { src: canvas.toDataURL(), selected: isSelected };
      images.push(image);
    }
    canvas.remove();
    setThumbnails(images);
    return images;
  }, []);

  const getPdfPages = useCallback(
    async ({ pageIdx, url }: { pageIdx: number; url?: string }) => {
      let pdfFile = pdfDoc;
      if (url && !pdfFile) {
        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer(),
        );
        pdfFile = await PDFDocument.load(existingPdfBytes);
        setPdfDoc(pdfFile);
      }
      if (!pdfFile) return;
      const newPdfDoc = await PDFDocument.create();
      const [extractedPage] = await newPdfDoc.copyPages(pdfFile, [pageIdx]);
      newPdfDoc.addPage(extractedPage);
      const pdfBytes = await newPdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(blob);
      setPdfPage(pageIdx);
      setPdfUrl(fileUrl);
    },
    [pdfDoc, setPdfPage],
  );

  const handleSelectThumbnail = ({
    id,
    index,
  }: {
    id: string;
    index: number;
  }) => {
    if (!pdfUrl) return;
    setThumbnails((prev) =>
      prev?.map((thumbnail) => {
        if (thumbnail.src === id) {
          return { ...thumbnail, selected: true };
        }
        return { ...thumbnail, selected: false };
      }),
    );
    getPdfPages({ url: pdfUrl, pageIdx: index });
  };

  const handleFullscreen = (screen: string) => {
    let screenPercentWidth = 50;
    setFullscreen((prev) => {
      if (prev === screen) return 'both';
      if (screen === 'sourceFile') screenPercentWidth = 100;
      if (screen === 'chunkFile') screenPercentWidth = 0;
      return screen;
    });
    setLeftPanelPercentWidth(screenPercentWidth);
  };

  useEffect(() => {
    if (pdfUrl) return;
    axios({
      method: 'get',
      url: `/docdownload/${index}/${doc_name}/pdf`,
      responseType: 'arraybuffer',
    })
      .then((response: AxiosResponse) => {
        const byteArray = new Uint8Array(response.data);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const fileUrl = window.URL.createObjectURL(blob);
        getPdfThumbnails({ url: fileUrl });
        getPdfPages({ url: fileUrl, pageIdx: 0 });
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, [index, doc_name, pdfUrl, getPdfPages, getPdfThumbnails]);

  useEffect(() => {
    if (!pdfUrl || !loading || thumbnails.length <= 0) return;
    setLoading(false);
  }, [thumbnails, pdfUrl, loading]);

  if (loading) return <ComponentLoading />;

  return (
    <SourceDocumentStyled>
      <div
        style={{
          width: '100%',
          display: 'flex',
          borderBottom: '0.5px solid var(--grey-grey-500, #b3b2b5)',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: 8,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <IconButton onClick={() => handleFullscreen('sourceFile')}>
            {fullscreen !== 'sourceFile' ? (
              <OpenInFullIcon />
            ) : (
              <CloseFullscreenIcon />
            )}
          </IconButton>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 56px)',
        }}
      >
        <SidebarStyled>
          {thumbnails.map((thumbnail, idx) => (
            <ThumbnailStyled selected={thumbnail.selected} key={idx}>
              <ThumbnailImageStyled>
                <Paper elevation={2}>
                  <img
                    src={thumbnail.src}
                    onClick={() =>
                      handleSelectThumbnail({
                        id: thumbnail.src,
                        index: idx,
                      })
                    }
                    alt="pdf thumbnails"
                    style={{ width: '100%', cursor: 'pointer' }}
                  />
                </Paper>
              </ThumbnailImageStyled>
              <ThumbnailNumberStyled>{idx + 1}</ThumbnailNumberStyled>
            </ThumbnailStyled>
          ))}
        </SidebarStyled>
        <FileStyled>
          <>
            {pdfUrl ? (
              <object
                data={pdfUrl}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <embed src={pdfUrl} type="application/pdf" />
              </object>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            )}
          </>
        </FileStyled>
      </div>
    </SourceDocumentStyled>
  );
}
