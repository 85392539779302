import {
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,
} from 'react-router-dom';

import Page, {
  ChatPage,
  CollectionPage,
  CollectionSettingsPage,
  CollectionUploadPage,
  CollectionsPage,
  ConfigPage,
  DocumentPage,
  ErrorPage,
  GraphPage,
  GraphsPage,
  MetadataPage,
  NotFoundPage,
  UploadsStatusPage,
  DocGeneration,
  TaskUploadPage,
  JobUploadPage,
  JobsPage,
} from 'components/Page';
import PrivateRoute from './PrivateRoute';

const HandleError = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  const errorStatus: number = isRouteErrorResponse(error) ? error.status : 0;
  const errorMessage: string = isRouteErrorResponse(error)
    ? error.statusText
    : '';

  return {
    errorStatus,
    errorMessage,
  };
};

const Router = createBrowserRouter([
  {
    path: '/',
    element: <Page page={<CollectionsPage />} />,
    errorElement: <Page page={<ErrorPage />} />,
  },
  {
    path: '/collection/:index',
    element: (
      <PrivateRoute
        children={<Page page={<CollectionPage />} />}
        allowedUsers={['ADMIN']}
        privateRoute="default_doc_idx"
      />
    ),
  },
  {
    path: '/collection/:index/document/:doc_name',
    element: <Page page={<DocumentPage />} />,
  },
  {
    path: '/collection/:index/settings',
    element: <Page page={<CollectionSettingsPage />} />,
  },
  {
    path: '/collection/:index/upload',
    element: (
      <PrivateRoute
        children={<Page page={<CollectionUploadPage />} />}
        allowedUsers={['USER', 'ADMIN']}
      />
    ),
  },
  {
    path: '/config',
    element: <Page page={<ConfigPage />} />,
    errorElement: <Page page={<ErrorPage />} />,
  },
  {
    path: '/ask-quasar',
    element: <Page page={<ChatPage />} />,
  },
  {
    path: '/taskgen-pro',
    element: <Page page={<DocGeneration />} />,
  },
  {
    path: '/taskgen-pro/jobs',
    element: <Page page={<JobsPage />} />,
  },
  {
    path: '/task-upload',
    element: <Page page={<TaskUploadPage />} />,
  },
  {
    path: '/job-upload/:hash?',
    element: <Page page={<JobUploadPage />} />,
  },
  {
    path: '/uploads-status',
    element: <Page page={<UploadsStatusPage />} />,
  },
  {
    path: '/graphs',
    element: <Page page={<GraphsPage />} />,
    errorElement: <Page page={<ErrorPage />} />,
  },
  {
    path: '/graph/:graph_name/industry_name/:industry',
    element: <Page page={<GraphPage />} />,
    errorElement: <Page page={<ErrorPage />} />,
  },
  {
    path: '/metadata',
    element: <Page page={<MetadataPage />} />,
  },
  {
    path: '*',
    element: <Page page={<NotFoundPage />} />,
  },
]);

export default Router;
