import { Divider, Typography, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getHeadCells } from 'common/utils/getCellsHead';
import { UserProperties } from 'components/AppWrapper';
import Table from 'components/UI/Table';
import {
  Cells,
  HeadCell,
  RBACControlResponse,
  RowActions,
  TableData,
  IPermissions,
} from 'types';
import { getRbacControl, updateRbacControl } from 'api';
import { SnackbarContext } from 'components/SnackbarProvider';
import ComponentLoading from 'components/ComponentLoading';

const tableHeadCells: HeadCell[] = getHeadCells([
  'Group',
  { label: 'Admin', cellProps: { align: 'center' } },
  { label: 'User', cellProps: { align: 'center' } },
  { label: 'Read', cellProps: { align: 'center' } },
]);

const rowMetadata = ['id'];

const cellProps: Cells = [
  {
    id: 'group',
    type: 'textField',
  },
  {
    id: 'admin',
    cellProps: { align: 'center' },
    type: 'checkbox',
  },
  {
    id: 'user',
    cellProps: { align: 'center' },
    type: 'checkbox',
  },
  {
    id: 'read',
    cellProps: { align: 'center' },
    type: 'checkbox',
  },
];

const rowActions: RowActions = [
  { name: 'Delete', color: 'error', icon: <DeleteIcon />, status: 'deleted' },
];

const permissionsSuffixes = ['_ADMIN', '_USER', '_READ'];

interface PermissionsProps {
  index: string;
}

const Permissions = ({ index }: PermissionsProps) => {
  const user = useContext(UserProperties);
  const isUserAdmin = user.role === 'ADMIN';
  const editable = isUserAdmin ? 'show_controls' : undefined;
  const createAlert = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<TableData[]>();
  const [permissions, setPermissions] = useState<IPermissions>();

  const getRows = useCallback((permission: IPermissions) => {
    const getGroup = (group: string) => {
      let name = '';
      permissionsSuffixes.forEach((permission) => {
        group.includes(permission) && (name = group.replace(permission, ''));
      });
      return name;
    };

    const allGroups = Array.from(
      new Set(
        Object.values(permission)
          .flat()
          .map((group) => getGroup(group)),
      ),
    );

    const rows = allGroups.map((group) => {
      const hasPermission = (role: string) =>
        permission[role].includes(`${group}_${role.toUpperCase()}`);

      const newRow = {
        group,
        admin: hasPermission('admin'),
        user: hasPermission('user'),
        read: hasPermission('read'),
        id: `${crypto.randomUUID()}`,
      };
      return newRow;
    });

    setRows(rows);
  }, []);

  const handleRowAction = ({
    row,
    action,
  }: {
    row: TableData;
    action: string;
  }) => {
    if (action === 'Delete') {
      setRows((prev) => prev?.filter((r) => r.id !== row.id));
    }
  };

  const onLoading = useCallback((isLoading: boolean) => {
    setLoading(isLoading);
  }, []);

  const getPermissions = useCallback(async () => {
    const onSuccess = (data: RBACControlResponse) => {
      const permissions = data[0] as unknown as IPermissions;
      delete permissions['index_name'];
      getRows(permissions);
      setPermissions(permissions);
    };

    const onError = () => {
      createAlert({
        message: `Something went wrong while trying to get the permissions list`,
        severity: 'error',
      });
    };

    await getRbacControl({ index, onSuccess, onError, onLoading });
  }, [index, getRows, onLoading, createAlert]);

  const updatePermissions = useCallback(async () => {
    if (!rows) return;
    const onSuccess = async () => {
      await getPermissions();
      createAlert({
        message: `Permissions updated succesfully`,
        severity: 'success',
      });
    };

    const getGroups = (role: string) => {
      return rows
        .filter((row) => row[role])
        .map((row) => `${row.group}_${role.toUpperCase()}`);
    };

    const data = {
      index_name: index,
      admin: getGroups('admin'),
      read: getGroups('read'),
      user: getGroups('user'),
    };

    const onError = () => {
      createAlert({
        message: `Something went wrong while trying to update the permissions list`,
        severity: 'error',
      });
    };

    await updateRbacControl({ data, onSuccess, onError, onLoading });
  }, [rows, index, onLoading, createAlert, getPermissions]);

  const handleEdit = (action: 'Save' | 'Cancel') => {
    action === 'Cancel' && permissions && getRows(permissions);
    action === 'Save' && updatePermissions();
  };

  const handleAddRow = () => {
    const newRow = {
      group: '',
      admin: false,
      user: false,
      read: false,
      id: `${crypto.randomUUID()}`,
    };
    setRows((prev) => (prev ? [...prev, newRow] : [newRow]));
  };

  const handleCellAction = ({
    row,
    columnKey,
    value,
  }: {
    row: TableData;
    columnKey: string;
    value: string | boolean;
  }) => {
    const newValue = columnKey === 'group' ? `${value}`.toUpperCase() : value;
    setRows((prev) =>
      prev?.map((r) => (r.id === row.id ? { ...r, [columnKey]: newValue } : r)),
    );
  };

  const textFieldValidator = (text: string) => {
    let error: string | undefined = undefined;
    permissionsSuffixes.forEach((invalidText) => {
      if (text.includes(invalidText)) {
        error = `The group name cannot contain the following word: "${invalidText}"`;
      }
    });
    return error;
  };

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return (
    <>
      <Typography variant="h3" mt={6} mb={3}>
        Permissions
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Typography mb={3}>
        Restrict access to this collection by adding users or group below. If no
        users or group are added, the collection will be accessible by all users
        in this instance by default. Restrictions work by assigning a
        workflow-specific permission in Identity Management.
      </Typography>
      <Typography mb={3}>
        Your collection-specific permission in IM is: LicensingRequest_21042023
      </Typography>
      {loading && <ComponentLoading />}
      {rows && !loading && (
        <Paper elevation={3}>
          <Table
            title="Group permissions"
            editable={editable}
            headCells={tableHeadCells}
            rows={rows}
            cellProps={cellProps}
            rowMetadata={rowMetadata}
            rowActions={rowActions}
            handleEdit={handleEdit}
            handleAddRow={handleAddRow}
            handleRowAction={handleRowAction}
            handleCellAction={handleCellAction}
            textFieldValidator={textFieldValidator}
          />
        </Paper>
      )}
    </>
  );
};

export default Permissions;
