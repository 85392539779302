import React from 'react';

export const PdfIcon = () => {
  return (
    <svg viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M23.0455 8.00279C22.1435 8.00193 21.2786 7.64863 20.6407 7.02045C20.0029 6.39228 19.6441 5.54054 19.6432 4.65216V0H4.44015C3.26254 0 2.13318 0.460706 1.30049 1.28075C0.467803 2.10078 0 3.21297 0 4.37268V30.7323C0.00174873 31.8909 0.470319 33.0015 1.30282 33.8201C2.13532 34.6387 3.26369 35.0985 4.44015 35.0985H22.6908C23.8673 35.0985 24.9956 34.6387 25.8281 33.8201C26.6606 33.0015 27.1292 31.8909 27.1309 30.7323V8.00279H23.0455Z"
          fill="#FF3E4C"
        />
      </g>
      <path
        d="M27.1303 8.00279H23.0449C22.1428 8.00193 21.2779 7.64863 20.6401 7.02045C20.0022 6.39228 19.6435 5.54054 19.6426 4.65216V0L27.1303 8.00279Z"
        fill="#FF3E4C"
      />
      <path
        d="M21.5498 24.51H4.51523C4.35113 24.51 4.19375 24.4458 4.07771 24.3316C3.96167 24.2173 3.89648 24.0623 3.89648 23.9007C3.89692 23.7394 3.9623 23.5848 4.07829 23.4709C4.19428 23.3569 4.35142 23.293 4.51523 23.293H21.5498C21.7135 23.293 21.8705 23.357 21.9862 23.4709C22.1019 23.5849 22.1669 23.7395 22.1669 23.9007C22.1671 23.9806 22.1513 24.0598 22.1204 24.1338C22.0895 24.2077 22.0441 24.2748 21.9868 24.3314C21.9294 24.388 21.8614 24.433 21.7864 24.4636C21.7114 24.4943 21.631 24.51 21.5498 24.51Z"
        fill="#FF3E4C"
      />
      <path
        d="M21.5498 17.6468H4.51523C4.35142 17.6468 4.19428 17.5828 4.07829 17.4688C3.9623 17.3549 3.89692 17.2004 3.89648 17.039C3.89648 16.8774 3.96167 16.7224 4.07771 16.6081C4.19375 16.4939 4.35113 16.4297 4.51523 16.4297H21.5498C21.631 16.4297 21.7114 16.4455 21.7864 16.4761C21.8614 16.5067 21.9294 16.5516 21.9868 16.6082C22.0441 16.6649 22.0895 16.732 22.1204 16.806C22.1513 16.8799 22.1671 16.9591 22.1669 17.039C22.1669 17.2002 22.1019 17.3548 21.9862 17.4688C21.8705 17.5827 21.7135 17.6468 21.5498 17.6468Z"
        fill="#FF3E4C"
      />
      <path
        d="M21.5498 21.0784H4.51523C4.35113 21.0784 4.19375 21.0142 4.07771 20.8999C3.96167 20.7856 3.89648 20.6307 3.89648 20.4691C3.89692 20.3077 3.9623 20.1531 4.07829 20.0392C4.19428 19.9253 4.35142 19.8613 4.51523 19.8613H21.5498C21.7135 19.8613 21.8705 19.9254 21.9862 20.0393C22.1019 20.1533 22.1669 20.3079 22.1669 20.4691C22.1671 20.549 22.1513 20.6282 22.1204 20.7021C22.0895 20.776 22.0441 20.8432 21.9868 20.8998C21.9294 20.9564 21.8614 21.0013 21.7864 21.032C21.7114 21.0626 21.631 21.0784 21.5498 21.0784Z"
        fill="#FF3E4C"
      />
      <path
        d="M14.831 14.2151H4.51523C4.35142 14.2151 4.19428 14.1512 4.07829 14.0372C3.9623 13.9233 3.89692 13.7687 3.89648 13.6074C3.89648 13.4458 3.96167 13.2908 4.07771 13.1765C4.19375 13.0623 4.35113 12.998 4.51523 12.998H14.831C14.9949 12.9985 15.1518 13.0629 15.2675 13.1771C15.3832 13.2913 15.4481 13.4461 15.4481 13.6074C15.4477 13.7684 15.3825 13.9228 15.2669 14.0366C15.1513 14.1505 14.9946 14.2147 14.831 14.2151Z"
        fill="#FF3E4C"
      />
      <path
        d="M14.831 10.7835H4.51523C4.35142 10.7835 4.19428 10.7195 4.07829 10.6056C3.9623 10.4916 3.89692 10.3371 3.89648 10.1758C3.89648 10.0141 3.96167 9.85912 4.07771 9.74485C4.19375 9.63057 4.35113 9.56641 4.51523 9.56641H14.831C14.9949 9.56684 15.1518 9.63121 15.2675 9.74544C15.3832 9.85967 15.4481 10.0144 15.4481 10.1758C15.4477 10.3368 15.3825 10.4911 15.2669 10.605C15.1513 10.7189 14.9946 10.783 14.831 10.7835Z"
        fill="#FF3E4C"
      />
      <path
        d="M30.3499 27.7227H9.71826C8.25476 27.7227 7.06836 28.891 7.06836 30.3323V36.39C7.06836 37.8313 8.25476 38.9997 9.71826 38.9997H30.3499C31.8134 38.9997 32.9998 37.8313 32.9998 36.39V30.3323C32.9998 28.891 31.8134 27.7227 30.3499 27.7227Z"
        fill="#FF3E4C"
      />
      <path
        d="M13.8365 34.6729V35.8542H12.5957V34.6729H13.8365Z"
        fill="white"
      />
      <path
        d="M15.6624 34.1396V35.8539H14.4629V30.9678H16.4066C16.9956 30.9678 17.4461 31.1124 17.7546 31.4C17.9108 31.5511 18.0327 31.733 18.1123 31.9338C18.1919 32.1346 18.2275 32.3497 18.2166 32.5651C18.2226 32.8491 18.1514 33.1296 18.0104 33.3775C17.8663 33.6199 17.6528 33.8149 17.3966 33.9382C17.0875 34.082 16.7483 34.1516 16.4066 34.1413L15.6624 34.1396ZM16.9956 32.5651C16.9956 32.1317 16.7547 31.9151 16.2729 31.9151H15.6624V33.1874H16.2729C16.7547 33.1917 16.9956 32.9843 16.9956 32.5651Z"
        fill="white"
      />
      <path
        d="M22.9564 34.6758C22.7498 35.0452 22.4377 35.3469 22.0588 35.5435C21.6324 35.7585 21.1583 35.8651 20.6794 35.8539H18.8066V30.9677H20.6794C21.1586 30.9558 21.6335 31.0602 22.0621 31.2715C22.44 31.4639 22.7513 31.7625 22.9564 32.1295C23.1711 32.5191 23.2791 32.957 23.2699 33.4002C23.2788 33.8449 23.1708 34.2843 22.9564 34.6758ZM21.6661 34.4304C21.8002 34.2948 21.9041 34.1332 21.9712 33.9558C22.0382 33.7784 22.067 33.5892 22.0555 33.4002C22.067 33.2115 22.0383 33.0225 21.9713 32.8453C21.9042 32.6682 21.8003 32.5068 21.6661 32.3716C21.366 32.1123 20.974 31.9803 20.5754 32.0044H20.0062V34.796H20.5754C20.9736 34.8198 21.3654 34.6885 21.6661 34.4304Z"
        fill="white"
      />
      <path
        d="M27.1849 30.9678V31.9086H25.1504V32.9794H26.7212V33.878H25.1504V35.8539H23.9492V30.9678H27.1849Z"
        fill="white"
      />
      <path
        d="M21.2461 9.56641H18.3289C17.8204 9.56641 17.4082 9.97235 17.4082 10.4731V13.346C17.4082 13.8468 17.8204 14.2527 18.3289 14.2527H21.2461C21.7546 14.2527 22.1668 13.8468 22.1668 13.346V10.4731C22.1668 9.97235 21.7546 9.56641 21.2461 9.56641Z"
        fill="#FF3E4C"
      />
    </svg>
  );
};

export default PdfIcon;
