import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  Cells,
  DefaultTableOrder,
  GetAsyncTaksStatusResponse,
  HeadCell,
  PopoverFilters,
  RowActions,
  TableData,
} from 'types';
import { getHeadCells } from 'common/utils/getCellsHead';
import Table from 'components/UI/Table';
import { SnackbarContext } from 'components/SnackbarProvider';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'api/axios';
import { titleCase } from 'common/utils/stringStyler';
import { getDate } from 'common/utils';
import { ConfirmationDialog } from 'components/UI';
import { CollectionContext } from 'components/CollectionProvider';

const taskHeadCells: HeadCell[] = getHeadCells([
  'Name',
  'Task',
  { label: 'Status', cellProps: { align: 'center' } },
  'Start Time',
  'End Time',
  'Output Collection',
  'Parent Job',
]);

const cellProps: Cells = [
  {
    id: 'status',
    cellProps: { align: 'center' },
    type: 'chip',
  },
  {
    id: 'outputCollection',
    type: 'chip',
    width: '100%',
  },
];

const initialRowActions: RowActions = [
  { name: 'Cancel', color: 'error', icon: <ClearIcon />, status: 'cancelled' },
  {
    name: 'Restart',
    color: 'secondary',
    icon: <ReplayIcon />,
    status: 'restarted',
  },
  {
    name: 'Duplicate',
    color: 'secondary',
    icon: <ContentCopyIcon />,
    status: 'duplicated',
  },
  { name: 'Delete', color: 'error', icon: <DeleteIcon />, status: 'deleted' },
];

const statusList = {
  started: 'Started',
  succes: 'Success',
  inProgress: 'In Progress',
  cancelled: 'Cancelled',
  error: 'Failure',
};

const tableFilters: PopoverFilters = {
  status: {
    options: ['Started', 'In Progress', 'Success', 'Cancelled', 'Failure'],
    multiselect: true,
  },
  endTime: {
    options: ['Last day', 'Last 7 days', 'Last 30 days', 'Last 3 months'],
  },
};

const defaultTableOrder: DefaultTableOrder = {
  name: 'startTime',
  order: 'desc',
};

const rowMetadata = ['rowId', 'output', 'epochStartTime', 'epochEndTime'];

const initialConfirmActionsList = [
  {
    open: false,
    title: `Cancel`,
    leftButtonProps: { name: 'Confirm' },
    rightButtonProps: { name: 'Cancel' },
    content: 'cancel',
    payload: { rowId: '', rowName: '', action: 'CANCEL', status: 'cancelled' },
  },
  {
    open: false,
    title: `Delete`,
    leftButtonProps: { name: 'Delete' },
    rightButtonProps: { name: 'Cancel' },
    content: 'delete',
    payload: { rowId: '', rowName: '', action: 'DELETE', status: 'deleted' },
  },
];

interface TaskTableProps {
  taskRows: TableData[] | null;
  setTaskRows: React.Dispatch<React.SetStateAction<TableData[] | null>>;
  loadingTasks: boolean;
  setLoadingTasks: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TaskTable({
  taskRows,
  loadingTasks,
  setTaskRows,
  setLoadingTasks,
}: TaskTableProps): React.JSX.Element {
  const navigate = useNavigate();
  const createAlert = useContext(SnackbarContext);
  const { collections, getCollections } = useContext(CollectionContext);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [rowActions, setRowActions] = useState(initialRowActions);
  const [selectedCollection, setSelectedCollection] = useState<string | null>(
    null,
  );
  const [confirmActionList, setConfirmActionList] = useState(
    initialConfirmActionsList,
  );
  const [isAnyTaskPenidng, setIsAnyTaskPenidng] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const getAsyncTasksStatus = useCallback(
    (silentRefresh?: boolean) => {
      !silentRefresh && setLoadingTasks(true);

      axios({
        url: `/getasynctasksstatus`,
        method: 'get',
      })
        .then((response: AxiosResponse) => {
          const data: GetAsyncTaksStatusResponse = response.data;

          const stopAutomaticRefresh = !data || data.length === 0;
          if (stopAutomaticRefresh) return setAutoRefresh(false);

          const newRows = data.map((newRow) => ({
            name: newRow.info.job_name ?? newRow.info.task_description,
            task: newRow.info.task_description,
            status: titleCase(newRow.Status),
            startTime: getDate(newRow.info.time_started),
            endTime: getDate(newRow.info.time_completed),
            epochStartTime: newRow.info.time_started,
            epochEndTime: newRow.info.time_completed,
            rowId: newRow.TaskID,
            output: newRow.info.output,
            outputCollection: newRow.info.output_collection,
            parentJob: newRow.info.parent_task_hash ?? 'Standalone',
          }));

          setTaskRows(newRows);

          const isAnyTaskPenidng =
            newRows
              .map((newRow) => titleCase(newRow.status))
              .filter(
                (status) =>
                  status === 'In Progress' ||
                  status === 'Started' ||
                  status.toLowerCase().includes('retry'),
              ).length > 0;

          setIsAnyTaskPenidng(isAnyTaskPenidng);

          const updateRowAtions = () => {
            const inactiveCancellableRows = newRows
              .filter((row) => row.status !== 'In Progress')
              .filter((row) => row.status !== 'Started')
              .filter((row) => !row.status.toLowerCase().includes('retry'))
              .map((row) => row.rowId);

            const inactiveDuplicatableRows = newRows
              .filter(
                (row) =>
                  row.status === 'Failure' ||
                  row.status === 'Cancelled' ||
                  row.parentJob !== 'Standalone',
              )
              .map((row) => row.rowId);

            const inactiveRestartRows = newRows
              .filter((row) => row.parentJob !== 'Standalone')
              .map((row) => row.rowId);

            const updatedRowActions = initialRowActions.map((rowAction) =>
              rowAction.name === 'Cancel'
                ? { ...rowAction, inactiveRows: inactiveCancellableRows }
                : rowAction.name === 'Duplicate'
                  ? { ...rowAction, inactiveRows: inactiveDuplicatableRows }
                  : rowAction.name === 'Restart'
                    ? { ...rowAction, inactiveRows: inactiveRestartRows }
                    : rowAction,
            );

            return updatedRowActions;
          };

          setRowActions(updateRowAtions());
        })
        .catch((error: AxiosError) => {
          setAutoRefresh(false);
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          if (error.code === 'ERR_CANCELED') return;
          createAlert({
            message: `Something went wrong`,
            severity: 'error',
          });
        })
        .finally(() => setLoadingTasks(false));
    },
    [createAlert, setTaskRows, setLoadingTasks],
  );

  const updateAsyncTask = useCallback(
    ({
      rowId,
      action,
      status,
    }: {
      rowId: string;
      action: string;
      status: string;
    }) => {
      setLoadingTasks(true);

      const body = {
        task: rowId,
        action: action.toUpperCase(),
      };

      axios({
        url: `/updateasynctask`,
        method: 'post',
        data: body,
      })
        .then(() => {
          getAsyncTasksStatus(true);
          createAlert({
            message: `Task ${status} successfully`,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => {
          setLoadingTasks(false);
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          if (error.code === 'ERR_CANCELED') return;
          createAlert({
            message: `Something went wrong`,
            severity: 'error',
          });
        });
    },
    [createAlert, setLoadingTasks, getAsyncTasksStatus],
  );

  const enableRowClick = (row: TableData) =>
    row.status === 'Success' && row.name !== selectedCollection;

  const handleRowClick = useCallback(
    (row: TableData) => {
      const collection = `${row['outputCollection']}`;
      setSelectedCollection(collection);
      getCollections();
    },
    [getCollections],
  );

  const closeConfirmationDialog = (payload: TableData) => {
    setConfirmActionList((prev) =>
      prev.map((item) =>
        item.payload.action === payload.action
          ? { ...item, open: false }
          : item,
      ),
    );
  };

  const handleRowAction = ({
    row,
    action,
    status,
  }: {
    row: TableData;
    action: string;
    status: string;
  }) => {
    const rowId = row['rowId'] as string;
    const rowName = row['name'] as string;
    const actionName = action.toUpperCase();
    const payload = { action: actionName, rowId, status, rowName };
    if (actionName !== 'DELETE' && actionName !== 'CANCEL') {
      return updateAsyncTask(payload);
    }

    setConfirmActionList((prev) =>
      prev.map((item) =>
        item.payload.action === actionName
          ? { ...item, payload, open: true }
          : item,
      ),
    );
  };

  // Function to notify the user that any task in progress is still being processed
  useEffect(() => {
    if (!isAnyTaskPenidng || isTimerActive) return;
    setTimeout(() => {
      createAlert({
        message:
          'Tasks are still in process, it will take a few minutes to generate document.',
        severity: 'info',
      });
      setIsTimerActive(false);
    }, 180000);
    setIsTimerActive(true);
  }, [isTimerActive, isAnyTaskPenidng, createAlert]);

  //Using use effect to iterate collections array since there is an issue when trying from async function
  useEffect(() => {
    if (!collections || !selectedCollection) return;
    createAlert({
      message: `Redirecting to "${selectedCollection}" collection...`,
      severity: 'info',
    });
    const isCollectionIncluded = collections.includes(selectedCollection);
    if (!isCollectionIncluded) return;
    navigate(`/collection/${selectedCollection}`);
  }, [collections, selectedCollection, navigate, createAlert]);

  useEffect(() => {
    if (!autoRefresh) return;
    const silentRefresh = true;
    getAsyncTasksStatus();
    const intervalId = setInterval(
      () => getAsyncTasksStatus(silentRefresh),
      1000 * 10,
    );
    return () => clearInterval(intervalId);
  }, [autoRefresh, getAsyncTasksStatus]);

  return (
    <>
      {!loadingTasks && taskRows && taskRows.length > 0 && (
        <Grid
          container
          item
          sx={{
            flexWrap: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Grid
            container
            item
            sx={{
              flexWrap: 'nowrap',
              display: 'flex',
              flexGrow: 1,
              overflowY: 'auto',
              flexDirection: 'column',
              gap: 1,
              padding: '20px',
            }}
          >
            <Paper elevation={3} sx={{ width: '100%', height: 'fit-content' }}>
              <Table
                editable={'hide_controls'}
                rows={taskRows}
                headCells={taskHeadCells}
                searchBy="task"
                title={'Submitted Tasks'}
                rowMetadata={rowMetadata}
                cellProps={cellProps}
                statusList={statusList}
                rowActions={rowActions}
                defaultRowsPerPage={10}
                tableFilters={tableFilters}
                defaultOrder={defaultTableOrder}
                handleRowAction={handleRowAction}
                enableRowClick={enableRowClick}
                handleRowClick={handleRowClick}
                refreshTable={() => getAsyncTasksStatus()}
              />
            </Paper>
            {confirmActionList.map((actionToConfirm, idx) => (
              <ConfirmationDialog
                key={idx}
                open={actionToConfirm.open}
                title={`${actionToConfirm.title} "${actionToConfirm.payload.rowName}" task`}
                leftButtonProps={{
                  ...actionToConfirm.leftButtonProps,
                  onClick: () => {
                    updateAsyncTask(actionToConfirm.payload);
                    closeConfirmationDialog(actionToConfirm.payload);
                  },
                }}
                rightButtonProps={{
                  ...actionToConfirm.rightButtonProps,
                  onClick: () =>
                    closeConfirmationDialog(actionToConfirm.payload),
                }}
                content={
                  <>
                    <p>
                      Are you sure you want to {actionToConfirm.content} this
                      task?
                    </p>
                    <br />
                    <p style={{ color: 'red' }}>
                      This action is permanent and cannot be undone'
                    </p>
                  </>
                }
              />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}
