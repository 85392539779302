import React from 'react';
import Grid from '@mui/material/Grid';

import SideMenu from 'components/SideMenu';
import SiteHeader from 'components/SiteHeader';
import SiteFooter from 'components/SiteFooter';

interface PageProps {
  /**
   * The page component that will be rendered inside the global Page component template
   */
  page: React.JSX.Element;
  userWithoutRole?: boolean;
}

/**
 * The global page template which wraps the page component (provided as a prop) in the global site template/header/footer
 */
export default function Page({
  page,
  userWithoutRole,
}: PageProps): React.JSX.Element {
  return (
    <Grid container id="app" direction="column" sx={{ minHeight: '100vh' }}>
      <Grid item>
        <SiteHeader />
      </Grid>
      <Grid
        item
        flexGrow={1}
        container
        direction="row"
        wrap="nowrap"
        sx={{ height: 'calc(100vh - 64px)' }}
      >
        {!userWithoutRole && (
          <Grid
            item
            flexShrink={0}
            sx={{
              height: '100%',
            }}
          >
            <SideMenu />
          </Grid>
        )}
        <Grid
          item
          flexGrow={1}
          container
          direction="column"
          sx={{ height: '100%', width: 'calc(100% - 306px)' }}
        >
          <Grid
            item
            flexGrow={1}
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {page}
          </Grid>
          <Grid item flexShrink={1}>
            <SiteFooter />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item flexShrink={1}>
        <SiteFooter />
      </Grid> */}
    </Grid>
  );
}
