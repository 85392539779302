import React, { ChangeEvent, useState, useContext, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ComponentLoading from 'components/ComponentLoading';
import { SnackbarContext } from 'components/SnackbarProvider';
import { uploadPlugin } from '../services';
import { Typography } from '@mui/material';
import { Loading } from 'mdi-material-ui';

type UploadPluginDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

type TReq = { message: string };

export default function UploadRuleDialog({
  open,
  setOpen,
}: Readonly<UploadPluginDialogProps>): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [message, setMessage] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [reqMessages, setReqMessages] = useState<TReq[]>([]);
  const createAlert = useContext(SnackbarContext);

  const handleDialogClose = () => {
    cleanLocalState();
    setOpen(false);
  };

  const cleanLocalState = () => {
    setSelectedFile(undefined);
    setMessage('');
    setReqMessages([]);
    setLoading(false);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage('uploading...');
    setLoading(true);
    setSelectedFile(event.target?.files?.[0]);
    setMessage('');
    setLoading(false);
  };

  const handleUpload = async (selectedFile: File) => {
    if (!!selectedFile) {
      setMessage('sending file...');
      setLoading(true);

      const msg: string = await uploadPlugin({ pluginFile: selectedFile });

      setMessage(msg);

      createAlert({
        message: msg,
        severity: 'info',
      });

      setLoading(false);
    } else {
      setMessage('Choose a .tar.gz file to upload as plugin');
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Upload <b>plugin</b>:
      </DialogTitle>
      <DialogContent>
        <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
          Click on the button below to upload a '.py' file or a '.tar.gz' file.
        </Typography>

        <Button
          fullWidth
          component="label"
          variant="outlined"
          color="secondary"
          disableElevation
          startIcon={selectedFile ? <CloudDoneIcon /> : <CloudUploadIcon />}
        >
          {selectedFile
            ? selectedFile.name + ' uploaded.'
            : "Upload '.tar.gz' file"}
          <VisuallyHiddenInput
            type="file"
            accept=".tar.gz"
            ref={inputRef}
            onChange={handleFileUpload}
          />
        </Button>

        <p style={{ color: '#CB2939' }}>{message}</p>
      </DialogContent>
      <DialogActions>
        {!!loading ? <ComponentLoading /> : ''}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading ?? !selectedFile}
          onClick={() => handleUpload(selectedFile!)}
        >
          Upload file
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
