import React, { useEffect, useState, createContext, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import SideBar from '../../ChatView/SideBar';
import ChatHeader from '../../ChatView/ChatHeader';

import { getTimeNow, generateSessionId } from 'common/utils';
import { ChatCompletionDTO } from 'api';
import { useAuthState } from 'components/AuthProvider';
import GenerateDocument from 'components/ChatView/GenerateDocument';
import {
  ChatItem,
  ChatContainer,
  ContentContainer,
  SidebarContainer,
} from './ChatPage.styles';
import {
  ChatData,
  AvailableChats,
  ChatContextInterface,
  SelectedMessagesInterface,
} from 'types';
import Chat from 'components/Chat';
import DocumentGenerator from 'components/DocumentGenerator';

const DEFAULT_CHAT: AvailableChats = {
  sessionid: generateSessionId(),
  name: 'New Chat',
  description: 'Description of chat, generated by Q++',
  lastEdited: getTimeNow(),
  messages: [
    {
      type: 'text',
      content:
        'Welcome to Chat One! Add an index and prompt to harvest document data...',
      id: crypto.randomUUID(),
    },
  ],
};

export const DEFAULT_INDEX = 'default_doc_idx';

export const ChatContext = createContext<ChatContextInterface | undefined>(
  undefined,
);

/**
 * The templates page component which should render all components to be displayed specific to this page
 */
export default function ChatPage(): React.JSX.Element {
  const { accessToken } = useAuthState();

  const [availableChats, setAvailableChats] = useState([DEFAULT_CHAT]);
  const [selectedMessages, setSelectedMessages] = useState<
    SelectedMessagesInterface[]
  >([]);
  const [messagesToReview, setMessagesToReview] = useState<
    SelectedMessagesInterface[] | undefined
  >([]);
  const [messages, setMessages] = useState<ChatCompletionDTO[]>([]);
  const [chatName, setChatName] = useState('');
  const [sessionid, setSessionId] = useState('');
  const [displayGenerateDocument, setDisplayGenerateDocument] = useState(false);
  const [openDocumentGnerator, setOpenDocumentGnerator] = useState(false);
  const [docName, setDocName] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(DEFAULT_INDEX);

  const handleLoadChat = (chatData: ChatData | null) => {
    if (!chatData) return;
    setChatName(chatData.name);
    setMessages(chatData.messages);
    setSessionId(chatData.sessionid);
  };

  const handleCreateChat = useCallback(() => {
    const newChat = {
      ...DEFAULT_CHAT,
      sessionid: generateSessionId(),
      lastEdited: getTimeNow(),
    };
    setAvailableChats((availableChats: ChatData[]) => [
      ...availableChats,
      newChat,
    ]);
    handleLoadChat(newChat);
  }, []);

  const handleUserInput = (textInput: string) => {
    setMessages((messages) => [
      ...messages,
      { type: 'user', content: textInput },
    ]);
    setAvailableChats((oldChats) =>
      oldChats.map((chat) => {
        if (chat.sessionid === sessionid) {
          return {
            ...chat,
            lastEdited: getTimeNow(),
            messages: [...chat.messages, { type: 'user', content: textInput }],
          }; // user messages only
        }
        return chat;
      }),
    );
  };

  const handleGenerateDocument = (state: boolean) => {
    setDisplayGenerateDocument(state);
  };

  useEffect(() => {
    if (availableChats.length === 1 && sessionid === '') {
      handleLoadChat(availableChats[availableChats.length - 1]); // initial load
    }
  }, [accessToken, availableChats, sessionid]);

  useEffect(() => {
    let temp_selected_messages = availableChats
      .reduce((res: SelectedMessagesInterface[], curr) => {
        let item = curr.messages.map((elem) => {
          return {
            chatName: curr.name,
            sessionid: curr.sessionid,
            type: elem.type,
            message: elem.content,
            timestamp: elem.timestamp,
            id: elem.id || '',
          };
        });
        res.push(...item);
        return res;
      }, [])
      .filter(
        (e) =>
          e.type === 'text' &&
          e.message !==
            'Welcome to Chat One! Add an index and prompt to harvest document data...',
      );
    setSelectedMessages(temp_selected_messages);
  }, [availableChats]);

  const handleOpenDocumentGenerator = () => setOpenDocumentGnerator(true);

  const reset = useCallback(() => {
    setDocName('');
    setSelectedIndex('');
  }, []);

  useEffect(() => setMessagesToReview(selectedMessages), [selectedMessages]);

  const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  return (
    <ChatContext.Provider
      value={{
        messages,
        availableChats,
        messagesToReview,
        selectedMessages,
        setMessages,
        setAvailableChats,
        setSelectedMessages,
        setMessagesToReview,
      }}
    >
      {openDocumentGnerator ? (
        <DocumentGenerator
          docName={docName}
          selectedIndex={selectedIndex}
          reset={reset}
          setOpenDocumentGnerator={setOpenDocumentGnerator}
        />
      ) : (
        <Grid container>
          <SidebarContainer item xs={2.5}>
            <SideBar
              setChatName={setChatName}
              handleLoadChat={handleLoadChat}
              availableChats={availableChats}
              setAvailableChats={setAvailableChats}
              handleCreateChat={handleCreateChat}
              // handleCreateChatFromSavedSearch={handleCreateChatFromSavedSearch}
              sessionid={sessionid}
            />
          </SidebarContainer>
          <ContentContainer item container xs={9.5}>
            <ChatHeader
              chatName={chatName}
              sessionid={sessionid}
              messages={messages}
              handleGenerateDocument={handleGenerateDocument}
              displayGenerateDocument={displayGenerateDocument}
              selectedMessages={selectedMessages}
              setMessagesToReview={setMessagesToReview}
              messagesToReview={messagesToReview}
              docName={docName}
              setDocName={setDocName}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              handleOpenDocumentGenerator={handleOpenDocumentGenerator}
            />
            <ChatContainer container item>
              <ChatItem item container>
                <Chat
                  messages={messages}
                  sessionid={sessionid}
                  displayGenerateDocument={displayGenerateDocument}
                  setMessages={setMessages}
                  handleUserInput={handleUserInput}
                  setAvailableChats={setAvailableChats}
                />
              </ChatItem>
              <GenerateDocument
                display={displayGenerateDocument}
                messages={messages}
                chatName={chatName}
              />
            </ChatContainer>
          </ContentContainer>
        </Grid>
      )}
    </ChatContext.Provider>
  );
}
