import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Autocomplete,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { grey } from 'theme';

import { ChatData } from 'types';
import SecureTextField from 'components/SecureTextField';
import ChatCard from 'components/ChatCard';
import { SavedSearch } from 'common/utils/savedSearchesContext';

interface SideBarInputProps {
  setChatName: React.Dispatch<React.SetStateAction<string>>;
  handleLoadChat: (chatData: ChatData | null) => void;
  handleCreateChat: () => void;
  handleCreateChatFromSavedSearch?: (savedSearch: SavedSearch) => void;
  availableChats: ChatData[];
  setAvailableChats: any;
  sessionid: string;
}

const SideBar: React.FC<SideBarInputProps> = ({
  handleLoadChat,
  handleCreateChat,
  setChatName,
  availableChats,
  setAvailableChats,
  sessionid,
}) => {
  useEffect(() => {
    if (availableChats.length === 0) {
      handleCreateChat(); // create a new chat and load it
    }
  }, [availableChats, handleCreateChat]);

  return (
    <>
      <Grid
        px={3}
        pt={3}
        pb={2}
        gap={1}
        sx={{
          height: '80px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Autocomplete
          id="autocomplete-search-chats"
          onChange={(_, value) => handleLoadChat(value)}
          options={availableChats}
          getOptionLabel={(option) => option.name}
          sx={{
            width: '100%',
            '.MuiOutlinedInput-root .MuiAutocomplete-input': { padding: 0 },
          }}
          renderInput={(params) => (
            <SecureTextField
              {...params}
              label=""
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              style={{ background: 'white', borderRadius: '5px' }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <IconButton
          color="primary"
          size="large"
          onClick={handleCreateChat}
          style={{ background: grey[200], borderRadius: '3px' }}
        >
          <EditNoteIcon />
        </IconButton>
      </Grid>

      <Divider variant="inset" sx={{ mx: 3 }} />
      <Box sx={{ overflow: 'auto', maxHeight: '700px' }}>
        {availableChats &&
          [...availableChats]
            .sort((a, b) => Date.parse(a.lastEdited) - Date.parse(b.lastEdited))
            .reverse()
            .map((chatData) => (
              <ChatCard
                key={chatData.sessionid}
                chatData={chatData}
                sessionid={sessionid}
                setAvailableChats={setAvailableChats}
                setChatName={setChatName}
                handleLoadChat={handleLoadChat}
              />
            ))}
      </Box>
    </>
  );
};

export default SideBar;
