import { useCallback, useContext, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import JobCard from 'components/JobCard';
import { SnackbarContext } from 'components/SnackbarProvider';
import { getFequentTask } from 'api';
import { Job } from 'types';
import { harcodedJobs } from 'components/Page/JobsPage/harcodedData';

interface JobListPreviewProps {
  loadingJobs: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  jobList: Job[] | null;
  setJobList: React.Dispatch<React.SetStateAction<Job[] | null>>;
}

export default function JobListPreview({
  loadingJobs,
  jobList,
  setJobList,
  setLoading,
}: JobListPreviewProps): React.JSX.Element {
  const navigate = useNavigate();
  const createAlert = useContext(SnackbarContext);

  const navigateToJobsPage = () => navigate('/taskgen-pro/jobs');

  const getJobList = useCallback(() => {
    const onError = () => {
      createAlert({
        message: `There was a problem trying to get the jobs`,
        severity: 'error',
      });
    };

    const onLoading = (loading: boolean) => setLoading(loading);

    getFequentTask({ list: 'jobs', onSuccess: setJobList, onError, onLoading });
  }, [setJobList, setLoading, createAlert]);

  useEffect(() => getJobList(), [getJobList]);

  return (
    <>
      {!loadingJobs && jobList && jobList.length > 0 && (
        <Grid
          item
          container
          wrap="nowrap"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: '20px',
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                padding: 2,
                fontFamily: 'Graphik',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '125%',
              }}
            >
              Submitted Jobs
            </Typography>
            <Box>
              <Button variant="outlined" onClick={navigateToJobsPage}>
                View {jobList.length} jobs
              </Button>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            {jobList.slice(0, 4).map((job, idx) => (
              <JobCard key={idx} job={job} />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}
