import { styled } from '@mui/system';

export const Subtitle = styled('div')`
  color: #000;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

export const Title = styled('div')`
  color: #000;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;
