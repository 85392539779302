import { Typography } from '@mui/material';
import React from 'react';
import { IType } from '../interfaces';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type TTypesObject = {
  typesObject: IType;
  selectedType: string;
  handleChangeSelectedType: (value: string) => void;
};

export default function TypesList({
  typesObject,
  selectedType,
  handleChangeSelectedType,
}: TTypesObject): React.JSX.Element {
  const typesList: string[] = Object.keys(typesObject);

  const TypesListItem = typesList.map((_item) => (
    <MenuItem key={_item} value={_item}>
      {_item}
    </MenuItem>
  ));

  const handleChangeChoice = (event: SelectChangeEvent) => {
    handleChangeSelectedType(event.target.value as string);
  };

  return (
    <Box
      sx={{
        width: 170,
      }}
      //flexGrow={1}
    >
      <FormControl fullWidth>
        <InputLabel id="subcategory">rules types</InputLabel>
        <Select
          labelId="types"
          id="types"
          value={selectedType}
          label="Types"
          onChange={handleChangeChoice}
        >
          {TypesListItem && TypesListItem.length > 0 ? TypesListItem : ''}
        </Select>
      </FormControl>
    </Box>
  );
}
