const QuasarChatAvatarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="10"
      viewBox="0 0 22 10"
      fill="none"
    >
      <path
        d="M5.58779 8.18209C5.58779 8.18209 2.4584 7.9923 2.47341 4.99226C2.47341 4.99226 2.29838 1.8075 5.16073 1.79126C5.16073 1.79126 7.95208 1.56494 7.95208 4.97399C7.95208 4.97399 7.97008 6.7998 6.13085 8.03189L8.66716 8.03899C8.66716 8.03899 9.7953 7.05251 9.91332 4.97399C9.91332 4.97399 10.2364 0.118706 5.16974 0.0162012C5.16974 0.0151863 0.594175 -0.129944 0.594175 4.99226C0.594175 4.99226 0.445157 9.97745 5.7258 9.97745H9.91132L9.91632 8.2034L5.58679 8.18209H5.58779Z"
        fill="#424242"
      />
      <path
        d="M6.13281 8.02894L8.66912 8.03604C8.66912 8.03604 10.3323 6.51268 9.80926 3.69531H7.79602C7.79602 3.69531 8.70213 6.29041 6.13281 8.02894Z"
        fill="#424242"
      />
      <path
        d="M12.6031 5.56084H10.6719V4.45156H12.6031V2.47656H13.6962V4.45156H15.6275V5.56084H13.6962V7.51047H12.6031V5.56084Z"
        fill="#424242"
      />
      <path
        d="M18.0836 5.56084H16.1523V4.45156H18.0836V2.47656H19.1767V4.45156H21.108V5.56084H19.1767V7.51047H18.0836V5.56084Z"
        fill="#424242"
      />
    </svg>
  );
};

export default QuasarChatAvatarIcon;
