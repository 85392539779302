import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import axios from 'api/axios';
import { Buffer } from 'buffer';
import { Paper, Box } from '@mui/material';
import LexicalEditorWrapper from '../LexicalEditor/LexicalEditorWrapper';
import ProcessingDocument from 'components/ProcessingDocument';
import ComponentLoading from 'components/ComponentLoading';
import FileTopBar from 'components/FileTopBar';
import PdfView from 'components/PdfView';
import DraftDetectedDialog from 'components/DraftDetectedDialog';
import {
  IChunkList,
  IChunk,
  IOrderedContent,
} from 'components/Page/DocumentPage';
import {
  ChunksPreviewStyled,
  ImageExtractedTextStyled,
} from './ProcessedFile.styles';
import { decode, encode } from 'html-entities';
import { SnackbarContext } from 'components/SnackbarProvider';
import { ChunkData } from 'components/Page/DocumentPage/DocumentPage';

export interface IImages {
  startIndex: number;
  src: string;
  imagePath: string;
}

export interface IText {
  startIndex: number;
  text: string;
}

export interface ImagesAndTextIndices {
  startIndex: number;
  src?: string;
  text?: string;
  chunk?: number;
  page?: number;
  imagePath?: string;
}

export interface DataToEdit {
  valueId: string;
  value: string;
  valueType: 'img' | 'txt';
}

type ImageFormat = 'tif' | 'png' | 'jpg' | 'bmp' | 'raw';

type ProcessedFileProps = {
  /**
   * Document chunk data provided by the BE API.
   */
  chunkList: IChunkList;
  /**
   * Document name.
   */
  doc_name: string;
  /**
   * The collection the document belongs to.
   */
  index: string;
  fullscreen: string;
  pdfPage: number;
  setFullscreen: React.Dispatch<React.SetStateAction<string>>;
  setLeftPanelPercentWidth: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * Display a preview and chunk data of a specific document.
 */
const imageType: ImageFormat[] = ['tif', 'png', 'jpg', 'bmp', 'raw'];

export default function ProcessedFile({
  chunkList,
  doc_name,
  index,
  fullscreen,
  pdfPage,
  setFullscreen,
  setLeftPanelPercentWidth,
}: ProcessedFileProps): React.JSX.Element {
  const [imageText, setImageText] = useState<(string | number)[]>([]);
  const [imageSelected, setImageSelected] = useState<string[]>([]);
  const [pdfView, setPdfView] = useState(false);
  const [editFile, setEditFile] = useState(false);
  const [newChunkList, setNewChunkList] = useState<IChunkList>([]);
  const [currentChunk, setCurrentChunk] = useState<ChunkData[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDraftDialog, setOpenDraftDialog] = useState(false);
  const [draft, setDraft] = useState<IChunkList | null>(null);
  const [newDocVersion, setNewDocVersion] = useState(false);
  const updatedContent = useRef<IOrderedContent[]>([]);
  const htmlStringFile = useRef<string | null>(null);
  const imageFormat = useRef<ImageFormat>('png');
  const [loadingDraft, setLoadingDraft] = useState(false);
  const createAlert = useContext(SnackbarContext);

  // Provisional control to display processing document view
  const processingDocument = false;

  const textAndImagesChunks = useMemo(
    () =>
      chunkList?.filter((chunk) => {
        return !chunk.content.rawContent.includes(
          'Below are the text extracted',
        );
      }),
    [chunkList],
  );

  const getImageFormat = useCallback((chunkContent: string) => {
    let newImageFormat: ImageFormat = 'png';
    imageType.forEach((format: ImageFormat) => {
      if (chunkContent.includes(format)) return (newImageFormat = format);
    });
    const currentFormat = imageFormat.current;
    if (currentFormat !== newImageFormat) return currentFormat;
    return newImageFormat;
  }, []);

  const getImagePaths = useCallback(
    (chunkContent: string) => {
      const imageType = getImageFormat(chunkContent);
      const imagesPathRegex = new RegExp(`(${index})(.+?)(.${imageType})`, 'g');
      return chunkContent.match(imagesPathRegex);
    },
    [index, getImageFormat],
  );

  const getImageStart = useCallback((chunkContent: string) => {
    const imagesStart = new RegExp(`Image Download URL:https://`, 'g');
    const startIndicesSearch = Array.from(chunkContent.matchAll(imagesStart));
    let imageStartIndices: number[] = [];
    startIndicesSearch.forEach((imageStart) => {
      if (imageStart.index === undefined) return imageStartIndices;
      return imageStartIndices.push(imageStart.index);
    });
    return imageStartIndices;
  }, []);

  const getImageEnd = useCallback((chunkContent: string) => {
    const imagesEnd = new RegExp(`.${imageFormat.current}`, 'g');
    const imageFormatLength = imageFormat.current.length + 1;
    const endIndicesSearch = Array.from(chunkContent.matchAll(imagesEnd));
    let imageEndIndices: number[] = [];
    endIndicesSearch.forEach((imageEnd) => {
      if (imageEnd.index === undefined) return imageEndIndices;
      return imageEndIndices.push(imageEnd.index + imageFormatLength);
    });
    return imageEndIndices;
  }, []);

  const getOrderedContent = useCallback(
    ({ images, text }: { images?: IImages[]; text?: IText[] }) => {
      let newContent: IOrderedContent[] = [];
      if (images && text) newContent = [...images, ...text];
      if (images && !text) newContent = [...images];
      if (!images && text) newContent = [...text];
      const unorderContent: IOrderedContent[] = newContent;
      const orderedContent = unorderContent.sort(function (a, b) {
        return a.startIndex - b.startIndex;
      });
      return orderedContent;
    },
    [],
  );

  const addOrderedContent = useCallback(
    ({
      chunkId,
      text,
      newImages,
    }: {
      chunkId: number;
      newImages?: IImages[];
      text?: IText[];
    }) => {
      const newContent = { images: newImages, text };
      const orderedContent = getOrderedContent(newContent);
      const selectedChunk = textAndImagesChunks.filter(
        (chunk) => chunk.chunkId === chunkId,
      )[0];

      const newChunk: IChunk = {
        ...selectedChunk,
        content: { ...selectedChunk.content, orderedContent },
      };

      setNewChunkList((prev) => {
        if (prev.length === 0) return [newChunk];
        const newChunkExist =
          prev.filter((chunk) => chunkId === chunk.chunkId).length === 1;
        const updatedList = prev.map((chunk) =>
          chunkId === chunk.chunkId ? newChunk : chunk,
        );
        return newChunkExist ? updatedList : [...updatedList, newChunk];
      });
    },
    [textAndImagesChunks, getOrderedContent],
  );

  const getImages = useCallback(
    ({
      chunkId,
      text,
      imagePaths,
      imageStartIndex,
      imageEndIndex,
    }: {
      chunkId: number;
      text: IText[];
      imagePaths: RegExpMatchArray | null;
      imageStartIndex: number[];
      imageEndIndex: number[];
    }) => {
      let newImages: IImages[] = [];
      if (!imagePaths) return newImages;
      imagePaths.forEach((imagePath, idx) => {
        axios({
          method: 'get',
          url: `/download/${imagePath}`,
          responseType: 'arraybuffer',
        })
          .then((response: AxiosResponse) => {
            const base64ImageString = Buffer.from(
              response.data,
              'binary',
            ).toString('base64');
            const src = `data:image/${imageFormat.current};base64,${base64ImageString}`;
            let newImage = {
              startIndex: imageStartIndex[idx],
              src,
              imagePath,
            };
            newImages.push(newImage);
            if (newImages.length === imagePaths.length) {
              addOrderedContent({ chunkId, newImages, text });
            }
          })
          .catch((error: AxiosError) => {
            if (process.env.NODE_ENV === 'development') {
              console.error(error);
            }
            return error;
          });
      });
    },
    [addOrderedContent],
  );

  const getText = useCallback(
    ({
      chunkId,
      chunkContent,
      imagePaths,
      imageStartIndex,
      imageEndIndex,
    }: {
      chunkId: number;
      chunkContent: string;
      imagePaths: RegExpMatchArray | null;
      imageStartIndex: number[];
      imageEndIndex: number[];
    }) => {
      let text: IText[] = [];
      let startIndex: number = 0;
      let endIndex: number = 0;
      //The chunk contains images
      if (imagePaths) {
        imagePaths.forEach((img, idx) => {
          if (imageStartIndex[0] > 0 && idx === 0) {
            // When the text is at the beginning of the chunk
            startIndex = idx;
            endIndex = imageStartIndex[idx];

            const extractedText = chunkContent.slice(startIndex, endIndex);
            const newText = {
              startIndex: startIndex,
              text: extractedText,
            };
            if (newText.text !== '' && newText.text !== '\n')
              text.push(newText);
          } else if (
            idx === imagePaths.length - 1 &&
            imageEndIndex[idx] < chunkContent.length - 1
          ) {
            //When text is the end of the chunk
            startIndex = imageEndIndex[idx];
            endIndex = chunkContent.length;
            const extractedText = chunkContent.slice(startIndex, endIndex);
            const newText = {
              startIndex: startIndex,
              text: extractedText,
            };
            if (newText.text !== '' && newText.text !== '\n')
              text.push(newText);

            startIndex = imageEndIndex[idx - 1];
            endIndex = imageStartIndex[idx];
            const middleText = chunkContent.slice(startIndex, endIndex);
            const rest = {
              startIndex: startIndex,
              text: middleText,
            };
            if (newText.text !== '' && newText.text !== '\n') text.push(rest);
          } else if (imageStartIndex[0] === 0) {
            //When the image is at the beginning of the chunk
            startIndex = imageEndIndex[idx];
            endIndex = imageStartIndex[idx + 1];
            const extractedText = chunkContent.slice(startIndex, endIndex);
            const newText = {
              startIndex: startIndex,
              text: extractedText,
            };
            if (newText.text !== '' && newText.text !== '\n')
              text.push(newText);
          } else {
            startIndex = imageEndIndex[idx - 1];
            endIndex = imageStartIndex[idx];
            const extractedText = chunkContent.slice(startIndex, endIndex);
            const newText = {
              startIndex: startIndex,
              text: extractedText,
            };
            if (newText.text !== '' && newText.text !== '\n')
              text.push(newText);
          }
        });
      }

      //The chunk doesn't contain images
      if (!imagePaths) {
        startIndex = 0;
        endIndex = chunkContent.length;
        const extractedText = chunkContent.slice(startIndex, endIndex);
        const newText: IText = {
          startIndex: startIndex,
          text: extractedText,
        };
        text.push(newText);
        addOrderedContent({ chunkId, text: [newText] });
      }
      return text;
    },
    [addOrderedContent],
  );

  const getNewChunkList = useCallback(() => {
    textAndImagesChunks.forEach((chunk) => {
      const chunkId = chunk.chunkId;
      const chunkContent = chunk.content.rawContent;
      const imagePaths = getImagePaths(chunkContent);
      const imageStartIndex = getImageStart(chunkContent);
      const imageEndIndex = getImageEnd(chunkContent);
      const text = getText({
        chunkId,
        chunkContent,
        imagePaths,
        imageStartIndex,
        imageEndIndex,
      });
      getImages({
        chunkId,
        imagePaths,
        imageStartIndex,
        imageEndIndex,
        text,
      });
    });
  }, [
    textAndImagesChunks,
    getImagePaths,
    getImageStart,
    getImageEnd,
    getImages,
    getText,
  ]);

  const handlePdfView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPdfView(event.target.checked);
  };

  const onDocContentUpdate = ({
    nodeArray,
    htmlString,
  }: {
    nodeArray: [];
    htmlString: string;
  }) => {
    htmlStringFile.current = htmlString;
    const newContent: IOrderedContent[] = [];
    nodeArray.forEach(
      (node: {
        key: number;
        value: { __text?: string; __src?: string; __altText: string };
      }) => {
        if (node.value.__src)
          return newContent.push({
            src: node.value.__src,
            startIndex: node.key,
            imagePath: node.value.__altText,
          });
        if (node.value.__text)
          return newContent.push({
            startIndex: node.key,
            text: encode(node.value.__text),
          });
      },
    );
    if (!newContent) return;
    updatedContent.current = newContent;
  };

  const onImgContentUpdate = (nodeMap: []) => {
    //Function to update extracted text from images
  };

  const onSaveDraft = () => {
    if (updatedContent.current.length === 0) return setEditFile(false);
    // With the following method the separation between chunks is being lost, it is necessary to find a way to remedy it
    let newContentList: IChunkList = [];
    setNewChunkList((chunk) => {
      const newList = [
        {
          ...chunk[0],
          content: {
            rawContent: chunk[0].content.rawContent,
            orderedContent: updatedContent.current,
          },
        },
      ];
      newContentList = newList;
      return newList;
    });
    // setNewChunkList((chunk) => {
    //   const newList = chunk.map((prevContent) => {
    //     const newContent = {
    //       ...prevContent,
    //       content: {
    //         rawContent: prevContent.content.rawContent,
    //         orderedContent: updatedContent.current,
    //       },
    //     };
    //     return newContent;
    //   });
    //   newContentList = newList;
    //   return newList;
    // });
    const jsonObj = JSON.stringify(newContentList);
    setLoadingDraft(true);

    axios({
      method: 'post',
      url: '/savedocdraft',
      headers: { 'Content-Type': 'application/json' },
      data: { index, filename: doc_name, data: jsonObj },
    })
      .then((response: AxiosResponse) => {
        if (response.status !== 200) return;
        setLoadingDraft(false);
        createAlert({
          message: `Draft saved successfully.`,
          severity: 'success',
        });
        setNewDocVersion(true);
        setEditFile(false);
      })
      .catch((error: AxiosError) => {
        setLoadingDraft(false);
        setNewDocVersion(true);
        createAlert({
          message: `Something went wrong when trying to save the draft.`,
          severity: 'error',
        });
        setEditFile(false);
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  };

  const getTextFromImage = useCallback(
    (imagePaths: string[]) => {
      const chunksData = chunkList?.map((chunk) => chunk.content.rawContent);
      if (!chunksData) return [];
      const AllTextExtractedFromImages = chunksData.filter((text) => {
        if (typeof text !== 'string') return [];
        return text.includes('Below are the text extracted');
      });
      let textExtractedFromImages: (string | number)[] = [];
      imagePaths.forEach((imagePath) => {
        const selectedText = AllTextExtractedFromImages.filter((text) => {
          return text.includes(imagePath);
        });
        textExtractedFromImages = [...textExtractedFromImages, ...selectedText];
      });
      setImageText(textExtractedFromImages);
    },
    [chunkList],
  );

  const removeImageFromList = useCallback(
    (src: string) => {
      const newList = imageSelected.filter((prev) => prev !== src);
      if (newList.length === 0) {
        setImageSelected([]);
        return setImageText([]);
      }
      getTextFromImage(newList);
      setImageSelected(newList);
    },
    [imageSelected, getTextFromImage],
  );

  const handleImageClick = useCallback(
    (src: string | undefined) => {
      if (!src) return;
      if (imageSelected.includes(src)) return removeImageFromList(src);
      setImageSelected((prev) => {
        if (prev.includes(src)) {
          getTextFromImage([...prev]);
          return [...prev];
        }
        getTextFromImage([...prev, src]);
        return [...prev, src];
      });
    },
    [imageSelected, getTextFromImage, removeImageFromList],
  );

  const handleDeleteDraft = useCallback(() => {
    axios({
      method: 'post',
      url: '/cleardocdraft',
      headers: { 'Content-Type': 'application/json' },
      data: { index, filename: doc_name },
    })
      .then((response: AxiosResponse) => {
        setDraft(null);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, [doc_name, index]);

  const getFileDraft = useCallback(() => {
    axios({
      method: 'get',
      url: `/getdocdraft/${index}/${doc_name}`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response: AxiosResponse) => {
        const savedDraft: IChunkList = JSON.parse(response.data.status);
        setDraft(savedDraft);
        setOpenDraftDialog(true);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        if (error && error.response?.status === 512) {
          setLoading(false);
          // setOpenDraftDialog(true);
          // setLoading(true);
        }
      });
  }, [doc_name, index]);

  const handleEditFile = useCallback(() => {
    setEditFile(true);
    setLoading(true);
    getFileDraft();
  }, [getFileDraft]);

  const handleDiscardDraft = useCallback(() => {
    setOpenDraftDialog(false);
    handleDeleteDraft();
  }, [handleDeleteDraft]);

  const handleUseDraft = useCallback(() => {
    if (!draft) return;
    setNewChunkList(draft);
    setOpenDraftDialog(false);
  }, [draft]);

  useEffect(() => {
    let updatedContent: ChunkData[] = [];
    newChunkList.forEach((chunk) => {
      if (!chunk.content.orderedContent) return;
      const updatedChunkContent = chunk.content.orderedContent.map(
        (content) => ({
          ...content,
          chunkId: chunk.chunkId,
        }),
      );
      updatedContent.push(...updatedChunkContent);
    });
    setCurrentChunk(updatedContent);
    setLoading(false);
  }, [pdfPage, newChunkList]);

  useEffect(() => {
    if (!newChunkList) return;
    let orderedContentExist = false;
    newChunkList.forEach(
      (chunk) => (orderedContentExist = Boolean(chunk.content.orderedContent)),
    );
    if (!orderedContentExist) getNewChunkList();
  }, [chunkList, newChunkList, getNewChunkList]);

  useEffect(() => {
    const onSelectImage = () => {
      const imagePath = localStorage.getItem(`imageSelected`);
      if (!imagePath) return;
      handleImageClick(imagePath);
    };

    window.addEventListener('storage', onSelectImage);
    return () => window.removeEventListener('storage', onSelectImage);
  }, [handleImageClick]);

  if (processingDocument) return <ProcessingDocument />;

  return (
    <>
      <DraftDetectedDialog
        openDraftDialog={openDraftDialog}
        handleDiscardDraft={handleDiscardDraft}
        handleUseDraft={handleUseDraft}
      />
      {loading ? (
        <ComponentLoading />
      ) : (
        <ChunksPreviewStyled>
          <FileTopBar
            currentChunk={currentChunk}
            htmlString={htmlStringFile.current}
            fullscreen={fullscreen}
            editFile={editFile}
            pdfView={pdfView}
            index={index}
            doc_name={doc_name}
            loadingDraft={loadingDraft}
            newDocVersion={newDocVersion}
            onSave={onSaveDraft}
            setNewDocVersion={setNewDocVersion}
            handlePdfView={handlePdfView}
            handleEditFile={handleEditFile}
            setFullscreen={setFullscreen}
            setLeftPanelPercentWidth={setLeftPanelPercentWidth}
            handleDeleteDraft={handleDeleteDraft}
          />
          <>
            {pdfView ? (
              <PdfView currentChunk={currentChunk} />
            ) : (
              <>
                {editFile ? (
                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <LexicalEditorWrapper
                      currentChunk={currentChunk}
                      onContentUpdate={onDocContentUpdate}
                    />
                  </div>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      direction: 'column',
                      justifyContent: 'center',
                      overflowY: 'auto',
                    }}
                  >
                    <Box width="100%" padding={6} sx={{ overflowY: 'auto' }}>
                      <Paper
                        elevation={3}
                        sx={{
                          minWidth: '688px',
                          minHeight: '970px',
                          boxSizing: 'border-box',
                        }}
                      >
                        {currentChunk.map((data, idx) => (
                          <div
                            style={{
                              padding: 8,
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                            }}
                            key={idx}
                          >
                            {data.src && (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <img
                                    src={data.src}
                                    alt=""
                                    key={idx}
                                    style={{
                                      width: '100%',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      data.imagePath &&
                                      handleImageClick(data.imagePath)
                                    }
                                  />
                                </div>
                              </>
                            )}
                            {data.text && <div>{decode(data.text)}</div>}
                          </div>
                        ))}
                      </Paper>
                    </Box>
                  </Box>
                )}

                {/* {imageText.length > 0 && (
                  <>
                    {imageText.map((data, idx) => (
                      <Paper
                        elevation={3}
                        key={idx}
                        style={{
                          padding: 20,
                        }}
                      >
                        {editFile ? (
                          <div style={{ width: '100%', overflow: 'hidden' }}>
                            <LexicalEditorWrapper
                              extractedText={data}
                              onContentUpdate={onImgContentUpdate}
                            />
                          </div>
                        ) : (
                          <ImageExtractedTextStyled>
                            {data}
                          </ImageExtractedTextStyled>
                        )}
                      </Paper>
                    ))}
                  </>
                )} */}
              </>
            )}
          </>
        </ChunksPreviewStyled>
      )}
    </>
  );
}
