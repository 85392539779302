import React, { useContext } from 'react';
import { UserProperties } from 'components/AppWrapper';

export interface IConfigUserPermissions {
  isQuasarAdmin?: boolean;
  isQuasarUser?: boolean;
  isQuasarReader?: boolean;
  ALLOW_DELETE_GRAPH_BTN?: boolean;
  ALLOW_UPLOAD_GRAPH_BTN?: boolean;
  ALLOW_EDIT_NODE_BTN?: boolean;
}

export default function ConfigUserPermissions(): IConfigUserPermissions {
  const user = useContext(UserProperties);

  const uniquePermissions = new Set(user?.permissions.map(({ name }) => name));
  const permissionsArray: string[] = Array.from(uniquePermissions).map(
    (name, index) => name,
  );

  const QUASAR_ADMIN_PERMISSIONS: string[] = [
    'REDIS_READ',
    'REDIS_WRITE',
    'MONGO_READ',
    'MONGO_WRITE',
    'CELERY_READ',
    'CELERY_WRITE',
    'METADATA_ACCESS',
    'LLM_ACCESS',
    'API_ACCESS',
  ];

  const QUASAR_USER_PERMISSIONS: string[] = [
    'REDIS_READ',
    'REDIS_WRITE',
    'MONGO_READ',
    'MONGO_WRITE',
    'LLM_ACCESS',
    'API_ACCESS',
  ];

  const QUASAR_READ_PERMISSIONS: string[] = [
    'REDIS_READ',
    'MONGO_READ',
    'LLM_ACCESS',
    'API_ACCESS',
  ];

  const checker = (rolePermissions: string[]) =>
    rolePermissions.every((v) => permissionsArray.includes(v));

  const isQuasarAdmin: boolean = checker(QUASAR_ADMIN_PERMISSIONS);
  const isQuasarUser: boolean = checker(QUASAR_USER_PERMISSIONS);
  const isQuasarReader: boolean = checker(QUASAR_READ_PERMISSIONS);

  const ALLOW_WRITE: boolean = permissionsArray.includes('MONGO_WRITE');

  const ConfigUserPermissionsObject: IConfigUserPermissions = {
    isQuasarAdmin,
    isQuasarUser,
    isQuasarReader,
    ALLOW_DELETE_GRAPH_BTN: isQuasarAdmin || isQuasarUser,
    ALLOW_UPLOAD_GRAPH_BTN: isQuasarAdmin || isQuasarUser,
    ALLOW_EDIT_NODE_BTN: isQuasarAdmin || isQuasarUser,
  };

  return { ...ConfigUserPermissionsObject };
}
