import React from 'react';

export const CsvIcon = () => {
  return (
    <svg viewBox="0 0 200 241" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M139.67 49.25C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0H26.91C19.773 0 12.9284 2.83523 7.88176 7.88184C2.83516 12.9284 0 19.7729 0 26.9099V189.13C0.0105984 196.26 2.85043 203.094 7.89589 208.132C12.9413 213.17 19.78 216 26.91 216H137.51C141.045 216.001 144.545 215.306 147.811 213.955C151.077 212.603 154.045 210.621 156.545 208.122C159.045 205.623 161.028 202.656 162.381 199.39C163.734 196.125 164.43 192.625 164.43 189.09V49.21L139.67 49.25Z"
          fill="#00C650"
        />
      </g>
      <path
        d="M164.43 49.25H139.67C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.23 39.3387 119.055 34.097 119.05 28.6299V0L164.43 49.25Z"
        fill="#00C650"
      />
      <path
        d="M183.94 170.61H58.8998C50.0302 170.61 42.8398 177.801 42.8398 186.67V223.95C42.8398 232.82 50.0302 240.01 58.8998 240.01H183.94C192.81 240.01 200 232.82 200 223.95V186.67C200 177.801 192.81 170.61 183.94 170.61Z"
        fill="#00C650"
      />
      <path d="M80.49 213.12V220.37H73V213.12H80.49Z" fill="white" />
      <path
        d="M107.4 193.14C109.93 195.189 111.65 198.071 112.25 201.271H104.59C104.079 199.943 103.175 198.802 102 198.001C100.763 197.19 99.3083 196.774 97.8297 196.811C96.8548 196.781 95.8862 196.976 94.9986 197.38C94.1109 197.784 93.3278 198.386 92.7097 199.14C91.3355 200.917 90.6437 203.128 90.7597 205.371C90.6429 207.605 91.3352 209.806 92.7097 211.571C93.3303 212.321 94.1143 212.919 95.0018 213.319C95.8892 213.72 96.8566 213.912 97.8297 213.88C99.3083 213.917 100.763 213.502 102 212.691C103.175 211.905 104.085 210.783 104.61 209.471H112.27C111.657 212.66 109.94 215.532 107.42 217.581C104.716 219.659 101.367 220.721 97.9597 220.581C95.2759 220.64 92.626 219.973 90.2897 218.65C88.1007 217.385 86.3283 215.508 85.1897 213.251C83.9571 210.799 83.3394 208.084 83.3897 205.341C83.3382 202.594 83.9559 199.875 85.1897 197.42C86.3283 195.163 88.1007 193.286 90.2897 192.021C92.626 190.699 95.2759 190.032 97.9597 190.091C101.366 189.973 104.706 191.052 107.4 193.14Z"
        fill="white"
      />
      <path
        d="M136.63 216.3C135.768 217.675 134.534 218.778 133.07 219.48C131.288 220.314 129.336 220.718 127.37 220.66C124.409 220.78 121.491 219.918 119.07 218.21C118.001 217.406 117.126 216.372 116.509 215.185C115.893 213.997 115.552 212.687 115.51 211.35H123.22C123.273 212.357 123.706 213.307 124.43 214.01C125.146 214.68 126.099 215.04 127.08 215.01C127.884 215.051 128.673 214.778 129.28 214.25C129.543 213.989 129.75 213.677 129.888 213.333C130.026 212.989 130.091 212.62 130.08 212.25C130.089 211.9 130.029 211.551 129.902 211.225C129.775 210.899 129.584 210.601 129.34 210.35C128.815 209.816 128.189 209.392 127.5 209.1C126.76 208.77 125.75 208.38 124.45 207.93C122.863 207.408 121.313 206.78 119.81 206.05C118.575 205.411 117.514 204.481 116.72 203.34C115.806 201.955 115.355 200.317 115.43 198.66C115.389 197.017 115.861 195.402 116.78 194.04C117.725 192.709 119.024 191.669 120.53 191.04C122.272 190.335 124.141 189.994 126.02 190.04C128.861 189.889 131.666 190.727 133.96 192.41C134.939 193.203 135.74 194.194 136.312 195.317C136.884 196.44 137.214 197.671 137.28 198.93H129.45C129.354 198.05 128.975 197.226 128.37 196.58C128.057 196.284 127.688 196.054 127.284 195.904C126.88 195.755 126.45 195.689 126.02 195.71C125.273 195.671 124.538 195.914 123.96 196.39C123.69 196.644 123.479 196.956 123.344 197.302C123.209 197.648 123.153 198.019 123.18 198.39C123.171 198.721 123.229 199.051 123.349 199.36C123.469 199.669 123.65 199.951 123.88 200.19C124.376 200.712 124.975 201.124 125.64 201.4C126.34 201.71 127.36 202.11 128.64 202.59C130.256 203.11 131.831 203.751 133.35 204.51C134.596 205.18 135.668 206.132 136.48 207.29C137.407 208.691 137.866 210.351 137.79 212.03C137.797 213.532 137.396 215.008 136.63 216.3Z"
        fill="white"
      />
      <path
        d="M147.44 190.43L154.77 212.96L162.1 190.43H169.85L159.43 220.37H150.07L139.69 190.43H147.44Z"
        fill="white"
      />
      <path
        d="M118.89 150.8H45.5403C39.1903 150.8 34.0303 144.48 34.0303 136.7V83.51C34.0303 75.74 39.1903 69.4199 45.5403 69.4199H118.89C125.24 69.4199 130.4 75.74 130.4 83.51V136.7C130.4 144.48 125.24 150.8 118.89 150.8ZM45.5403 76.6201C42.4403 76.6201 39.9103 79.71 39.9103 83.51V136.7C39.9103 140.5 42.4403 143.59 45.5403 143.59H118.89C121.99 143.59 124.51 140.5 124.51 136.7V83.51C124.51 79.71 121.99 76.6201 118.89 76.6201H45.5403Z"
        fill="#00C650"
      />
      <path d="M127.46 118.77H36.9697V125.98H127.46V118.77Z" fill="#00C650" />
      <path d="M127.46 94.0498H36.9697V101.26H127.46V94.0498Z" fill="#00C650" />
      <path d="M104.29 73.0195H97.0801V147.19H104.29V73.0195Z" fill="#00C650" />
      <path
        d="M67.3496 73.0195H60.1396V147.19H67.3496V73.0195Z"
        fill="#00C650"
      />
    </svg>
  );
};

export default CsvIcon;
