import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { GenericObject, INewDocument } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledTableCell, StyledChip } from './FileTable.styles';
import { Grid, IconButton } from '@mui/material';
import { AxiosResponse, AxiosError } from 'axios';
import axios from 'api/axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Refresh } from '@mui/icons-material';
import { FilesContext } from 'components/FilesProvider';
import { stableSort } from 'common/utils/stableSort';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreOptions, { Option } from 'components/MoreOptions';
import EditMetadataDialog from 'components/EditMetadataDialog';
import { SnackbarContext } from 'components/SnackbarProvider';
import {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CollectionContext } from 'components/CollectionProvider';
import { getAllMetadatas } from 'components/MetadataProvider';
import { IMetadataSettings } from 'components/MetadataSettings';

interface FilesTableProps {
  collection: string;
  rows: INewDocument[];
  selectedRows: string[];
  getFileList: () => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  handleSelection: (doc_name: string) => void;
}

export type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof INewDocument;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'doc_name',
    numeric: false,
    disablePadding: true,
    label: 'Document Name',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: true,
    label: 'Metadata',
  },
  {
    id: 'last_update_time',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'chunk_count',
    numeric: false,
    disablePadding: true,
    label: 'Chunk Count',
  },
  {
    id: 'size',
    numeric: false,
    disablePadding: true,
    label: 'Size',
  },
  {
    id: 'uploaded by',
    numeric: false,
    disablePadding: true,
    label: 'Added by',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  metadataMenu: Option[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof INewDocument,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  handleSelectOption: (option: string) => void;
}

interface IDownloadFileDialog {
  doc_name: string | null;
  open: boolean;
  handleDialogClose: () => void;
}

const INITIAL_METADATA_MENU = [{ name: 'Edit', icon: <ModeEditIcon /> }];

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    // metadataMenu,
    onRequestSort,
    // handleSelectOption,
  } = props;
  const createSortHandler =
    (property: keyof INewDocument) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { index } = useParams();

  return (
    <TableHead>
      <TableRow>
        {index !== 'default_doc_idx' && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="secondary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </StyledTableCell>
        )}

        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              {/* {headCell.label === 'Metadata' && (
                <MoreOptions
                  options={metadataMenu}
                  position="vertical"
                  onSelectOption={handleSelectOption}
                />
              )} */}
            </Box>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function DownloadFileDialog(props: IDownloadFileDialog) {
  const { open, handleDialogClose, doc_name } = props;
  const { index } = useParams();

  const downloadDocument = () => {
    axios({
      method: 'get',
      url: `/docdownload/${index}/${doc_name}`,
      responseType: 'blob',
    })
      .then((response: AxiosResponse) => {
        if (!doc_name) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc_name);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) link.parentNode.removeChild(link);
        handleDialogClose();
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        return error;
      });
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="xs">
      <DialogTitle>Download File</DialogTitle>
      <DialogContent>Do you want to download {doc_name}?</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={downloadDocument}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function FileTable(props: FilesTableProps) {
  const createAlert = useContext(SnackbarContext);
  const { collectionsDetails } = useContext(CollectionContext);
  const { currentIndex } = useContext(FilesContext);
  const {
    rows,
    selectedRows,
    collection,
    getFileList,
    setSelectedRows,
    handleSelection,
  } = props;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof INewDocument>('doc_name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [openDownloadFileDialog, setOpenDownloadFileDialog] = useState(false);
  const selectedFileName = useRef<null | string>(null);
  const [editMetadata, setEditMetadata] = useState(false);
  const [indexMetadata, setIndexMetadata] = useState<GenericObject[] | null>(
    null,
  );
  const [indexMandatoryMetadata, setIndexMandatoryMetadata] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [fileToEdit, setFileToEdit] = useState<string | null>(null);

  const handleSelectOption = (option: string) => {
    if (!fileToEdit && selectedRows.length < 1) {
      createAlert({
        message: `Please select one file`,
        severity: 'info',
      });
      return;
    }
    if (option === 'Edit') {
      getIndexMetadata();
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof INewDocument,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.doc_name);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleDialogClose = () => setOpenDownloadFileDialog(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (doc_name: string) =>
    selectedRows.indexOf(doc_name) !== -1;

  const isPdforTxtFile = (doc_name: string) => {
    return (
      doc_name.includes('pdf') ||
      doc_name.includes('txt') ||
      doc_name.includes('docx')
    );
  };

  const handleRowClick = ({
    doc_name,
    metadata,
  }: {
    doc_name: string;
    metadata: { [x: string]: string[] };
  }) => {
    selectedFileName.current = doc_name;
    const openEditor = isPdforTxtFile(doc_name);
    if (openEditor) {
      navigate(`/collection/${collection}/document/${doc_name}`);
    } else {
      if (!metadata) return setOpenDownloadFileDialog(true);
      const metadataValues = Object.values(metadata).flat();
      const containsScrub = metadataValues.includes('scrub');
      !containsScrub && setOpenDownloadFileDialog(true);
    }
  };

  const getStyledChip = (metadatas: { [x: string]: string[] }) => {
    let keys = Object.keys(metadatas);
    return keys.map((e) => {
      if (
        metadatas &&
        metadatas[e] &&
        metadatas[e].length > 0 &&
        typeof metadatas[e] === 'object'
      ) {
        return metadatas[e].map((item) => {
          return item === '' ? (
            <Fragment key={crypto.randomUUID()}></Fragment>
          ) : (
            <StyledChip key={crypto.randomUUID()} label={item} />
          );
        });
      }
      return <Fragment key={crypto.randomUUID()}></Fragment>;
    });
  };

  const visibleRows = useMemo(
    () =>
      stableSort({ rows, order, orderBy }).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const getIndexMetadata = useCallback(() => {
    if (indexMetadata) return setEditMetadata(true);

    const notifyError = (message?: string) => {
      createAlert({
        message:
          message ??
          'Something went wrong while trying to get the collection metadata',
        severity: 'error',
      });
    };

    if (!collectionsDetails) return notifyError();
    const selectedIndex = collectionsDetails?.find(
      (index) => index.index_name === collection,
    );
    if (!selectedIndex) return notifyError();

    getAllMetadatas(true, collection)
      .then((response) => {
        const data = response as unknown as IMetadataSettings;
        const rawIndexMetadata = data.filter((m) => m.status);

        const indexmandatoryMetadata = selectedIndex.mandatory_metadata;
        const indexMandatoryMetadaObj =
          indexmandatoryMetadata.find(
            (item) => item && Object.keys(item).length > 0,
          ) ?? null;

        if (!rawIndexMetadata && !indexMandatoryMetadaObj) {
          return notifyError(
            `This collection does not have metadata configured yet`,
          );
        }

        const metadata = rawIndexMetadata.flatMap((obj) =>
          obj.items.map((item) => ({
            category: obj.name,
            subcategory: item,
          })),
        );

        setIndexMetadata(metadata);
        setIndexMandatoryMetadata(indexMandatoryMetadaObj);
        setEditMetadata(true);
      })
      .catch(() => {
        notifyError();
      });
  }, [collection, indexMetadata, collectionsDetails, createAlert]);

  const handleCloseEditMetadataDialog = () => {
    setEditMetadata(false);
    setFileToEdit(null);
  };

  return (
    <>
      {indexMetadata && (
        <EditMetadataDialog
          open={editMetadata}
          collection={collection}
          files={visibleRows}
          selectedFileName={fileToEdit}
          selectedFiles={selectedRows}
          indexMetadata={indexMetadata}
          indexMandatoryMetadata={indexMandatoryMetadata}
          getFileList={getFileList}
          onClose={handleCloseEditMetadataDialog}
        />
      )}
      <Grid
        container
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <IconButton title="Refresh document list" onClick={getFileList}>
          <Refresh />
        </IconButton>
      </Grid>
      <DownloadFileDialog
        doc_name={selectedFileName.current}
        open={openDownloadFileDialog}
        handleDialogClose={handleDialogClose}
      />
      <Box sx={{ width: '100%', height: '100%' }}>
        <Paper
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - 350px)',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TableContainer
            sx={{
              height: '100%',
              width: '100%',
              padding: '0px 16px',
              borderRadius: 2,
              px: 3,
              py: 2,
            }}
          >
            <Table
              sx={{
                minWidth: 750,
                '& .Mui-selected': {
                  backgroundColor: '#F2F2F2 !important',
                },
                '& .table-select-cell:focus': {
                  color: '#03000F',
                  backgroundColor: '#F2F2F2',
                },
                '& .table-select-cell:hover': {
                  color: '#03000F',
                  backgroundColor: '#F2F2F2',
                },
              }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                numSelected={selectedRows.length}
                order={order}
                orderBy={orderBy}
                rowCount={rows.length}
                metadataMenu={INITIAL_METADATA_MENU}
                onRequestSort={handleRequestSort}
                handleSelectOption={handleSelectOption}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.doc_name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.doc_name}
                      selected={isItemSelected}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {currentIndex !== 'default_doc_idx' && (
                        <StyledTableCell
                          onClick={() => handleSelection(row.doc_name)}
                          padding="checkbox"
                        >
                          <Checkbox
                            color="secondary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        onClick={() =>
                          handleRowClick({
                            doc_name: row.doc_name,
                            metadata: row.metadata,
                          })
                        }
                      >
                        {row.doc_name}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          handleRowClick({
                            doc_name: row.doc_name,
                            metadata: row.metadata,
                          })
                        }
                        sx={{
                          gap: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          {row.metadata
                            ? getStyledChip(row.metadata)
                            : 'Not Defined'}
                        </Box>
                        <MoreOptions
                          onClick={() => setFileToEdit(row.doc_name)}
                          options={INITIAL_METADATA_MENU}
                          position="vertical"
                          onSelectOption={handleSelectOption}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          handleRowClick({
                            doc_name: row.doc_name,
                            metadata: row.metadata,
                          })
                        }
                      >
                        {row.last_update_time}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          handleRowClick({
                            doc_name: row.doc_name,
                            metadata: row.metadata,
                          })
                        }
                      >
                        {row.chunk_count}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          handleRowClick({
                            doc_name: row.doc_name,
                            metadata: row.metadata,
                          })
                        }
                      >
                        {row.size}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          handleRowClick({
                            doc_name: row.doc_name,
                            metadata: row.metadata,
                          })
                        }
                      >
                        {row['uploaded by']}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ overflowY: 'hidden' }}
          />
        </Paper>
      </Box>
    </>
  );
}
