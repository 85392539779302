import React from 'react';

export const PngIcon = () => {
  return (
    <svg viewBox="0 0 201 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M140.24 49.24C134.773 49.2347 129.531 47.0606 125.666 43.1947C121.8 39.3288 119.626 34.0871 119.62 28.62V0H27.4803C20.3433 0 13.4987 2.83517 8.45209 7.88177C3.40548 12.9284 0.570312 19.773 0.570312 26.91V189.13C0.580911 196.26 3.42073 203.094 8.46619 208.132C13.5116 213.17 20.3502 216 27.4803 216H138.09C145.222 216 152.062 213.169 157.108 208.129C162.154 203.089 164.992 196.252 165 189.12V49.24H140.24Z"
          fill="#005FAD"
        />
      </g>
      <path
        d="M165 49.24H140.24C134.773 49.2347 129.532 47.0606 125.666 43.1947C121.8 39.3288 119.626 34.0871 119.62 28.62V0L165 49.24Z"
        fill="#005FAD"
      />
      <path
        d="M184.51 170.6H59.4702C50.6005 170.6 43.4102 177.79 43.4102 186.66V223.94C43.4102 232.809 50.6005 240 59.4702 240H184.51C193.38 240 200.57 232.809 200.57 223.94V186.66C200.57 177.79 193.38 170.6 184.51 170.6Z"
        fill="#005FAD"
      />
      <path d="M79.6306 213.37V220.64H72.1006V213.37H79.6306Z" fill="white" />
      <path
        d="M90.6902 210.1V220.64H83.4102V190.57H95.1902C98.7702 190.57 101.494 191.457 103.36 193.23C104.308 194.16 105.048 195.279 105.532 196.515C106.017 197.75 106.234 199.075 106.17 200.4C106.202 202.149 105.767 203.875 104.91 205.4C104.042 206.893 102.746 208.091 101.19 208.84C99.3192 209.733 97.262 210.165 95.1902 210.1H90.6902ZM98.7702 200.4C98.7702 197.734 97.3102 196.4 94.3902 196.4H90.6902V204.23H94.3902C97.3102 204.257 98.7702 202.98 98.7702 200.4Z"
        fill="white"
      />
      <path
        d="M136.451 220.64H129.18L117 202.18V220.64H109.73V190.57H117L129.17 209.2V190.57H136.44L136.451 220.64Z"
        fill="white"
      />
      <path
        d="M164.86 193C167.287 194.815 168.917 197.499 169.41 200.49H161.71C161.205 199.418 160.394 198.521 159.38 197.909C158.235 197.23 156.922 196.884 155.59 196.909C154.556 196.867 153.524 197.051 152.569 197.451C151.613 197.85 150.757 198.453 150.06 199.219C148.599 200.975 147.856 203.219 147.98 205.5C147.98 208.36 148.71 210.556 150.17 212.09C150.991 212.886 151.969 213.502 153.042 213.898C154.115 214.295 155.259 214.462 156.4 214.39C158.032 214.418 159.628 213.916 160.95 212.96C162.297 211.951 163.268 210.523 163.71 208.9H154.53V203.669H169.8V211C169.209 212.788 168.282 214.448 167.07 215.89C165.761 217.442 164.13 218.691 162.29 219.549C160.204 220.513 157.927 220.991 155.63 220.95C152.839 221.02 150.079 220.351 147.63 219.01C145.389 217.753 143.565 215.869 142.38 213.59C141.113 211.134 140.477 208.402 140.53 205.64C140.47 202.86 141.106 200.11 142.38 197.64C143.563 195.365 145.38 193.483 147.61 192.219C150.023 190.89 152.746 190.224 155.5 190.289C158.835 190.126 162.129 191.08 164.86 193Z"
        fill="white"
      />
      <path
        d="M55.2705 66.7197H41.5205V80.4697H55.2705V66.7197Z"
        fill="#005FAD"
      />
      <path
        d="M69.0303 80.4697H55.2803V94.2197H69.0303V80.4697Z"
        fill="#005FAD"
      />
      <path
        d="M82.7803 66.7197H69.0303V80.4697H82.7803V66.7197Z"
        fill="#005FAD"
      />
      <path
        d="M96.5303 80.4697H82.7803V94.2197H96.5303V80.4697Z"
        fill="#005FAD"
      />
      <path
        d="M110.301 66.7197H96.5508V80.4697H110.301V66.7197Z"
        fill="#005FAD"
      />
      <path
        d="M124.051 80.4697H110.301V94.2197H124.051V80.4697Z"
        fill="#005FAD"
      />
      <path
        d="M55.2705 94.2002H41.5205V107.95H55.2705V94.2002Z"
        fill="#005FAD"
      />
      <path d="M69.0303 107.95H55.2803V121.7H69.0303V107.95Z" fill="#005FAD" />
      <path
        d="M82.7803 94.2002H69.0303V107.95H82.7803V94.2002Z"
        fill="#005FAD"
      />
      <path d="M96.5303 107.95H82.7803V121.7H96.5303V107.95Z" fill="#005FAD" />
      <path
        d="M110.301 94.2002H96.5508V107.95H110.301V94.2002Z"
        fill="#005FAD"
      />
      <path d="M124.051 107.95H110.301V121.7H124.051V107.95Z" fill="#005FAD" />
      <path
        d="M55.2705 121.811H41.5205V135.561H55.2705V121.811Z"
        fill="#005FAD"
      />
      <path
        d="M69.0303 135.561H55.2803V149.311H69.0303V135.561Z"
        fill="#005FAD"
      />
      <path
        d="M82.7803 121.811H69.0303V135.561H82.7803V121.811Z"
        fill="#005FAD"
      />
      <path
        d="M96.5303 135.561H82.7803V149.311H96.5303V135.561Z"
        fill="#005FAD"
      />
      <path
        d="M110.301 121.811H96.5508V135.561H110.301V121.811Z"
        fill="#005FAD"
      />
      <path
        d="M124.051 135.561H110.301V149.311H124.051V135.561Z"
        fill="#005FAD"
      />
    </svg>
  );
};

export default PngIcon;
