import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { encode } from 'html-entities';

import LexicalEditorWrapper from 'components/LexicalEditor/LexicalEditorWrapper';
import ComponentLoading from 'components/ComponentLoading';
import { ChatContext } from 'components/Page/ChatPage';
import { IOrderedContent } from 'components/Page/DocumentPage';

interface DocumentEditorProps {
  documentContent: React.MutableRefObject<IOrderedContent[] | undefined>;
}

const DocumentEditor = ({ documentContent }: DocumentEditorProps) => {
  const context = useContext(ChatContext);
  const messagesToReview = context?.messagesToReview;
  const [text, setText] = useState<IOrderedContent[]>();

  const onDocContentUpdate = ({ nodeArray }: { nodeArray: [] }) => {
    const newContent: IOrderedContent[] = [];
    nodeArray.forEach(
      (node: {
        key: number;
        value: { __text?: string; __src?: string; __altText: string };
      }) => {
        if (node.value.__src)
          return newContent.push({
            src: node.value.__src,
            startIndex: node.key,
            imagePath: node.value.__altText,
          });
        if (node.value.__text)
          return newContent.push({
            startIndex: node.key,
            text: encode(node.value.__text),
          });
      },
    );
    if (!newContent) return;
    documentContent.current = newContent;
  };

  const getDocumentContent = useCallback(() => {
    if (!messagesToReview) return;
    const docuemntContent: IOrderedContent[] = messagesToReview?.map(
      (message, key) => ({
        startIndex: key,
        text: `${message.message}\n\n`,
      }),
    );
    setText(docuemntContent);
    documentContent.current = docuemntContent;
  }, [messagesToReview, documentContent]);

  useEffect(() => {
    getDocumentContent();
  }, [getDocumentContent]);

  return (
    <Grid container wrap="nowrap" direction="column" height="100%">
      <Grid
        item
        borderBottom="1px solid #B3B2B5"
        paddingX={3}
        paddingY={2}
        justifyContent="space-between"
        sx={{ backgroundColor: '#FAFAFA' }}
      >
        <Typography variant="h6" fontWeight="bold">
          Edit Document
        </Typography>
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection={'column'}
        flexGrow={1}
        sx={{ overflowY: 'auto' }}
      >
        {!text ? (
          <ComponentLoading />
        ) : (
          <Box sx={{ overflowY: 'auto' }}>
            <LexicalEditorWrapper
              currentChunk={text}
              onContentUpdate={onDocContentUpdate}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentEditor;
