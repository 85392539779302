import { useContext, useState, useCallback, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { SnackbarContext } from 'components/SnackbarProvider';
import { useNavigate } from 'react-router-dom';

import axios from 'api/axios';
import {
  MultiSourceConfigResponse,
  FormValues,
  FormConfig,
  SourceType,
} from 'types';
import {
  getEmptyConfig,
  getFormInputs,
} from 'common/utils/getSourceFormInputs';
interface UseMultiUploadFormProps {
  index: string;
  isNewConfig?: boolean;
}

function useMultiUploadForm({ index, isNewConfig }: UseMultiUploadFormProps) {
  const createAlert = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState<string[] | null>(null);
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [storedConfigurations, setStoredConfigurations] = useState<
    MultiSourceConfigResponse[]
  >([]);

  const getConfigurations = useCallback(async () => {
    try {
      const response = await axios.get('/multisourceconfig');
      setStoredConfigurations(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
    }
  }, []);

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);
  const navigate = useNavigate();

  const getSubCategories = useCallback(async () => {
    let {
      categoryID,
      subCategories,
      error,
    }: { subCategories?: string[]; categoryID?: string; error?: AxiosError } =
      {};

    setLoading(true);

    await axios({
      method: 'get',
      url: `/getconfigurable`,
    })
      .then((response: AxiosResponse) => {
        const data: {
          id: string;
          categoryLabel: string;
        }[] = response.data;
        categoryID = data.filter(
          (category) => category.categoryLabel === 'multisource',
        )[0].id;
      })
      .catch((errorResponse: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(errorResponse);
        }
        createAlert({
          message: `Something went wrong`,
          severity: 'error',
        });
        error = errorResponse;
      });

    if (!categoryID) {
      setLoading(false);
      createAlert({
        message: `Something went wrong`,
        severity: 'error',
      });
    }

    await axios({
      method: 'get',
      url: `/getconfigurable/${categoryID}`,
    })
      .then((response: AxiosResponse) => {
        const data: {
          label: string;
          id: string;
        }[] = response.data;
        subCategories = data.map((selectedSub) => selectedSub.label);
        setSubCategories(subCategories);
      })
      .catch((errorResponse: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(errorResponse);
        }
        createAlert({
          message: `Something went wrong`,
          severity: 'error',
        });
        error = errorResponse;
      });
    setLoading(false);
    return { subCategories, error };
  }, [createAlert]);

  const configurateForm = useCallback(
    (initialFormValues: FormValues[]) => {
      if (!subCategories) return;
      let initialFormConfig: FormConfig = {};
      initialFormValues.forEach((initialValues) => {
        const newElement = {
          formInputs: getFormInputs({ subCategories, initialValues }),
          formValues: initialValues,
        };
        initialFormConfig[initialValues.source_type]
          ? initialFormConfig[initialValues.source_type].push(newElement)
          : (initialFormConfig[initialValues.source_type] = [newElement]);
      });
      setFormConfig(initialFormConfig);
    },
    [subCategories],
  );

  const getConfigList = useCallback(() => {
    setLoading(true);
    axios({
      method: 'get',
      url: `/multisourceconfig`,
    })
      .then((response: AxiosResponse) => {
        const config: MultiSourceConfigResponse[] = response.data;
        const selectedConfig = config.filter(
          (c) => c.connection_config.index_name === index,
        );
        if (selectedConfig.length === 0) return;
        let prepopulatedConfig: FormValues[] = selectedConfig.map(
          (config, i) => ({
            key: `${crypto.randomUUID()}`,
            config_name: selectedConfig[i].config_name,
            source_type: selectedConfig[i].source_type,
            pull_interval: selectedConfig[i].pull_interval,
            source_folder: selectedConfig[i].connection_config.source_folder,
            index_name: index,
            subcategory: selectedConfig[i].subcategory ?? '',
            region: selectedConfig[i].connection_config.region,
            bucket_name: selectedConfig[i].connection_config.bucket_name,
            container_name: selectedConfig[i].connection_config.container_name,
            base_url: selectedConfig[i].connection_config.base_url,
            space_key: selectedConfig[i].connection_config.space_key,
            page_title: selectedConfig[i].connection_config.page_title,
            enabled: selectedConfig[i].connection_config.enabled,
            access_token: selectedConfig[i].connection_config.access_token,
            verify: selectedConfig[i].connection_config.verify,
            keep_folder_path:
              selectedConfig[i].connection_config.keep_folder_path,
            pull_entire_structure:
              selectedConfig[i].connection_config.pull_entire_structure,
          }),
        );
        prepopulatedConfig.forEach((config, i) => {
          Object.keys(config).forEach(
            (key) =>
              config[key as keyof FormValues] === undefined &&
              delete prepopulatedConfig[i][key as keyof FormValues],
          );
        });
        configurateForm(prepopulatedConfig);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        createAlert({
          message: `Something went wrong`,
          severity: 'error',
        });
      })
      .finally(() => setLoading(false));
  }, [configurateForm, index, createAlert]);

  const removeConfiguration = useCallback(
    ({
      key,
      source_type,
      config_name,
    }: {
      key: string;
      source_type: string;
      config_name: string;
    }) => {
      if (!formConfig || !formConfig[source_type]) {
        createAlert({
          message: 'No configurations available',
          severity: 'warning',
        });
        return;
      }

      const config = formConfig[source_type].find(
        (c) => c.formValues.key === key,
      );

      if (!config) {
        createAlert({
          message: 'No configuration found',
          severity: 'warning',
        });
        return;
      }

      // const { formValues } = config;
      const filteredConfig = formConfig[source_type].filter(
        (c) => c.formValues.key !== key,
      );

      // const selectedConfigName = config.formValues.config_name;

      setFormConfig((prev) => {
        if (!prev) return {};
        if (filteredConfig.length === 0) {
          const { [source_type]: _, ...rest } = prev;
          return rest;
        }
        return { ...prev, [source_type]: filteredConfig };
      });

      axios({
        method: 'delete',
        url: `/multisourceconfig/${config_name}`,
      })
        .then((_response: AxiosResponse) => {
          createAlert({
            message: `Configuration '${config_name}' deleted`,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Something went wrong`,
            severity: 'error',
          });
        });
    },
    [createAlert, formConfig],
  );

  const handleCreateConfig = ({
    source_type,
    prevFormValues,
  }: {
    source_type: SourceType;
    prevFormValues?: FormValues[];
  }) => {
    const initialValues = getEmptyConfig({
      index,
      source_type,
    });
    const formValues = prevFormValues
      ? [...prevFormValues, ...initialValues]
      : initialValues;
    configurateForm(formValues);
  };

  const checkContainerExists = async (
    containerName: string,
  ): Promise<boolean> => {
    if (!storedConfigurations || storedConfigurations.length === 0) {
      return false;
    }
    return storedConfigurations.some(
      (config) => config.connection_config.container_name === containerName,
    );
  };

  const isValidContainerName = (
    name: string,
    sourceType: SourceType,
  ): boolean => {
    if (sourceType !== 'azure_blob') {
      return true;
    } else {
      const strictPattern = /^[a-z0-9-]+$/;
      return strictPattern.test(name) && name.length >= 3 && name.length <= 63;
    }
  };

  const validateConfig = async (config: FormValues[]): Promise<void> => {
    setLoading(true);

    try {
      const validationPromises = config.map(
        async ({ key, ...validationPayload }) => {
          const sourceType = validationPayload.source_type || '';
          if (sourceType === 'azure_blob') {
            const containerName = validationPayload.container_name || '';
            if (!containerName) {
              throw new Error('Container name is empty');
            }
            if (!(await checkContainerExists(containerName))) {
              throw new Error(`Container "${containerName}" does not exist.`);
            }
            if (!isValidContainerName(containerName, sourceType)) {
              throw new Error(
                `Invalid container name for Azure. Container names must adhere to specific rules.`,
              );
            }
          }

          return axios({
            method: 'post',
            url: '/multisourceconfig',
            headers: {
              'Content-Type': 'application/json',
            },
            data: { ...validationPayload, verify: true, enabled: true },
          });
        },
      );

      await Promise.all(validationPromises);
      setIsSaveEnabled(true);
      createAlert({
        message: 'Validation successful',
        severity: 'success',
      });
    } catch (error: unknown) {
      setIsSaveEnabled(false);

      const errorMessage = 'Validation failed: ';
      createAlert({
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateConfig = async (updatedConfig: FormValues[]) => {
    setLoading(true);

    try {
      await Promise.all(
        updatedConfig.map(({ key, ...payload }) =>
          axios({
            method: 'post',
            url: '/multisourceconfig',
            data: { ...payload, verify: false },
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        ),
      );

      createAlert({
        message: `Configuration saved successfully`,
        severity: 'success',
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      createAlert({
        message: `Unable to save configuration`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubCategories();
  }, [getSubCategories]);

  return {
    loading,
    formConfig,
    subCategories,
    getConfigList,
    handleCreateConfig,
    removeConfiguration,
    validateConfig,
    updateConfig,
    isSaveEnabled,
    getEmptyConfig,
  };
}

export default useMultiUploadForm;
