import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'api/axios';

import { ConfirmationDialog } from '../UI';
import { CreateAsyncJobRequest, GetFrequentTasksResponse, Job } from 'types';
import { SnackbarContext } from 'components/SnackbarProvider';
import { Item } from 'common/hooks/useUpdateJobs';

interface UpdateJobsDialogProps {
  open: boolean;
  linkedJobs: Job[];
  updateJobUpdatesAvailableList: (newItem: Item) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateJobsDialog = ({
  open,
  linkedJobs,
  setOpen,
  setLoading,
  updateJobUpdatesAvailableList,
}: UpdateJobsDialogProps) => {
  const { index } = useParams();
  const createAlert = useContext(SnackbarContext);
  const [baypassChecked, setBaypassChecked] = useState(false);
  const [selectedJobs, setSelectedJobs] =
    useState<GetFrequentTasksResponse | null>(null);
  const jobListLength = linkedJobs.length;
  const selectedJobsLength = selectedJobs?.length ?? 0;

  const onClose = () => setOpen(false);

  const onSelectCollection = (selectedJob: Job) => {
    const hash = selectedJob.hash;
    setSelectedJobs((prev) => {
      if (!prev) return [selectedJob];
      const isAlreadyIncluded = prev.find((job) => job.hash === hash);
      const newList = isAlreadyIncluded
        ? prev.filter((job) => job.hash !== hash)
        : [...prev, selectedJob];
      return newList;
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'selectAll') setSelectedJobs(checked ? linkedJobs : []);
    if (name === 'baypass') setBaypassChecked(checked);
  };

  const hanldeSubmit = () => {
    if (!index) return;

    setLoading(true);

    selectedJobs?.forEach(async (job, idx) => {
      const body: CreateAsyncJobRequest = {
        hash: job.hash,
        overwrite: true, //set by default
        bypass_initialise_task: baypassChecked, //value taken from form
        input_collections: [index], // Updated collection
      };
      await axios({
        method: 'post',
        url: '/createasyncjob',
        data: body,
      })
        .then((_response: AxiosResponse) => {
          createAlert({
            message: `Job${selectedJobsLength > 1 ? 's' : ''} updated successfully`,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          createAlert({
            message: `Unable to update the job${selectedJobsLength > 1 ? 's' : ''}`,
            severity: 'error',
          });
        })
        .finally(() => {
          idx === selectedJobsLength - 1 && setLoading(false);
        });

      // Set the jobs as updated
      updateJobUpdatesAvailableList({
        index,
        readyToBeUpdated: false,
        updateAvailable: false,
      });
    });
  };

  return (
    <ConfirmationDialog
      title="Update job sources associated with this collection"
      form
      size={'md'}
      open={open}
      onClose={onClose}
      leftButtonProps={{
        name: 'Cancel',
        color: 'inherit',
        variant: 'outlined',
        onClick: onClose,
      }}
      rightButtonProps={{
        name: 'Update',
        variant: 'contained',
        color: 'secondary',
        type: 'submit',
        disabled: selectedJobsLength === 0,
        onClick: hanldeSubmit,
      }}
      content={
        <>
          <Typography variant="body1" sx={{ paddingY: 1 }}>
            The Licensing Requests collection is used by{' '}
            <span style={{ fontWeight: 'bold' }}>{jobListLength} tasks</span> as
            input. Do you want to regenerate tasks associated with this
            collection?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              gap: 3,
              flexDirection: 'column',
            }}
          >
            <Box display={'flex'}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="selectAll"
                      color="secondary"
                      onChange={handleCheckboxChange}
                      checked={jobListLength === selectedJobsLength}
                    />
                  }
                  label={'Select All'}
                />
              </FormGroup>
            </Box>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Tasks
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {linkedJobs.map((job, idx) => (
                    <Grid item xs={4} sm={3} lg={3} key={`${job.hash}_${idx}`}>
                      <Chip
                        color={
                          selectedJobs?.includes(job) ? 'primary' : 'default'
                        }
                        sx={{ width: '200px' }}
                        label={job.name ?? job.task}
                        onClick={() => onSelectCollection(job)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              padding: 2,
              flexDirection: 'column',
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="bypass"
                    color="secondary"
                    onChange={handleCheckboxChange}
                    checked={baypassChecked}
                  />
                }
                label={'Bypass'}
              />
            </FormGroup>
            <Typography variant="caption">
              Checking this box will update the source without regenerating.
            </Typography>
          </Box>
        </>
      }
    />
  );
};

export default UpdateJobsDialog;
