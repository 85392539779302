import { Box, Button, Chip, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';

import { byteSizeConverter } from 'common/utils';
import { isObjectEmpty, isValidExtension } from 'common/utils/common';
import { SnackbarContext } from 'components/SnackbarProvider';
import { DivStyled } from 'components/Upload/DragDropFile/DragDropFile.styles';
import { FileInput as IFileInput } from 'types';
import { Label } from './FileInput.styles';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FormHelperText from '@mui/material/FormHelperText';

interface FileInputProps {
  id: string;
  file: File | undefined;
  fileName?: string;
  disabled: boolean;
  errorDetected?: number;
  helperText?: string;
  dragAndDrop?: boolean;
  allowedExtensions: string[];
  onSaveFile: (files: IFileInput) => void;
  onRemoveFile: (fileInputKey: string) => void;
}

const FileInput = ({
  id,
  file,
  fileName,
  disabled,
  dragAndDrop,
  helperText,
  errorDetected,
  allowedExtensions,
  onSaveFile,
  onRemoveFile,
}: FileInputProps) => {
  const createAlert = useContext(SnackbarContext);
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleRemove = () => onRemoveFile(id);

  const onFileDrop = (fileList: FileList) => {
    if (fileList.length > 0) {
      const files: IFileInput = {};
      Object.values(fileList).forEach((file) => {
        if (isValidExtension(file.name, allowedExtensions)) {
          return (files[id] = file);
        }
        createAlert({
          message: 'This file is not supported',
          severity: 'error',
        });
        setErrorMessage('Please provide a valid file');
      });
      if (isObjectEmpty(files)) return;
      onSaveFile(files);
      setErrorMessage(null);
    }
  };

  // handle drag events
  const handleDrag = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer?.files) {
      onFileDrop(event.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChangeDnD = function (event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    if (event.target?.files) onFileDrop(event.target.files);
  };

  const getFileName = (f: File) =>
    `${f.name ?? fileName} ${f.size ? byteSizeConverter(f.size) : ''}`;

  useEffect(() => {
    errorDetected && helperText && setErrorMessage(helperText);
  }, [errorDetected, helperText]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '200px' }}>
      {file ? (
        <Chip
          label={getFileName(file)}
          variant="outlined"
          onDelete={handleRemove}
          disabled={disabled}
        />
      ) : (
        <>
          {dragAndDrop ? (
            <Label
              htmlFor={id}
              onDragEnter={(event) => handleDrag(event)}
              className={`${dragActive ? 'drag-active' : ''} ${''}`}
              sx={{ borderColor: '1px solid black' }}
            >
              <Typography>Upload a document</Typography>
              {dragActive && (
                <DivStyled
                  id={id}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                />
              )}
            </Label>
          ) : (
            <Button
              variant={'outlined'}
              color={errorMessage && !disabled ? 'error' : 'secondary'}
              type="button"
              onClick={() => inputRef.current?.click()}
              startIcon={<FolderOpenIcon />}
              disabled={disabled}
            >
              Select document
            </Button>
          )}
          <input
            style={{ opacity: 0, height: '1px', float: 'left' }}
            type="file"
            id={id}
            onChange={handleChangeDnD}
            ref={inputRef}
            // required={!disabled}
            name="template-file"
          />
        </>
      )}
      {errorMessage && !disabled && (
        <FormHelperText sx={{ mx: '14px', color: '#cb2939' }}>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FileInput;
