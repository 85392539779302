import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Modal,
  Typography,
  styled,
} from '@mui/material';
import theme from 'theme';

export const CardContainer = styled(Grid)({
  width: `calc((100% - (8px * 3)) / 3)`,
  minWidth: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  flexDirection: 'column',
  margin: 3,
  gap: 3,
  border: 'solid 1px lightgrey',
  borderRadius: '2px',
  flex: '0 1 calc(25% - 1em)',
  borderTop: `${theme.palette.primary.main} solid 1px`,
});

export const CardTitle = styled(Grid)({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'center',
  alignSelf: 'stretch',
  background: '#fafafa',
  borderBottom: 'solid 1px lightgray',
  ':hover': {
    cursor: 'pointer',
  },
});

export const TitleTypography = styled(Typography)({
  fontWeight: 'bold',
});

export const CardBody = styled(Grid)({
  padding: '5px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const BodyItem = styled(Grid)({
  display: 'flex',
});

export const CardFooter = styled(Grid)({
  padding: '5px',
  borderTop: 'solid 1px lightgray',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

export const FooterActionGroup = styled(ButtonGroup)({
  justifyContent: 'space-between',
  display: 'flex',
  flex: 1,
});

export const FooterAction = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  border: none;
`;

export const EditCardTitle = styled(Grid)({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  background: '#fafafa',
  borderBottom: 'solid 1px lightgray',
  ':hover': {
    cursor: 'pointer',
  },
});

export const StyledMenuItem = styled(Grid)`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MoveModal = styled(Modal)({
  border: 'solid red 1px',
});
export const MoveContainer = styled(Box)({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: 400,
  height: 400,
  border: '2px solid #000',
  boxShadow: '100',
  p: 4,
  transform: 'translate(-50%, -50%)',
  background: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
});
