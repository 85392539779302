import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';

type msg = {
  message: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  downloadBTN?: boolean;
  downloadFileNAME?: string;
};

export default function MessageAlertDialog({
  message,
  open,
  setOpen,
  downloadBTN,
  downloadFileNAME,
}: Readonly<msg>): React.JSX.Element {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Message:'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {downloadBTN && (
          <IconButton
            size="large"
            color="secondary"
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify({
                message,
              }),
            )}`}
            download={
              downloadFileNAME && downloadFileNAME.length < 1
                ? 'message.txt'
                : `${downloadFileNAME}`
            }
          >
            <FileDownloadOutlined color="secondary" />
          </IconButton>
        )}
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
