import React, { useEffect, useState, useContext } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import PrivReq from 'api/axios';
import ComponentLoading from 'components/ComponentLoading';
import { GraphData, ITypeColor } from 'types';
import { Graph } from 'react-d3-graph';
import MessageAlertDialog from '../../../MessageAlertDialog/MessageAlertDialog';
import TypesList from './TypesList';
import EditNodeDialog from './EditNodeDialog';
import DeleteGraphDialog, {
  DeleteGraphButton,
} from 'components/Page/Graphs/DeleteGraphDialog/DeleteGraphDialog';
import ContextDialog from './ContextDialog';
import Typography from '@mui/material/Typography';
import { Download, DownloadDoneOutlined } from '@mui/icons-material';
import { Icon } from '@mui/material';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import { INode, IGraphContext } from './interfaces';

import getGraphContext from './services/getGraphContext';
import { SnackbarContext } from 'components/SnackbarProvider';

export default function GraphPage(): React.JSX.Element {
  const initGraphPayloadState: GraphData = {
    nodes: [
      {
        id: '',
        title: '',
        type: 'Industry',
      },
    ],
    links: [
      {
        source: '',
        target: '',
        type: 'having',
      },
    ],
  };

  const [now, setNow] = useState(Date.now());
  const [loadingGraphs, setLoadingGraphs] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteGraphDialogOpen, setDeleteGraphDialogOpen] = useState(false);
  const [alertMSG, setAlertMSG] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>(initGraphPayloadState);
  const [graphAttr, setGraphAttr] = useState({});
  const [coloredType, setColoredType] = useState<ITypeColor[]>([]);
  const { graph_name, industry } = useParams();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextData, setContextData] = useState<IGraphContext>({
    node_id: '',
    graph_name: '',
    action_id: '',
    industry_name: '',
  });
  const [nodeData, setNodeData] = useState<INode>({
    id: '',
    title: '',
    type: '',
    graph_name: '',
    industry: '',
  });
  const createAlert = useContext(SnackbarContext);
  const { nodes: _nodes } = graphData;

  const colors: string[] = [
    '#607d8b',
    '#009688',
    '#ffc107',
    '#e65100',
    '#b2ff59',
    '#9c27b0',
    '#3f51b5',
    '#673ab7',
    '#2196f3',
    '#009688',
  ];

  const typeColors = (graphData: GraphData, colors: string[]): ITypeColor[] => {
    const uniqueTypes = new Set(graphData.nodes.map(({ type }) => type));
    const _typesWithColors = Array.from(uniqueTypes).map((type, index) => ({
      type,
      color: colors[index % colors.length],
    }));
    return _typesWithColors;
  };

  const updateGraphData = (
    graphData: GraphData,
    coloredTypes: ITypeColor[],
  ): GraphData => {
    const nodes: INode[] = graphData.nodes.map((node) => ({
      ...node,
      color: coloredTypes.find((ct) => ct.type === node.type)?.color || '#000',
    }));
    const links = graphData.links;
    return { nodes, links };
  };

  const handleSubmit = async (node: string) => {
    try {
      setLoadingGraphs(true);
      const resp = await PrivReq(
        `/graphattr/${graph_name}/${node}/right_click_attribute`,
      );
      setGraphAttr(resp.data);
      setLoadingGraphs(false);
      setShowDialog(true);
    } catch (_e) {
      setAlertMSG(`Something went wrong`);
      setShowAlert(true);
    }
  };

  // const handleContextClick = async (type: string, nodeId: string):Promise<{data: any}> => {
  //   const contextNodeData = await PrivReq({
  // headers: {
  // "Content-Type": "application/json"
  // },
  // withCredentials: true,
  //     method: 'get',
  //     url: `/graphcontext/${graph_name}/${type}/${nodeId}`,
  //   })
  //     .then((response: AxiosResponse) => {
  //       return response.data;
  //     })
  //     .catch((error: AxiosError) => {
  //       if (process.env.NODE_ENV === 'development') {
  //
  //       }
  //     });
  //   return contextNodeData;
  // }

  const onClickNode = function (nodeId: string) {
    handleSubmit(nodeId);
  };

  const onClickLink = function (source: any, target: any) {
    setAlertMSG(`Clicked link between ${source} and ${target}`);
    setShowAlert(true);
  };

  const onRightClickNode = async function (event: any, nodeId: string) {
    event.preventDefault();
    let type = '';
    for (let item in graphData.nodes) {
      if (graphData.nodes[item].id == nodeId) {
        type = graphData.nodes[item].type;
      }
    }

    setNodeData({
      id: nodeId,
      title: graph_name ?? '',
      type,
      graph_name,
      industry,
    });

    const { graphContext, status } = await getGraphContext(
      graph_name ?? '',
      type,
      nodeId,
    );

    setContextData(graphContext);
    setTimeout(() => {
      createAlert({
        message: status,
        severity: 'info',
      });
    }, 1000);

    setShowContextMenu(true);
  };

  const onRightClickLink = function (event: any, source: any, target: any) {
    event.preventDefault();
  };

  const customLabelBuilder = function (node: any) {
    return node['title'];
  };

  const saveFile = async (blob: any, filename: string) => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const handleDownloadClick = function () {
    try {
      setLoadingGraphs(true);
      PrivReq({
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        method: 'get',
        url: `/kgfiledownload/${graph_name}/${industry}`,
        responseType: 'blob',
      })
        .then((response: AxiosResponse) => {
          //setContextData(response.data);
          //setShowContextMenu(true);
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const contentDisposition = response.headers['content-disposition'];
          const downloadFilename = contentDisposition.split('filename=')[1];
          saveFile(blob, downloadFilename);
          //axios.saveAs(blob, 'filename.xlsx')

          setLoadingGraphs(false);
          //url: `/kgfiledownload/${graph_name}/${industry}`,
        })
        .catch((error: AxiosError) => {
          // setAlertMSG(`${error}`);
          // setShowAlert(true);
          setLoadingGraphs(false);
        });
    } catch (_e) {
      //setAlertMSG(`${_e}`);
      //setShowAlert(true);
      //setLoadingGraphs(false);
      setAlertMSG('Error downloading file');
    }
  };

  useEffect(() => {
    setLoadingGraphs(true);
    PrivReq({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      method: 'get',
      url: `/graphdata/${graph_name}/industry_name/${industry}`,
    })
      .then((response: AxiosResponse) => {
        const coloredTypes = typeColors(response.data, colors);
        setGraphData(updateGraphData(response.data, coloredTypes));
        setColoredType(coloredTypes);
        setLoadingGraphs(false);
      })
      .catch((error: AxiosError) => {
        setAlertMSG(`Something went wrong`);
        setLoadingGraphs(false);
      });
  }, [now]);

  const graphConfig = {
    automaticRearrangeAfterDropNode: true,
    width: 1200,
    height: 600,
    directed: true,
    collapsible: true,
    nodeHighlightBehavior: true,
    highlightDegree: 2,
    highlightOpacity: 0.2,
    linkHighlightBehavior: true,
    maxZoom: 12,
    minZoom: 0.05,
    initialZoom: 1,
    panAndZoom: false,
    staticGraph: false,
    staticGraphWithDragAndDrop: false,
    focusAnimationDuration: 0.75,
    focusZoom: 1,
    freezeAllDragEvents: false,
    node: {
      size: 970,
      highlightStrokeColor: 'blue',
      labelProperty: customLabelBuilder,
      fontSize: 14,
    },
    link: {
      color: 'lightgray',
      fontColor: 'black',
      fontSize: 14,
      fontWeight: 'normal',
      highlightColor: 'black',
      highlightFontSize: 14,
      highlightFontWeight: 'normal',
      mouseCursor: 'pointer',
      opacity: 1,
      renderLabel: false,
      semanticStrokeWidth: true,
      strokeWidth: 3,
      markerHeight: 6,
      markerWidth: 6,
      type: 'STRAIGHT',
      selfLinkDirection: 'TOP_RIGHT',
      strokeDasharray: 0,
      strokeDashoffset: 0,
      strokeLinecap: 'butt',
    },
  };

  return (
    <Grid
      container
      flexDirection="column"
      //height="100%"
      onContextMenu={(event) => event.preventDefault()}
    >
      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        minHeight="5%"
      >
        <Grid item style={{ height: '5%', padding: '20px' }}>
          <Typography variant="h2" gutterBottom>
            {graph_name}
          </Typography>
        </Grid>
        <Grid item>{loadingGraphs ? <ComponentLoading /> : ''}</Grid>
        <Grid
          item
          style={{
            height: '5%',
            padding: '20px',
            marginRight: '5px',
          }}
        >
          <Icon>
            <FileDownloadOutlined
              color="secondary"
              onClick={handleDownloadClick}
            />
          </Icon>
          <DeleteGraphButton
            setOpen={setDeleteGraphDialogOpen}
            trashIcon={true}
          />
        </Grid>
      </Grid>

      <Grid
        container
        flexDirection="row"
        style={{
          flex: 1,
          overflowY: 'visible',
          width: '100%',
          //height: '80%',
        }}
      >
        <Grid item xs={8}>
          <div
            id="graph_chart"
            style={{
              padding: '5px',
            }}
          >
            <Graph
              id="graph-id"
              data={graphData}
              config={graphConfig}
              onClickNode={onClickNode}
              onClickLink={onClickLink}
              onRightClickNode={onRightClickNode}
              onRightClickLink={onRightClickLink}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <TypesList types_and_colors={coloredType} />
        </Grid>

        <DeleteGraphDialog
          open={deleteGraphDialogOpen}
          setOpen={setDeleteGraphDialogOpen}
          graphName={graph_name}
          industryName={industry}
        />

        <EditNodeDialog
          content={graphAttr}
          open={showDialog}
          setOpen={setShowDialog}
        />
        <MessageAlertDialog
          message={alertMSG}
          open={showAlert}
          setOpen={setShowAlert}
        />
        <ContextDialog
          open={showContextMenu}
          setOpen={setShowContextMenu}
          content={[]}
          node={nodeData}
        />
      </Grid>
    </Grid>
  );
}

/**
 * dropdown menu
 *
 * number of defects
 * lines of code (sum)
 *
 * clicking -> suggestion fix
 *   -> edit code and send
 *   -> able to ask AI to break code and give suggestions...
 *
 */
// graph_name, industry_name, Travel
