import React, { useContext, useEffect, useState } from 'react';
import { SearchOutlined } from '@mui/icons-material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Grid, IconButton, Typography } from '@mui/material';

import { UserProperties } from 'components/AppWrapper';
import CollectionList from 'components/CollectionList';
import { Collection, CollectionContext } from 'components/CollectionProvider';
import ComponentLoading from 'components/ComponentLoading';
import SecureTextField from 'components/SecureTextField';
import CreateCollectionDialog, {
  CreateCollectionButton,
} from 'components/CreateCollectionDialog';
import { grey } from 'theme';

/**
 * The collections page component which should render all components to be displayed specific to this page
 */
export default function CollectionsPage(): React.JSX.Element {
  const user = useContext(UserProperties);
  const [createCollectionDialogOpen, setCreateCollectionDialogOpen] =
    useState(false);
  const { loadingCollections, collectionsInfo } = useContext(CollectionContext);
  const [itemsToList, setItemsToList] = useState<Collection[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  useEffect(() => {
    collectionsInfo && setItemsToList(collectionsInfo);
  }, [collectionsInfo]);

  if (loadingCollections || collectionsInfo === null) {
    // Show the loading interface
    return <ComponentLoading />;
  }

  if (collectionsInfo.length === 0) {
    // Show the no collections interface
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          minHeight="100%"
          flexDirection="column"
          gap={3}
        >
          <Inventory2OutlinedIcon sx={{ color: grey[500], fontSize: 65 }} />
          <Typography variant="h3">There are no collections yet</Typography>
          <Typography maxWidth="sm" textAlign="center">
            Use collections to help you manage your organisation's documents.
            After creating a collection, you will be able to upload documents
            into that collection.
          </Typography>
          <CreateCollectionButton setOpen={setCreateCollectionDialogOpen} />
        </Grid>
        <CreateCollectionDialog
          open={createCollectionDialogOpen}
          setOpen={setCreateCollectionDialogOpen}
        />
      </>
    );
  }

  // Show the main collections interface
  return (
    <>
      <Grid container flexDirection="column" minHeight="100%">
        <Grid
          container
          item
          alignItems="center"
          bgcolor="white"
          px={3}
          pt={0}
          sx={{
            borderBottom: `1px solid ${grey[500]}`,
            boxSizing: 'border-box',
            height: '80px',
            alignItems: 'center',
          }}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h2">Collections</Typography>
          </Grid>
          <Grid flexGrow={1} display={'flex'} flexDirection={'row'}>
            <SecureTextField
              fullWidth
              color="secondary"
              variant="standard"
              placeholder="Filter Collections"
              value={searchValue}
              onUpdate={(value) => setSearchValue(value)}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  setItemsToList(
                    collectionsInfo.filter((e) =>
                      e?.index_name?.includes(searchValue),
                    ),
                  );
                }
              }}
            />
            <IconButton
              onClick={() =>
                setItemsToList(
                  collectionsInfo.filter((e) =>
                    e?.index_name?.includes(searchValue),
                  ),
                )
              }
            >
              <SearchOutlined color="secondary" />
            </IconButton>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }} textAlign="right">
            {user.role !== 'READ' && (
              <CreateCollectionButton setOpen={setCreateCollectionDialogOpen} />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          flexGrow={1}
          bgcolor={grey[200]}
          py={2}
          px={4}
          sx={{ height: 'calc(100vh - 204px)', overflowY: 'auto' }}
        >
          <CollectionList collections={itemsToList} />
        </Grid>
      </Grid>
      {user.role !== 'READ' && (
        <CreateCollectionDialog
          open={createCollectionDialogOpen}
          setOpen={setCreateCollectionDialogOpen}
        />
      )}
    </>
  );
}
