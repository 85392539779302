import { IGraphContext } from '../interfaces';
import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';

export default function getGraphContext(
  graph_name: string,
  type: string,
  node_id: string,
): Promise<{
  graphContext: IGraphContext;
  status: string;
}> {
  const graphContextEndpoint: string =
    '/graphcontext/' + graph_name + '/' + type + '/' + node_id;
  const GraphContext = async () =>
    await PrivReq({
      method: 'get',
      url: graphContextEndpoint,
    })
      .then((response: AxiosResponse) => {
        return {
          graphContext: response.data,
          status: 'success',
        };
      })
      .catch((error: AxiosError) => {
        return {
          graphContext: {
            node_id: '',
            graph_name: '',
            action_id: '',
            industry_name: '',
          },
          status: error.message || 'error',
        };
      });

  return GraphContext();
}
