import { styled, keyframes } from '@mui/material';

const animation = keyframes`
0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;

export const Typing = styled('div')`
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
`;

export const Dot = styled('div')`
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: ${animation} 1s infinite;
`;
