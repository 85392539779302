import React, { useContext, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material/styles';
import PrivReq from 'api/axios';
import { SnackbarContext } from 'components/SnackbarProvider';
import ComponentLoading from 'components/ComponentLoading';
import { IconButton, Tabs } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { List, ListItemText, ListItemButton } from '@mui/material';
import SecureTextField from 'components/SecureTextField';
import SecureTextareaAutosize from 'components/SecureTextareaAutosize';

type UploadGraphDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

/* Tab Custom Component statr*/

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ marginBottom: '0px' }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, marginBottom: '0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DEFAULT_VALUE = {
  operation: 'new',
  data: {
    id: '',
    name: 'sapusecase',
    description: '',
    display_name: 'SAP Uase Case',
    relations: [
      {
        source_column: 'function',
        target_column: 'component',
        relation: 'implements',
      },
      {
        source_column: 'component',
        target_column: 'code_id',
        relation: 'runs',
      },
    ],
    node_type: {
      function: 'function',
      component: 'component',
      code_id: 'code_id',
    },
  },
};

/* Custom Tab Component ends */

export default function AddGraphConfigDialog({
  open,
  setOpen,
}: Readonly<UploadGraphDialogProps>): React.JSX.Element {
  const [createLoading, setCreateLoading] = useState(false);
  const [graphConfigValue, setGraphConfigValue] = useState(
    'Add rules as below sample :: ' + JSON.stringify(DEFAULT_VALUE),
  );
  const [contextContentValue, setContextContentValue] =
    useState('Context content');
  const [contextContentTemplate, setContextContentTemplate] = useState('');
  const [graphRuleName, setGraphRuleName] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [graphName, setGraphName] = useState('');
  const [message, setMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [prompt, setPrompt] = useState(''); // this is used in Graph search
  const inputRef = React.useRef<HTMLInputElement>(null);
  const createAlert = useContext(SnackbarContext);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [value, setValue] = React.useState(0);
  const [promptList, setPromptList] = useState([]);
  const [promptItem, setPromptItem] = useState(Object);
  const [rulesPromptName, setRulesPromptName] = useState('');
  const [rulesPrmptValue, setRulesPrmptValue] = useState('');
  const [newPromptName, setNewPromptName] = useState('');
  const [promptMode, setPromptMode] = useState('update');

  const navigate = useNavigate();

  const file = new File(['empty-file'], 'empty-file.txt', {
    type: 'text/plain',
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfigChange = (value: string) => {
    setGraphConfigValue(value);
  };

  const handleContextContentValueChange = (value: string) => {
    setContextContentValue(value);
  };

  const cleanState = () => {};

  const handleRuleChange = (value: string) => {
    setGraphRuleName(value);
  };

  const handleIndustryChange = (value: string) => {
    setIndustryName(value);
  };

  const handleGraphNameChange = (value: string) => {
    setGraphName(value);
  };

  const saveGraphConfig = () => {
    setMessage('');
    setCreateLoading(true);
    if (graphConfigValue.length > 1) {
      let validJson = false;
      try {
        JSON.parse(graphConfigValue);
        validJson = true;
      } catch (err) {
        setMessage('Invalid Json format');
        setCreateLoading(false);
        return;
      }

      PrivReq({
        withCredentials: true,
        method: 'post',
        url: '/insertgraphconfig',
        data: graphConfigValue,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            createAlert({
              message: `Graph Config Saved`,
              severity: 'success',
            });
            cleanState();
            setTimeout(() => {
              handleDialogClose();
              navigate(`/graphs`);
            }, 2000);
            setCreateLoading(false);
          } else {
            createAlert({
              message: `Unable to save graph config:'${response.data.message}'`,
              severity: 'error',
            });
            cleanState();
            setCreateLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          createAlert({
            message: `Unable to save graph config`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        });
    } else {
      cleanState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };

  const getGraphConfig = () => {
    setMessage('');
    setCreateLoading(true);
    if (graphRuleName.length > 1) {
      let ruleName = { name: graphRuleName };

      PrivReq({
        withCredentials: true,
        method: 'post',
        url: '/getgraphconfig',
        data: ruleName,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            createAlert({
              message: `Graph Config / Rule Retrived`,
              severity: 'success',
            });
            setGraphConfigValue(response.data.data);

            setCreateLoading(false);
          } else {
            createAlert({
              message: `Unable to get graph config:'${response.data.message}'`,
              severity: 'error',
            });
            cleanState();
            setCreateLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          createAlert({
            message: `Unable to get graph config`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        });
    } else {
      cleanState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };

  const saveContextContent = () => {
    setMessage('');
    setCreateLoading(true);
    if (contextContentValue.length > 1) {
      let validJson = false;
      try {
        JSON.parse(contextContentValue);
        validJson = true;
      } catch (err) {
        setMessage('Invalid Json format');
        setCreateLoading(false);
        return;
      }

      PrivReq({
        withCredentials: true,
        method: 'post',
        url: '/insertgraphcontext',
        data: contextContentValue,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            createAlert({
              message: `Graph Context Saved`,
              severity: 'success',
            });
            cleanState();
            setTimeout(() => {
              handleDialogClose();
            }, 2000);
            setCreateLoading(false);
          } else {
            createAlert({
              message: `Unable to save graph context:'${response.data.message}'`,
              severity: 'error',
            });
            cleanState();
            setCreateLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          createAlert({
            message: `Unable to save graph context`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        });
    } else {
      cleanState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };

  const generateContextTemplate = () => {
    setMessage('');
    setCreateLoading(true);
    if (graphName.length > 1 && industryName.length > 1) {
      let data = { graph_name: graphName, industry_name: industryName };

      PrivReq({
        withCredentials: true,
        method: 'post',
        url: '/generatecontexttemplate',
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            createAlert({
              message: `Graph context template generated`,
              severity: 'success',
            });
            setContextContentTemplate(JSON.stringify(response.data.data));

            setCreateLoading(false);
          } else {
            createAlert({
              message: `Unable to generate context template:'${response.data.message}'`,
              severity: 'error',
            });
            cleanState();
            setCreateLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          createAlert({
            message: `Unable to generate context template`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        });
    } else {
      cleanState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };

  /**
   * search/query graph functions
   */

  const handleSearchQueryChange = (value: string) => {
    setSearchQuery(value);
  };

  const searchGraph = () => {
    setMessage('');
    setCreateLoading(true);
    if (graphName.length > 1 && searchQuery.length > 1) {
      let data = { graph_name: graphName, query: searchQuery, prompt: prompt };
      //let data = {'graph_name':graphName,'query':searchQuery}

      PrivReq({
        withCredentials: true,
        method: 'post',
        url: '/searchgraph',
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            createAlert({
              message: `Graph Query Success`,
              severity: 'success',
            });
            setSearchResult(response.data.data);
            setCreateLoading(false);
          } else {
            createAlert({
              message: `Unable to get search data:'${response.data.message}'`,
              severity: 'error',
            });
            cleanState();
            setCreateLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          createAlert({
            message: `Unable to get search data`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        });
    } else {
      cleanState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };

  const loadPromptList = () => {
    PrivReq({
      withCredentials: true,
      method: 'get',
      url: '/getkgpromptlist/generate',
      data: contextContentValue,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: AxiosResponse) => {
        if (response.data.status.toLowerCase() === 'success') {
          setPromptList(JSON.parse(response.data.data));
          cleanState();
          setTimeout(() => {
            handleDialogClose();
          }, 2000);
          setCreateLoading(false);
        } else {
          createAlert({
            message: `Unable to get prompt list`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        createAlert({
          message: `Unable to get prompt list`,
          severity: 'error',
        });
        cleanState();
        setCreateLoading(false);
      });
  };

  const handlePromptChange = (value: string) => {
    setPrompt(value);
  };

  const handlePromptValueChange = (value: string) => {
    setRulesPrmptValue(value);
  };

  /**
   * end of search/query graph functions
   */

  /*Tab related functions */

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 4) {
      loadPromptList();
    }
    setValue(newValue);
  };

  const handleListItemClick = (index: any) => {
    setSelectedIndex(index);
    let selectedPrompt: any = promptList[index];
    setPromptItem(selectedPrompt);
    setPromptMode('update');
    setRulesPromptName('');
    setRulesPrmptValue('');
    setNewPromptName('');
    setRulesPrmptValue(selectedPrompt.prompt);
    setRulesPromptName(selectedPrompt.prompt_name);
  };

  const handleNewPromptValueChange = (value: string) => {
    setNewPromptName(value);
  };

  const handleAddNewPromptClicked = () => {
    setPromptMode('new');
    setRulesPromptName('');
    setRulesPrmptValue('');
    setSelectedIndex(null);
  };

  const saveOrUpdateRulesPrompt = () => {
    let data = {
      operation: '',
      prompt_name: '',
      type: 'generate',
      prompt: '',
    };
    if (promptMode === 'new') {
      data.operation = 'new';
      data.prompt_name = newPromptName;
      data.prompt = rulesPrmptValue;
    }
    if (promptMode === 'update') {
      data.operation = 'update';
      data.prompt_name = rulesPromptName;
      data.prompt = rulesPrmptValue;
    }

    PrivReq({
      withCredentials: true,
      method: 'post',
      url: '/insertkgpromptrules',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: AxiosResponse) => {
        if (response.data.status.toLowerCase() === 'success') {
          setRulesPromptName('');
          setRulesPrmptValue('');
          setSelectedIndex(null);
          setNewPromptName('');
          loadPromptList();
          cleanState();
          setTimeout(() => {
            handleDialogClose();
          }, 2000);
          setCreateLoading(false);
        } else {
          createAlert({
            message: `Unable update/save rules prompt`,
            severity: 'error',
          });
          cleanState();
          setCreateLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        createAlert({
          message: `Unable update/save rules promptt`,
          severity: 'error',
        });
        cleanState();
        setCreateLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
      <DialogTitle>Rules and Configuration</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Rules" {...a11yProps(0)} />
            <Tab label="Context Configuration" {...a11yProps(1)} />
            <Tab label="Context Config Template" {...a11yProps(2)} />
            <Tab label="Search/Query Graph" {...a11yProps(3)} />
            <Tab label="Prompts" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SecureTextareaAutosize
            autoFocus
            minRows={30}
            minLength={300}
            style={{ width: '100%' }}
            contentEditable
            value={graphConfigValue}
            onUpdate={(value) => handleConfigChange(value)}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SecureTextareaAutosize
            autoFocus
            minRows={30}
            minLength={300}
            style={{ width: '100%' }}
            contentEditable
            value={contextContentValue}
            onUpdate={(value) => handleContextContentValueChange(value)}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box
            sx={{
              borderBottom: 0,
              borderColor: 'divider',
              direction: 'row',
              display: 'inline-flex',
              width: '100%',
            }}
          >
            <SecureTextField
              autoFocus
              margin="dense"
              id="idgraphname"
              label="Graph Name"
              type="text"
              variant="outlined"
              value={graphName}
              onUpdate={(value) => handleGraphNameChange(value)}
            />
            &nbsp;&nbsp;
            <SecureTextField
              autoFocus
              margin="dense"
              id="idindustryname"
              label="Industry Name"
              type="text"
              variant="outlined"
              value={industryName}
              onUpdate={(value) => handleIndustryChange(value)}
            />
            <Box
              sx={{
                with: '100%',
                paddingLeft: '20px',
                verticalAlign: 'center',
                float: 'right',
                alignContent: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => generateContextTemplate()}
              >
                Generate Context Content Template
              </Button>
            </Box>
          </Box>
          <SecureTextareaAutosize
            autoFocus
            minRows={30}
            minLength={300}
            style={{ width: '100%' }}
            contentEditable
            defaultValue={'Context content generation'}
            value={contextContentTemplate}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
              <SecureTextField
                autoFocus
                margin="dense"
                id="idgraphname"
                label="Graph Name"
                type="text"
                variant="outlined"
                value={graphName}
                onUpdate={(value) => handleGraphNameChange(value)}
              />
            </Box>

            <Box
              sx={{ borderBottom: 0, borderColor: 'divider', minWidth: '71%' }}
            >
              <SecureTextField
                autoFocus
                margin="dense"
                id="idsearch"
                label="Search/Cypher Query"
                type="text"
                fullWidth
                variant="outlined"
                value={searchQuery}
                onUpdate={(value) => handleSearchQueryChange(value)}
              />
            </Box>
          </Stack>

          <Box
            sx={{ borderBottom: 0, borderColor: 'divider', display: 'flex' }}
          >
            <SecureTextField
              autoFocus
              margin="dense"
              id="idprompt"
              label="Prompt"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              value={prompt}
              onUpdate={(value) => handlePromptChange(value)}
            />

            <IconButton disabled={createLoading} onClick={() => searchGraph()}>
              <SearchOutlined color="secondary" />
            </IconButton>
          </Box>
          <SecureTextareaAutosize
            autoFocus
            minRows={25}
            minLength={300}
            value={searchResult}
            style={{ width: '100%' }}
            contentEditable
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Box>
                <Stack direction="row" spacing={2}>
                  <Grid item xs={3}>
                    <b>Prompts</b>
                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      visibility={
                        promptMode === 'update' ? 'visible' : 'hidden'
                      }
                      display={promptMode === 'update' ? 'flex' : 'none'}
                    >
                      <b>Name:</b>
                      <text style={{ marginLeft: '4px' }}>
                        {rulesPromptName}
                      </text>
                    </Box>
                    <Box
                      visibility={promptMode === 'new' ? 'visible' : 'hidden'}
                      display={promptMode === 'new' ? 'flex' : 'none'}
                    >
                      <SecureTextField
                        autoFocus
                        margin="dense"
                        id="newpromptName"
                        label="New Prompt Name"
                        type="text"
                        variant="outlined"
                        value={newPromptName}
                        onUpdate={(value) => handleNewPromptValueChange(value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <b>Type:</b>
                    <text style={{ marginLeft: '4px' }}>generate</text>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <List>
                  {promptList.map((item: any, index) => (
                    <ListItemButton
                      key={index}
                      selected={selectedIndex === index}
                      onClick={() => handleListItemClick(index)}
                    >
                      <ListItemText primary={item.prompt_name} />
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box>
                <SecureTextareaAutosize
                  autoFocus
                  minRows={25}
                  minLength={300}
                  value={rulesPrmptValue}
                  style={{ width: '100%' }}
                  contentEditable
                  onUpdate={(value) => handlePromptValueChange(value)}
                />
              </Box>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </DialogContent>
      <DialogActions>
        <Box
          visibility={value === 0 ? 'visible' : 'hidden'}
          display={value === 0 ? 'flex' : 'none'}
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'right' }}
        >
          <p style={{ color: '#CB2939' }}>{message}</p>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {createLoading && <ComponentLoading />}
          <Stack direction="row" spacing={0.5}>
            <div>
              <SecureTextField
                size="small"
                autoFocus
                margin="none"
                id="industryname"
                label="Graph Rule Name"
                type="text"
                variant="outlined"
                value={graphRuleName}
                onUpdate={(value) => handleRuleChange(value)}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                disabled={createLoading}
                onClick={() => getGraphConfig()}
              >
                Get Rule
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                disabled={createLoading}
                onClick={() => saveGraphConfig()}
              >
                Save Rule
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
            </div>
          </Stack>
        </Box>
        <Box
          visibility={value === 1 ? 'visible' : 'hidden'}
          display={value === 1 ? 'flex' : 'none'}
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'right' }}
        >
          <p style={{ color: '#CB2939', float: 'left' }}>{message}</p>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {createLoading && <ComponentLoading />}
          <Button
            variant="contained"
            color="secondary"
            disabled={createLoading}
            onClick={() => saveContextContent()}
          >
            Save Context Content
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
        </Box>
        <Box
          visibility={value === 2 ? 'visible' : 'hidden'}
          display={value === 2 ? 'flex' : 'none'}
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'right' }}
        >
          <p style={{ color: '#CB2939', float: 'left' }}>{message}</p>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {createLoading && <ComponentLoading />}
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
        </Box>
        <Box
          visibility={value === 3 ? 'visible' : 'hidden'}
          display={value === 3 ? 'flex' : 'none'}
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'right' }}
        >
          <p style={{ color: '#CB2939', float: 'left' }}>{message}</p>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {createLoading && <ComponentLoading />}
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
        </Box>
        <Box
          visibility={value === 4 ? 'visible' : 'hidden'}
          display={value === 4 ? 'flex' : 'none'}
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'right' }}
        >
          <p style={{ color: '#CB2939', float: 'left' }}>{message}</p>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {createLoading && <ComponentLoading />}
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAddNewPromptClicked}
          >
            Add New Prompt
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={saveOrUpdateRulesPrompt}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

type AddGraphConfigButtonProps = {
  setOpen: (value: boolean) => void;
};
/**
 * An optional styled button to use to open the UploadGraphDialog component
 */

export function AddGraphConfigButton({
  setOpen,
}: Readonly<AddGraphConfigButtonProps>): React.JSX.Element {
  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={<AddIcon />}
      onClick={() => setOpen(true)}
    >
      Graph Rule/Config
    </Button>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
