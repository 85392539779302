import React, { createContext, useEffect, useState } from 'react';

import { ICategories } from '../interfaces';

import { getCategories } from '../services';

type ConfigurationContextProps = {
  categoriesList: ICategories[];
  msgFromConfProvider: string;
  loading: boolean;
  updateAll: () => void;
};

export const ConfigurationContext = createContext<ConfigurationContextProps>({
  categoriesList: [],
  msgFromConfProvider: '',
  loading: true,
  updateAll: () => {},
});

type ConfigurationProviderProps = {
  /**
   * The page component that will be rendered inside the global Page component template
   */
  children: React.JSX.Element;
};

/**
 * Provides the list of available configuration categories and subcategories
 *  to the app
 */

export default function ConfigurationProvider({
  children,
}: Readonly<ConfigurationProviderProps>): React.JSX.Element {
  const [now, setNow] = useState(Date.now());
  const [categoriesList, setCategoriesList] = useState<ICategories[]>([]);
  const [msgFromConfProvider, setMsgFromConfProvider] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const updateAll = () => {
    setNow(Date.now());
  };

  const CallForCategoriesList = async () => {
    const { categories, status } = await getCategories();
    if (categories && categories.length > 0) {
      setCategoriesList(categories);
      setMsgFromConfProvider(status);
      setLoading(false);
    } else {
      setMsgFromConfProvider(status);
    }
  };

  useEffect(() => {
    setLoading(true);
    CallForCategoriesList();
  }, [now]);

  return (
    <ConfigurationContext.Provider
      value={{
        categoriesList,
        msgFromConfProvider,
        loading,
        updateAll,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
}
