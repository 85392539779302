import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { ISubCategories } from '../interfaces';

export default async function getSubCategories(categoryID: string): Promise<{
  subCategories: ISubCategories[];
  status: string;
}> {
  return await PrivReq({
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    method: 'get',
    url: `/getconfigurable/${categoryID}`,
  })
    .then((response: AxiosResponse) => {
      return {
        subCategories: response.data,
        status: 'success, loading subcategories list',
      };
    })
    .catch((error: AxiosError) => {
      if (process.env.NODE_ENV === 'development') {
        return {
          subCategories: [],
          status: 'error fetching subcategories from server',
        };
      }
      return {
        subCategories: [],
        status: 'error returning subcategories list',
      };
    });
}
