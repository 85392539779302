import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

export const Title = styled(Typography)`
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
