import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { summarizeDocumentAPI } from 'api';
import { useAuthState } from 'components/AuthProvider';
import { grey } from 'theme';
import { GetSummaryRequest } from 'types';
import { SnackbarContext } from 'components/SnackbarProvider';
import axios from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { UserProperties } from 'components/AppWrapper';
import { Title } from './DocumentTopBar.styles';
import { Tooltip } from '@mui/material';

type DocumentTopBarProps = {
  /**
   * Document name.
   */
  doc_name: string;
  /**
   * The collection the document belongs to.
   */
  index: string;
};

export default function DocumentTopBar({
  doc_name,
  index,
}: DocumentTopBarProps): React.JSX.Element {
  const user = useContext(UserProperties);
  const navigate = useNavigate();
  const { accessToken } = useAuthState();
  const createAlert = useContext(SnackbarContext);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [enableDownload, setEnableDownload] = useState(false);
  // const [openDownloadDialog, setopenDownloadDialog] = useState(false);

  const handleSummarizeClick = async () => {
    const body: GetSummaryRequest = {
      index: index,
      file_name: doc_name,
      options: {
        output: 'action_steps',
        word_limit: '150',
        mode: 'map_reduce',
      },
      save_index: 'True',
    };
    setLoadingSummary(true);
    const { data, error } = await summarizeDocumentAPI(accessToken, body);
    if (data) {
      createAlert({
        message: `Summary created`,
        severity: 'success',
      });
    } else {
      const unauthorizedMessage =
        'The server could not verify that you are authorized to access the URL requested.';
      if (error?.includes(unauthorizedMessage)) {
        createAlert({
          message: `Access denied`,
          severity: 'error',
        });
      } else {
        createAlert({
          message: `Something went wrong when trying to summarize file "${doc_name}"`,
          severity: 'error',
        });
      }
    }
    setLoadingSummary(false);
  };

  const deleteFile = useCallback(() => {
    axios({
      method: 'post',
      url: '/clearindex',
      headers: { 'Content-Type': 'application/json' },
      data: {
        file_name: doc_name,
        index,
      },
    })
      .then((response: AxiosResponse) => {
        createAlert({
          message: `File "${doc_name}" removed`,
          severity: 'success',
        });
        navigate(`/collection/${index}`);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        createAlert({
          message: `Something went wrong when trying to delete "${doc_name}"`,
          severity: 'error',
        });
      });
  }, [doc_name, index, createAlert, navigate]);

  // const confirmDownload = () => setopenDownloadDialog(true);
  // const handleClose = () => setopenDownloadDialog(false);

  const downloadDocument = () => {
    axios({
      method: 'get',
      url: `/docdownload/${index}/${doc_name}`,
      responseType: 'blob',
    })
      .then((response: AxiosResponse) => {
        if (!doc_name) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc_name);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) link.parentNode.removeChild(link);
        createAlert({
          message: `File "${doc_name}" downloaded.`,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        createAlert({
          message: `Something went wrong when trying to download "${doc_name}" file.`,
          severity: 'error',
        });
        return error;
      });
  };

  const getFileList = useCallback(() => {
    axios({
      method: 'get',
      url: `/listdocs/${index}`,
    })
      .then((response: AxiosResponse) => {
        const data: {
          doc_name: string;
          metadata: {
            [key: string]: string[];
          };
        }[] = response.data;
        const selectedFile = data.filter(
          (file) => file.doc_name === doc_name,
        )[0];
        if (!selectedFile.metadata) return setEnableDownload(true);
        const metadataValues = Object.values(selectedFile.metadata).flat();
        const containsScrub = metadataValues.includes('scrub');
        setEnableDownload(!containsScrub);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, [index, doc_name]);

  useEffect(() => getFileList(), [getFileList]);

  return (
    <Grid
      container
      px={3}
      pt={3}
      pb={2}
      sx={{
        borderBottom: `1px solid ${grey[500]}`,
        boxSizing: 'border-box',
        height: '80px',
      }}
      gap={2}
      wrap="nowrap"
    >
      <Grid item sx={{ flexshrink: 1 }}>
        <IconButton component={Link} to={`/collection/${index}`}>
          <KeyboardBackspaceIcon />
        </IconButton>
      </Grid>
      <Grid item flexGrow={1}>
        <Typography variant="subtitle2">
          Collections / <Link to={`/collection/${index}`}>{index}</Link>
        </Typography>
        <Tooltip title={doc_name}>
          <Title variant="h2">{doc_name}</Title>
        </Tooltip>
      </Grid>
      <Grid item gap={1} display="flex" height={'100%'}>
        {user.role !== 'READ' && (
          <>
            <Button
              variant="outlined"
              onClick={handleSummarizeClick}
              disabled={loadingSummary}
            >
              <div style={{ paddingRight: '8px' }}>Summarize</div>
              {loadingSummary && <CircularProgress size={15} color="inherit" />}
            </Button>
            {/* <IconButton>
              <InfoOutlinedIcon />
            </IconButton> */}
            {enableDownload && (
              <IconButton onClick={downloadDocument}>
                <SaveAltIcon />
              </IconButton>
            )}
            <IconButton color="error" onClick={deleteFile}>
              <DeleteOutlineIcon />
            </IconButton>
            {/* <IconButton>
              <MoreVertIcon />
            </IconButton> */}
          </>
        )}
      </Grid>
    </Grid>
  );
}
