const purple = {
  100: '#F7EBFF',
  200: '#DFC3F0',
  300: '#BE82FF',
  400: '#A055F5',
  500: '#8300D5',
  600: '#610099',
  core1: '#A100FF',
  core2: '#7500C0',
  core3: '#460073',
};

export default purple;
