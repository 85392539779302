import React, { useContext, useState, useMemo } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import {
  Box,
  MenuItem,
  Menu,
  IconButton,
  Grid,
  Button,
  Typography,
} from '@mui/material';

import axios from 'api/axios';
import { INewDocument } from 'types';
import Searchbar from 'components/SearchVectorBar';
import { SnackbarContext } from 'components/SnackbarProvider';
import { UserProperties } from 'components/AppWrapper';
import { Action } from 'components/FilesProvider/FilesProvider';
import LoadingButton from 'components/UI/LoadingButton';

/**
 * The individual collection page component which should render all components to be displayed specific to this page
 */

interface CollectionPageHeaderProps {
  documents: INewDocument[];
  currentIndex: string | undefined;
  updatingJobs: boolean;
  selectedRows: string[];
  areJobUpdatesAvailable: boolean;
  dispatch: React.Dispatch<Action>;
  updateFileList: () => Promise<void>;
  handleSelection: (doc_name: string) => void;
  setLoadingFiles: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setFilteredDocuments: React.Dispatch<React.SetStateAction<INewDocument[]>>;
  setOpenUpdateJobsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CollectionPageHeader({
  documents,
  updatingJobs,
  selectedRows,
  areJobUpdatesAvailable,
  dispatch,
  updateFileList,
  handleSelection,
  setLoadingFiles,
  setLoadingSearch,
  setFilteredDocuments,
  setOpenUpdateJobsModal,
}: CollectionPageHeaderProps): React.JSX.Element {
  const navigate = useNavigate();
  const user = useContext(UserProperties);
  const createAlert = useContext(SnackbarContext);
  const { index: collectionName } = useParams();
  const index = useMemo(() => collectionName ?? '', [collectionName]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => setMenuAnchorEl(null);

  const navigateToSettings = () => navigate(`/collection/${index}/settings`);

  const handleAddDocument = () => {
    dispatch({
      type: 'resetState',
    });
  };

  const handleDeleteConfirmed = () => {
    setLoadingFiles(true);
    selectedRows.forEach((fileName) => {
      axios({
        method: 'post',
        url: '/clearindex',
        headers: { 'Content-Type': 'application/json' },
        data: {
          file_name: fileName,
          index: index,
        },
      })
        .then((response: AxiosResponse) => {
          createAlert({
            message: `File "${fileName}" deleted successfully`,
            severity: 'success',
          });
          handleSelection(fileName);
          updateFileList();
        })
        .catch((error: AxiosError) => {
          createAlert({
            message: `An error occurred while trying to delete ${fileName}`,
            severity: 'error',
          });
          handleSelection(fileName);
          setLoadingFiles(false);
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
        });
    });
  };

  return (
    <Grid
      container
      wrap="nowrap"
      borderBottom="1px solid #B3B2B5"
      height="80px"
      px={3}
      pt={0}
      boxSizing={'border-box'}
      alignItems="center"
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
    >
      <Grid item sx={{ flexGrow: 1 }}>
        <Typography variant="h2">{index}</Typography>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }} textAlign="center">
        <Box>
          <Searchbar
            index={index}
            documents={documents}
            setLoadingSearch={setLoadingSearch}
            setFilteredDocuments={setFilteredDocuments}
          />
        </Box>
      </Grid>
      <Grid
        item
        sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}
        textAlign="right"
      >
        {user.role !== 'READ' &&
          selectedRows.length > 0 &&
          index !== 'default_doc_idx' && (
            <>
              <Button
                variant="outlined"
                sx={{ marginRight: '8px' }}
                color="error"
                onClick={handleDeleteConfirmed}
              >
                Delete
              </Button>

              {/* <Button
            variant="outlined"
            color="secondary"
            sx={{ marginRight: '8px' }}
            onClick={() => setOpenMoveFileDialog(true)}
          >
            Move
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ marginRight: '8px', display: 'none' }}
          >
            Export
          </Button> */}
            </>
          )}
        {user.role !== 'READ' && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {areJobUpdatesAvailable && (
              <LoadingButton
                variant="outlined"
                color="primary"
                loading={updatingJobs}
                startIcon={<UpgradeIcon />}
                onClick={() => setOpenUpdateJobsModal(true)}
              >
                {updatingJobs ? 'Updating' : 'Update'} jobs
              </LoadingButton>
            )}
            {index !== 'default_doc_idx' && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/collection/${index}/upload`}
                startIcon={<AddIcon />}
                onClick={handleAddDocument}
              >
                Add document
              </Button>
            )}
          </Box>
        )}
        {index !== 'default_doc_idx' ? (
          <>
            <IconButton
              aria-controls={!!menuAnchorEl ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={!!menuAnchorEl ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="collection-menu"
              anchorEl={menuAnchorEl}
              open={!!menuAnchorEl}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={navigateToSettings}>Settings</MenuItem>
            </Menu>
          </>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
}
