import { ICategories, IConfigData, IUploadData } from '../interfaces';
import { generateSessionId as randomId } from 'common/utils';
type TPayload = IConfigData & ICategories & IUploadData;

export default function CreateIdForPayload(
  payload: TPayload[],
  categories: ICategories[],
) {
  const map = new Map<string, string>();

  const insertConfigId = (obj: TPayload) => {
    return {
      ...obj,
      data: obj.data.map<IConfigData>((item) => ({
        ...item,
        configID: randomId(),
      })),
    };
  };

  for (const obj of categories) {
    map.set(obj.categoryLabel, obj.id);
  }

  return payload.map((obj: TPayload) => {
    const id = map.get(obj.categoryLabel || '');
    obj = insertConfigId(obj);
    return { ...obj, id };
  });
}
