import React, { createContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { IProperty } from 'types';
import { getTokenPropertiesAPI, getUserPropertiesAPI } from 'api';
import { getUserRole } from 'common/utils';
import { useAuthState } from 'components/AuthProvider';
import ComponentLoading from 'components/ComponentLoading';
import ConfidentialInfoReminder from 'components/ConfidentialInfoReminder';
import Page, { NonRoleUserPage } from 'components/Page';

type AppWrapperProps = {
  children: React.ReactNode;
};

export const UserProperties = createContext<IProperty>({
  username: '',
  email: '',
  groups: [],
  permissions: [],
  ticketGroups: [],
  creationDate: 0,
  expirationDate: 0,
  token: '',
  timezone: '',
  role: undefined,
  samlUser: undefined,
});

/**
 * A wrapper for the main app content that provides UserProperties context, and displays a spinner while waiting for authentication to finish
 */
export default function AppWrapper({
  children,
}: AppWrapperProps): React.JSX.Element {
  const { accessToken } = useAuthState();
  const [showReminder, setShowReminder] = useState(false);
  const [loading, setLoading] = useState(true);

  const [properties, setProperties] = useState<IProperty>({
    username: '',
    email: '',
    groups: [],
    permissions: [],
    ticketGroups: [],
    creationDate: 0,
    expirationDate: 0,
    token: '',
    timezone: '',
    role: undefined,
    samlUser: undefined,
  });

  useEffect(() => {
    const isConfidentionalInfoAccepted = localStorage.getItem(
      'isConfidentionalInfoAccepted',
    );
    if (isConfidentionalInfoAccepted !== 'true') {
      if (isConfidentionalInfoAccepted) return setShowReminder(true);
      localStorage.setItem('isConfidentionalInfoAccepted', 'false');
    }
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        if (!accessToken) return;
        const { data: tokenData } = await getTokenPropertiesAPI(accessToken);
        if (!tokenData) return;
        const user = tokenData.username;
        const { data: userData } = await getUserPropertiesAPI(user);
        if (!userData) return;
        setProperties({
          username: tokenData.username,
          email: tokenData.email,
          groups: tokenData.groups,
          permissions: tokenData.permissions,
          ticketGroups: tokenData.ticketGroups,
          creationDate: tokenData.creationDate,
          expirationDate: tokenData.expirationDate,
          token: tokenData.token,
          timezone: tokenData.timezone,
          role: getUserRole(tokenData.permissions),
          samlUser: userData.samlUser,
        });
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
      setLoading(false);
    };

    fetchProperties();
  }, [accessToken]);

  return (
    <>
      {loading ? (
        <Box sx={{ height: '100vh' }}>
          <ComponentLoading />
        </Box>
      ) : (
        <UserProperties.Provider value={properties}>
          {accessToken &&
          properties.role &&
          properties.samlUser !== undefined ? (
            <>
              {showReminder && (
                <ConfidentialInfoReminder
                  openDialog={showReminder}
                  setOpenDialog={setShowReminder}
                />
              )}
              {children}
            </>
          ) : (
            <Box sx={{ height: '100vh' }}>
              <Page page={<NonRoleUserPage />} userWithoutRole={true} />
            </Box>
          )}
        </UserProperties.Provider>
      )}
    </>
  );
}
