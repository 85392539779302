import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import axios from 'api/axios';
import { CollectionContext } from 'components/CollectionProvider';
import { FilesContext } from 'components/FilesProvider';
import DocumentList from 'components/DocumentList';
import ComponentLoading from 'components/ComponentLoading';
import { INewDocument } from 'types';
import MoveFileDialog from 'components/MoveFileDialog';
import CollectionNotFound from 'components/CollectionNotFound';
import CollectionPageHeader from 'components/CollectionPageHeader';
import UpdateJobsDialog from 'components/UpdateJobsDialog/UpdateJobsModal';

/**
 * The individual collection page component which should render all components to be displayed specific to this page
 */
export default function CollectionPage(): React.JSX.Element {
  const {
    documents,
    currentIndex,
    itemStatus,
    linkedJobs,
    dispatch,
    getFileList,
    setCurrentIndex,
    updateJobUpdatesAvailableList,
  } = useContext(FilesContext);
  const { collections, loadingCollections, getCollections } =
    useContext(CollectionContext);
  const { index: collectionName } = useParams();
  const index = useMemo(() => collectionName ?? '', [collectionName]);
  const currentCollection =
    collections && collections.find((item) => item === index);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [openMoveFileDialog, setOpenMoveFileDialog] = useState(false);
  const [updatingJobs, setUpdatingJobs] = useState(false);
  const [openUpdateJobsModal, setOpenUpdateJobsModal] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [filteredDocuments, setFilteredDocuments] =
    useState<INewDocument[]>(documents);
  const areJobUpdatesAvailable =
    !!itemStatus?.readyToBeUpdated &&
    itemStatus.updateAvailable &&
    !!linkedJobs;

  const updateFileList = useCallback(async () => {
    setLoadingFiles(true);
    await getFileList(index);
    setLoadingFiles(false);
  }, [index, getFileList]);

  const handleMoveFile = (target_index: string | null) => {
    if (!target_index) return;
    setLoadingFiles(true);
    selectedRows.forEach((fileName) => {
      axios({
        method: 'post',
        url: '/reindexdoc',
        headers: { 'Content-Type': 'application/json' },
        data: { filename: fileName, source_index: index, target_index },
      })
        .then((response: AxiosResponse) => {
          if (
            response.data.status.toLowerCase() === 'success' &&
            process.env.NODE_ENV === 'development'
          ) {
            handleSelection(fileName);
            updateFileList();
          }
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
        });
    });
  };

  const handleSelection = (doc_name: string) => {
    const selectedIndex = selectedRows.indexOf(doc_name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, doc_name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelected);
  };

  useEffect(() => {
    if (loadingCollections || loadingFiles) return;
    setFilteredDocuments(documents);
    setInitialized(true);
  }, [loadingCollections, loadingFiles, documents]);

  useEffect(() => {
    if (!collectionName) return;
    updateFileList();
    setCurrentIndex(collectionName);
  }, [collectionName, updateFileList, setCurrentIndex]);

  if (loadingCollections || !initialized) {
    // Show the loading interface
    return <ComponentLoading />;
  }

  if (currentCollection === undefined) {
    // Show the no documents interface
    return <CollectionNotFound getCollections={getCollections} />;
  }

  return (
    <>
      <MoveFileDialog
        openDialog={openMoveFileDialog}
        setOpenDialog={setOpenMoveFileDialog}
        handleMoveFile={handleMoveFile}
      />
      {linkedJobs && (
        <UpdateJobsDialog
          linkedJobs={linkedJobs}
          open={openUpdateJobsModal}
          setOpen={setOpenUpdateJobsModal}
          setLoading={setUpdatingJobs}
          updateJobUpdatesAvailableList={updateJobUpdatesAvailableList}
        />
      )}
      <CollectionPageHeader
        documents={documents}
        currentIndex={currentIndex}
        updatingJobs={updatingJobs}
        selectedRows={selectedRows}
        areJobUpdatesAvailable={areJobUpdatesAvailable}
        dispatch={dispatch}
        updateFileList={updateFileList}
        handleSelection={handleSelection}
        setLoadingFiles={setLoadingFiles}
        setLoadingSearch={setLoadingSearch}
        setFilteredDocuments={setFilteredDocuments}
        setOpenUpdateJobsModal={setOpenUpdateJobsModal}
      />
      {currentCollection && index && (
        <DocumentList
          index={index}
          files={filteredDocuments}
          selectedRows={selectedRows}
          loadingFiles={loadingFiles}
          loadingSearch={loadingSearch}
          collection={currentCollection}
          getFileList={updateFileList}
          setSelectedRows={setSelectedRows}
          handleSelection={handleSelection}
        />
      )}
    </>
  );
}
