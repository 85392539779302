import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Modal, Typography } from '@mui/material';
import ChatEndpointsForm from 'components/ChatEndpointsForm';
import SavedSearchesModal from 'components/SavedSearch';
import {
  Endpoint,
  EndpointConfigs,
} from 'components/ChatView/ChatInput/ChatInput';
import { useSavedSearches } from 'common/utils/savedSearchesContext';
interface ChatEndpointTabsProps {
  open: boolean;
  createPrompt?: boolean;
  chatEndpoint: Endpoint;
  documents?: string[] | null;
  selectedTab: number;
  endpointConfigs: EndpointConfigs;
  handleCloseForm: () => void;
  updateEndpointConfig?: (formData: Endpoint) => void;
  handleSelect: ({ name, value }: { name: string; value: string }) => void;
}

const ChatEndpointFormTabs: React.FC<ChatEndpointTabsProps> = ({
  open,
  createPrompt,
  chatEndpoint,
  documents,
  selectedTab,
  endpointConfigs,
  handleCloseForm,
  updateEndpointConfig = () => {},
  handleSelect,
}) => {
  const { setSelectedOption, apiTypes, selectedOption } = useSavedSearches();
  const [endpointConfig, setEndpointConfig] = useState(chatEndpoint);
  const [currentTab, setCurrentTab] = useState(selectedTab);

  const getEndpointName = (index: number) => {
    const endpointNames = [
      'Chat Completion',
      'Search',
      'Search Vectors',
      'Search And Generate',
    ];
    return endpointNames[index];
  };

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedOption(apiTypes[newValue] || '');
    setCurrentTab(newValue);

    const selectedEndpoint = endpointConfigs.find(
      (e) => e.name === getEndpointName(newValue),
    );

    if (!selectedEndpoint) return;

    // Set endpoint config
    setEndpointConfig(selectedEndpoint);
  };

  const handleSelectAPI = (formData: Endpoint) => {
    const endpointIndex = apiTypes.indexOf(selectedOption ?? 'search');
    handleSelect({
      name: 'chatEndpoint',
      value: getEndpointName(endpointIndex),
    });
    updateEndpointConfig(formData);
  };

  useEffect(() => {
    setCurrentTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    setEndpointConfig(chatEndpoint);
  }, [chatEndpoint]);

  return (
    <Modal open={open} onClose={handleCloseForm}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 1000,
          maxHeight: '80vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
          {currentTab < 4 ? 'Select Mode' : 'Prompt Library'}
        </Typography>
        <Tabs value={currentTab} onChange={handleChange}>
          {apiTypes.map((apiType) => (
            <Tab
              key={apiType}
              label={apiType}
              sx={{ borderBottom: '1px solid #B3B2B5' }}
            />
          ))}
          <Tab
            label="Prompt Library"
            disabled={currentTab === 2}
            sx={{ marginLeft: 'auto' }}
          />
        </Tabs>
        <Box mt={3}>
          {currentTab < 4 ? (
            <ChatEndpointsForm
              chatEndpoint={endpointConfig}
              documents={documents}
              handleCloseForm={handleCloseForm}
              updateEndpointConfig={handleSelectAPI}
            />
          ) : currentTab === 4 ? (
            <SavedSearchesModal
              open={true}
              onClose={handleCloseForm}
              selectedChatEndpoint={endpointConfig.name}
            />
          ) : (
            <div>No content available</div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatEndpointFormTabs;
