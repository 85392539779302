import React, { useState } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button, Typography } from '@mui/material';
import { grey } from 'theme';
import { SelectedMessagesInterface } from 'types';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import { ChatCompletionDTO } from 'api';
import { decode } from 'html-entities';
import { pdfStyles } from 'components/PdfView';
import ReviewDocumentModal from '../ReviewDocumentModal';

interface ChatHeaderInputProps {
  chatName: string;
  sessionid: string;
  messages: ChatCompletionDTO[];
  handleGenerateDocument: (state: boolean) => void;
  displayGenerateDocument: boolean;
  selectedMessages: SelectedMessagesInterface[];
  setMessagesToReview: React.Dispatch<
    React.SetStateAction<SelectedMessagesInterface[] | undefined>
  >;
  messagesToReview: SelectedMessagesInterface[] | undefined;
  docName: string;
  setDocName: React.Dispatch<React.SetStateAction<string>>;
  selectedIndex: string;
  setSelectedIndex: React.Dispatch<React.SetStateAction<string>>;
  handleOpenDocumentGenerator: () => void;
}

const ChatHeader: React.FC<ChatHeaderInputProps> = ({
  chatName,
  messages,
  handleGenerateDocument,
  displayGenerateDocument,
  selectedMessages,
  setMessagesToReview,
  messagesToReview,
  docName,
  setDocName,
  selectedIndex,
  setSelectedIndex,
  handleOpenDocumentGenerator,
}) => {
  const [openReviewDoc, setOpenReviewDoc] = useState<boolean>(false);

  const reviewResponses = () => {
    setOpenReviewDoc(true);
  };
  return (
    <Grid
      container
      item
      alignItems="center"
      bgcolor="white"
      px={4}
      py={3}
      sx={{
        borderBottom: `1px solid ${grey[500]}`,
        boxSizing: 'border-box',
        height: '80px',
      }}
    >
      <Grid item sx={{ flexGrow: 1 }}>
        <Typography variant="h2">{chatName}</Typography>
      </Grid>
      <Grid
        item
        flexGrow={1}
        textAlign="right"
        sx={{
          gap: 2,
          display: 'flex',
          justifyContent: 'end',
          height: 5,
          alignItems: 'center',
        }}
      >
        {!displayGenerateDocument ? (
          <PDFDownloadLink
            document={
              <Document>
                <Page size="A4" style={pdfStyles.page}>
                  <View style={pdfStyles.section}>
                    <Text>
                      {messages.map(
                        (message) =>
                          `${message.type === 'user' ? 'User' : 'Quasar++'}
                     
                      ${decode(message.content)} 
                     
                  `,
                      )}
                    </Text>
                  </View>
                </Page>
              </Document>
            }
            fileName="Qusar_Chat.pdf"
          >
            <IconButton color="secondary" size="large">
              <SaveAltIcon />
            </IconButton>
          </PDFDownloadLink>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setMessagesToReview && setMessagesToReview(selectedMessages);
              handleGenerateDocument(false);
            }}
            sx={{
              marginRight: '16px',
            }}
          >
            Cancel
          </Button>
        )}

        <Button
          color="primary"
          variant="contained"
          disabled={messagesToReview && messagesToReview.length < 1}
          onClick={() => {
            displayGenerateDocument
              ? reviewResponses()
              : handleGenerateDocument(true);
          }}
        >
          {displayGenerateDocument ? 'Review Selected Responses' : 'Generate'}
        </Button>
      </Grid>
      {openReviewDoc && (
        <ReviewDocumentModal
          open={openReviewDoc}
          docName={docName}
          selectedIndex={selectedIndex}
          setOpen={setOpenReviewDoc}
          setDocName={setDocName}
          setSelectedIndex={setSelectedIndex}
          handleOpenDocumentGenerator={handleOpenDocumentGenerator}
        />
      )}
    </Grid>
  );
};

export default ChatHeader;
