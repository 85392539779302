import { ListOfValues, FieldValues } from 'types';
import { getAllMetadatas } from 'components/MetadataProvider';

export const Category: FieldValues = {
  ID: '1',
  CATEGORY: 'Document_Metadata',
  SUBCATEGORY: 'Category',
  FIELD_NAME: 'category',
  OPTIONS: ['CSV', 'SOP', 'Document', 'PowerPoint', 'Transcripts'],
};

export const Industry: FieldValues = {
  ID: '2',
  CATEGORY: 'Document_Metadata',
  SUBCATEGORY: 'Industry',
  FIELD_NAME: 'industry',
  OPTIONS: ['Industry1', 'Industry2', 'Industry3'],
};

export const SubIndustry: FieldValues = {
  ID: '3',
  CATEGORY: 'Document_Metadata',
  SUBCATEGORY: 'Sub-Industry',
  FIELD_NAME: 'sub_industry',
  OPTIONS: ['SubIndustry1', 'SubIndustry2', 'SubIndustry3'],
};

export const LOV: ListOfValues = { values: [Category, Industry, SubIndustry] };

export default async function UploadDataGridTableProvider(index: string) {
  return await getMetadata(index);
}

const getMetadata = (index: string) => {
  return new Promise((resolve, reject) => {
    getAllMetadatas(false, index).then((response) => {
      resolve(response);
    });
  });
};
