import { TableCellProps } from '@mui/material';

import { HeadCell } from 'types';
import { camelize } from './stringStyler';

export const getHeadCells = (
  headCellsData: (
    | string
    | {
        label: string;
        cellProps?: TableCellProps;
      }
  )[],
): HeadCell[] =>
  headCellsData.map((headCell) => {
    const isString = typeof headCell === 'string';
    return {
      id: camelize(isString ? headCell : headCell?.label),
      label: isString ? headCell : headCell.label,
      cellProps: isString ? undefined : headCell.cellProps,
    };
  });

export default getHeadCells;
