import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc((100% - (8px * 3)) / 3);
  min-width: 300px;
  border-radius: 8px;
  background: var(--grey-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`;

export const CardHeader = styled('div')`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  align-self: stretch;
  background: var(--grey-grey-100, #fafafa);
`;

export const HeaderTitle = styled(Typography)`
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 25px */
`;

export const CardMenu = styled('div')`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

export const ColumnTitle = styled('div')`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const MenuItem = styled('div')`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ItemText = styled(Typography)`
  color: var(--grey-grey-1000, #03000f);
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  padding: 8px;
`;

export const CardFooter = styled('div')`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const FooterAction = styled(Button)`
  display: flex;
  padding: 8px 24px 8px 16px;
  align-items: center;
  gap: 8px;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;
