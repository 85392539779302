import { Fragment, useEffect, useState } from 'react';
import { CodeBlock } from 'react-code-blocks';
import { ChatCompletionDTO } from 'api';
import { BlockCodeContainer } from './Message.styles';
import { getFormattedMessage } from 'common/utils/blockCodeExtractor';
import CSVDataTable from 'components/CSVDataTable';

const Message = ({ rawMessage }: { rawMessage: ChatCompletionDTO }) => {
  const [message, setMessage] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessage = async () => {
      setLoading(true);
      const formattedMessage = await getFormattedMessage(rawMessage.content);
      setMessage(formattedMessage);
      setLoading(false);
    };
    fetchMessage();
  }, [rawMessage]);

  if (loading) return <p>Loading...</p>;

  return (
    <BlockCodeContainer>
      {message &&
        message.map((m) => (
          <Fragment key={m.key}>
            {m.text && (
              <p style={{ color: rawMessage.type === 'error' ? 'red' : '' }}>
                {m.text.split('[Image]').map((part: string, index: number) => (
                  <Fragment key={index}>
                    {part}
                    {index < m.images.length && m.images[index].imageData && (
                      <img
                        key={m.images[index].key}
                        src={m.images[index].imageData}
                        alt="Chat response"
                        style={{ maxWidth: '50%', height: 'auto' }}
                      />
                    )}
                  </Fragment>
                ))}
              </p>
            )}
            {m.code && <CodeBlock text={m.code} showLineNumbers={true} />}
            {m.table && <CSVDataTable tableContent={m.table} />}
          </Fragment>
        ))}
    </BlockCodeContainer>
  );
};

export default Message;
