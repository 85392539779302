import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/system';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F2F2F2',
    color: 'black',
    fontFamily: 'Graphik',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '25px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: 'black',
    fontFamily: 'Graphik',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    letterspacing: '0.16px',
  },
}));
