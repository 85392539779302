import SearchOffIcon from '@mui/icons-material/SearchOff';
import Button from '@mui/material/Button';
import theme from 'theme';

import {
  EmptyIndexStyled,
  EmptyIndexContentStyled,
  EmptyIndexTitleStyled,
  EmptyIndexSubTitleStyled,
} from './NothingToSeeHere.styles';
import { useNavigate } from 'react-router-dom';

interface NothingToSeeHereProps {
  currentIndex: string | undefined;
  docGenPage?: boolean;
  elementToAdd: string;
  handleAddButton: () => void;
  handleRefreshButton: () => void;
}
export default function NothingToSeeHere({
  currentIndex,
  docGenPage,
  elementToAdd,
  handleAddButton,
  handleRefreshButton,
}: NothingToSeeHereProps) {
  const navigate = useNavigate();

  return (
    <EmptyIndexStyled>
      <EmptyIndexContentStyled>
        <SearchOffIcon sx={{ width: '65px', height: '65px' }} />
        <EmptyIndexTitleStyled>
          {currentIndex || docGenPage
            ? 'Nothing to see here!'
            : 'Collection not found!'}
        </EmptyIndexTitleStyled>
        {currentIndex || docGenPage ? (
          <EmptyIndexSubTitleStyled>
            Start by
            <Button
              sx={{
                color: '#7500C0',
                ':hover': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
              onClick={handleAddButton}
            >
              {' '}
              adding a {elementToAdd}
            </Button>
            {docGenPage ? 'Or' : 'to the collection. Or'}
            <Button
              sx={{
                color: '#7500C0',
                ':hover': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
              onClick={handleRefreshButton}
            >
              refreshing
            </Button>
            the list of {elementToAdd}s
          </EmptyIndexSubTitleStyled>
        ) : (
          <EmptyIndexSubTitleStyled>
            Start by creating your collection from the
            <Button
              sx={{
                color: '#7500C0',
                ':hover': {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
              onClick={() => navigate('/')}
            >
              {' '}
              home page.
            </Button>
          </EmptyIndexSubTitleStyled>
        )}
      </EmptyIndexContentStyled>
    </EmptyIndexStyled>
  );
}
