import { Button, ButtonProps, CircularProgress } from '@mui/material';

type LoadingButtonProps = ButtonProps & {
  loading: boolean;
};
const LoadingButton = ({
  loading,
  children,
  startIcon,
  endIcon,
  ...rest
}: LoadingButtonProps) => {
  return (
    <Button
      disabled={loading}
      startIcon={loading ? undefined : startIcon}
      endIcon={loading ? undefined : endIcon}
      {...rest}
    >
      {loading && startIcon && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '10px',
          }}
        >
          <CircularProgress size={15} color="inherit" />
        </div>
      )}
      {children}
      {loading && (endIcon || (!endIcon && !startIcon)) && (
        <div
          style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}
        >
          <CircularProgress size={15} color="inherit" />
        </div>
      )}
    </Button>
  );
};

export default LoadingButton;
