import { FilterOptionsState } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import SecureTextField from 'components/SecureTextField';
import React, { useEffect, useState } from 'react';

type Index = {
  index_name: string;
  inputValue: string;
};

interface SearchIndexInputProps {
  inputValue: string | null;
  setInputValue: React.Dispatch<React.SetStateAction<string | null>>;
}

const filter = createFilterOptions<Index>();

export default function SearchIndexInput({
  inputValue,
  setInputValue,
}: SearchIndexInputProps) {
  const [indexList, setIndexList] = useState<Index[] | null>(null);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | Index | null,
  ) => {
    if (typeof newValue === 'string') return;
    if (newValue && newValue.inputValue) {
      return setInputValue(newValue.inputValue);
    }
    if (newValue) setInputValue(newValue.index_name);
  };

  const getFilterOptions = (
    options: Index[],
    params: FilterOptionsState<Index>,
  ) => {
    const filtered = filter(options, params);
    return filtered;
  };

  const getOptionLabel = (option: string | Index) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.index_name;
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: '/listindexes',
    })
      .then((response: AxiosResponse) => {
        setIndexList(response.data);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      });
  }, []);

  if (!indexList) return <></>;

  return (
    <div style={{ paddingTop: '2rem' }}>
      <Autocomplete
        value={inputValue}
        onChange={handleChange}
        filterOptions={getFilterOptions}
        id="columns-search"
        options={indexList}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>{option.index_name}</li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <SecureTextField {...params} label="Search" />}
      />
    </div>
  );
}
