import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

import {
  BodyItem,
  CardBody,
  CardContainer,
  CardFooter,
  CardTitle,
  EditCardTitle,
  FooterAction,
  StyledMenuItem,
  TitleTypography,
} from './MetadataCard.styles';
import {
  deleteMetadata,
  saveMetadata,
  updateMetadata,
} from 'components/MetadataProvider';
import { SnackbarContext } from 'components/SnackbarProvider';
import SecureTextField from 'components/SecureTextField';
import { shorten } from 'common/utils';

export const MetadataCard = (props: {
  title: string;
  items: string[];
  edit: boolean;
  updateList: any;
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [items, setItems] = useState<string[]>(props.items);
  const [edit, setEdit] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [editedCard, setEditedCard] = useState<{
    title: string;
    items: string[];
  }>({ title: props.title, items: props.items });
  const [newMetadata, setNewMetadata] = useState<boolean>(false);

  const createAlert = useContext(SnackbarContext);

  useEffect(() => {
    if (props.edit) {
      setEdit(true);
      setNewMetadata(true);
    }
  }, [items]);

  const title = props.title;

  const handleDeleteMetadata = (item: string) => {
    deleteMetadata({ name: title, values: [''] })
      .then((_response) => {
        createAlert({
          message: 'Metadata dropped with success.',
          severity: 'success',
        });
        props.updateList();
      })
      .catch((_error) => {
        createAlert({
          message:
            'Error on drop metadata, if this metadata is on use then it cannot be deleted',
          severity: 'error',
        });
      });
  };

  const handleSortItems = () => {
    setItems(items.reverse());
  };

  const handleEdit = () => {
    setEditedCard({ title, items });
    setEdit(!edit);
  };

  const handleSave = () => {
    const addedItems = editedCard.items.filter((item) => !items.includes(item));
    const deletedItems = items.filter(
      (item) => !editedCard.items.includes(item),
    );
    if (newMetadata) {
      saveMetadata(editedCard.title, editedCard.items)
        .then((_response) => {
          createAlert({
            message: 'Metadata saved with success.',
            severity: 'success',
          });
          props.updateList();
        })
        .catch(() => {
          createAlert({
            message:
              'Error on save metadata. If a metadata is on use then it cannot be deleted',
            severity: 'error',
          });
        });
      return;
    }
    updateMetadata(editedCard.title, editedCard.items)
      .then((_response) => {
        createAlert({
          message: 'Metadata updated with success.',
          severity: 'success',
        });
        props.updateList();
      })
      .catch((_error) => {
        createAlert({
          message: `Cannot update this metadata. ${_error.message}`,
          severity: 'error',
        });
      });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const str = event.target.value;
    const tags = str.split(/\r?\n/);
    setEditedCard((prev) => {
      const items = prev.items.flatMap((elem, index) =>
        idx === index ? [...tags] : elem,
      );
      items.includes('') ? setDisabledSave(true) : setDisabledSave(false);
      return { ...prev, items };
    });
  };

  const handleDeleteItem = (index: number) => {
    setDisabledSave(false);
    setEditedCard({
      title: editedCard.title,
      items: editedCard.items.filter((_item, idx) => index !== idx),
    });
  };

  const NormalCard = () => (
    <CardContainer>
      <CardTitle container onClick={() => setExpand((prev) => !prev)}>
        <Tooltip title={title}>
          <TitleTypography>{`${shorten(title, 25)} (${items.length})`}</TitleTypography>
        </Tooltip>
      </CardTitle>
      <CardBody display={expand ? 'flex' : 'none'} container>
        {items.sort().map((item: string) => (
          <React.Fragment key={crypto.randomUUID()}>
            <BodyItem key={item}>
              <Tooltip title={item}>
                <p>{shorten(item, 23)}</p>
              </Tooltip>
            </BodyItem>
          </React.Fragment>
        ))}
      </CardBody>
      <CardFooter display={expand ? 'flex' : 'none'} container>
        <FooterAction
          color="error"
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={() => handleDeleteMetadata(title)}
        >
          Delete
        </FooterAction>
        <FooterAction
          startIcon={<SortByAlphaOutlinedIcon />}
          onClick={handleSortItems}
        >
          Sort By
        </FooterAction>
        <FooterAction startIcon={<EditOutlinedIcon />} onClick={handleEdit}>
          Edit
        </FooterAction>
        {/* <FooterActionGroup variant="outlined">
          </FooterActionGroup> */}
      </CardFooter>
    </CardContainer>
  );

  const EditCard = () => {
    return (
      <CardContainer>
        <EditCardTitle container onClick={() => setExpand((prev) => !prev)}>
          <Tooltip title={editedCard?.title}>
            <TitleTypography>{shorten(editedCard?.title, 25)}</TitleTypography>
          </Tooltip>
          <IconButton
            onClick={() => [
              setEditedCard((prev) => {
                if (!prev.items.includes('')) {
                  prev.items.push('');
                  setDisabledSave(true);
                }
                return prev;
              }),
            ]}
          >
            <AddOutlinedIcon />
          </IconButton>
        </EditCardTitle>
        <CardBody display={'flex'} container>
          <SecureTextField
            key={'title'}
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={editedCard.title}
            sx={{ marginBottom: 1 }}
            onUpdate={(value, event) => {
              setEditedCard({
                items: editedCard.items,
                title: event.target.value,
              });
            }}
          />
          {editedCard?.items.map((item, idx) => (
            <StyledMenuItem key={idx}>
              <SecureTextField
                autoFocus
                key={idx}
                id={item}
                label="Tag"
                type="text"
                fullWidth
                variant="outlined"
                value={item}
                multiline
                sx={{
                  marginBottom: 1,
                  '& .MuiInputBase-input': { padding: 0 },
                }}
                onUpdate={(value, event) => {
                  handleChange(event, idx);
                }}
              />
              <IconButton
                onClick={() => {
                  handleDeleteItem(idx);
                }}
              >
                <RemoveOutlinedIcon />
              </IconButton>
            </StyledMenuItem>
          ))}
        </CardBody>
        <CardFooter display={'flex'} container>
          <FooterAction
            color="error"
            startIcon={<ClearOutlinedIcon />}
            onClick={handleEdit}
          >
            Cancel
          </FooterAction>
          <FooterAction
            startIcon={<CheckOutlinedIcon />}
            onClick={handleSave}
            disabled={!!disabledSave}
          >
            Save
          </FooterAction>
        </CardFooter>
      </CardContainer>
    );
  };

  return <React.Fragment>{!edit ? NormalCard() : EditCard()}</React.Fragment>;
};
