import { Order } from 'components/FileTable';
import { INewDocument } from 'types';

function getFileSize(size: string | number) {
  if (typeof size === 'number') return 0;
  const value = size.split(' ').shift();
  if (!value) return 0;

  let numericValue = +value;
  const kiloByte = 1000;
  const megaByte = 1000000;
  const gigaByte = 1000000000;

  if (size.includes('B')) return numericValue;
  if (size.includes('KB')) return numericValue * kiloByte;
  if (size.includes('MB')) return numericValue * megaByte;
  if (size.includes('GB')) return numericValue * gigaByte;
  return 0;
}

function descendingComparator(
  a: INewDocument,
  b: INewDocument,
  orderBy: keyof INewDocument,
) {
  let x = a;
  let y = b;

  if (orderBy === 'size') {
    x = { ...x, size: getFileSize(x.size) };
    y = { ...y, size: getFileSize(y.size) };
  }
  if (orderBy === 'metadata') {
    x = { ...x, metadata: Object.values(x.metadata).sort() };
    y = { ...y, metadata: Object.values(y.metadata).sort() };
  }
  if (y[orderBy] < x[orderBy]) return -1;
  if (y[orderBy] > x[orderBy]) return 1;
  return 0;
}

export function comparator({
  a,
  b,
  order,
  orderBy,
}: {
  a: INewDocument;
  b: INewDocument;
  order: Order;
  orderBy: keyof INewDocument;
}) {
  return order === 'desc'
    ? descendingComparator(a, b, orderBy)
    : -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort({
  rows,
  order: selectedOrder,
  orderBy,
}: {
  rows: INewDocument[];
  order: Order;
  orderBy: keyof INewDocument;
}) {
  const stabilizedThis = rows.map(
    (el, index) => [el, index] as [INewDocument, number],
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator({
      a: a[0],
      b: b[0],
      order: selectedOrder,
      orderBy,
    });
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
