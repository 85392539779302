import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';

export const MetadataTabs = styled('div')`
  display: flex;
  padding: 8px 0px;
  align-items: flex-start;
  align-self: stretch;
`;

export const Tab = styled('div')<{ active: boolean }>`
  display: flex;
  cursor: pointer;
  height: 30px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.active ? '2px solid #7500C0' : '2px solid #CCCBCE'};

  .title {
    color: ${(props) => (props.active ? '#03000F' : '#CCCBCE')};
    text-align: center;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  .badge {
    display: flex;
    padding: 2px 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: ${(props) => (props.active ? '#7500c0' : '#CCCBCE')};
  }
`;

export const TabContent = styled('div')`
  display: flex;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: space-between;
  gap: 10px;
  flex: 1 0 0;
`;

export const BadgeNumber = styled(Typography)`
  text-align: center;
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.4px;
`;

// export const MetadataCards = styled('div')`
//   display: flex;
//   padding-bottom: 16px;
//   align-items: flex-start;
//   gap: 8px;
//   overflow: auto;

//   &::-webkit-scrollbar {
//     height: 8px;
//   }
//   &::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     background: #888;
//   }
//   &::-webkit-scrollbar-track {
//     border-radius: 5px;
//   }
//   &::-webkit-scrollbar-button:vertical:start:increment {
//     display: none;
//   }
//   &::-webkit-scrollbar-button:vertical:end:decrement {
//     display: none;
//   }
// `;

export const MetadataCards = styled('div')({
  minHeight: '150px',
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
  paddingBottom: '16px',
  gap: '8px',
  overflow: 'hidden',

  '&::-webkit-scroolbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    background: '#888',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-button:vertical:start:increment': {
    display: 'none',
  },
  '&::-webkit-scrollbar-button:vertical:end:decrement': {
    display: 'none',
  },
});

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc((100% - (8px * 3)) / 3);
  border-radius: 8px;
  background: var(--grey-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`;

export const CardHeader = styled('div')`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  align-self: stretch;
  background: var(--grey-grey-100, #fafafa);
`;

export const HeaderTitle = styled(Typography)`
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 25px */
`;

export const CardMenu = styled('div')`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

export const ColumnTitle = styled('div')`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const MenuItem = styled('div')`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ItemText = styled(Typography)`
  color: var(--grey-grey-1000, #03000f);
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  padding: 8px;
`;

export const CardFooter = styled('div')`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const FooterAction = styled(Button)`
  display: flex;
  padding: 8px 24px 8px 16px;
  align-items: center;
  gap: 8px;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

export const AddColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 290px;
  border-radius: 8px;
  border: 1px dashed var(--Core-Purples-Core-Purple-1, #a100ff);
  background: var(--Grey-White, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  cursor: pointer;
`;

export const AddColumnButton = styled(Button)`
  display: flex;
  height: 24px;
  padding: 3px 16px 3px 8px;
  width: 300px;
  height: 100%;
  justify-content: center;
  gap: 4px;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
  letter-spacing: 0.4px;
`;

export const DialogTitleStyled = styled(DialogTitle)`
  color: var(--Black, #0f172a);
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 25px */
  padding-bottom: 0;
`;
