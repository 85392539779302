import { Grid } from '@mui/material';
import { styled } from '@mui/system';

export const SidebarContainer = styled(Grid)`
  border-right: 2px solid #e5e5e5;
  box-sizing: border-box;
`;

export const ContentContainer = styled(Grid)`
  flex-direction: column;
  min-height: 100%;
`;

export const ChatContainer = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 204px);
  background-color: #f2f2f2;
`;

export const ChatItem = styled(Grid)`
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px 32px;
  height: calc(100vh - 204px);
  flex-direction: column;
  flex-wrap: nowrap;
`;
