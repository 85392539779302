import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { TextStyled } from 'components/Upload/DragDropFile/DragDropFile.styles';

export default function UnauthorizedPage(): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          gap: '24px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px 36px',
        }}
      >
        <LockIcon sx={{ color: '#B3B2B5', width: '150px', height: '150px' }} />
        <TextStyled fontSize="30px" color="#03000F" fontHeight="500">
          Unauthorized Access
        </TextStyled>

        <TextStyled>
          The server could not verify that you are authorized to access the URL
          requested.
        </TextStyled>

        <TextStyled>
          You can{' '}
          <span
            style={{ color: '#7500C0', cursor: 'pointer' }}
            onClick={() => navigate(-1)}
          >
            go back
          </span>{' '}
          if you ended up here by mistake.
        </TextStyled>
      </Box>
    </Box>
  );
}
