import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import DocumentEditor from 'components/DocumentEditor';
import { useCallback, useContext, useRef } from 'react';
import { IOrderedContent } from 'components/Page/DocumentPage';
import PdfView from 'components/PdfView';
import { pdf } from '@react-pdf/renderer';
import { Files } from 'types';
import { FilesContext } from 'components/FilesProvider';
import useMetadataFile from 'common/hooks/useMetadataFile';

interface DocumentGeneratorProps {
  docName: string;
  selectedIndex: string;
  reset: () => void;
  setOpenDocumentGnerator: React.Dispatch<React.SetStateAction<boolean>>;
}

const DocumentGenerator = ({
  docName,
  selectedIndex,
  reset,
  setOpenDocumentGnerator,
}: DocumentGeneratorProps) => {
  const navigate = useNavigate();
  const { dispatch, uploadFiles } = useContext(FilesContext);
  const { mandatoryMetadata } = useMetadataFile({ index: selectedIndex });
  const documentContent = useRef<IOrderedContent[]>();

  const handleCancel = () => setOpenDocumentGnerator(false);

  const handleGenerate = useCallback(async () => {
    if (!documentContent || !documentContent.current) return;

    const blob = await pdf(
      PdfView({ currentChunk: documentContent.current, getDocument: true }),
    ).toBlob();
    const file = new File([blob], `${docName}.pdf`, {
      type: blob.type,
    });
    const filesProcessed: Files = { [file.name]: { file } };
    dispatch({ type: 'enableImport' });
    dispatch({ type: 'addFiles', payload: filesProcessed });
    const containsMandatoryMetadata =
      mandatoryMetadata && mandatoryMetadata.length > 0;
    if (containsMandatoryMetadata) {
      reset();
      return navigate(`/collection/${selectedIndex}/upload`);
    }
    uploadFiles(filesProcessed);
    navigate(`/collection/${selectedIndex}`);
    reset();
  }, [
    docName,
    selectedIndex,
    documentContent,
    mandatoryMetadata,
    dispatch,
    navigate,
    uploadFiles,
    reset,
  ]);

  return (
    <Grid container height="100%" flexDirection="column" wrap="nowrap">
      <Grid
        item
        borderBottom="1px solid #B3B2B5"
        height="80px"
        padding={3}
        pb={2}
        boxSizing={'border-box'}
      >
        <Grid container alignItems="center" bgcolor="white">
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h2">{docName}</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', gap: 2 }} textAlign="right">
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGenerate}
            >
              Generate document
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item flexGrow={1} height="calc(100vh - 204px)">
        <Grid container item height="100%" wrap="nowrap" flexGrow={1}>
          <DocumentEditor documentContent={documentContent} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentGenerator;
