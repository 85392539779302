import { Box, Typography } from '@mui/material';

interface DisclaimerProps {
  message: string;
}

const Disclaimer = ({ message }: DisclaimerProps) => {
  return (
    <Box display="flex" justifyContent="center">
      <Typography
        variant="caption"
        fontStyle="italic"
        textAlign="center"
        style={{ color: 'rgb(139 139 139)' }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default Disclaimer;
