import { Grid } from '@mui/material';
import toolbarIconsList from './toolbarIconsList';
import useOnClickListener from './useOnClickListener';

const LexicalEditorTopBar = () => {
  const { onClick, selectedEventTypes, blockType, modal } =
    useOnClickListener();

  const isIconSelected = (plugin) =>
    selectedEventTypes.includes(plugin.event) ||
    blockType.includes(plugin.event);

  return (
    <Grid
      container
      justifyContent="space-between"
      gap={2}
      alignItems="center"
      sx={{ background: 'white', py: 1.5, px: 2 }}
    >
      {toolbarIconsList.map((plugin) => (
        <Grid key={plugin.id} sx={{ cursor: 'pointer' }} item>
          {
            <plugin.Icon
              sx={plugin.iconSx}
              onClick={() => onClick(plugin.event)}
              color={isIconSelected(plugin) ? 'secondary' : undefined}
            />
          }
        </Grid>
      ))}
      {modal}
    </Grid>
  );
};

export default LexicalEditorTopBar;
