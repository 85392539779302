import { AxiosError, AxiosResponse } from 'axios';
import PrivReq from 'api/axios';

type TPlugin = {
  pluginFile: File;
};

export default async function uploadPlugin({
  pluginFile,
}: TPlugin): Promise<string> {
  type TReq = { message: string };

  const req: TReq = { message: '...' };

  const formData = new FormData();
  formData.append('plugin', pluginFile);

  await PrivReq({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    method: 'post',
    url: `/uploadplugin`,
    data: formData,
  })
    .then((response: AxiosResponse) => {
      return (req.message = response.data.status || 'plugin file uploaded');
    })
    .catch((error) => {
      return (req.message =
        error.response.data.status || 'Error uploading file');
    });
  return req.message;
}
