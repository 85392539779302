import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Chip,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import { decamelize } from 'common/utils/stringStyler';
import { useState } from 'react';

import { FilterValues, Filters } from 'types';

type ClickEvent = React.MouseEvent<HTMLElement>;

interface ConfigurationButtonProps {
  filters: Filters;
  filterValues: FilterValues;
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValues>>;
}

const PopoverFilter = ({
  filters,
  filterValues,
  setFilterValues,
}: ConfigurationButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const activeFilters = Object.keys(filterValues).flatMap((category) =>
    Object.keys(filterValues[category])
      .filter((checkboxKey) => !!filterValues[category][checkboxKey])
      .map((filterKey) => ({
        key: category,
        name: filterKey,
        label: `${category}: ${decamelize(filterKey)}`,
      })),
  );

  const handleClick = (e: ClickEvent) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const { name, checked } = e.target;
    const isMultioption = filters.filter((f) => f.title === key)[0].multiselect;
    isMultioption
      ? setFilterValues((prev) => ({
          ...prev,
          [key]: { ...prev[key], [name]: checked },
        }))
      : setFilterValues((prev) => {
          const allFilterValues = prev[key];
          const newCheckboxValues = JSON.parse(
            JSON.stringify(allFilterValues, (checkboxKey, value) =>
              Object(value) === value
                ? value
                : checkboxKey === name
                  ? checked
                  : false,
            ),
          );
          return { ...prev, [key]: newCheckboxValues };
        });
  };

  const handleRemove = ({ key, name }: { key: string; name: string }) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: { ...prev[key], [name]: false },
    }));
  };

  return (
    <Grid
      container
      sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'nowrap' }}
    >
      <Grid container item spacing={1} justifyContent={'end'}>
        {activeFilters.map(({ label, ...rest }, idx) => (
          <Grid item key={idx}>
            <Chip
              label={label}
              variant="outlined"
              onDelete={() => handleRemove(rest)}
              color="primary"
            />
          </Grid>
        ))}
      </Grid>

      <Grid item>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          id="account-menu"
          open={!!anchorEl}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 15,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '240px',
              padding: '15px',
              gap: 2,
            }}
          >
            {filters.map((filter, idx) => (
              <Box key={idx}>
                <Typography
                  sx={{
                    textWrap: 'wrap',
                    fontFamily: 'Graphik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    paddingBottom: 1,
                    color: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  {filter.title}
                </Typography>
                <FormGroup>
                  {filter.options.map((item, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          name={item.name}
                          color="secondary"
                          onChange={(e) =>
                            handleCheckboxChange(e, filter.title)
                          }
                          checked={
                            filterValues[`${filter.title}`][
                              item.name
                            ] as boolean
                          }
                        />
                      }
                      label={item.label}
                      sx={{
                        textWrap: 'wrap',
                        fontFamily: 'Graphik',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        color: 'black',
                      }}
                    />
                  ))}
                </FormGroup>
              </Box>
            ))}
          </Box>
        </Popover>
      </Grid>
    </Grid>
  );
};

export default PopoverFilter;
