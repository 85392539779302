import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { TGraphsIndustries } from 'components/GraphIndustryProvider';

const GraphsIndustriesList = ({
  graph_name,
  industry,
}: TGraphsIndustries): React.JSX.Element => {
  const navigate = useNavigate();
  const [selectedIndustry, setSelectedIndustry] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedIndustry(event.target.value);
  };

  const industriesList = industry.map((_industry: string) => (
    <MenuItem
      key={_industry}
      value={_industry}
      onClick={() =>
        navigate(`/graph/${graph_name}/industry_name/${_industry}`)
      }
    >
      {_industry}
    </MenuItem>
  ));

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Industry</InputLabel>

      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedIndustry}
        label="Industry"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>Industry</em>
        </MenuItem>
        {industriesList}
      </Select>
    </FormControl>
  );
};

export default GraphsIndustriesList;
