import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface Option {
  name: string;
  icon?: JSX.Element;
}

interface MoreOptionsProps {
  options: Option[];
  position?: 'vertical' | 'horizontal';
  onClick?: () => void;
  onSelectOption: (option: string) => void;
}

const MoreOptions = ({
  options,
  position,
  onClick,
  onSelectOption,
}: MoreOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    if (onClick) onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = ({
    e,
    option,
  }: {
    e: React.MouseEvent<HTMLLIElement, MouseEvent>;
    option: string;
  }) => {
    e.stopPropagation();
    handleClose();
    onSelectOption(option);
  };

  return (
    <>
      <IconButton color="secondary" size="large" onClick={handleOpenMenu}>
        {position === 'vertical' ? <MoreVertIcon /> : <MoreHorizIcon />}
      </IconButton>
      <Menu
        id="collection-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={(e) => handleSelectOption({ e, option: option.name })}
            sx={{ gap: 2, justifyContent: 'space-between' }}
          >
            {option.name}
            {option.icon && option.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreOptions;
