import { styled } from '@mui/system';

export const StyledLink = styled('a')`
  color: white;

  &:visited {
    color: #d386ff;
    background-color: transparent;
    text-decoration: none;
  }

  &:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
  }

  &:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }
`;
