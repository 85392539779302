import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import {
  StyledContent,
  StyledTitle,
  StyledActions,
} from './ConfidentionalInfoReminder.styles';

interface ConfidentialInfoReminderProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConfidentialInfoReminder({
  openDialog,
  setOpenDialog,
}: ConfidentialInfoReminderProps): React.JSX.Element {
  const handleAccept = () => {
    localStorage.setItem('isConfidentionalInfoAccepted', 'true');
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} maxWidth={'md'}>
      <div style={{ padding: '24px' }}>
        <StyledTitle>Confidential Information – Reminder</StyledTitle>
        <StyledContent>
          <p>
            You are using an Accenture proprietary tool Quasar++. You understand
            that this tool may contain {` `}
            <span>
              ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION
            </span>
            . You are obligated to adhere to applicable confidentiality
            obligations and Accenture’s policies, including Policy 69 –
            Confidentiality, when using the tool and information in the tool.
            <br />
            Information in the tool can only be shared with those authorized to
            receive it. If you are downloading/exporting Confidential
            Information to another file or report, you must label that file or
            report as Accenture Confidential. If you have any questions, please
            email your question to{' '}
            <a href="mailto:confidentiality@accenture.com">
              confidentiality@accenture.com
            </a>{' '}
            or contact your Leadership. Thank you!
          </p>
        </StyledContent>
        <StyledActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#A100FF' }}
            onClick={handleAccept}
          >
            I UNDERSTAND
          </Button>
        </StyledActions>
      </div>
    </Dialog>
  );
}
