const grey = {
  100: '#FAFAFA',
  200: '#F2F2F2',
  300: '#E5E5E5',
  400: '#CCCBCE',
  500: '#B3B2B5',
  600: '#837F89',
  700: '#4F4B53',
  800: '#3B3944',
  900: '#1D1823',
  1000: '#03000F',
};

export default grey;
