import { Paper } from '@mui/material';
import React from 'react';

interface CSVDataTableProps {
  tableContent: { [key: string]: string }[];
}

const CSVDataTable = ({ tableContent }: CSVDataTableProps) => {
  const headers = tableContent.length > 0 ? Object.keys(tableContent[0]) : [];
  return (
    <>
      {tableContent.length === 0 ? (
        <p>No data available.</p>
      ) : (
        <Paper elevation={2}>
          <table
            style={{
              borderCollapse: 'collapse',
              width: '100%',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#ffffff',
                      backgroundColor: 'black',
                      borderBottom: '1px solid #ddd',
                      padding: '15px',
                      textAlign: 'left',
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableContent.map((row, index) => (
                <tr key={index}>
                  {headers.map((header, columnIndex) => (
                    <td
                      key={columnIndex}
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        borderBottom: '1px solid #ddd',
                        padding: '15px',
                        backgroundColor: '#fff',
                      }}
                    >
                      {row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
      )}
    </>
  );
};

export default CSVDataTable;
