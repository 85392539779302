import { useParams } from 'react-router-dom';

import { CollectionContext } from 'components/CollectionProvider';
import ComponentLoading from 'components/ComponentLoading';
import Upload from 'components/Upload';
import CollectionNotFound from 'components/CollectionNotFound';
import React, { useContext, useEffect } from 'react';
import { FilesContext } from 'components/FilesProvider';

/**
 * The upload page component for an individual collection which should render all components to be displayed specific to this page
 */
export default function CollectionUploadPage(): React.JSX.Element {
  const { collections, loadingCollections, getCollections } =
    useContext(CollectionContext);
  const { index } = useParams();
  const currentCollection =
    collections && collections.find((item) => item === index);
  const { setMetadatas, setMandatoryMetadatas, setCurrentIndex } =
    useContext(FilesContext);

  useEffect(() => {
    if (!index) return;
    setCurrentIndex(index);
    setMetadatas([]);
    setMandatoryMetadatas([]);
  }, [index, setCurrentIndex, setMetadatas, setMandatoryMetadatas]);

  if (loadingCollections || currentCollection === null) {
    // Show the loading interface
    return <ComponentLoading />;
  }

  if (currentCollection === undefined) {
    // Show the no collection interface
    return <CollectionNotFound getCollections={getCollections} />;
  }

  // Show the main collection upload interface
  return <Upload />;
}
