const shorten = (str: string, maxLen: number) => {
  if (!str) return;
  if (str.length <= maxLen) return str;
  const shortenStatement = str.slice(0, str.lastIndexOf(' ', maxLen));
  const shortenString = str.slice(0, maxLen);
  const isShortenStatementExceeded = shortenStatement.length > maxLen;
  return `${isShortenStatementExceeded ? shortenString : shortenStatement}...`;
};

export default shorten;
