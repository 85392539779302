export const ApiIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 280 189"
    enableBackground="new 0 0 280 189"
    xmlSpace="preserve"
  >
    <title>Select API</title>
    <path
      fill="none"
      opacity="1.000000"
      stroke="none"
      d="
M173.000000,190.000000 
   C115.333344,190.000000 58.166679,190.000000 1.000015,190.000000 
   C1.000010,127.000015 1.000010,64.000031 1.000005,1.000032 
   C94.333305,1.000021 187.666611,1.000021 280.999939,1.000011 
   C280.999939,63.999969 280.999939,126.999939 280.999969,189.999954 
   C245.166672,190.000000 209.333328,190.000000 173.000000,190.000000 
M274.162689,155.593384 
   C290.648346,119.916580 270.055634,80.540703 231.312439,73.898964 
   C227.548340,73.253677 225.836151,71.853096 224.867188,67.989052 
   C216.566193,34.886173 187.909927,9.160644 154.175171,4.172096 
   C119.358963,-0.976373 84.773773,15.290988 66.996315,45.451141 
   C65.011192,48.818974 62.695904,50.287464 58.980515,50.944748 
   C23.883934,57.153633 -0.689336,89.725044 2.823738,125.162140 
   C6.449101,161.731903 35.592831,188.127304 72.377609,188.133804 
   C121.683701,188.142517 170.989960,188.060852 220.295807,188.167999 
   C244.607849,188.220825 262.273499,177.432144 274.162689,155.593384 
z"
    />
    <path
      fill="#84808A"
      opacity="1.000000"
      stroke="none"
      d="
M274.017151,155.938995 
   C262.273499,177.432144 244.607849,188.220825 220.295807,188.167999 
   C170.989960,188.060852 121.683701,188.142517 72.377609,188.133804 
   C35.592831,188.127304 6.449101,161.731903 2.823738,125.162140 
   C-0.689336,89.725044 23.883934,57.153633 58.980515,50.944748 
   C62.695904,50.287464 65.011192,48.818974 66.996315,45.451141 
   C84.773773,15.290988 119.358963,-0.976373 154.175171,4.172096 
   C187.909927,9.160644 216.566193,34.886173 224.867188,67.989052 
   C225.836151,71.853096 227.548340,73.253677 231.312439,73.898964 
   C270.055634,80.540703 290.648346,119.916580 274.017151,155.938995 
M142.500000,165.057266 
   C169.323151,165.056870 196.146927,165.170990 222.969254,165.022614 
   C247.845474,164.884979 264.367767,137.993118 253.320221,115.730171 
   C246.213089,101.407959 233.941666,96.063789 218.571594,95.851242 
   C207.046509,95.691864 207.050659,95.438431 204.868637,84.366478 
   C204.547180,82.735382 204.210724,81.107239 203.890869,79.475830 
   C198.539291,52.180885 178.705734,31.663488 151.945831,27.413202 
   C124.486916,23.051891 100.170425,34.219818 86.985886,57.665054 
   C81.525650,67.374641 76.184181,72.482323 64.188187,73.761337 
   C39.367519,76.407745 23.621778,99.009888 25.970129,124.870018 
   C27.999048,147.212540 48.384247,165.021759 72.026398,165.050873 
   C95.184227,165.079407 118.342133,165.057236 142.500000,165.057266 
z"
    />
    <path
      fill="none"
      opacity="1.000000"
      stroke="none"
      d="
M142.000000,165.057312 
   C118.342133,165.057236 95.184227,165.079407 72.026398,165.050873 
   C48.384247,165.021759 27.999048,147.212540 25.970129,124.870018 
   C23.621778,99.009888 39.367519,76.407745 64.188187,73.761337 
   C76.184181,72.482323 81.525650,67.374641 86.985886,57.665054 
   C100.170425,34.219818 124.486916,23.051891 151.945831,27.413202 
   C178.705734,31.663488 198.539291,52.180885 203.890869,79.475830 
   C204.210724,81.107239 204.547180,82.735382 204.868637,84.366478 
   C207.050659,95.438431 207.046509,95.691864 218.571594,95.851242 
   C233.941666,96.063789 246.213089,101.407959 253.320221,115.730171 
   C264.367767,137.993118 247.845474,164.884979 222.969254,165.022614 
   C196.146927,165.170990 169.323151,165.056870 142.000000,165.057312 
M86.212753,126.544373 
   C87.596474,125.680489 88.939468,124.160393 90.371277,124.071556 
   C95.342758,123.763062 100.357101,124.161644 105.331429,123.876892 
   C108.650414,123.686905 110.878426,124.532440 111.539299,127.941689 
   C112.615372,133.492813 116.141006,135.016708 121.288635,134.164948 
   C122.572075,133.952576 123.900246,134.010498 125.662125,133.921158 
   C124.899330,131.949203 124.323860,130.243164 123.592941,128.606552 
   C117.836243,115.716766 111.833649,102.930428 106.400101,89.906425 
   C104.699211,85.829460 102.104080,85.797722 98.647148,85.680061 
   C94.966255,85.554779 92.570442,86.257111 90.947472,90.098312 
   C85.260086,103.559151 79.178139,116.853119 73.277344,130.224274 
   C72.792137,131.323746 72.543175,132.527481 72.155853,133.774841 
   C82.745850,134.801987 82.745850,134.801987 86.212753,126.544373 
M131.052841,119.495209 
   C131.052841,124.255623 131.052841,129.016037 131.052841,133.698624 
   C135.178314,133.698624 138.553986,133.698624 142.386368,133.698624 
   C142.386368,129.329895 142.386368,125.399178 142.386368,120.946945 
   C145.630035,120.946945 148.420822,121.066612 151.196564,120.913475 
   C154.004105,120.758575 156.838165,120.539925 159.587997,119.987640 
   C167.358139,118.427055 172.570389,112.714760 173.124557,105.430992 
   C173.832382,96.127396 170.355316,88.995567 161.853760,87.396996 
   C151.940140,85.532898 141.613907,85.863045 131.052902,85.215714 
   C131.052902,97.257149 131.052902,107.876778 131.052841,119.495209 
M181.178635,99.513748 
   C181.178635,110.940201 181.178635,122.366661 181.178635,133.836258 
   C185.400879,133.836258 188.822449,133.836258 192.802490,133.836258 
   C192.802490,118.402527 192.840942,103.285965 192.706879,88.170937 
   C192.698837,87.264870 191.465469,85.814316 190.542572,85.560135 
   C183.566559,83.638855 181.180099,85.489563 181.178635,92.522766 
   C181.178223,94.521942 181.178787,96.521126 181.178635,99.513748 
z"
    />
    <path
      fill="#86828B"
      opacity="1.000000"
      stroke="none"
      d="
M86.077896,126.923714 
   C82.745850,134.801987 82.745850,134.801987 72.155853,133.774841 
   C72.543175,132.527481 72.792137,131.323746 73.277344,130.224274 
   C79.178139,116.853119 85.260086,103.559151 90.947472,90.098312 
   C92.570442,86.257111 94.966255,85.554779 98.647148,85.680061 
   C102.104080,85.797722 104.699211,85.829460 106.400101,89.906425 
   C111.833649,102.930428 117.836243,115.716766 123.592941,128.606552 
   C124.323860,130.243164 124.899330,131.949203 125.662125,133.921158 
   C123.900246,134.010498 122.572075,133.952576 121.288635,134.164948 
   C116.141006,135.016708 112.615372,133.492813 111.539299,127.941689 
   C110.878426,124.532440 108.650414,123.686905 105.331429,123.876892 
   C100.357101,124.161644 95.342758,123.763062 90.371277,124.071556 
   C88.939468,124.160393 87.596474,125.680489 86.077896,126.923714 
M104.588341,110.958282 
   C102.715157,106.508133 100.841980,102.057983 98.542488,96.595062 
   C95.870354,103.340965 93.675468,108.882019 91.318474,114.832321 
   C96.762260,114.832321 101.143730,114.832321 105.900291,114.832321 
   C105.479698,113.526230 105.183716,112.607109 104.588341,110.958282 
z"
    />
    <path
      fill="#85818B"
      opacity="1.000000"
      stroke="none"
      d="
M131.052872,118.995804 
   C131.052902,107.876778 131.052902,97.257149 131.052902,85.215714 
   C141.613907,85.863045 151.940140,85.532898 161.853760,87.396996 
   C170.355316,88.995567 173.832382,96.127396 173.124557,105.430992 
   C172.570389,112.714760 167.358139,118.427055 159.587997,119.987640 
   C156.838165,120.539925 154.004105,120.758575 151.196564,120.913475 
   C148.420822,121.066612 145.630035,120.946945 142.386368,120.946945 
   C142.386368,125.399178 142.386368,129.329895 142.386368,133.698624 
   C138.553986,133.698624 135.178314,133.698624 131.052841,133.698624 
   C131.052841,129.016037 131.052841,124.255623 131.052872,118.995804 
M157.727615,110.520699 
   C161.463211,108.475876 163.058807,104.955399 161.594360,101.336716 
   C160.581345,98.833481 157.816269,95.996437 155.319702,95.374969 
   C151.335129,94.383102 146.922775,95.109711 142.521301,95.109711 
   C142.521301,100.807571 142.521301,106.012100 142.521301,112.122398 
   C147.598633,111.676399 152.294815,111.263878 157.727615,110.520699 
z"
    />
    <path
      fill="#8A868F"
      opacity="1.000000"
      stroke="none"
      d="
M181.178772,99.017029 
   C181.178787,96.521126 181.178223,94.521942 181.178635,92.522766 
   C181.180099,85.489563 183.566559,83.638855 190.542572,85.560135 
   C191.465469,85.814316 192.698837,87.264870 192.706879,88.170937 
   C192.840942,103.285965 192.802490,118.402527 192.802490,133.836258 
   C188.822449,133.836258 185.400879,133.836258 181.178635,133.836258 
   C181.178635,122.366661 181.178635,110.940201 181.178772,99.017029 
z"
    />
    <path
      fill="none"
      opacity="1.000000"
      stroke="none"
      d="
M104.738037,111.323135 
   C105.183716,112.607109 105.479698,113.526230 105.900291,114.832321 
   C101.143730,114.832321 96.762260,114.832321 91.318474,114.832321 
   C93.675468,108.882019 95.870354,103.340965 98.542488,96.595062 
   C100.841980,102.057983 102.715157,106.508133 104.738037,111.323135 
z"
    />
    <path
      fill="none"
      opacity="1.000000"
      stroke="none"
      d="
M157.359314,110.686035 
   C152.294815,111.263878 147.598633,111.676399 142.521301,112.122398 
   C142.521301,106.012100 142.521301,100.807571 142.521301,95.109711 
   C146.922775,95.109711 151.335129,94.383102 155.319702,95.374969 
   C157.816269,95.996437 160.581345,98.833481 161.594360,101.336716 
   C163.058807,104.955399 161.463211,108.475876 157.359314,110.686035 
z"
    />
  </svg>
);
