import * as React from 'react';
import { useState, useEffect, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { grey } from 'theme';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PrivReq from 'api/axios';
import ComponentLoading from 'components/ComponentLoading';
import { INode, IChildNode } from './interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridRowMode,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowSelectionModel,
  GridRowEditStopReasons,
  GridActionsColDef,
  GridIconSlotsComponent,
  GridSlotsComponent,
  useGridApiRef,
  GridValueGetterParams,
  GridRowEditStopParams,
  //GridSlots,
  //GridValueGetter,
  useGridApiContext,
} from '@mui/x-data-grid';

import { generateSessionId as randomId } from 'common/utils';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { RandomUUIDOptions } from 'crypto';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    // justifyContent: 'space-between',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IShowNodeChildDialogProps {
  show: boolean;
  action: string;
}
interface IChildNodeDialogProps {
  node: INode;
  content: string[];
  open: boolean;
  setOpen: (value: IShowNodeChildDialogProps) => void;
  typeAction: string;
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

interface EditNodeToolbarProps {
  setNewNodeObjArray: (
    newRows: (oldRows: GridRowsProp) => GridRowsProp,
  ) => void;
  setNodeRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, name: '', value: '', isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="secondary" variant="contained" onClick={handleClick}>
        Add properties
      </Button>
    </GridToolbarContainer>
  );
}

function EditNodeToolbar(props: EditNodeToolbarProps) {
  const { setNewNodeObjArray, setNodeRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setNewNodeObjArray((oldRows) => [
      ...oldRows,
      { id, nodeName: '', nodeType: '', isNew: true },
    ]);
    setNodeRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nodeName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="secondary" variant="contained" onClick={handleClick}>
        Add nodes
      </Button>
    </GridToolbarContainer>
  );
}

export default function NodeChildDialog({
  node,
  content,
  open,
  setOpen,
  typeAction,
}: Readonly<IChildNodeDialogProps>) {
  const InitialChildNode: IChildNode = {
    graph_name: '',
    industry: '',
    current_node_id: '',
    current_node_title: '',
    current_node_type: '',
    node_level: '',
    relation: '',
    node_list: [
      {
        node_type: '',
        node_title: '',
        node_properties: [{ name: '', value: '' }],
      },
    ],
  };

  interface IProperty {
    id: string;
    name: string;
    value: string;
    isNew?: boolean;
  }

  interface INewNodeObj {
    id: string;
    nodeName: string;
    nodeType?: string;
    isNew?: boolean;
  }

  const initialNodeObjArray: INewNodeObj[] = [
    {
      id: randomId(),
      nodeName: '',
      nodeType: '',
      isNew: false,
    },
  ];

  const initialRows: IProperty[] = [
    { id: randomId(), name: '', value: '', isNew: false },
  ];

  const [childNodeData, setChildNodeData] =
    useState<IChildNode>(InitialChildNode);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [rows, setRows] = React.useState<IProperty[]>(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [rowNodeModesModel, setNodeRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [newNodeObjArray, setNewNodeObjArray] =
    React.useState<INewNodeObj[]>(initialNodeObjArray);

  /**
   * DataGrid for properties
   * @param params
   * @param event
   */
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: IProperty) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   *
   */

  /**
   * DataGrid for nodes
   * @param params
   * @param event
   */

  const processNodeRowUpdate = (newRow: INewNodeObj) => {
    const updatedRow = { ...newRow, isNew: false };
    setNewNodeObjArray(
      newNodeObjArray.map((newNodeObjArray) =>
        newNodeObjArray.id === newRow.id ? updatedRow : newNodeObjArray,
      ),
    );
    return updatedRow;
  };

  const handleNodeRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleNodeEditClick = (id: GridRowId) => () => {
    setNodeRowModesModel({
      ...rowNodeModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleNodeSaveClick = (id: GridRowId) => () => {
    setNodeRowModesModel({
      ...rowNodeModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleNodeDeleteClick = (id: GridRowId) => () => {
    setNewNodeObjArray(newNodeObjArray.filter((row) => row.id !== id));
  };

  const handleNodeCancelClick = (id: GridRowId) => () => {
    setNodeRowModesModel({
      ...rowNodeModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = newNodeObjArray.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setNewNodeObjArray(newNodeObjArray.filter((row) => row.id !== id));
    }
  };

  const handleNodeRowModesModelChange = (
    newRowModesModel: GridRowModesModel,
  ) => {
    setNodeRowModesModel(newRowModesModel);
  };

  /**
   *
   */

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 171, editable: true },
    {
      field: 'value',
      headerName: 'Value',
      type: 'string',
      width: 171,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'inherit',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const nodesColumns: GridColDef[] = [
    { field: 'nodeName', headerName: 'Node name', width: 171, editable: true },
    {
      field: 'nodeType',
      headerName: 'Node type',
      type: 'string',
      width: 171,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowNodeModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'inherit',
              }}
              onClick={handleNodeSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleNodeCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleNodeEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleNodeDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  interface IPropertiesObj {
    [key: string]: string;
  }

  interface IProperties {
    name: string;
    value: string;
  }

  interface INodeList {
    node_title: string;
    node_type?: string;
    node_properties: IProperties[];
  }

  function nodePropertiesObj(inputArray: IProperty[]): IProperties[] {
    //const result: IPropertiesObj[] = [
    //   {
    //     ...inputArray.reduce((acc, item) => {
    //       acc[item.name] = item.value;
    //       return acc;
    //     }, {} as IPropertiesObj),
    //   },
    // ];

    const result: IProperties[] = inputArray.map((item: IProperty) => ({
      name: item.name,
      value: item.value,
    }));
    return result;
  }

  function nodeListObjArray(inputArray: INewNodeObj[]): INodeList[] {
    const nodeProperties = nodePropertiesObj(rows);

    const result: INodeList[] = inputArray.map((item: INewNodeObj) => ({
      node_title: item.nodeName,
      node_type: item.nodeType,
      node_properties: nodeProperties,
    }));

    return result;
  }

  const handleClose = () => {
    resetStates();
    setOpen({
      show: false,
      action: '',
    });
  };

  const submitData = async () => {
    setLoading(true);

    const node_list = nodeListObjArray(newNodeObjArray);
    const payload = {
      graph_name: node.graph_name,
      industry: node.industry,
      current_node_id: node.id,
      current_node_title: node.title,
      current_node_type: node.type,
      node_level: typeAction === 'Create' ? 'child' : '',
      relation: typeAction === 'Create' ? 'read' : '',
      node_list,
    };

    await PrivReq({
      withCredentials: true,
      method: 'post',
      url: `/createnewnode`,
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: AxiosResponse) => {
        setMessage(response.data.status);
      })
      .catch((error: AxiosError) => {
        setMessage(error.message || 'Server error inserting data');
      });
    setLoading(false);
  };

  const resetStates = () => {
    setMessage('');
    setRows(initialRows);
    setNewNodeObjArray(initialNodeObjArray);
    setRowModesModel({});
    setNodeRowModesModel({});
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
      style={{ overflowX: 'hidden' }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {typeAction} Node
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ overflowX: 'hidden', p: 3 }}>
        <Typography variant="h2" gutterBottom>
          selected node type: {node.type}
        </Typography>

        <Box
          sx={{
            //height: 500,
            //width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            // '& .textPrimary': {
            //   color: 'text.primary',
            // },
          }}
        >
          <DataGrid
            rows={newNodeObjArray}
            columns={nodesColumns}
            editMode="row"
            rowModesModel={rowNodeModesModel}
            onRowModesModelChange={handleNodeRowModesModelChange}
            onRowEditStop={handleNodeRowEditStop}
            processRowUpdate={processNodeRowUpdate}
            density="comfortable"
            initialState={{
              columns: {
                columnVisibilityModel: {
                  nodeType: typeAction === 'Create',
                },
              },
            }}
            slots={{
              toolbar: EditNodeToolbar,
            }}
            slotProps={{
              toolbar: { setNewNodeObjArray, setNodeRowModesModel },
            }}
          />
        </Box>

        <Divider
          sx={{
            mt: 1,
            mb: 1,
          }}
        />

        <Box
          sx={{
            //height: 500,
            //width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            density="comfortable"
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <div>
            {!!loading && <ComponentLoading />}
            {!loading && message.length > 1 && `${message}`}
          </div>

          <Button
            component="label"
            variant="contained"
            color="secondary"
            disableElevation
            disabled={loading}
            onClick={() => submitData()}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </BootstrapDialog>
  );
}
