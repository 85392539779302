import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import SecureTextField from 'components/SecureTextField';

import axios from 'api/axios';
import { SnackbarContext } from 'components/SnackbarProvider';
import { CollectionContext } from 'components/CollectionProvider';
import MultipleSelectChip from 'components/MultipleSelectChip';
import { getAllMetadatas } from 'components/MetadataProvider';

import { CIMetadataValues } from 'types';
import Grid from '@mui/material/Grid';
import { containsSpecialChars } from 'common/utils/common';

type CreateCollectionDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

/**
 * Shows a dialog that will call the API to create a new collection
 */

export const MAX_METADATA_LIMIT = 12;

export default function CreateCollectionDialog({
  open,
  setOpen,
}: CreateCollectionDialogProps): React.JSX.Element {
  const [newCollectionName, setNewCollectionName] = useState('');
  const [newCollectionDescription, setNewCollectionDescription] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const createAlert = useContext(SnackbarContext);
  const { collections, getCollections: updateCollections } =
    useContext(CollectionContext);
  const [metadatas, setMetadatas] = useState<CIMetadataValues>([]);
  const [listOfMetadatas, setListOfMetadatas] = useState<string[]>([]);
  const [optional, setOptional] = useState<string[]>([]);
  const [mandatory, setMandatory] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [descriptionErrorMessage, setDescriptionErrorMessage] =
    useState<string>('');

  const navigate = useNavigate();
  const file = new File(['empty-file'], 'empty-file.txt', {
    type: 'text/plain',
  });
  const not = (a: readonly string[], b: readonly string[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };
  const intersection = (a: readonly string[], b: readonly string[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
  };
  const union = (a: readonly string[], b: readonly string[]) => {
    return [...a, ...not(b, a)];
  };

  useEffect(() => {
    getAllMetadatas(true)
      .then((response: any) => {
        let listOfMetadatas = response.map((elem: any) => {
          return elem.name;
        });
        setListOfMetadatas(listOfMetadatas);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(err);
        }
      })
      .finally(() => {
        let str = '';
        str = localStorage.getItem('createIndexTempInfo') || '';
        if (str) {
          let obj = JSON.parse(str);
          setOpen(true);
          setNewCollectionName(obj.modalInfo.temporaryName);
          setNewCollectionDescription(obj.modalInfo.temporaryDescription);
          setMetadatas(obj.modalInfo.metadatas.selected);
          setOptional(obj.modalInfo.metadatas.optional);
          setMandatory(obj.modalInfo.metadatas.mandatory);
        }
      });
  }, []);

  useEffect(() => {
    let listOfOptions = metadatas.map((elem) => elem.field);
    let interssec = intersection(mandatory, listOfOptions);
    if (interssec.length)
      setMandatory((prev) => prev.filter((elem) => !interssec.includes(elem)));

    setOptional(listOfOptions.filter((elem) => !mandatory.includes(elem)));
  }, [metadatas]);

  const handleDialogClose = () => {
    setOpen(false);
    setNewCollectionName('');
    setNewCollectionDescription('');
    setMandatory([]);
    setOptional([]);
    setDescriptionErrorMessage('');
    localStorage.removeItem('createIndexTempInfo');
  };

  const handleCreate = () => {
    // TODO: The following API request is a placeholder until the BE API supports
    // creating an index. The work around used here is to call the upload API
    // endpoint, which will create a new index when a file is uploaded to an
    // index that doesn't exist.

    // const createMetadataObject = (
    //   data: CIMetadataValues /*{name:string, mandatory:boolean}[]*/,
    //   mandatory: boolean,
    // ) => {
    //   let mountedObject: { [key: string]: string } = {};
    //   data
    //     .filter((item) => {
    //       return mandatory ? item.mandatory : !item.mandatory;
    //     })
    //     .forEach((item) => {
    //       mountedObject[item.field] = '';
    //     });
    //   return mountedObject;
    // };

    const createMetadataObject = (data: string[]) => {
      let mountedObject: { [key: string]: string } = {};
      data.forEach((item) => (mountedObject[item] = ''));
      return mountedObject;
    };

    if (
      newCollectionName !== '' &&
      collections &&
      collections.find((item) => item === newCollectionName) === undefined
    ) {
      setCreateLoading(true);
      // let obj = {
      //   metadata: {
      //     country: '',
      //   },
      //   index: 'required_metadata_idx',
      //   mandatory_metadata: {
      //     Teste: '',
      //   },
      // };
      // let required_metadata = metadatas.map((item) => {
      //   return { [item.field]: '' };
      // });
      let metadata = createMetadataObject(optional);
      let mandatory_metadata = createMetadataObject(mandatory);
      axios({
        method: 'post',
        url: '/createindex',
        data: {
          index: newCollectionName,
          mandatory_metadata,
          metadata,
          description: newCollectionDescription,
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.Status.toLowerCase() === 'success') {
            createAlert({
              message: `'${newCollectionName}' collection created`,
              severity: 'success',
            });
            // TODO: Update collections works, but is called so soon after the upload finishes
            // that the BE doesn't return the new collection when asked. When a create collection
            // API endpoint is added it should return the updated list of collections to avoid
            // having to use a setTimeout to wait long enough for the BE to update.
            setTimeout(() => {
              handleDialogClose();
              updateCollections();
              navigate(`/collection/${newCollectionName}`);
            }, 2000);
          } else {
            createAlert({
              message: `Unable to create '${newCollectionName}' collection`,
              severity: 'error',
            });
            setCreateLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
          setCreateLoading(false);
          createAlert({
            message: `Unable to create '${newCollectionName}' collection`,
            severity: 'error',
          });
        });
    } else {
      if (newCollectionName === '') {
        createAlert({
          message: `Must enter a collection name`,
          severity: 'error',
        });
      } else {
        createAlert({
          message: `A collection named '${newCollectionName}' already exists`,
          severity: 'error',
        });
      }
    }
  };

  const toggleMetadatas = (items: string[], targetList: string) => {
    setSelected([]);

    if (targetList === 'Mandatory') {
      let newOptional = intersection(optional, items);
      setOptional(newOptional);
      let newMandatory = union(mandatory, items);
      setMandatory(newMandatory);
      return;
    }

    setMandatory(intersection(mandatory, items));
    setOptional(union(optional, items));

    //  else {
    //   // let newMandatory = [...mandatory].filter((elem) => elem !== item);
    //   // setMandatory(newMandatory);
    //   // // setMandatory((prev) => {
    //   // //   return prev.filter((elem) => elem !== item);
    //   // // });

    //   // let newOptional = [...optional];
    //   // newOptional.push(items);
    //   // setOptional(newOptional);
    //   // // setOptional((prev) => {
    //   // //   prev.push(item);
    //   // //   return prev;
    //   // // });
    // }
  };

  const customList = (title: string, items: string[]) => (
    <Grid width={377}>
      <Card sx={{ height: '400px', overflow: 'auto' }}>
        <CardHeader avatar={<>{title}</>}></CardHeader>
        <Divider />
        <List component="div" role="list">
          {items &&
            items.map((item) => (
              <ListItem
                key={item}
                role="listitem"
                button
                onClick={() => {
                  if (selected.includes(item)) {
                    let prev = [...selected].filter((e) => e !== item);
                    setSelected(prev);
                    return;
                  }
                  let prev = [...selected];
                  prev.push(item);
                  setSelected(prev);
                  // setSelected((prev) => {
                  //   if (prev.includes(item)) {
                  //     prev = prev.slice(prev.indexOf(item), 1);
                  //     return prev;
                  //   }
                  //   prev.push(item);
                  //   return prev;
                  // });
                }}
                disableRipple
              >
                <ListItemIcon>
                  <Checkbox checked={selected.includes(item)} />
                  <ListItemText id={item} primary={item} />
                </ListItemIcon>
              </ListItem>
            ))}
        </List>
      </Card>
    </Grid>
  );

  const selectMetadata = (event: React.SetStateAction<CIMetadataValues>) => {
    const isAllowedToSelectMoreMetadata =
      optional.length + mandatory.length <= MAX_METADATA_LIMIT &&
      metadatas.length <= MAX_METADATA_LIMIT;

    isAllowedToSelectMoreMetadata && setMetadatas(event);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
      <DialogTitle>Create new collection</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
          <SecureTextField
            autoFocus
            margin="dense"
            id="name"
            label="Collection name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCollectionName}
            onUpdate={(value) => setNewCollectionName(value)}
          />
          <SecureTextField
            autoFocus
            margin="dense"
            id="description"
            label="Collection description"
            type="text"
            fullWidth
            variant="outlined"
            value={newCollectionDescription}
            onUpdate={(v: string) => setNewCollectionDescription(v)}
          />
          <Grid container>
            <MultipleSelectChip
              setValuesFunction={selectMetadata}
              selectedValues={metadatas}
              listOfValues={listOfMetadatas}
              maxSelectedLimit={MAX_METADATA_LIMIT}
            />
            <Button
              onClick={() => {
                let temp = JSON.stringify({
                  modalInfo: {
                    temporaryName: newCollectionName,
                    temporaryDescription: newCollectionDescription,
                    metadatas: {
                      selected: metadatas,
                      optional,
                      mandatory,
                    },
                  },
                });
                localStorage.setItem('createIndexTempInfo', temp);
                navigate('/metadata');
              }}
            >
              Manage Metadata
            </Button>
          </Grid>
          {((optional && optional.length > 0) ||
            (mandatory && mandatory.length > 0)) && (
            <Grid container>
              {customList('Optional Metadata', optional)}
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleMetadatas(selected, 'Mandatory');
                  }}
                >
                  {'>'}
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleMetadatas(selected, 'Optional');
                  }}
                >
                  {'<'}
                </Button>
              </Grid>
              {customList('Mandatory Metadata', mandatory)}
            </Grid>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreate}
          disabled={createLoading}
        >
          Create Collection
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type CreateCollectionButtonProps = {
  setOpen: (value: boolean) => void;
};

/**
 * An optional styled button to use to open the CreateCollectionDialog component
 */
export function CreateCollectionButton({
  setOpen,
}: CreateCollectionButtonProps): React.JSX.Element {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => setOpen(true)}
    >
      Create Collection
    </Button>
  );
}
