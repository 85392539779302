export function validateUrl(url: string) {
  const re =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return re.test(url);
}

export const validateInput = ({
  type,
  value,
}: {
  type: string;
  value?: string | number | boolean;
}) => {
  if (typeof value !== 'string' || 'boolean') return;
  if (type !== 'url' || value === '') return;
  if (!validateUrl(value)) return 'Please enter a valid URL.';
};
