import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { grey } from 'theme';

import { CardContainer } from 'components/ChatCard/ChatCard.styles';
import SecureTextField from 'components/SecureTextField';
import MoreOptions from 'components/MoreOptions';
import ConfirmationDialog from 'components/UI/ConfirmationDialog';
import { ChatData } from 'types';

interface SideBarInputProps {
  chatData: ChatData;
  sessionid: string;
  setAvailableChats: any;
  setChatName: React.Dispatch<React.SetStateAction<string>>;
  handleLoadChat: (chatData: ChatData | null) => void;
}

const options = [
  { name: 'Edit', icon: <EditIcon /> },
  { name: 'Delete', icon: <DeleteIcon /> },
];

const ChatCard: React.FC<SideBarInputProps> = ({
  chatData,
  sessionid,
  setAvailableChats,
  setChatName,
  handleLoadChat,
}) => {
  const [openConfirmDeletion, setOpenConfirmDeletion] = useState(false);
  const [selectedChat, setSelectedChat] = useState<ChatData | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    chatName: chatData.name,
    description: chatData.description,
  });

  const handleUpdate = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    setChatName(formData.chatName);
    setAvailableChats((chats: ChatData[]) =>
      chats.map((chat) =>
        chat.sessionid === chatData.sessionid
          ? {
              ...chat,
              name: formData.chatName,
              description: formData.description,
            }
          : chat,
      ),
    );
    setEditMode(false);
  };

  const handleChange = (
    cleanValue: string,
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
  ) => {
    const { name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: cleanValue }));
  };

  const handleDeleteChat = () => {
    if (selectedChat) {
      setAvailableChats((oldChats: ChatData[]) => {
        const updatedChats = oldChats.filter(
          (chat) => chat.sessionid !== selectedChat.sessionid,
        );
        if (selectedChat.sessionid === sessionid) {
          if (updatedChats.length > 0) {
            handleLoadChat(updatedChats[updatedChats.length - 1]);
          }
        }
        return updatedChats;
      });
    }
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setEditMode(false);
    setFormData({
      chatName: chatData.name,
      description: chatData.description,
    });
  };

  const handleSelectOption = (option: string) => {
    if (option === 'Delete') setOpenConfirmDeletion(true);
    if (option === 'Edit') {
      setEditMode(true);
      setFormData({
        chatName: chatData.name,
        description: chatData.description,
      });
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirmDeletion}
        title={`Delete Conversation ${chatData.name}`}
        content={
          <>
            <p>Are you sure you want to delete this conversation?</p>
            <br />
            <p style={{ color: 'red' }}>This action cannot be undone.</p>
          </>
        }
        leftButtonProps={{ name: 'Delete', onClick: handleDeleteChat }}
        rightButtonProps={{
          name: 'Cancel',
          onClick: () => setOpenConfirmDeletion(false),
        }}
      />
      <CardContainer
        sx={{ m: 3 }}
        onClick={() => handleLoadChat(chatData)}
        active={chatData.sessionid === sessionid ? 1 : 0}
        component={'form'}
        onSubmit={handleUpdate}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {editMode ? (
            <SecureTextField
              required
              fullWidth
              id={'chat_name_id'}
              name={'chatName'}
              type={'text'}
              variant="outlined"
              placeholder={'Chat name'}
              value={formData['chatName']}
              onUpdate={handleChange}
              onClick={(e) => e.stopPropagation()}
              sx={{ paddingBottom: 2 }}
            />
          ) : (
            <h4 style={{ margin: 0 }}>{chatData.name}</h4>
          )}

          <MoreOptions
            options={options}
            onSelectOption={handleSelectOption}
            onClick={() => setSelectedChat(chatData)}
          />
        </Box>
        {editMode ? (
          <SecureTextField
            required
            fullWidth
            id={'description_id'}
            name={'description'}
            type={'text'}
            variant="outlined"
            placeholder={'Description'}
            value={formData['description']}
            onUpdate={handleChange}
            onClick={(e) => e.stopPropagation()}
            sx={{ paddingBottom: 2 }}
          />
        ) : (
          <p>{chatData.description}</p>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            gap: 1,
            flexDirection: 'column',
          }}
        >
          <p style={{ color: grey[500], margin: 0 }}>{chatData.lastEdited}</p>
          {editMode && (
            <Box display="flex" gap={1}>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={handleClose}
                sx={{
                  padding: '0px 16px',
                  height: '25px',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={(e) => e.stopPropagation()}
                sx={{
                  padding: '0px 16px',
                  height: '25px',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </CardContainer>
    </>
  );
};

export default ChatCard;
