import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TGraphsIndustries } from 'components/GraphIndustryProvider';
import { grey, purple } from 'theme';
import GraphsIndustriesList from './GraphsIndustriesList';
import DeleteGraphDialog, {
  DeleteGraphButton,
} from 'components/Page/Graphs/DeleteGraphDialog';
import { SnackbarContext } from 'components/SnackbarProvider';
import GraphListProps from './GraphListProps';

export default function GraphList({
  graphs,
}: Readonly<GraphListProps>): React.JSX.Element {
  const navigate = useNavigate();
  const [showDeleteGraphDialog, setShowDeleteGraphDialog] = useState(false);
  const [graphToDelete, setGraphToDelete] = useState('');
  const createAlert = useContext(SnackbarContext);

  return (
    <Grid container rowSpacing={3} columnSpacing={6.75}>
      {graphs.map((item: TGraphsIndustries, index) => (
        <Grid key={index} item width="350px">
          <Card
            sx={{
              cursor: 'pointer',
              border: '1px solid white',
              borderTop: `1px solid ${purple['core1']}`,
              '&:hover': { border: `1px solid ${purple['core1']}` },
            }}
          >
            <CardHeader
              sx={{
                borderBottom: `1px solid ${grey[300]}`,
                pb: 1,
                mb: 1,
              }}
              title={item.graph_name}
            />
            <CardContent>
              {/* <Typography pb={2}></Typography>
              <Typography color={grey[600]}>Last updated</Typography>
              <Grid container justifyContent="space-between">
                <Typography>9 Sept 2023</Typography>
                <Typography></Typography>
            </Grid> */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="bottom"
              >
                <Grid item minHeight="73px">
                  {item.industry.length > 1 ? (
                    <GraphsIndustriesList
                      graph_name={item.graph_name}
                      industry={item.industry}
                    />
                  ) : (
                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      style={{ borderRadius: '7px' }}
                      onClick={
                        item.industry.length === 1
                          ? () =>
                              navigate(
                                `/graph/${item.graph_name}/industry_name/${item.industry[0]}`,
                              )
                          : item.industry.length === 0
                            ? () => {
                                createAlert({
                                  message: `'${item.graph_name}' has no content`,
                                  severity: 'error',
                                });
                              }
                            : () => {}
                      }
                    >
                      <b>Industry: </b>&nbsp;{' '}
                      {item.industry.length > 0 ? item.industry : ' no content'}
                    </Button>
                  )}
                </Grid>
                <Grid item marginBottom="0">
                  <DeleteGraphButton
                    setOpen={setShowDeleteGraphDialog}
                    trashIcon={true}
                    trashIconColor="secondary"
                    graphName={item.graph_name}
                    setGraphToDelete={setGraphToDelete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <DeleteGraphDialog
        open={showDeleteGraphDialog}
        setOpen={setShowDeleteGraphDialog}
        graphName={graphToDelete}
      />
    </Grid>
  );
}
