import SearchOffIcon from '@mui/icons-material/SearchOff';

import { Container, Content, Title } from './NotFound.styles';

interface NothingToSeeHereProps {
  itemNotFound: string;
  backgroundColor?: string;
}
export default function NotFound({
  itemNotFound,
  backgroundColor,
}: NothingToSeeHereProps) {
  return (
    <Container backgroundColor={backgroundColor ?? '#f2f2f2'}>
      <Content>
        <SearchOffIcon sx={{ width: '65px', height: '65px' }} />
        <Title>No {itemNotFound} found.</Title>
      </Content>
    </Container>
  );
}
