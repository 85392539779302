import PrivReq from 'api/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { ICategories } from '../interfaces';

export default function getCategories(): Promise<{
  categories: ICategories[];
  status: string;
}> {
  const Categories = async () =>
    PrivReq({
      method: 'get',
      url: `/getconfigurable`,
    })
      .then((response: AxiosResponse) => {
        return {
          categories: response.data,
          status: 'success, loading categories list',
        };
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          return {
            categories: [],
            status: 'There is an error fetching categories from server',
          };
        }
        return {
          categories: [],
          status: 'error on trying to load categories list',
        };
      });

  return Categories();
}
