import { Chip } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/system';
import theme from 'theme';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: 'black',
    fontFamily: 'Graphik',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '125%',
  },
  [`&.${tableCellClasses.body}`]: {
    color: 'black',
    fontFamily: 'Graphik',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    letterspacing: '0.16px',
  },
}));

export const StyledChip = styled(Chip)({
  backgroundColor: '#F7EBFF',
  color: '#7500C0',
  border: '#7500C0 solid 1px',
  fontSize: 14,
  fontFamily: 'Graphik',
  fontWeight: '400',
  letterSpacing: 0.16,
  wordWrap: 'break-word',
  padding: '4px',
});
