import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConfirmationDialog } from '../UI';
import {
  FormData,
  GenericObject,
  INewDocument,
  MetadataResponse,
  UpdateMetadataRequest,
} from 'types';
import GroupedAutocomplete from 'components/GroupedAutocomplete';
import { updateMetadata } from 'api';
import { SnackbarContext } from 'components/SnackbarProvider';

const INITIAL_FORM_STATE: FormData = {
  files: [] as [],
  metadata: [] as GenericObject[],
};

interface UpdateJobsDialogProps {
  open: boolean;
  collection: string;
  files: INewDocument[];
  selectedFileName: string | null;
  selectedFiles: string[];
  indexMetadata: GenericObject[];
  indexMandatoryMetadata: { [key: string]: string[] } | null;
  getFileList: () => void;
  onClose: () => void;
}

const UpdateJobsDialog = ({
  open,
  collection,
  files,
  selectedFileName,
  selectedFiles,
  indexMetadata,
  indexMandatoryMetadata,
  onClose,
  getFileList,
}: UpdateJobsDialogProps) => {
  const { index } = useParams();
  const createAlert = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_STATE);
  const [missingMandatoryMetadata, setMissingMandatoryMetadata] =
    useState<GenericObject | null>(null);

  const onSelectMetadata = (value: GenericObject[]) => {
    setFormData((prev) => ({ ...prev, metadata: value }));
  };

  const extractMetadata = useCallback(
    (rawMetadata: { [key: string]: string[] }) => {
      const categories = Object.keys(rawMetadata);
      const metadata = categories.flatMap((category) =>
        rawMetadata[category].map((subcategory) => ({
          category,
          subcategory,
        })),
      );
      return metadata;
    },
    [],
  );

  const checkMissingMandatoryMetadata = useCallback(() => {
    const selectedMetadata = (formData['metadata'] as GenericObject[]) ?? [];
    const missingData: GenericObject = {};

    for (const key in indexMandatoryMetadata) {
      const found = selectedMetadata.some((item) => item.category === key);
      if (!found) {
        missingData[key] = indexMandatoryMetadata[key].join(', ');
      }
    }

    const missingMetadata =
      Object.keys(missingData).length > 0 ? missingData : null;
    setMissingMandatoryMetadata(missingMetadata);
    return missingMetadata;
  }, [formData, indexMandatoryMetadata]);

  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    onClose();
  };

  const hanldeSubmit = () => {
    if (!index) return;
    const isMandatoryMetadataMissing = checkMissingMandatoryMetadata();
    if (isMandatoryMetadataMissing) return;

    const updateFileMetadata = async (fileName: string) => {
      const selectedMetadata = formData['metadata'] as GenericObject[];
      const metadata: MetadataResponse = {};

      const onSuccess = () => {
        createAlert({
          message: `The metadata of the file "${fileName}" has been successfully updated`,
          severity: 'success',
        });

        // Provisional
        getFileList();
      };

      const onError = () => {
        createAlert({
          message: `Unable to update metadata for file ${fileName}`,
          severity: 'error',
        });
      };

      selectedMetadata.forEach((item) => {
        const category = item.category;
        const exists = metadata[category] !== undefined;
        metadata[category] = exists
          ? [...metadata[category], item.subcategory]
          : [item.subcategory];
      });

      const data: UpdateMetadataRequest = {
        doc_name: fileName,
        index: collection,
        metadata,
      };

      await updateMetadata({ data, onSuccess, onError, onLoading: setLoading });
    };

    if (!selectedFileName) return;
    updateFileMetadata(selectedFileName);
    // selectedFiles.forEach(async (fileName) => updateFileMetadata(fileName));
  };

  const prepopulateForm = useCallback(() => {
    if (!selectedFileName) return;
    const selectedFile = files.find(
      (file) => file.doc_name === selectedFileName,
    );
    if (!selectedFile) return;
    const fileMetadata = selectedFile.metadata;
    if (!fileMetadata) return;
    const metadata = extractMetadata(fileMetadata);
    setFormData((prev) => ({ ...prev, metadata }));
  }, [files, selectedFileName, extractMetadata]);

  useEffect(() => {
    open && prepopulateForm();
  }, [open, selectedFiles, prepopulateForm]);

  return (
    <ConfirmationDialog
      title="Edit metadata"
      form
      size={'md'}
      open={open}
      leftButtonProps={{
        name: 'Cancel',
        color: 'inherit',
        variant: 'outlined',
        onClick: handleClose,
      }}
      rightButtonProps={{
        name: 'Update',
        variant: 'contained',
        color: 'secondary',
        type: 'submit',
        onClick: hanldeSubmit,
      }}
      content={
        <>
          <GroupedAutocomplete
            disabled={loading}
            value={formData['metadata'] as unknown as []}
            groupBy="category"
            error={!!missingMandatoryMetadata}
            helperText={`Please select one of the following options from the mandatory metadata categories. ${JSON.stringify(missingMandatoryMetadata)}`}
            getOptionLabel="subcategory"
            onChange={onSelectMetadata}
            options={indexMetadata}
          />
        </>
      }
    />
  );
};

export default UpdateJobsDialog;
