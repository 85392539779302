import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { grey } from 'theme';
import { AccentureLogo } from 'icons';

/**
 * The global site header component that appears on every page
 */
export default function SiteFooter(): React.JSX.Element {
  return (
    <Grid
      container
      id="site-footer"
      component="footer"
      alignItems="center"
      gap={2}
      p={2}
      wrap="nowrap"
      sx={{
        borderTop: `1px solid ${grey[400]}`,
        boxSizing: 'border-box',
        height: '60px',
      }}
    >
      <Grid item flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <AccentureLogo />
      </Grid>
      <Grid item flexShrink={0}>
        <Typography variant="subtitle1" component="p">
          &copy; 2001-2024 Accenture. All Rights Reserved. Powered By Accenture.
        </Typography>
      </Grid>
    </Grid>
  );
}
