import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { grey } from 'theme';

export const MessageBox = styled(Box)<{ user: number }>`
  background: ${(props) => (props.user ? 'transparent' : 'white')};
  white-space: pre-line;
  border: ${(props) =>
    props.user ? 'none' : 'box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'};
  border-radius: 5px;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const MessageContainer = styled(Box)`
  flex-grow: 1;
  overflow: auto;
  padding-right: 16px;
`;
