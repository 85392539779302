import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import PrivReq from 'api/axios';
import { SnackbarContext } from 'components/SnackbarProvider';
import { GraphIndustryContext } from 'components/GraphIndustryProvider';
import ComponentLoading from 'components/ComponentLoading';
import SecureTextField from 'components/SecureTextField';
import { containsSpecialChars } from 'common/utils/common';
import { Tab, Tabs, Typography, Autocomplete, InputLabel } from '@mui/material';
import SecureTextareaAutosize from 'components/SecureTextareaAutosize';
type UploadGraphDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ marginBottom: '0px' }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, marginBottom: '0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function UploadGraphDialog({
  open,
  setOpen,
}: Readonly<UploadGraphDialogProps>): React.JSX.Element {
  const [createLoading, setCreateLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [graphName, setGraphName] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [indexName, setIndexName] = useState('');
  const [graphRuleName, setGraphRuleName] = useState('');
  const [message, setMessage] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const createAlert = useContext(SnackbarContext);
  const { updateGraphs } = useContext(GraphIndustryContext);
  const [selectedRule, setSelectedRule] = useState<any>('');
  const [rulesList, setRulesList] = useState<string[]>([]);
  const defaultRule: {} = { name: 'Select Rules' };
  const [generatedGraphRule, setGeneratedGraphRule] = useState('');
  const [selectedPrompt, setSelectedPrompt] = useState<any>('');
  const [promptList, setPromptList] = useState<string[]>([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const file = new File(['empty-file'], 'empty-file.txt', {
    type: 'text/plain',
  });

  const handleDialogClose = () => {
    cleanState();
    setOpen(false);
  };

  const handleGraphNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setGraphName(event.target.value);
  };

  const handleIndustryNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIndustryName(event.target.value);
  };

  const handleIndexNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIndexName(event.target.value);
  };

  const handleGraphRuleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setGraphRuleName(event.target.value);
  };

  const handleInvalidChars = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isNotValidString = containsSpecialChars(event.target.value);
    if (isNotValidString) {
      setMessage(`The following characters are not allowed: \\/:*”’<>|`);
      return (event.target.value = '');
    }
    setMessage('');
    if (event.target.value.length > 100) {
      setMessage('Character Limit cannot exceed 100 characters.');
      return (event.target.value = '');
    }
  };

  const cleanState = () => {
    setGraphName('');
    setIndustryName('');
    setIndexName('');
    setSelectedFile(undefined);
    setGeneratedGraphRule('');
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage('uploading...');
    setCreateLoading(true);
    setSelectedFile(event.target?.files?.[0]);
    setMessage('');
    setCreateLoading(false);
  };

  const allowedXlsExtensions = ['xlsx'];

  // Function to check the file extension
  const checkFileExtension = (fileName: string): boolean => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return fileExtension ? allowedXlsExtensions.includes(fileExtension) : false;
  };

  const handleSelect = (value: any) => {
    setSelectedRule(value);
  };

  const handlePromptSelect = (value: any) => {
    setSelectedPrompt(value);
  };
  const handleGeneratedGraphRuleChange = (value: string) => {
    setGeneratedGraphRule(value);
  };

  const handleCreateNeRule = (selectedFile: File) => {
    setMessage('');
    setCreateLoading(true);
    if (selectedFile && checkFileExtension(selectedFile.name) === true) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      if (selectedPrompt.prompt_name != null) {
        formData.append('prompt_name', selectedPrompt.prompt_name);
        formData.append('type', selectedPrompt.type);
      }
      PrivReq({
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        method: 'post',
        url: '/generaterulesfromfile',
        data: formData,
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            setTimeout(() => {
              setGeneratedGraphRule(response.data.data);
              setCreateLoading(false);
              //cleanState();
              createAlert({
                message: `New rules generated`,
                severity: 'success',
              });
            }, 2000);
          } else {
            cleanState();
            setCreateLoading(false);
            createAlert({
              message: `Failed to generate new rule`,
              severity: 'error',
            });
          }
        })
        .catch((error: AxiosError) => {
          cleanState();
          setCreateLoading(false);
          createAlert({
            message: `Unable to create new rule`,
            severity: 'error',
          });
        });
    } else {
      createAlert({
        message: `Please select excel file to upload`,
        severity: 'error',
      });
      cleanState();
      setCreateLoading(false);
    }
  };

  const handleUpload = (selectedFile: File) => {
    setMessage('');
    setCreateLoading(true);
    if (graphName.length > 1 && industryName.length > 1 && selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('graph_name', graphName);
      formData.append('industry_name', industryName);

      if (indexName.length > 1) {
        formData.append('index', indexName);
      }

      if (checkFileExtension(selectedFile.name) === true) {
        if (selectedRule && selectedRule.name.length > 0) {
          formData.append('graph_rule_name', selectedRule.name);
        }

        if (generatedGraphRule && generatedGraphRule.length > 0) {
          const generatedGraphRuleAlt = generatedGraphRule
            .toString()
            .replaceAll("'", '"');
          formData.append('graph_rules', generatedGraphRuleAlt);
        }
      }

      PrivReq({
        method: 'post',
        url: '/uploadknowledgegraph',
        data: formData,
      })
        .then((response: AxiosResponse) => {
          if (response.data.status.toLowerCase() === 'success') {
            setTimeout(() => {
              updateGraphs();
              setCreateLoading(false);
              handleDialogClose();
              cleanState();
              //   loadRulesList();
              createAlert({
                message: `Graph '${graphName}' created`,
                severity: 'success',
              });
            }, 2000);
          } else {
            cleanState();
            setCreateLoading(false);
            createAlert({
              message: `Unable to create ${graphName} graph: ${response.statusText}`,
              severity: 'error',
            });
          }
        })
        .catch((error: AxiosError) => {
          cleanState();
          setCreateLoading(false);
          createAlert({
            message: `Unable to create ${graphName} graph`,
            severity: 'error',
          });
        });
    } else {
      cleanState();
      setMessage('All fields are required');
      setCreateLoading(false);
    }
  };
  const loadRulesList = () => {
    PrivReq({
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      method: 'get',
      url: '/getkgrules',
    })
      .then((response: AxiosResponse) => {
        if (response.data.status.toLowerCase() === 'success') {
          setTimeout(() => {
            //let data = JSON.parse(response.data.data);
            setRulesList(response.data);

            //setCreateLoading(false);
            //handleDialogClose();
            //cleanState();
          }, 2000);
        } else {
        }
      })
      .catch((error: AxiosError) => {
        cleanState();
        setCreateLoading(false);
        createAlert({
          message: `Unable to create '${graphName}' graph`,
          severity: 'error',
        });
      });
  };

  const loadPromptList = () => {
    PrivReq({
      method: 'get',
      url: '/getkgpromptlist/generate',
    })
      .then((response: AxiosResponse) => {
        if (response.data.status.toLowerCase() === 'success') {
          setTimeout(() => {
            //let data = JSON.parse(response.data.data);
            setPromptList(response.data);

            //setCreateLoading(false);
            //handleDialogClose();
            //cleanState();
          }, 2000);
        } else {
        }
      })
      .catch((error: AxiosError) => {
        cleanState();
        setCreateLoading(false);
        createAlert({
          message: `Could not get prompt list`,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    loadRulesList();
    loadPromptList();
  }, []);

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Create new <b>graph</b>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="from .JSON file" {...a11yProps(0)} />
            <Tab label="from .XLSX file" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <SecureTextField
            autoFocus
            margin="dense"
            id="graphname"
            label="Graph name"
            type="text"
            fullWidth
            variant="outlined"
            value={graphName}
            onUpdate={(
              value,
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              handleInvalidChars(event);
              handleGraphNameChange(event);
            }}
          />
          <SecureTextField
            autoFocus
            margin="dense"
            id="industryname"
            label="Industry name"
            type="text"
            fullWidth
            variant="outlined"
            value={industryName}
            onUpdate={(
              value,
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              handleIndustryNameChange(event);
            }}
          />
          <SecureTextField
            autoFocus
            margin="dense"
            id="indexname"
            label="Collection name (optional)"
            type="text"
            fullWidth
            variant="outlined"
            value={indexName}
            onUpdate={(
              value,
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              handleIndexNameChange(event);
            }}
          />

          <Button
            fullWidth
            component="label"
            variant="outlined"
            color="secondary"
            disableElevation
            startIcon={selectedFile ? <CloudDoneIcon /> : <CloudUploadIcon />}
          >
            {selectedFile
              ? selectedFile.name + ' uploaded.'
              : "Upload '.json' file"}
            <VisuallyHiddenInput
              type="file"
              accept=".csv, .json"
              ref={inputRef}
              onChange={handleFileUpload}
            />
          </Button>
          <p style={{ color: '#CB2939' }}>{message}</p>
          {createLoading && <ComponentLoading />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SecureTextField
            autoFocus
            margin="dense"
            id="graphname"
            label="Graph name"
            type="text"
            fullWidth
            variant="outlined"
            value={graphName}
            onUpdate={(
              value,
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              handleInvalidChars(event);
              handleGraphNameChange(event);
            }}
          />
          <SecureTextField
            autoFocus
            margin="dense"
            id="industryname"
            label="Industry name"
            type="text"
            fullWidth
            variant="outlined"
            value={industryName}
            onUpdate={(
              value,
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              handleInvalidChars(event);
              handleIndustryNameChange(event);
            }}
          />
          {/*
          <SecureTextField
            autoFocus
            margin="dense"
            id="graph_rule_name"
            label="Config/Rules name (Required in case of excel)"
            type="text"
            fullWidth
            variant="outlined"
            value={graphRuleName}
            sanitizerMode={true}
            onUpdate={(event) => handleGraphRuleNameChange(event)}
          />
          */}
          <InputLabel sx={{ fontSize: 16 }}>
            Rules (Rules to convert excel to graph)
          </InputLabel>
          <Autocomplete
            fullWidth
            clearOnBlur
            handleHomeEndKeys
            selectOnFocus
            disabled={createLoading || rulesList.length < 1}
            id="autocomplete-rules-select"
            value={selectedRule}
            options={rulesList}
            getOptionLabel={(option) => option.name || ''}
            defaultValue={'Select Rules'}
            onChange={(e, value) => handleSelect(value ?? 'None')}
            renderInput={(params) => (
              <SecureTextField
                {...params}
                label={'Select Rules'}
                InputLabelProps={{ shrink: false }}
                variant="outlined"
                style={{ background: 'white', borderRadius: '5px' }}
              />
            )}
          />
          <InputLabel sx={{ fontSize: 16 }}>
            Or create new rules with excel file
          </InputLabel>
          <Autocomplete
            fullWidth
            clearOnBlur
            handleHomeEndKeys
            selectOnFocus
            disabled={createLoading || rulesList.length < 1}
            id="autocomplete-prompt-select"
            value={selectedPrompt}
            options={promptList}
            getOptionLabel={(option) => option.prompt_name || ''}
            defaultValue={'Select Prompt'}
            onChange={(e, value) => handlePromptSelect(value ?? 'None')}
            renderInput={(params) => (
              <SecureTextField
                {...params}
                label={'Select Prompt'}
                InputLabelProps={{ shrink: false }}
                variant="outlined"
                style={{ background: 'white', borderRadius: '5px' }}
              />
            )}
          />
          <Button
            fullWidth
            component="label"
            variant="outlined"
            color="secondary"
            disableElevation
            disabled={createLoading || !selectedFile}
            onClick={() => handleCreateNeRule(selectedFile!)}
          >
            Generate New Rule
          </Button>
          <SecureTextareaAutosize
            autoFocus
            minRows={10}
            maxRows={13}
            minLength={300}
            style={{
              width: '100%',
              marginTop: 10,
              display: generatedGraphRule !== '' ? 'block' : 'none',
            }}
            contentEditable
            value={generatedGraphRule}
            onUpdate={(value) => handleGeneratedGraphRuleChange(value)}
          />
          <Button
            fullWidth
            component="label"
            variant="outlined"
            color="secondary"
            disableElevation
            startIcon={selectedFile ? <CloudDoneIcon /> : <CloudUploadIcon />}
          >
            {selectedFile && selectedFile.name.indexOf('xlsx')
              ? selectedFile.name + ' uploaded.'
              : "Upload '.xlsx' file"}
            <VisuallyHiddenInput
              type="file"
              accept=".xlsx"
              ref={inputRef}
              onChange={handleFileUpload}
            />
          </Button>
          <p style={{ color: '#CB2939' }}>{message}</p>
          {createLoading && <ComponentLoading />}
        </CustomTabPanel>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={createLoading}
          onClick={() => handleUpload(selectedFile!)}
        >
          Create graph
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type UploadGraphButtonProps = {
  setOpen: (value: boolean) => void;
};

/**
 * An optional styled button to use to open the UploadGraphDialog component
 */
export function UploadGraphButton({
  setOpen,
}: Readonly<UploadGraphButtonProps>): React.JSX.Element {
  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={<AddIcon />}
      onClick={() => setOpen(true)}
    >
      Upload Graph
    </Button>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
