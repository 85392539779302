import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from 'api/axios';
import config from 'config';
import axios from 'axios';
import {
  IProfile,
  IToken,
  IProperty,
  GetSummaryRequest,
  ChatCompletionRequest,
  SearchRequest,
  SearchVectorsRequest,
  SearchAndGenerateRequest,
  RBACControlResponse,
  RBACControlRequest,
  GetFrequentTasksResponse,
  MetadataResponse,
  UpdateMetadataRequest,
} from 'types';
import { formatResponseAsChatCompletion } from 'common/utils/getResponse';

interface BaseResponse<T = any> {
  error?: string;
  success?: string;
  data?: T;
}

interface ResponseData {
  output_summary: string;
  batch_summaries: Array<{ chunk: string; chunk_summary: string }>;
}

export interface ResponseWithIndex {
  summary_index: string;
  summary_data: ResponseData;
}

export type SummaryDTO = ResponseData | ResponseWithIndex;

export interface ChatCompletionDTO {
  type: string;
  content: string;
  source?: string;
  timestamp?: string;
  id?: string;
}

export type SearchDTO = string[];

export type SearchVectorsDTO = string[];

const errorHandler = (error: AxiosError<any>): string => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
      case 403:
        return `Error: ${error.message}`;
      default:
        return `Error: ${error.response.data?.error ?? 'Unknown Error.'}`;
    }
  } else if (error.request) {
    return `Error: ${error.message}`;
  } else {
    return `Error: ${error.message}`;
  }
};

export const showError = (error: AxiosError | unknown) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }
};

export const getTokenAPI = async (): Promise<{ data: IToken }> => {
  try {
    const url = `${config.urls.base}/${config.urls.api.auth}/short-token`;
    const { data } = await axios.post<IToken>(
      url,
      config.environment === 'prod'
        ? {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        : {
            username: config.constant.auth.username,
            password: config.constant.auth.password,
          },
    );
    return { data };
  } catch (error) {
    const { protocol, host } = window.location;
    const signInUrl = `${protocol}//${host}/${config.urls.redirect.signin}`;
    if (window.location.href !== signInUrl) {
      // Check href before redirecting to prevent redirect loop when there is an issue with auth servers
      window.location.assign(signInUrl);
    }
    return { data: '' } as unknown as { data: IToken };
  }
};

export const logoutAPI = async (isSamlUser: boolean): Promise<any> => {
  function isValidCookieName(text: string) {
    const invalidCharRegex = /[^A-Za-z0-9_]/;
    return !invalidCharRegex.test(text);
  }

  function deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const cookieName = name.replaceAll(' ', '');
      if (!isValidCookieName(cookieName)) return;
      document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  const samlLogout = async () => {
    try {
      const logoutUrl = `${config.urls.base}/${config.urls.api.authSAML}/logout`;
      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', logoutUrl);
      form.style.display = 'none';
      document.body.appendChild(form);
      form.submit();
      deleteAllCookies();
      sessionStorage.clear();
    } catch (e) {
      showError(e);
    }
  };

  const localLogout = async () => {
    const { protocol, host } = window.location;
    const url = `${config.urls.base}/${config.urls.api.auth}/logout`;
    await axios.post(url, null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    sessionStorage.clear();
    localStorage.removeItem('apiToken');
    window.location.assign(
      `${protocol}//${host}/${config.urls.redirect.signout}`,
    );
  };

  isSamlUser ? await samlLogout() : await localLogout();
};

export const getTokenPropertiesAPI = async (
  apiToken: string,
): Promise<BaseResponse<IProperty>> => {
  try {
    const url = `${config.urls.base}/${config.urls.api['identity-management']}/auth/token/properties`;
    const { data } = await axiosInstance.post(
      url,
      { token: apiToken },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const getUserPropertiesAPI = async (
  user: string,
): Promise<BaseResponse<IProperty>> => {
  try {
    const url = `${config.urls.base}/${config.urls.api['identity-management']}/users/${user}`;
    const { data } = await axiosInstance.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const getProfileAPI = async (
  apiToken: string,
  username: string | undefined,
): Promise<BaseResponse<IProfile>> => {
  if (username === undefined) {
    return {};
  }
  try {
    const url = `${config.urls.base}/${config.urls.api['identity-management']}/users/${username}`;
    const { data } = await axiosInstance.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const updateProfileAPI = async (
  apiToken: string,
  body: any,
): Promise<BaseResponse> => {
  try {
    const url = `${config.urls.base}/${config.urls.api['identity-management']}/users/${body.id}`;
    const { data } = await axiosInstance.put(url, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const summarizeDocumentAPI = async (
  apiToken: string,
  requestData: GetSummaryRequest,
): Promise<BaseResponse<SummaryDTO>> => {
  try {
    const body = JSON.stringify(requestData);
    const url = `${config.urls.host}/${config.urls.api.quasar}/summarizedocument`;
    const { data } = await axiosInstance.post(url, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const listIndexesAPI = async (
  apiToken: string,
): Promise<BaseResponse> => {
  try {
    const url = `${config.urls.host}/${config.urls.api.quasar}/listindexes`;
    const { data } = await axiosInstance.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const chatCompletionAPI = async (
  apiToken: string,
  requestData: ChatCompletionRequest,
): Promise<BaseResponse<ChatCompletionDTO>> => {
  try {
    const body = requestData;
    const url = `${config.urls.host}/${config.urls.api.quasar}/chatcompletion`;
    const { data } = await axiosInstance.post(url, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    return { data };
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const searchAPI = async (
  apiToken: string,
  requestData: SearchRequest,
): Promise<BaseResponse<ChatCompletionDTO>> => {
  try {
    const body = requestData;
    const apiUrl = config.urls.api.quasar;
    const url = `${config.urls.host}/${apiUrl}/search`;

    const { data } = await axiosInstance.post(url, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    const response = formatResponseAsChatCompletion(data);
    return response;
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const searchVectorsAPI = async (
  apiToken: string,
  requestData: SearchVectorsRequest,
): Promise<BaseResponse<ChatCompletionDTO>> => {
  try {
    const body = requestData;
    const url = `${config.urls.host}/${config.urls.api.quasar}/searchvectors`;
    const { data } = await axiosInstance.post(url, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    const response = formatResponseAsChatCompletion(data);
    return response;
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const searchAndGenerate = async (
  apiToken: string,
  requestData: SearchAndGenerateRequest,
): Promise<BaseResponse<ChatCompletionDTO>> => {
  try {
    const body = requestData;
    const url = `${config.urls.host}/${config.urls.api.quasar}/searchandgenerate`;
    const { data } = await axiosInstance.post(url, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiToken,
      },
    });
    const response = formatResponseAsChatCompletion(data);
    return response;
  } catch (error: any) {
    return { error: errorHandler(error) };
  }
};

export const getRbacControl = async ({
  index,
  onSuccess,
  onError,
  onLoading,
}: {
  index: string;
  onSuccess?: (data: RBACControlResponse) => void;
  onError?: (error: AxiosError) => void;
  onLoading?: (isLoading: boolean) => void;
}) => {
  onLoading && onLoading(true);
  const url = `${config.urls.host}/${config.urls.api.quasar}/rbaccontrol/${index}`;

  await axiosInstance({ method: 'get', url })
    .then((response: AxiosResponse) => {
      const data: RBACControlResponse = response.data;
      onSuccess && onSuccess(data);
    })
    .catch((error: AxiosError) => {
      onError && onError(error);
    })
    .finally(() => onLoading && onLoading(false));
};

export const updateRbacControl = async ({
  data,
  onSuccess,
  onError,
  onLoading,
}: {
  data: RBACControlRequest;
  onSuccess?: (data: string) => void;
  onError?: (error: AxiosError) => void;
  onLoading?: (isLoading: boolean) => void;
}) => {
  onLoading && onLoading(true);
  const url = `${config.urls.host}/${config.urls.api.quasar}/rbaccontrol/${data.index_name}`;

  await axiosInstance({ method: 'post', url, data })
    .then((response: AxiosResponse) => {
      const data: string = response.data;
      onSuccess && onSuccess(data);
    })
    .catch((error: AxiosError) => {
      onError && onError(error);
    })
    .finally(() => onLoading && onLoading(false));
};

export const getFequentTask = async ({
  list,
  onSuccess,
  onError,
  onLoading,
}: {
  list: 'tasks' | 'jobs';
  onSuccess?: (
    data: GetFrequentTasksResponse | React.SetStateAction<any>,
  ) => void;
  onError?: (error: AxiosError) => void;
  onLoading?: (isLoading: boolean | React.SetStateAction<any>) => void;
}) => {
  onLoading && onLoading(true);
  const url = `/getjobdata`;
  // const url = `/getfrequenttasks`;

  await axiosInstance({ method: 'get', url })
    .then((response: AxiosResponse) => {
      const data: GetFrequentTasksResponse = response.data;
      if (onSuccess) {
        const taskList = data.filter((item) => !item.input_collections);
        const jobList = data.filter((item) => item.input_collections);
        list === 'tasks' && onSuccess(taskList);
        list === 'jobs' && onSuccess(jobList);
      }
    })
    .catch((error: AxiosError) => {
      onError && onError(error);
      showError(error);
    })
    .finally(() => onLoading && onLoading(false));
};

export const getAllMetadata = async ({
  onSuccess,
  onError,
  onLoading,
}: {
  onSuccess?: (data: MetadataResponse | React.SetStateAction<any>) => void;
  onError?: (error: AxiosError) => void;
  onLoading?: (isLoading: boolean | React.SetStateAction<any>) => void;
}) => {
  onLoading && onLoading(true);
  const url = `/metadata`;

  await axiosInstance({ method: 'get', url })
    .then((response: AxiosResponse) => {
      const data: MetadataResponse = response.data;
      onSuccess && onSuccess(data);
    })
    .catch((error: AxiosError) => {
      onError && onError(error);
      showError(error);
    })
    .finally(() => onLoading && onLoading(false));
};

export const updateMetadata = async ({
  data,
  onSuccess,
  onError,
  onLoading,
}: {
  data: UpdateMetadataRequest;
  onSuccess?: (data: React.SetStateAction<any>) => void;
  onError?: (error: AxiosError) => void;
  onLoading?: (isLoading: boolean | React.SetStateAction<any>) => void;
}) => {
  onLoading && onLoading(true);
  const url = `/updatedocmetadata`;

  await axiosInstance({ method: 'post', url, data })
    .then((response: AxiosResponse) => {
      const data = response.data;
      onSuccess && onSuccess(data);
    })
    .catch((error: AxiosError) => {
      onError && onError(error);
      showError(error);
    })
    .finally(() => onLoading && onLoading(false));
};
