export const formatResponseAsChatCompletion = (
  data: any,
  api?: 'serachvectors' | 'searchandgenerate' | 'searchV2' | 'searchV3',
) => {
  const response = {
    type: 'text',
    content: '',
    source: '',
  };
  const isAnArray = data.constructor === Array;

  const getDataFromObj = () => {
    response.content = data.content;
    response.source = data.source ?? '';
  };

  const getDataFromArray = () => {
    const isEmptyArray = data.length === 0;
    if (isEmptyArray) {
      throw new Error(
        'Sorry, something has gone wrong or the information that was requested could not be found',
      );
    }

    const isStringArray = data[0].content === undefined;

    if (isStringArray) {
      data.forEach((elem: string) => (response.content += elem + '\n\n'));
      const content = response.content;
      const singleSource = content.indexOf(`\nSource:`) + 1;
      const multipleSources = content.indexOf(`\nSources:`) + 1;
      const sourceIndex = singleSource > 0 ? singleSource : multipleSources;
      const isSourceInsideTheContent = sourceIndex > 0;
      if (!isSourceInsideTheContent) return;
      response.source = content.substring(sourceIndex);
      response.content = content.substring(0, sourceIndex);
      return;
    }

    data.forEach(
      (message: { content: string }) =>
        (response.content += message.content + '\n\n'),
    );
    response.source = data[0].metadata?.file_name ?? data[0].source ?? '';
  };

  isAnArray ? getDataFromArray() : getDataFromObj();

  return { data: response };
};
