import React, { createContext, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import axios from 'api/axios';

export type TGraphsIndustries = {
  graph_name: string;
  industry: string[];
};

type GraphIndustryContextProps = {
  graphsIndustries: TGraphsIndustries[];
  loadingGraphs: boolean;
  updateGraphs: () => void;
  removeGraph: (graph: TGraphsIndustries[]) => void;
};

export const GraphIndustryContext = createContext<GraphIndustryContextProps>({
  graphsIndustries: [],
  loadingGraphs: true,
  updateGraphs: () => {},
  removeGraph: (_) => {},
});

type GraphIndustryProviderProps = {
  /**
   * The page component that will be rendered inside the global Page component template
   */
  children: React.JSX.Element;
};

/**
 * Provides the list of available graphsIndustries to the app
 */
export default function GraphsIndustriesProvider({
  children,
}: Readonly<GraphIndustryProviderProps>): React.JSX.Element {
  const [now, setNow] = useState(Date.now());
  const [graphsIndustries, setGraphsIndustries] = useState<TGraphsIndustries[]>(
    [],
  );
  const [loadingGraphs, setLoadingGraphs] = useState(true);
  const updateGraphs = () => {
    setNow(Date.now());
  };
  const removeGraph = (graph: TGraphsIndustries[]) => {
    setGraphsIndustries((graphs) => {
      // const newGraphs = graphs.filter((item) => item !== graphs);
      const newGraphs = graphs;
      return newGraphs;
    });
  };

  const getGraphs = async () => {
    await axios({
      method: 'get',
      url: '/listgraphs/industry',
    })
      .then((response: AxiosResponse) => {
        setGraphsIndustries(response.data);
        setLoadingGraphs(false);
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
        setLoadingGraphs(false);
      });
  };

  useEffect(() => {
    setLoadingGraphs(true);
    getGraphs();
  }, [now]);

  return (
    <GraphIndustryContext.Provider
      value={{
        graphsIndustries,
        loadingGraphs,
        updateGraphs,
        removeGraph,
      }}
    >
      {children}
    </GraphIndustryContext.Provider>
  );
}
