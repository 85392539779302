import { styled } from '@mui/system';

export const ListContainerStyled = styled('div')`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  position: absolute;
  z-index: 2;
  bottom: 64px;
`;

export const ButtonGroupStyled = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: center;
  width: fit-content;
`;

export const ButtonStyled = styled('button')<{
  color?: string;
  center?: boolean;
}>`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;
  border: 1px solid #cccbce;
  border-left: ${(props) => (props.center ? '0px solid #CCCBCE' : 'null')};
  border-right: ${(props) => (props.center ? '0px solid #CCCBCE' : 'null')};
  box-sizing: border-box;
  cursor: pointer;
  color: ${(props) => props.color ?? '#03000f'};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Graphik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  background-color: #fff;
`;
