import React, { useContext, useState } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import AsteriskIcon from 'components/MetadataCard/Assets/AsteriskIcon';
import AsteriskOutlined from 'components/MetadataCard/Assets/AsteriskOutlinedIcon';
import { Column, IMetadataSettings } from 'components/MetadataSettings';
import {
  Card,
  CardHeader,
  HeaderTitle,
  CardMenu,
  MenuItem,
  ItemText,
  CardFooter,
  FooterAction,
} from './MetadataCard.styles';
import {
  indexChangeMandatory,
  updateIndexMetadatas,
} from 'components/MetadataProvider/MetadaProvider';
import { SnackbarContext } from 'components/SnackbarProvider';
import { MAX_METADATA_LIMIT } from 'components/CreateCollectionDialog';
import { shorten } from 'common/utils';

type MetadataCardProps = {
  column: Column;
  setMetadataSettings: React.Dispatch<React.SetStateAction<IMetadataSettings>>;
  index: string;
  updateList: () => void;
  isMetadataLimitReached: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MetadataCard({
  column,
  setMetadataSettings,
  index,
  updateList,
  isMetadataLimitReached,
  setLoading,
}: MetadataCardProps): React.JSX.Element {
  const createAlert = useContext(SnackbarContext);
  const [showCard, setShowCard] = useState<boolean>(false);

  const handleHideColumn = () => {
    if (isMetadataLimitReached && !column.status) {
      createAlert({
        severity: 'error',
        message: `Maximum limit of selected items reached (${MAX_METADATA_LIMIT}). Try deselecting any of the current ones first`,
      });
      return;
    }
    setLoading(true);
    updateIndexMetadatas(index, column.name, column.status, column.mandatory)
      .then((response) => {
        if (response) updateList();
      })
      .catch((err) => updateList());
  };

  const handleEditColumn = ({
    key,
    status,
  }: {
    key: number;
    status: boolean;
  }) => {
    setMetadataSettings((prev) =>
      prev.map((column) => {
        if (column.key === key) {
          return { ...column, edit: status };
        }
        return column;
      }),
    );
  };

  const handleDeleteItem = (key: number) => {
    let deleteItem;
    setMetadataSettings((prev) => {
      deleteItem = prev.filter((column) => column.key === key);
      return prev.filter((column) => column.key !== key);
    });
  };

  const handleMandatory = (key: number) => {
    indexChangeMandatory(index, column.name, column.mandatory)
      .then((_result) =>
        createAlert({
          severity: 'success',
          message: `Metadata updated with sucess.`,
        }),
      )
      .catch((_err) =>
        createAlert({
          severity: 'error',
          message: `Error on update Metadata.`,
        }),
      )
      .finally(() => {
        updateList();
      });
  };

  return (
    <Card>
      <CardHeader>
        <Grid
          onClick={() => setShowCard(!showCard)}
          sx={{
            flexGrow: 1,
            ':hover': { cursor: 'pointer' },
          }}
        >
          <Tooltip title={column.name}>
            <HeaderTitle>{`${shorten(column.name, 25)} (${column.items.length})`}</HeaderTitle>
          </Tooltip>
        </Grid>
        <Grid>
          <IconButton
            onClick={() => {
              handleMandatory(column.key);
            }}
            sx={{ display: column.status ? '' : 'none' }}
          >
            {column.mandatory ? (
              <AsteriskIcon title="Mandatory" />
            ) : (
              <AsteriskOutlined title="Non-Mandatory" />
            )}
          </IconButton>
          <IconButton onClick={handleHideColumn}>
            {column.status ? (
              <RemoveIcon fontSize="medium" titleAccess="Remove from index" />
            ) : (
              <AddIcon fontSize="medium" titleAccess="Add to index" />
            )}
          </IconButton>
        </Grid>
      </CardHeader>
      <CardMenu sx={{ display: showCard ? '' : 'none' }}>
        {column.items.map((item) => (
          <MenuItem key={item}>
            <Tooltip title={item}>
              <ItemText>{shorten(item, 23)}</ItemText>
            </Tooltip>
          </MenuItem>
        ))}
      </CardMenu>
      <CardFooter sx={{ display: 'none' }}>
        <FooterAction
          color="error"
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={() => handleDeleteItem(column.key)}
        >
          Delete
        </FooterAction>
        <FooterAction startIcon={<SortByAlphaOutlinedIcon />}>
          Sort
        </FooterAction>
        <FooterAction
          onClick={() =>
            handleEditColumn({
              key: column.key,
              status: true,
            })
          }
          startIcon={<EditOutlinedIcon />}
        >
          Edit
        </FooterAction>
      </CardFooter>
    </Card>
  );
}
