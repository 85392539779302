import React from 'react';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

/*
import local Nunito Sans font
*/
import './App.css';

import AppWrapper from 'components/AppWrapper';
import AuthProvider from 'components/AuthProvider';
import CollectionProvider from 'components/CollectionProvider';
import GraphIndustryProvider from 'components/GraphIndustryProvider';
import SnackbarProvider from 'components/SnackbarProvider';
import UploadProvider from 'components/FilesProvider';
import { ConfigurationProvider } from 'components/Page/ConfigPage/ConfigGeneral/ConfigurationProvider';
import Router from 'components/Router';
import theme from 'theme';
import { SavedSearchesProvider } from 'common/utils/savedSearchesContext';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <AppWrapper>
          <SnackbarProvider>
            <CollectionProvider>
              <GraphIndustryProvider>
                <UploadProvider>
                  <SavedSearchesProvider>
                    <ConfigurationProvider>
                      <RouterProvider router={Router} />
                    </ConfigurationProvider>
                  </SavedSearchesProvider>
                </UploadProvider>
              </GraphIndustryProvider>
            </CollectionProvider>
          </SnackbarProvider>
        </AppWrapper>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
