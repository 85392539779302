import { styled } from '@mui/system';

export const ChunksPreviewStyled = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;

export const ImageExtractedTextStyled = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #888;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button:vertical:start:increment {
    display: none;
  }
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
  }
`;
