import { Dot, Typing } from './ThreeDotsLoading.styles';

const ThreeDotsLoading = () => (
  <div>
    <Typing>
      <Dot
        style={{
          animationDelay: '0s',
        }}
      />
      <Dot
        style={{
          animationDelay: '0.2s',
        }}
      />
      <Dot
        style={{
          animationDelay: '0.4s',
        }}
      />
    </Typing>
  </div>
);

export default ThreeDotsLoading;
